import React from "react"
import ReactDOM from "react-dom"
import {fonts} from "@uprise/core"
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing"
import {Provider} from "react-redux"
import * as serviceWorker from "./serviceWorker"
import {Auth0Provider} from "@auth0/auth0-react"
import {oauth_client_id, oauth_domain, oauth_audience} from "./config/auth0-config"

// UI
import {ModalRoot} from "@uprise/modal"
// Store
import configureAppStore from "./app/store"
// History
import history from "./utils/history"

// base
import "./styles/base.css"
// Grid
import "./styles/bootstrap-grid-utils.css"
// Font
import "./assets/fonts/proxima-nova-web-fonts/fonts.css"

const {store} = configureAppStore()

const onRedirectCallback = async appState => {
	history.replace({
		pathname: appState?.returnTo || window.location.pathname,
		search: ""
	})
}

Sentry.init({
	dsn: "https://9aa234e113404c5cb534f9b16faca3af@o500414.ingest.sentry.io/5901333",
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.NODE_ENV,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
})

const render = () => {
	const App = require("./App").default

	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<Auth0Provider
					domain={oauth_domain}
					clientId={oauth_client_id}
					redirectUri={window.location.origin}
					audience={oauth_audience}
					onRedirectCallback={onRedirectCallback}
					scope='openid email app_metadata'>
					<App />
					<ModalRoot />
				</Auth0Provider>
			</Provider>
		</React.StrictMode>,
		document.getElementById("root")
	)
}

render()

// HMR - Create React App 4 / Webpack specific
if (process.env.NODE_ENV === "development" && module.hot) {
	module.hot.accept("./App", render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
