let jwt = null
let dispatch = null
const env = process.env.NODE_ENV || "development"
console.log("NODE_ENV in new crm", env, window.location.host)
export let BASE_URL = "//" + window.location.host
export let API_URL = "//" + window.location.host + "/api/"

if (env === "development") {
	API_URL = "//localhost:8080/api/"
	BASE_URL = "//localhost:8080"
}

if (window.location.host == "crm-us-v1.uprise.co") {
	BASE_URL = "//" + "us-v1.uprise.co"
	API_URL = "//" + "us-v1.uprise.co" + "/api/"
}

if (window.location.host == "testing-crm2.uprise.co") {
	BASE_URL = "//" + "testing-app.uprise.co"
	API_URL = "//" + "testing-app.uprise.co" + "/api/"
}

if (window.location.host === "testing-crm-us.uprise.co") {
	BASE_URL = "//" + "testing-us.uprise.co"
	API_URL = "//" + "testing-us.uprise.co" + "/api/"
}

if (window.location.host === "staging-crm-us.uprise.co") {
	BASE_URL = "//" + "staging-us.uprise.co"
	API_URL = "//" + "staging-us.uprise.co" + "/api/"
}

if (window.location.host === "10.0.2.2:3000") {
	API_URL = "//10.0.2.2:8080/api/"
	BASE_URL = "//10.0.2.2:8080"
}

export function setJwt(newJwt) {
	jwt = newJwt
}

export function setDispatch(fn) {
	dispatch = fn
}

export function apiCall(url, method = "GET", payload = {}, contentType, header = {}) {
	let headers = new Headers()

	headers.set("Content-Type", "application/json; charset=utf-8")
	// Hey developer, please don't remove these headers as they are the one keeping IE in leash
	// And, believe me you don't want IE to unleash
	headers.set("Cache-control", "private, max-age=0, no-cache, no-store")
	// headers.set("pragma", "no-cache")
	for (const [key, value] of Object.entries(header)) {
		// console.log("key", key)
		// console.log("value", value)
		headers.set(key, value)
	}

	let options = {
		method,
		headers,
		credentials: "include"
	}
	// console.log("options", options)
	if (method === "POST" || method === "PUT") {
		options.body = JSON.stringify(payload)
	}

	if (contentType === "text/html") {
		/* global Raven */
		return fetch(API_URL + url, options)
			.then(r => {
				return r.status === 200 ? r.blob() : r.json()
			})
			.catch(err => {
				console.log(err)
				throw err
			})
	} else {
		return fetch(API_URL + url, options)
			.then(r => {
				return r.text().then(text => {
					return text ? JSON.parse(text) : {}
				})
			})
			.catch(err => {
				console.log("fetch error", err)
				throw err
			})
			.then(r => {
				r.jwt && setJwt(r.jwt)
				return r
			})
	}
}

export function apiCallUpload(url, method = "GET", payload = {}, contentType, header = {}, optionArgs = {}) {
	let headers = new Headers()

	// headers.set("Content-Type", "application/json; charset=utf-8")
	// Hey developer, please don't remove these headers as they are the one keeping IE in leash
	// And, believe me you don't want IE to unleash
	// headers.set("Cache-control", "private, max-age=0, no-cache, no-store")
	// headers.set("pragma", "no-cache")
	for (const [key, value] of Object.entries(header)) {
		// console.log("key", key)
		// console.log("value", value)
		headers.set(key, value)
	}

	let options = {
		method,
		headers,
		credentials: "include",
		...optionArgs
	}
	// console.log("options", options)
	// if (method === "POST" || method === "PUT") {
	// 	options.body = JSON.stringify(payload)
	// }
	options.body = payload
	if (contentType === "text/html") {
		/* global Raven */
		return fetch(API_URL + url, options)
			.then(r => {
				return r.status === 200 ? r.blob() : r.json()
			})
			.catch(err => {
				console.log(err)
				throw err
			})
	} else {
		return fetch(API_URL + url, options)
			.then(r => {
				return r.text().then(text => {
					return text ? JSON.parse(text) : {}
				})
				// if (r) {
				// 	return r.json()
				// } else {
				// 	return r
				// }
			})
			.catch(err => {
				console.log("fetch error", err)
				throw err
			})
			.then(r => {
				r.jwt && setJwt(r.jwt)
				return r
			})
	}
}

export function put(url, payload, contentType, header = {}) {
	return apiCall(url, "PUT", payload, contentType, header)
}

export function post(url, payload, contentType, header = {}) {
	return apiCall(url, "POST", payload, contentType, header)
}

export function upload(url, payload, contentType, header = {}, options = {}) {
	return apiCallUpload(url, "POST", payload, contentType, header, options)
}

export function get(url, contentType, header = {}) {
	return apiCall(url, "GET", null, contentType, header)
}
