import React, {useState, useRef, useEffect} from "react"
import {useDispatch} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"
import {Modal} from "@uprise/modal"
import {useSelector} from "react-redux"
import {usePrevious} from "helpers/hooks"
// Uprise
import {Container, Col, Row} from "@uprise/grid"
import {H4, H5} from "@uprise/headings"
import {Button} from "@uprise/button"

// Components
import {Note} from "../Notes"
import {ConfirmModal} from "components/shared/ConfirmModal"
// Slices
import {fetchUserNotes, userDataSelector} from "../../Users/usersSlice"
// Styles
import {NoDataDiv, ButtonWrap, Content} from "./index.style"
import {Fragment} from "react"

export const Notes = ({userEmail, coachId}) => {
	const {notes, userNotesApiState} = useSelector(userDataSelector)
	const [userNotes, setUserNotes] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const [cancelIndex, setCancelIndex] = useState(null)

	const {getAccessTokenSilently} = useAuth0()
	const dispatch = useDispatch()

	const prevNotes = usePrevious(notes)

	async function handleFetchNotes() {
		const token = await getAccessTokenSilently()

		dispatch(fetchUserNotes({userEmail, coachId, token}))
	}

	useEffect(() => {
		handleFetchNotes()
	}, [])

	useEffect(() => {
		let updatedNotes = []
		if (notes.length) {
			updatedNotes =
				Notes &&
				notes?.map(note => {
					return {
						id: note.id,
						client: userEmail,
						coachId: note.coachId,
						title: note.title,
						content: note.content,
						type: "",
						createdAt: note.createdAt
					}
				})
		}

		setUserNotes(updatedNotes)
	}, [notes])

	const _handleNoteDelete = index => {
		setUserNotes(userNotes.splice(index, 1))
	}

	const _handleCancelModal = index => {
		setOpenModal(true)
		setCancelIndex(index)
	}

	const _handleCancel = () => {
		// assigning the list to temp variable
		const temp = [...userNotes]

		// removing the element using splice
		temp.splice(cancelIndex, 1)

		// updating the list
		setUserNotes(temp)
		setOpenModal(false)
		setCancelIndex(null)
	}

	const addNewNote = () => {
		setUserNotes(
			userNotes.concat([
				{
					client: userEmail,
					coachId: coachId,
					title: "",
					content: "",
					type: "new"
				}
			])
		)
	}

	return (
		<Container>
			<Row>
				<Col>
					<Content>
						<H4 className='m-t-3'>Notes</H4>

						{userNotes?.length ? (
							userNotes?.map((note, index) => (
								<Fragment>
									<Note
										key={note.id}
										note={note}
										userEmail={userEmail}
										handleCancel={() => _handleCancelModal(index)}
									/>
								</Fragment>
							))
						) : (
							<NoDataDiv>No saved notes</NoDataDiv>
						)}

						<Button
							className='m-t-10'
							title='Add note'
							variant='tertiary'
							size='large'
							onClick={() => addNewNote()}
						/>

						<ConfirmModal
							openModal={openModal}
							handleSubmit={() => _handleCancel()}
							handleClose={() => setOpenModal(false)}>
							Are you sure you want to cancel?
						</ConfirmModal>
					</Content>
				</Col>
			</Row>
		</Container>
	)
}
