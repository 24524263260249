import React, {useState, useEffect, Fragment} from "react"
import {Alert} from "@uprise/alert"
import {useDispatch, useSelector} from "react-redux"
import {P} from "@uprise/text"
import {Modal} from "@uprise/modal"
import {H4} from "@uprise/headings"
import {Container} from "@uprise/grid"

// Internal Dependancies
import General from "./General"
import Code from "./Code"
import Plan from "./Plan"
import UserUtilization from "./UserUtilization"
import {OptionMenuArray} from "./helpers/constants"

import {useAuth0} from "@auth0/auth0-react"

import {
	fetchEmployerByCode,
	employerSelector,
	updateEmployerDetailsApi,
	clearEmployerApiState,
	resetCapsApi
} from "../employerSlice"
import {Alerts} from "../Alerts"
import DropDown from "components/shared/DropDown"
import EditEmployerDetails from "./EditEmployerDetail"
import ResetCaps from "./ResetCaps"
import ComponentWrapper from "components/HOC/ComponentWrapper"
import GeneralIcon from "assets/images/icons/svg/icon-general.svg"
import PlanIcon from "assets/images/icons/svg/icon-plans.svg"

import {
	HeaderTableContent,
	EmployerButton,
	FormLevelContainer,
	FormLevel,
	Icon,
	FormLevelLabelContainer,
	FormLevelLabelHeader,
	FormLevelLabelText,
	ItemContainer,
	FormItem,
	ModalButtonContainer
} from "./index.style"
const EmployerDetails = ({history, match}) => {
	const dispatch = useDispatch()

	const [activeIndex, setActiveIndex] = useState(0)
	const [customOptionMenuArray, setCustomOptionMenuArray] = useState(OptionMenuArray)
	const [openModal, setOpenModal] = useState(false)
	const [showCapsModal, setShowCapsModal] = useState(false)

	const [isEdit, setEdit] = useState(false)

	const [token, setToken] = useState(null)
	const {getAccessTokenSilently} = useAuth0()
	const {
		selectedEmployerDetails,
		utilisationApiState,
		updateEmployerState,
		employerByCodeApiState,
		resetCapsApi: resetCapsApiState
	} = useSelector(employerSelector)

	useEffect(() => {
		if (match.params.code) {
			;(async () => {
				const token = await getAccessTokenSilently()
				setToken(token)
				dispatch(fetchEmployerByCode({code: match.params.code, token}))
			})()
		}

		// Clear state on unmount
		return () => dispatch(clearEmployerApiState())
	}, [])

	useEffect(() => {
		if (activeIndex === 1) {
			setCustomOptionMenuArray([
				...OptionMenuArray,
				{
					label: "Reset call cap",
					value: "reset",
					icon: "icon-edit"
				}
			])
		}
	}, [activeIndex])

	const onActionButtonClick = value => {
		if (value.value === "edit") {
			setOpenModal(true)
		} else if (value.value === "reset") {
			setShowCapsModal(true)
		}
	}

	const disableEdit = () => {
		setEdit(false)
	}

	const handleEmployerDetailUpdate = data => {
		dispatch(updateEmployerDetailsApi({data, token}))
		setOpenModal(false)
	}

	const handleResetCaps = data => {
		dispatch(resetCapsApi({data, token}))

		setShowCapsModal(false)
	}

	return (
		<ComponentWrapper
			menuActiveIndex={2}
			subMenuActiveIndex={9}
			headerTitle={`Employer: ${match.params.code}`}
			headerRightAlignContent={
				<HeaderTableContent>
					<EmployerButton onClick={() => history.push("/employers/new/form")} title={"New employer"} />
					<DropDown
						items={customOptionMenuArray.map(menuItem => {
							return {
								label: menuItem.label,
								value: menuItem.value
							}
						})}
						containerStyle={{
							listStyleType: "none",
							alignSelf: "center",
							marginRight: "12px",
							position: "relative",
							zIndex: "2"
						}}
						isLast={true}
						label='Actions'
						variant={"primary"}
						menuStyle={{position: "absolute"}}
						onChange={v => onActionButtonClick(v)}
					/>
				</HeaderTableContent>
			}>
			<Container style={{marginBottom: "15px"}}>
				{employerByCodeApiState.isSuccess && employerByCodeApiState.message && (
					<Alert className='m-b-5' textAlign='center' type={"success"}>
						{employerByCodeApiState.message}
					</Alert>
				)}

				{employerByCodeApiState.isError && employerByCodeApiState.message && (
					<Alert className='m-b-5' textAlign='center' type={"error"}>
						{employerByCodeApiState.message}
					</Alert>
				)}

				<Alerts updateEmployerState={updateEmployerState} resetCapsApi={resetCapsApiState} />

				{employerByCodeApiState?.isSuccess && (
					<Fragment>
						{selectedEmployerDetails?.data && (
							<Fragment>
								<FormLevelContainer data-testid='headerEmployerDetails'>
									<FormLevel onClick={() => setActiveIndex(0)} active={activeIndex === 0}>
										<FormLevelLabelContainer
											firstChild={true}
											active={activeIndex === 0}
											data-testid='general'>
											<Icon src={GeneralIcon} />
											{activeIndex === 0 ? (
												<FormLevelLabelHeader>General</FormLevelLabelHeader>
											) : (
												<FormLevelLabelText>General</FormLevelLabelText>
											)}
										</FormLevelLabelContainer>
									</FormLevel>

									<FormLevel
										onClick={() => setActiveIndex(1)}
										active={activeIndex === 1}
										data-testid='codeAndCaps'>
										<FormLevelLabelContainer active={activeIndex === 1}>
											<Icon src={GeneralIcon} />
											{activeIndex === 1 ? (
												<FormLevelLabelHeader>Code & Caps</FormLevelLabelHeader>
											) : (
												<FormLevelLabelText>Code & Caps</FormLevelLabelText>
											)}
										</FormLevelLabelContainer>
									</FormLevel>
									<FormLevel
										onClick={() => setActiveIndex(2)}
										active={activeIndex === 2}
										data-testid='planAndAddons'>
										<FormLevelLabelContainer active={activeIndex === 2}>
											<Icon src={PlanIcon} />
											{activeIndex === 2 ? (
												<FormLevelLabelHeader>Plan & Add-ons</FormLevelLabelHeader>
											) : (
												<FormLevelLabelText>Plan & Add-ons</FormLevelLabelText>
											)}
										</FormLevelLabelContainer>
									</FormLevel>

									<FormLevel
										onClick={() => setActiveIndex(3)}
										active={activeIndex === 3}
										data-testid='userUtilization'>
										<FormLevelLabelContainer lastChild={true} active={activeIndex === 3}>
											<Icon src={PlanIcon} />
											{activeIndex === 3 ? (
												<FormLevelLabelHeader>User Utilization</FormLevelLabelHeader>
											) : (
												<FormLevelLabelText>User Utilization</FormLevelLabelText>
											)}
										</FormLevelLabelContainer>
									</FormLevel>
								</FormLevelContainer>
								<ItemContainer>
									<Fragment>
										<FormItem active={activeIndex === 0} data-testid='generalData'>
											<General
												isEditEnabled={isEdit && activeIndex === 0}
												onEditCancel={disableEdit}
												data={selectedEmployerDetails?.data?.general}
											/>
										</FormItem>

										<FormItem active={activeIndex === 1} data-testid='codeAndCapsData'>
											<Code
												isEditEnabled={isEdit && activeIndex === 1}
												capDetails={selectedEmployerDetails.caps}
												employerCode={selectedEmployerDetails.code}
												expiryDate={selectedEmployerDetails.expiryDate}
												data={selectedEmployerDetails?.data?.code}
												onPrevious={() => setActiveIndex(0)}
											/>
										</FormItem>
										<FormItem active={activeIndex === 2} data-testid='planAndAddOnsData'>
											<Plan
												isEditEnabled={isEdit && activeIndex === 2}
												data={selectedEmployerDetails?.data}
												onPrevious={() => setActiveIndex(1)}
											/>
										</FormItem>

										<FormItem active={activeIndex === 3} data-testid='userUtilizationData'>
											<UserUtilization
												isEditEnabled={isEdit && activeIndex === 3}
												data={selectedEmployerDetails?.data}
												loading={utilisationApiState.isFetching}
												onPrevious={() => setActiveIndex(2)}
											/>
										</FormItem>
									</Fragment>
								</ItemContainer>
							</Fragment>
						)}
					</Fragment>
				)}
			</Container>
			<Modal
				isOpen={openModal}
				handleClose={() => setOpenModal(false)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"70%"}
				scroll={true}
				textAlign='center'>
				<H4>{`Edit Employer`}</H4>
				<ModalButtonContainer>
					<EditEmployerDetails
						capDetails={selectedEmployerDetails.caps}
						expiryDate={selectedEmployerDetails.expiryDate}
						employerCode={selectedEmployerDetails.code}
						data={selectedEmployerDetails?.data}
						onComplete={handleEmployerDetailUpdate}
						onCancel={() => setOpenModal(false)}
					/>
				</ModalButtonContainer>
			</Modal>
			<Modal
				isOpen={showCapsModal}
				handleClose={() => setShowCapsModal(false)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"70%"}
				overflow={"visible"}
				scroll={false}
				textAlign='center'>
				<H4>{`Reset Call Cap`}</H4>
				<ModalButtonContainer>
					<ResetCaps
						capDetails={selectedEmployerDetails.caps}
						startingDate={selectedEmployerDetails?.data?.contract?.dateSigned}
						planData={selectedEmployerDetails?.data?.plan}
						onComplete={handleResetCaps}
						onCancel={() => setShowCapsModal(false)}
						code={match.params.code}
					/>
				</ModalButtonContainer>
			</Modal>
		</ComponentWrapper>
	)
}

export default EmployerDetails
