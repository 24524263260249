import styled from "styled-components"
import {P} from "@uprise/text"
import {ProgressBar} from "@uprise/charts"
import {Container, Grid, Row, Col} from "@uprise/grid"

export const UploadContainer = styled(Container)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 175px;
	justify-content: space-between;
`

export const FileInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const FileInputLabel = styled.label`
	display: block;
	position: relative;
	width: 200px;
	height: 50px;
	border-radius: 25px;
	background: linear-gradient(40deg, #ff6ec4, #7873f5);
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: bold;
	cursor: pointer;
	transition: transform 0.2s ease-out;
`

export const FileInput = styled.input`
	opacity: 0;
	width: 0.1px;
	height: 0.1px;
	position: absolute;
`
export const SelectedFileText = styled(P)`
	margin-left: 10px;
`

export const UploadProgressBar = styled(ProgressBar)`
	margin-top: 20px;
`

export const ButtonContainer = styled.div`
	display: flex;
	width: 250px;
`
