import Styled from "styled-components"

import {text} from "@uprise/typography"
import {extended, primary} from "@uprise/colors"
import {spacing} from "@uprise/spacing"

export const Table = Styled.table`
	width : 100%;
 	border-spacing: 0px;
`

export const TableHead = Styled.thead`
	
`

export const TableBody = Styled.tbody`
`

export const TableRow = Styled.tr`
	td {
		border-bottom: 1px solid ${extended.purple.five};
		padding: ${spacing.s3} 0;
	}

	&:first-child {
		border-bottom: 0;

		td {
			padding-top: ${spacing.s5};
		}
	}
`

export const TableHeader = Styled.th`
	width: ${props => props.width};
	border-bottom: 1px solid rgb(125,96,255);
	padding: ${spacing.s1} 0 ${spacing.s5} 0;
	margin-bottom: ${spacing.s4};
	overflow-wrap: break-word;
	font-family: "Proxima Nova";
	font-size: ${text.t5};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #2f2d40;
`

export const TableData = Styled.td`
	text-align : ${props => props.alignText};
	font-family: "Proxima Nova";
	font-size: ${text.t5};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #6d6c79;
`

export const Pagination = Styled.div`
	display :flex;
	padding : 0 10px;
	margin : ${spacing.s6} 0 ${spacing.s3} 0;  
	justify-content: flex-end;
`

export const PaginationIndex = Styled.div`
	display: flex;
	font-family: "Proxima Nova";
	font-size: ${text.t3};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: ${extended.charcoal.one};
	margin: ${spacing.s6} 0 0;
`

export const PaginationButtonContainer = Styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 120px;
	margin: ${spacing.s6} 0 0 ${spacing.s10};
`

export const PaginationButton = Styled.div`
	width: 100%;
	cursor: pointer;
	display : flex;
	height: 18px;
	font-family: "Proxima Nova";
	font-size: ${text.t4};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	color: ${extended.purple.one};
`

export const RightIconSpan = Styled.span`
	margin-left : ${spacing.s3};
	display: inline-block;
`

export const LeftIconSpan = Styled.span`
	margin-right : ${spacing.s3};
	display: inline-block;
`

export const BackButtonIcon = Styled.i`
	border: solid 0.5px #7d60ff;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(135deg);
`

export const NextButtonIcon = Styled.i`
	border: solid 0.5px #7d60ff;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(-45deg);
`

export const NoDataWrap = Styled.div`
  display: flex;
  justify-content: center;
`
export const PaginationContainer = Styled.div`
	margin-top: 16px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`