import React, {useState, useEffect, Fragment} from "react"
import styled from "styled-components"
import {Container, Grid, Row, Col} from "@uprise/grid"
import {H5, H6} from "@uprise/headings"
import moment from "moment"
import {useDispatch, useSelector} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"

import {fetchEmployerCodeUserUtilization} from "../employerSlice"

import AutoComplete from "components/shared/AutoComplete"
import DatePicker from "components/shared/DatePicker"
import DropDown from "components/shared/DropDown"

import DownloadIcon from "assets/images/icons/svg/icon-download.svg"
import Preloader from "components/shared/Preloader/Preloader"
const ElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #edeafa;
`

const ElementSubContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const ElementSubItemContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #edeafa;
	padding-left: 24px;
`

const ElementTextContainer = styled.div`
	margin-top: 16px;
	margin-bottom: 8px;
`

const ElementLabel = styled(H5)`
	margin: 16px 0 8px 0;
`

const ItemValueContainer = styled.div`
	/* flex-basis: 60%; */
	color: #6d6c79;
	align-self: center;
`

const DateContainers = styled.div`
	display: flex;
	justify-content: space-evenly;
`

const Icon = styled.figure`
	margin: 0;
	cursor: pointer;
`

const SpanText = styled.span`
	color: #6d6c79;
`

const UserUtilization = ({data, loading}) => {
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()

	const [state, setState] = useState({
		code: data.code.allCodes[0],
		from: moment(),
		to: moment()
	})

	const [token, setToken] = useState(null)

	useEffect(() => {
		async function getToken() {
			const token = await getAccessTokenSilently()
			setToken(token)
		}

		getToken()
	}, [])

	const handleUserUtilizationClick = () => {
		dispatch(fetchEmployerCodeUserUtilization({data: state, token}))
	}

	return (
		<Container>
			<Row>
				<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
					<H6>Type</H6>
					<DropDown
						variant='primary'
						items={data?.code?.allCodes?.map(code => ({label: code, value: code}))}
						label='Select Code'
						onChange={item => setState({...state, code: item.value})}
						initialSelectedItem={{label: state.code, value: state.code}}
						menuStyle={{position: "absolute", zIndex: 2}}
					/>
				</Col>
				<Col className='col-6 col-lg-6 col-md-6 col-sm-12 mb-4'>
					<H6>Date</H6>

					<DateContainers>
						<DatePicker
							allowPreviousDates={true}
							date={state.from}
							onChange={e => setState({...state, from: e.target.value})}
						/>

						<DatePicker
							date={state.to}
							onChange={e => {
								console.log("value", e)
								setState({...state, to: e.target.value})
							}}
						/>
						<Icon onClick={handleUserUtilizationClick}>
							<img src={DownloadIcon} alt='' />
						</Icon>
					</DateContainers>
				</Col>
			</Row>
			{loading && (
				<Row className='justify-content-center'>
					<Preloader />
				</Row>
			)}
			{data.plan.userUtilization ? (
				<Fragment>
					<Row>
						<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
							<H6>Plan Details</H6>
						</Col>

						<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
							<H6>Other Stats</H6>
						</Col>
					</Row>

					<Row>
						<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
							<ElementContainer>
								<ElementLabel>Total Users</ElementLabel>

								<ItemValueContainer>{data.plan.userUtilization.totalUsers}</ItemValueContainer>
							</ElementContainer>

							<ElementContainer>
								<ElementLabel>Total Signups</ElementLabel>
								<ItemValueContainer>{data.plan.userUtilization.signUps}</ItemValueContainer>
							</ElementContainer>

							<ElementContainer>
								<div>
									<ElementLabel>Total Active</ElementLabel>
									<SpanText>Atleast 1 call made</SpanText>
								</div>

								<ItemValueContainer>{data.plan.userUtilization.totalActiveCalls}</ItemValueContainer>
							</ElementContainer>

							<ElementContainer>
								<div>
									<ElementLabel>Total Active</ElementLabel>
									<SpanText>Atlease 1 module task completed </SpanText>
								</div>
								<ItemValueContainer>{data.plan.userUtilization.totalActiveModules}</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<div>
									<ElementLabel>Calls Completed</ElementLabel>
									<SpanText>1/2 hour</SpanText>
								</div>
								<ItemValueContainer>{data.plan.userUtilization.calls["30"]}</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<div>
									<ElementLabel>Calls Completed</ElementLabel>
									<SpanText>1 hour</SpanText>
								</div>
								<ItemValueContainer>{data.plan.userUtilization.calls["60"]}</ItemValueContainer>
							</ElementContainer>
							<ElementSubContainer>
								<div>
									<ElementLabel>Risk Callbacks</ElementLabel>
									{/* <SpanText>15 mins</SpanText> */}
								</div>

								{/* <ItemValueContainer>{data.plan.userUtilization.riskcallbackCount}</ItemValueContainer> */}
							</ElementSubContainer>
							<ElementSubItemContainer>
								<ElementTextContainer>
									<SpanText>Number of Users</SpanText>
								</ElementTextContainer>

								<ItemValueContainer>{data.plan.userUtilization.riskcallbackCount}</ItemValueContainer>
							</ElementSubItemContainer>

							<ElementSubItemContainer>
								<ElementTextContainer>
									<SpanText>Clinical Hours</SpanText>
								</ElementTextContainer>

								<ItemValueContainer>
									{`${data.plan.userUtilization.riskcallbackDuration} mins`}
								</ItemValueContainer>
							</ElementSubItemContainer>

							<ElementSubItemContainer>
								<ElementTextContainer>
									<SpanText>Number of Calls</SpanText>
								</ElementTextContainer>

								<ItemValueContainer>{data.plan.userUtilization.riskcallbackCalls}</ItemValueContainer>
							</ElementSubItemContainer>
							{/* <ElementContainer>
								<ElementLabel>F2F Sessions</ElementLabel>
								<ItemValueContainer>Sample</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>CIR</ElementLabel>
								<ItemValueContainer>Sample</ItemValueContainer>
							</ElementContainer> */}
						</Col>

						<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
							<ElementContainer>
								<ElementLabel>Wellbeing Checks</ElementLabel>

								<ItemValueContainer>
									{data.plan.userUtilization.wellbeingCompleteCount}
								</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>Pre-Signup Wellbeing Checks</ElementLabel>

								<ItemValueContainer>
									{data.plan.userUtilization.preWellbeingCounts.totalChecks}
								</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>Post-Signup Wellbeing Checks</ElementLabel>

								<ItemValueContainer>
									{data.plan.userUtilization.postWellbeingCounts.totalChecks}
								</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>Cancelled within 24 hours</ElementLabel>

								<ItemValueContainer>{data.plan.userUtilization.within24}</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>User No Show</ElementLabel>

								<ItemValueContainer>{data.plan.userUtilization.userNoShow}</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>Coach Cancelled</ElementLabel>

								<ItemValueContainer>{data.plan.userUtilization.coachCancelled}</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>User Cancelled</ElementLabel>

								<ItemValueContainer>{data.plan.userUtilization.userCancelled}</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>Outside given date</ElementLabel>

								<ItemValueContainer>{data.plan.userUtilization.outside}</ItemValueContainer>
							</ElementContainer>
							<ElementContainer>
								<ElementLabel>Modules Completed</ElementLabel>

								<ItemValueContainer>
									{data.plan.userUtilization.totalSkillsCompleted}
								</ItemValueContainer>
							</ElementContainer>
						</Col>
					</Row>
				</Fragment>
			) : null}
		</Container>
	)
}

export default UserUtilization
