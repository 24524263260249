import React from "react"
import {useSelector} from "react-redux"

// UI
import {spacing} from "@uprise/spacing"
import {ContainerFluid} from "@uprise/grid"
import {Card} from "@uprise/card"

// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import SuccessPage from "./SuccessPage"
import FormPage from "./FormPage"
// Slices
import {bookingDataSelector} from "../bookingSlice"

const CreateBackDateBooking = () => {
	const {createBackDateBookingApiState} = useSelector(bookingDataSelector)

	return (
		<ComponentWrapper
			menuActiveIndex={4}
			subMenuActiveIndex={13}
			headerTitle='Create Back Date Booking'
		>
			<ContainerFluid>
				<Card
					style={{overflow: "visible"}}
					backgroundColor='white'
					padding={`${spacing.s5} 0px`}
					className='m-t-5 m-b-10'>
					{createBackDateBookingApiState.isSuccess && <SuccessPage />}
					{!createBackDateBookingApiState.isSuccess && <FormPage />}
				</Card>
			</ContainerFluid>
		</ComponentWrapper>
	)
}

export default CreateBackDateBooking