import React, {useEffect, useState} from "react"

import Styled from "styled-components"
// Colors
import {extended} from "@uprise/colors"

// Components
import {Medium} from "@uprise/text"
import {H5, H6} from "@uprise/headings"

// spacing
import {spacing} from "@uprise/spacing"

const SummaryStyles = Styled.section`
      background-color: #fff;
`

const Content = Styled.div`

`

const Ul = Styled.ul`
      margin-bottom: ${spacing.s3};
      list-style-type: none;
      padding-left: 0;
`

const Li = Styled.li`
      display: flex;
	flex-direction: column;
      margin-bottom: ${spacing.s6};
	padding-bottom: ${spacing.s4};
	border: 1px solid ${extended.purple.four};
	border-right: 0;
	border-left: 0;
	border-top: 0;

	&:last-child {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
`

const removeHtmlTagsFromString = value => {
	return value.replace(/(<([^>]+)>)/gi, "")
}

export const STRESSBHCResult = ({index, analysis, surveyTasks, survey}) => {
	const [surveyQuestions, setSurveyQuestions] = useState({})
	useEffect(() => {
		let questionsArr = {}
		surveyTasks.map(task => {
			let surveyQuestionsArr = survey.questions.filter(question => question.batteryId === task)

			questionsArr = {...questionsArr, [task]: surveyQuestionsArr}
		})
		setSurveyQuestions(questionsArr)
	}, [])

	return (
		<SummaryStyles>
			<Content>
				<H5 className='m-b-0 m-t-0'>Stress</H5>
				<Ul className='m-b-5'>
					{analysis.STRESS &&
						Object.entries(analysis.STRESS)
							.slice(1)
							.map((answer, index) => {
								if (typeof answer[1] === "object" && answer[1]) {
									if (answer[0] === "humanReadable") {
										return (
											<Li key={index}>
												<Medium weight='bold'>
													Overall : {answer[1] ? answer[1]["STRESS"] : "-"}
												</Medium>
											</Li>
										)
									}
								} else if (answer[1]) {
									return (
										<Li key={index}>
											<H6 className='m-t-0 m-b-3'>
												{" "}
												{surveyQuestions["STRESS"] &&
													surveyQuestions["STRESS"][index] &&
													removeHtmlTagsFromString(surveyQuestions["STRESS"][index].prompt)}
											</H6>
											<Medium>
												{surveyQuestions["STRESS"] &&
													surveyQuestions["STRESS"][index] &&
													surveyQuestions["STRESS"][index].options[answer[1]]}
											</Medium>
										</Li>
									)
								}
							})}
				</Ul>
			</Content>
		</SummaryStyles>
	)
}
