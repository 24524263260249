import React, {Fragment, useEffect, useState, useRef} from "react"
import {bool, func, object, string, array, element, number} from "prop-types"
import Checkbox from "../Checkbox"
import useOnOutsideClick from "../../../hooks/useOnOutsideClick"

import {
	Container,
	TriggerButtonContainer,
	TriggerButton,
	ArrowIcon,
	MenuContainer,
	LeftMenuContainer,
	LeftMenuItemContainer,
	LeftMenuItems,
	LeftMenuLink,
	LeftMenuText,
	LeftMenuArrowIcon,
	RightMenuContainer,
	RightMenuItems,
	RightMenuItemContainer,
	CheckboxLabel,
	CountSpan
} from "./index.style"

const TagsMultiLevelDropdown = ({
	leftNavItems,
	rightNavItems,
	name,
	onChange,
	containerStyle,
	buttonWidth,
	label,
	initialSelectedItems,
	maxSelectedItemCount,
	onValidationError
}) => {
	const [isOpen, setOpen] = useState(false)
	const [highlightedParentId, setHighlightedParentId] = useState(0)
	const [selectedChildItems, setSelectedChildItems] = useState(initialSelectedItems)
	const [childItems, setChildItems] = useState([])
	const [parentItems, setParentItems] = useState([])

	const containerRef = useRef()

	useEffect(() => {
		const childFilteredItems = rightNavItems.filter(item => item.parentId === highlightedParentId)
		setChildItems(childFilteredItems)
	}, [highlightedParentId])

	useEffect(() => {
		setParentItems(
			leftNavItems.map(items => {
				return {
					...items,
					selectedCount: 0
				}
			})
		)
	}, [leftNavItems])

	useEffect(() => {
		setSelectedChildItems(initialSelectedItems)
	}, [initialSelectedItems])

	useOnOutsideClick(containerRef, () => setOpen(false))

	const handleBtnClick = () => {
		setOpen(!isOpen)
	}

	const handleParentMenuClick = id => {
		setHighlightedParentId(id)
	}

	const handleItemSelect = selectedItem => {
		if (selectedChildItems.includes(selectedItem.value)) {
			setSelectedChildItems(selectedChildItems.filter(item => item !== selectedItem.value))
			setParentItems(
				parentItems.map(item => {
					if (selectedItem.parentId === item.id) {
						return {
							...item,
							selectedCount: item.selectedCount - 1
						}
					} else {
						return item
					}
				})
			)
			onChange(selectedChildItems.filter(item => item !== selectedItem.value))
		} else {
			if (maxSelectedItemCount && selectedChildItems.length >= maxSelectedItemCount) {
				onValidationError("Reached Max Limit")
			} else {
				setSelectedChildItems([...selectedChildItems, selectedItem.value])
				setParentItems(
					parentItems.map(item => {
						if (selectedItem.parentId === item.id) {
							return {
								...item,
								selectedCount: item.selectedCount + 1
							}
						} else {
							return item
						}
					})
				)
				onChange([...selectedChildItems, selectedItem.value])
			}
		}
	}

	return (
		<Container style={containerStyle}>
			<TriggerButtonContainer>
				<TriggerButton name={name} buttonWidth={buttonWidth} onClick={handleBtnClick} type='button'>
					{label}
					<ArrowIcon isOpen={isOpen} />
				</TriggerButton>
			</TriggerButtonContainer>
			<MenuContainer ref={containerRef} isOpen={isOpen}>
				{isOpen ? (
					<Fragment>
						<LeftMenuContainer>
							<LeftMenuItemContainer>
								{parentItems.map(item => {
									return (
										<LeftMenuItems key={item.id}>
											<LeftMenuLink
												onClick={() => handleParentMenuClick(item.id)}
												highlightedIndex={item.id === highlightedParentId}>
												<LeftMenuText>{item.label}</LeftMenuText>
												{/* {item.selectedCount > 0 ? (
													<CountSpan>{item.selectedCount}</CountSpan>
												) : null} */}
												<LeftMenuArrowIcon />
											</LeftMenuLink>
										</LeftMenuItems>
									)
								})}
							</LeftMenuItemContainer>
						</LeftMenuContainer>

						<RightMenuContainer>
							{childItems.map(item => {
								return (
									<RightMenuItems key={item.id}>
										<RightMenuItemContainer>
											<label>
												<Checkbox
													checked={selectedChildItems.includes(item.value)}
													onChange={() => handleItemSelect(item)}
													height={"12"}
													width={"12"}
													iconYPos={"-70"}
												/>
											</label>
											<CheckboxLabel>{item.value}</CheckboxLabel>
										</RightMenuItemContainer>
									</RightMenuItems>
								)
							})}
						</RightMenuContainer>
					</Fragment>
				) : null}
			</MenuContainer>
		</Container>
	)
}

TagsMultiLevelDropdown.propTypes = {
	leftNavItems: array.isRequired,
	rightNavItems: array.isRequired,
	onChange: func.isRequired,
	label: string.isRequired,
	buttonWidth: string,
	containerStyle: object,
	menuStyle: object,
	initialSelectedItems: array.isRequired,
	maxSelectedItemCount: number || null,
	onValidationError: func
}

TagsMultiLevelDropdown.defaultProps = {
	leftNavItems: [],
	rightNavItems: [],
	label: "",
	onChange: () => {},
	containerStyle: {},
	menuStyle: {},
	buttonWidth: "400px",
	initialSelectedItems: [],
	maxSelectedItemCount: null,
	onValidationError: () => {}
}

export default TagsMultiLevelDropdown
