import Styled from "styled-components"
// Colors
import {extended, semantic} from "@uprise/colors"

export const DropDownWrap = Styled.div` 
	margin-top: ${props => (props.isFirst ? "21px" : "0px")};
	margin-bottom: ${props => (props.isLast ? "0px" : "21px")};
  align-self : center;
`
export const LabelStyles = Styled.label`
  font-size: ${props => (props.isOpen ? "12px" : "16px")};
  color: ${extended.charcoal.two};
  display: block;
  margin-bottom: 4px;
  transition: font-size 0.2s;
`

export const Container = Styled.div`
  position: relative;
`

const background = variant => {
	switch (variant) {
		case "primary":
			return "transparent"
		case "white":
			return "white"
		default:
			extended.purple.five
	}
}

const textColor = variant => {
	switch (variant) {
		case "primary":
			return extended.charcoal.one
		case "white":
			return extended.charcoal.two
		default:
			extended.purple.one
	}
}

export const TriggerButton = Styled.button`
  width: ${props => (props.width ? `${props.width}px` : "100%")};
  outline: none;
  padding: 6px 22px 6px 12px;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : props.isOpen ? "10px 10px 0 0" : "10px")};
  text-align: left;
  background-color:  ${props => background(props.variant)}; 
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.55;
  letter-spacing: normal;
  color:  ${props => textColor(props.variant)}; 
  position: relative;
  border: ${props => {
		if (props.focused) {
			return `1px solid ${extended.blue.one}`
		} else if (props.validation?.errors?.length > 0 || props.validation?.message) {
			return `1px solid ${semantic.error}`
		} else if (["primary", "white"].includes(props.variant)) {
			return `1px solid ${extended.purple.four}`
		} else {
			return "0px"
		}
  }};
`

export const ArrowIcon = Styled.span`
  border: solid #7d60ff;
  border-width: 0 1px 1px 0;
  padding: 3px;
  float: right;
  display: inline-block;
  transform: rotate(45deg);
  position: absolute;
  right: 12px;
  top: 35%;
  `

export const MenuList = Styled.ul`
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  outline: none;
  width: "150px";
  margin: 0 auto;
  padding: 0 0 0 0px;
  box-shadow: 0 2px 4px 0 rgba(219, 221, 227, 0.5);
  background-color: #ffffff;
`

export const MenuListItem = Styled.li`
  padding: 4px 4px 4px 10px;
  text-align: left;
  list-style-type: none;
  border-bottom: 1px solid #edeafa;
  font-family: "Proxima Nova";
  font-size: 13px;
  cursor: pointer;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: ${props => (props.selectedItem ? "#7d60ff" : "#9796a0")};
  border: ${props => (["white", "primary"].includes(props.variant) ? `1px solid ${extended.purple.four}` : `0`)}; 
  border-top: 0;
`

export const MessageStyles = Styled.label`
      font-size: 12px;
      color:  ${props => {
			if (props.focused) {
				return `${extended.blue.one}`
			} else if (props.validation?.errors?.length > 0 || props.validation?.message) {
				return `${semantic.error}`
			} else {
				return `${extended.purple.five}`
			}
		}};
      display: block;
      margin-top: 8px;
      transition: font-size 0.2s;
`
