import {backgrounds, extended} from "@uprise/colors"
import styled from "styled-components"

export const PhoneInputStyler = styled.div`
	input {
		border: 0;
		font-size: 16px;
		width: 100%;

		margin-left: 5px;

		&::placeholder {
			font-family: "Proxima Nova";
			font-size: 13px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.55;
			color: ${extended.charcoal.one};
			padding: 2px 22px 2px 8px;
			opacity: 0.9;
		}
	}
`
