import React from "react"

function IconRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width="1em"
      height="1em"
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
      }}
      viewBox="0 0 8 8"
      transform="rotate(360)"
    >
      <path d="M5 1v2H0v2h5v2l3-3.03L5 1z" fill={props?.fill ? props?.fill : "#626262"} />
    </svg>
  )
}

export default IconRight;
