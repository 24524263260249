import React from "react"

export const IconEmployers = props => {
	const fill = props?.fill ? props?.fill : "#D5D5D8"
	
	return (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
		<g fill='none' fillRule='evenodd'>
			<path d='M0 0h12v12H0z' />
			<path
				fill={fill.toString()}
				d='M7.429 0C7.744 0 8 .256 8 .571h2.857c.631 0 1.143.512 1.143 1.143v8.572c0 .63-.512 1.143-1.143 1.143H1.143A1.143 1.143 0 010 10.286V1.714C0 1.084.512.571 1.143.571H4C4 .256 4.256 0 4.571 0H7.43zM6 6.286c-1.674 0-3.042 1.347-3.143 3.047v.381c0 .316.256.572.572.572H8.57a.57.57 0 00.51-.314c.007-.012.013-.026.02-.042a.577.577 0 00.042-.216v-.38C9.042 7.633 7.673 6.285 6 6.285zm0-3.429a1.43 1.43 0 100 2.859 1.43 1.43 0 000-2.859z'
			/>
		</g>
	</svg>)
}
