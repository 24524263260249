import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import styled from "styled-components"
import {useForm, Controller} from "react-hook-form"
import {useAuth0} from "@auth0/auth0-react"

// Components
import Toggle from "components/shared/Toggle"
// Slices
import {
	disableUser,
	userDataSelector,
	toggleDashboardViewForUser,
	notifyUserIntentToWork,
	clearUserNotificationState
} from "../usersSlice"
import {employerSelector} from "../../Employers/employerSlice"
// UI
import {Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {Card} from "@uprise/card"
import {P} from "@uprise/text"
import {H6} from "@uprise/headings"
import {Button} from "@uprise/button"
import {spacing} from "@uprise/spacing"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
	height: 60px;
`

const FormElementLabel = styled(H6)`
	width: 40%;
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputContainer = styled.div`
	width: 60%;
	flex-basis: 60%;
	position: relative;
	align-self: center;
`

const FormTextInput = styled(TextInputHorizontal)``

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const NavigationButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
`

const Profile = ({isEditEnabled, onEditCancel}) => {
	const {register, control, handleSubmit, watch, reset, trigger, setError, errors} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onSubmit",
		reValidateMode: "onChange"
	})

	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()
	const {profile, userNotificationState} = useSelector(userDataSelector)
	const {selectedEmployerDetails} = useSelector(employerSelector)

	useEffect(() => {
		if (userNotificationState.isSuccess || userNotificationState.isError) {
			setTimeout(() => {
				dispatch(clearUserNotificationState())
			}, 3000)
		}
	}, [userNotificationState])

	async function disableUserAPI() {
		const token = await getAccessTokenSilently()
		dispatch(
			disableUser({
				email: profile.email,
				disabled: !profile.disabled,
				token
			})
		)
	}

	async function toggleDashboardViewForUserAPI() {
		const token = await getAccessTokenSilently()
		dispatch(
			toggleDashboardViewForUser({
				email: profile.email,
				isDashboardViewEnabled: !profile.isDashboardViewEnabled,
				token
			})
		)
	}

	const handleSendInterestNotification = async () => {
		const token = await getAccessTokenSilently()
		dispatch(notifyUserIntentToWork({email: profile.email, token}))
	}

	const onSubmit = data => {}

	return (
		<Card padding={spacing.s10}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer>
							<FormElementLabel>Name</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"name"}
										control={control}
										rules={{required: true}}
										defaultValue={profile?.name}
										as={<FormTextInput type='text' placeholder='Name' />}
									/>
								) : (
									<P>{profile?.name}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Email</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"email"}
										control={control}
										rules={{required: true}}
										defaultValue={profile?.email}
										as={<FormTextInput type='text' placeholder='Name' />}
									/>
								) : (
									<P>{profile?.email}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Phone Number</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"Phone"}
										control={control}
										defaultValue={profile?.phone}
										as={<FormTextInput type='tel' placeholder='Phone Number' />}
									/>
								) : (
									<P>
										{profile?.countryCode
											? profile?.countryCode + " " + profile?.phone
											: " " + profile?.phone}
									</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Employer Name</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"companyName"}
										defaultValue={profile.companyName}
										control={control}
										as={<FormTextArea></FormTextArea>}
									/>
								) : (
									<P>{profile?.companyName || "-"}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Employer Code</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"employerCode"}
										defaultValue={profile.employerCode}
										control={control}
										as={<FormTextInput type='text' placeholder='Employer Code' />}
									/>
								) : (
									<P>{profile?.employerCode || "-"}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>
					</Col>

					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer>
							<FormElementLabel>Enabled</FormElementLabel>
							<FormInputContainer>
								<Toggle
									checked={!profile?.disabled}
									onChange={() => disableUserAPI()}
									backgroundColorChecked={"#7d60ff"}
									width='42'
									height='24'
									sliderHeight='20.8'
									sliderWidth='20.8'
									translate='18'
									justifyContent='flex-start'
								/>
							</FormInputContainer>
						</FormElementContainer>
						<FormElementContainer>
							<FormElementLabel>Dashboard view</FormElementLabel>
							<FormInputContainer>
								<Toggle
									checked={profile?.isDashboardViewEnabled}
									onChange={() => toggleDashboardViewForUserAPI()}
									backgroundColorChecked={"#7d60ff"}
									width='42'
									height='24'
									sliderHeight='20.8'
									sliderWidth='20.8'
									translate='18'
									justifyContent='flex-start'
								/>
							</FormInputContainer>
						</FormElementContainer>
						<FormElementContainer>
							<FormElementLabel>Caps calls*</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"companyCallCap"}
										defaultValue={profile?.companyCallCap}
										control={control}
										as={<FormTextInput type='text' placeholder='Employer Code' />}
									/>
								) : (
									<P>
										{!selectedEmployerDetails?.caps?.capCalls
											? "Unlimited"
											: profile?.companyCallCap}
									</P>
								)}
							</FormInputContainer>
						</FormElementContainer>
					</Col>
				</Row>
				{isEditEnabled ? (
					<Row className='flex-row-reverse'>
						<ButtonContainer>
							<NavigationButton type='submit' variant={"primary"} title={"Update"} />
						</ButtonContainer>
						<ButtonContainer>
							<NavigationButton variant={"secondary"} onClick={onEditCancel} title={"Cancel"} />
						</ButtonContainer>
					</Row>
				) : null}
			</form>
		</Card>
	)
}

export default Profile
