import React from "react"
import {useDispatch, useSelector} from "react-redux"
// Colors
import {Loader} from "@uprise/loader"
import {Button} from "@uprise/button"
import ComponentWrapper from "components/HOC/ComponentWrapper/index"
import DropDown from "components/shared/DropDown"
import {
	HeaderTableContent,
	SearchUserInput,
	Container,
	HomeImage,
	HomeTitle,
	EmployerHomeDescription,
	EmployerButton
} from "../Employers/index.style"
import CoachesSVG from "assets/images/svg/icon-coaches.svg"

const Employer = ({history}) => {
	return (
		<ComponentWrapper menuActiveIndex={7} headerTitle='Coaches'>
			<Container style={{marginTop: "calc(100vh / 5)"}}>
				<HomeImage src={CoachesSVG} />
				<HomeTitle>Your Coach Central</HomeTitle>
				<EmployerHomeDescription>Manage your coaches, their information, and more.</EmployerHomeDescription>
				<EmployerHomeDescription></EmployerHomeDescription>
				<EmployerButton size={"Medium"} onClick={() => history.push("/coaches/all")} title={"All coaches"} />
			</Container>
		</ComponentWrapper>
	)
}

export default Employer
