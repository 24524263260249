import React, {Fragment} from "react"

// UI
import {Modal} from "@uprise/modal"
import {H4, H5, H6} from "@uprise/headings"
// Constants
import {cancelReason, cancelStatus} from "helpers/constants"

// Styles
import {HeaderTableContent, ModalButtonContainer, RadioDiv, RadioLabel, CustomButton} from "./index.style"

const CancelBookingModal = ({
	isOpen,
	handleClose,
	handleCancelBooking,
	selectedUserBookingToDelete,
	cancellingBookingBefore24Hours,
	checkCancelOption,
	cancelReasonState,
	checkCancelStatus,
	status
}) => {
	return (
		<Modal
			isOpen={isOpen}
			handleClose={() => handleClose(false)}
			padding={"24px"}
			backgroundColor={"#ffffff"}
			width={"20%"}
			textAlign='center'>
			<H4>{`Cancel Booking`}</H4>
			<ModalButtonContainer>
				{cancellingBookingBefore24Hours ? (
					<div>
						<H5 className='m-t-0 m-b-10'>Booking was cancelled:</H5>
						<H5 textAlign={"left"} className='m-b-0'>
							Status:
						</H5>
						<RadioDiv className='radio'>
							<RadioLabel>
								<input
									className='radio-input'
									type='radio'
									name='status'
									value={"coach_cancelled"}
									checked={status === "coach_cancelled"}
									onClick={() => checkCancelStatus("coach_cancelled")}
								/>
								<span>{cancelStatus["coach_cancelled"]}</span>
							</RadioLabel>
						</RadioDiv>
						<RadioDiv className='radio'>
							<RadioLabel>
								<input
									className='radio-input'
									type='radio'
									name='status'
									value={"user_cancelled"}
									checked={status === "user_cancelled"}
									onClick={() => checkCancelStatus("user_cancelled")}
								/>
								{cancelStatus["user_cancelled"]}
							</RadioLabel>
						</RadioDiv>
						<H5 textAlign={"left"} className='m-b-0'>
							Cancel Reason:
						</H5>
						<RadioDiv className='radio'>
							<RadioLabel>
								<input
									className='radio-input'
									type='radio'
									name='cancelReason'
									value='inTime'
									checked={cancelReasonState === "inTime"}
									onClick={() => checkCancelOption("inTime")}
								/>
								<span>{cancelReason["inTime"]}</span>
							</RadioLabel>
						</RadioDiv>
						<RadioDiv className='radio'>
							<RadioLabel>
								<input
									className='radio-input'
									type='radio'
									name='cancelReason'
									value='within24'
									checked={cancelReasonState === "within24"}
									onClick={() => checkCancelOption("within24")}
								/>
								{cancelReason["within24"]}
							</RadioLabel>
						</RadioDiv>
						<RadioDiv className='radio'>
							<RadioLabel>
								<input
									className='radio-input'
									type='radio'
									name='cancelReason'
									value='userNoShow'
									checked={cancelReasonState === "userNoShow"}
									onClick={() => checkCancelOption("userNoShow")}
								/>
								{cancelReason["userNoShow"]}
							</RadioLabel>
						</RadioDiv>
						<HeaderTableContent className='m-t-10'>
							<CustomButton
								title='Cancel'
								data-testid='cancelButton'
								size='medium'
								onClick={() => handleClose()}
								variant='secondary'
							/>
							<CustomButton
								title='Confirm'
								data-testid='confirmCancelBooking'
								onClick={() => handleCancelBooking()}
								size='medium'
								variant='primary'
							/>
						</HeaderTableContent>
					</div>
				) : (
					<Fragment>
						<H5>Are you Sure want to Cancel Booking for User</H5>
						<H6
							style={{
								wordWrap: "break-word"
							}}>{`${selectedUserBookingToDelete?.email}`}</H6>
						<HeaderTableContent className='m-t-10'>
							<CustomButton
								title='Cancel'
								size='medium'
								data-testid='cancelButton'
								onClick={() => handleClose()}
								variant='secondary'
							/>
							<CustomButton
								title='Confirm'
								data-testid='confirmCancelBooking'
								onClick={() => handleCancelBooking()}
								size='medium'
								variant='primary'
							/>
						</HeaderTableContent>
					</Fragment>
				)}
			</ModalButtonContainer>
		</Modal>
	)
}

export default CancelBookingModal
