import React from "react"
import styled from "styled-components"
import {TextInputHorizontal} from "@uprise/form"
import {H6} from "@uprise/headings"
import UserIcon from "assets/images/svg/coach.svg"
import {extended, semantic} from "@uprise/colors"
import {spacing} from "@uprise/spacing"

const StyledImg = styled.div`
	width: ${props => (props.width ? props.width : `137px`)};
	height: ${props => (props.height ? props.height : `137px`)};
	margin: ${props => (props.noMargin ? "0px" : "10px auto 16px")};
	border-radius: 13.7px;
	background-image: ${props => (props.src ? `url(${props.src})` : `url(${UserIcon})`)};
	background-repeat: no-repeat;
	background-size: ${props => (props.width ? `${props.width} ${props.width}` : `137px`)};
	background-color: ${extended.charcoal.five};
`
const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: ${props => (props.error ? `1px solid ${semantic.error}` : `1px solid ${extended.purple.five}`)};
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`
const FormInputContainer = styled.div`
	flex-basis: 80%;
	position: relative;
	align-self: center;
`
const generateRandomNumber = () => Math.floor(Math.random() * 10000 + 1)

const TextInputHorizontalWrapper = React.forwardRef(({onChange = () => {}, ...props}, ref) => (
	<TextInputHorizontal
		name={`input-${generateRandomNumber()}`}
		id={`input-${generateRandomNumber()}`}
		onChange={onChange}
		{...props}
	/>
))

const FormTextInput = styled(TextInputHorizontalWrapper)`
	padding: ${spacing.s2};

	&::placeholder {
		opacity: 0.5;
	}
`

const SubmitButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	margin-top: 50px;
`

const HiddenTextArea = styled.textarea`
	display: none;
`

const StyledTextArea = styled.textarea`
	width: 100%;
	max-height: 160px;
	border: 0;
	font-family: inherit;
	font-size: inherit;
	margin: 16px 0 8px 2px;

	&::placeholder {
		opacity: 0.5;
	}
`

const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`

const CalendarIconFigure = styled.figure`
	margin: 0;
`

export {
	StyledImg,
	FormElementContainer,
	FormElementLabel,
	FormInputContainer,
	FormTextInput,
	SubmitButtonContainer,
	StyledTextArea,
	HiddenTextArea,
	MessageStyles,
	CalendarIconFigure
}
