import {useAuth0} from "@auth0/auth0-react"
import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router"
import {Link} from "react-router-dom"

// UI
import {Alert} from "@uprise/alert"
import {Button} from "@uprise/button"
import {Col, ContainerFluid, Row} from "@uprise/grid"
// Components
import Toggle from "components/shared/Toggle"
import ComponentWrapper from "components/HOC/ComponentWrapper/index"
import Preloader from "components/shared/Preloader/Preloader"
import Wysiwyg from "components/shared/Wysiwyg/index"
import AvailabilityTable from "components/shared/AvailabilityTable"
// Styles
import {FormElementContainer, FormElementLabel, FormInputContainer, StyledImg} from "../../../shared/Form/index.style"
import {TableContainer} from "../../Employers/AllEmployers/index.style"
import {HeaderTableContent} from "../../RiskCallBacks/index.style"
// Slices
import {clearCoachUpdateApiState, clearCoachCreateApiState, coachDataSelector, fetchCoachById} from "../coachesSlice"
import {coachCalendarSelector} from "../coachesSlice"

function CoachDetails() {
	const params = useParams()
	const dispatch = useDispatch()

	const {getAccessTokenSilently} = useAuth0()
	React.useEffect(() => {
		;(async () => {
			const token = await getAccessTokenSilently()
			const id = params.id
			dispatch(fetchCoachById({token, id}))
		})()
	}, [params.id, getAccessTokenSilently, dispatch])

	const {fetchCoachByIdApiState, coachUpdateApiState, createCoachApiState} = useSelector(coachDataSelector)

	const validCals = useSelector(coachCalendarSelector)

	useEffect(() => {
		if (coachUpdateApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearCoachUpdateApiState())
			}, 3000)
		}

		if (createCoachApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearCoachCreateApiState())
			}, 3000)
		}
	}, [coachUpdateApiState, createCoachApiState])

	return (
		<ComponentWrapper
			menuActiveIndex={7}
			subMenuActiveIndex={12}
			headerTitle='Coach Details'
			headerRightAlignContent={
				<HeaderTableContent>
					<Link style={{textDecoration: "none"}} to={`/coaches/${params.id}/edit`}>
						<Button
							// onClick={SubmitClick}
							size='small'
							title={"Edit"}
							style={{margin: "10px", padding: "20px 24px"}}
							variant='primary'
						/>
					</Link>
				</HeaderTableContent>
			}>
			{createCoachApiState.isSuccess && (
				<Alert className='m-b-5' type='success'>
					{createCoachApiState.message}
				</Alert>
			)}
			{coachUpdateApiState.isSuccess && (
				<Alert className='m-b-5' type='success'>
					{coachUpdateApiState.message}
				</Alert>
			)}
			<TableContainer>
				{fetchCoachByIdApiState.isFetching && !fetchCoachByIdApiState.isSuccess && <Preloader />}
				{fetchCoachByIdApiState.isSuccess && (
					<ContainerFluid style={{padding: "40px"}}>
						<Row>
							<Col className='col-2 col-lg-2 col-md-2 col-sm-12'>
								<StyledImg
									src={
										fetchCoachByIdApiState.coach.data?.photos?.imageURL
											? fetchCoachByIdApiState.coach.data.photos.imageURL
											: ""
									}
								/>
							</Col>
							<Col className='col-10 col-lg-10 col-md-10 col-sm-12'>
								<FormElementContainer>
									<FormElementLabel>Coach Name</FormElementLabel>
									<FormInputContainer>{fetchCoachByIdApiState.coach.name}</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Coach ID</FormElementLabel>
									<FormInputContainer>{fetchCoachByIdApiState.coach.id}</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Location</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data?.location
											? fetchCoachByIdApiState.coach.data.location
											: "NA"}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Time Zone</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.timezone}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Email Address</FormElementLabel>
									<FormInputContainer>{fetchCoachByIdApiState.coach.email}</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Phone Number</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.phoneNumber}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Status</FormElementLabel>
									<FormInputContainer>
										<div style={{width: "50px"}}>
											<Toggle
												checked={!Boolean(fetchCoachByIdApiState.coach.disabled)}
												disabled={true}
												// onChange={e => props.onChange(!Boolean(props.value))}
												backgroundColorChecked={"#7d60ff"}
												width='42'
												height='24'
												sliderHeight='20.8'
												sliderWidth='20.8'
												translate='18'
											/>
										</div>
									</FormInputContainer>
								</FormElementContainer>

								{/* TODO */}
								<FormElementContainer>
									<FormElementLabel>Calendar API Status</FormElementLabel>
									<FormInputContainer>
										{validCals[fetchCoachByIdApiState.coach.data.calendarId] === "owner"
											? "📘"
											: validCals[fetchCoachByIdApiState.coach.data.calendarId] === "writer"
											? "📗"
											: validCals[fetchCoachByIdApiState.coach.data.calendarId] === "reader"
											? "📕"
											: "❌"}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Degree</FormElementLabel>
									<FormInputContainer>
										{Array.isArray(fetchCoachByIdApiState.coach.data.degree)
											? fetchCoachByIdApiState.coach.data.degree.join(", ")
											: fetchCoachByIdApiState.coach.data.degree}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>License</FormElementLabel>
									<FormInputContainer>{fetchCoachByIdApiState.coach.data.license}</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Qualification</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.qualification
											? fetchCoachByIdApiState.coach.data.qualification
											: ""}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Designation</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.designation
											? fetchCoachByIdApiState.coach.data.designation
											: ""}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Age</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.age
											? fetchCoachByIdApiState.coach.data.age
											: ""}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Gender</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.gender
											? fetchCoachByIdApiState.coach.data.gender
											: ""}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Experience</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.experience
											? fetchCoachByIdApiState.coach.data.experience
											: ""}
									</FormInputContainer>
								</FormElementContainer>

								{/* <FormElementContainer>
									<FormElementLabel>Skills</FormElementLabel>
									<FormInputContainer>
										{Array.isArray(fetchCoachByIdApiState.coach.data.skills)
											? fetchCoachByIdApiState.coach.data.skills.join(", ")
											: fetchCoachByIdApiState.coach.data.skills}
									</FormInputContainer>
								</FormElementContainer> */}

								<FormElementContainer>
									<FormElementLabel>Area of Interest</FormElementLabel>
									<FormInputContainer>
										{Array.isArray(fetchCoachByIdApiState.coach.data.interest)
											? fetchCoachByIdApiState.coach.data.interest.join(", ")
											: fetchCoachByIdApiState.coach.data.interest}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Specialization</FormElementLabel>
									<FormInputContainer>
										{Array.isArray(fetchCoachByIdApiState.coach.data.specialization)
											? fetchCoachByIdApiState.coach.data.specialization.join(", ")
											: fetchCoachByIdApiState.coach.data.specialization}
									</FormInputContainer>
								</FormElementContainer>

								{/* <FormElementContainer>
									<FormElementLabel>Expertise</FormElementLabel>
									<FormInputContainer>{fetchCoachByIdApiState.coach.data.expertise}</FormInputContainer>
								</FormElementContainer> */}

								<FormElementContainer>
									<FormElementLabel>Language(s)</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data?.languages?.length
											? fetchCoachByIdApiState.coach.data.languages.join(", ")
											: "NA"}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>F2F Sessions</FormElementLabel>
									<FormInputContainer>
										<div style={{width: "50px"}}>
											<Toggle
												checked={fetchCoachByIdApiState.coach.data.f2fSessions}
												disabled={true}
												backgroundColorChecked={"#7d60ff"}
												width='42'
												height='24'
												sliderHeight='20.8'
												sliderWidth='20.8'
												translate='18'
											/>
										</div>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Practice Address</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.address
											? [
													fetchCoachByIdApiState.coach.data.address.line1,
													fetchCoachByIdApiState.coach.data.address.line2,
													fetchCoachByIdApiState.coach.data.address.state,
													fetchCoachByIdApiState.coach.data.address.country,
													fetchCoachByIdApiState.coach.data.address.postcode
											  ].join(", ")
											: "NA"}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Main Calendar ID</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.calendarId}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Extra Calendar ID</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.extraCalendars?.length
											? fetchCoachByIdApiState.coach.data.extraCalendars.join(", ")
											: "NA"}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Active On</FormElementLabel>
									<div style={{width: "80%"}}>
										<FormElementContainer>
											<FormElementLabel>Corporate version</FormElementLabel>
											<FormInputContainer>
												<div style={{width: "50px"}}>
													<Toggle
														checked={
															fetchCoachByIdApiState.coach?.data?.activeOn?.corporate
														}
														backgroundColorChecked={"#7d60ff"}
														disabled={true}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>RTW version</FormElementLabel>
											<FormInputContainer>
												<div style={{width: "50px"}}>
													<Toggle
														checked={fetchCoachByIdApiState.coach.data?.activeOn?.rtw}
														disabled={true}
														backgroundColorChecked={"#7d60ff"}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>Risk callback</FormElementLabel>
											<FormInputContainer>
												<div style={{width: "50px"}}>
													<Toggle
														checked={
															fetchCoachByIdApiState.coach.data?.activeOn?.riskcallback
														}
														backgroundColorChecked={"#7d60ff"}
														disabled={true}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>AUS</FormElementLabel>
											<FormInputContainer>
												<div style={{width: "50px"}}>
													<Toggle
														checked={fetchCoachByIdApiState.coach.data?.activeOn?.aus}
														backgroundColorChecked={"#7d60ff"}
														disabled={true}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>US</FormElementLabel>
											<FormInputContainer>
												<div style={{width: "50px"}}>
													<Toggle
														checked={fetchCoachByIdApiState.coach.data?.activeOn?.us}
														backgroundColorChecked={"#7d60ff"}
														disabled={true}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>Students</FormElementLabel>
											<FormInputContainer>
												<div style={{width: "50px"}}>
													<Toggle
														checked={fetchCoachByIdApiState.coach.data?.activeOn?.students}
														backgroundColorChecked={"#7d60ff"}
														disabled={true}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>Allianz</FormElementLabel>
											<FormInputContainer>
												<div style={{width: "50px"}}>
													<Toggle
														checked={fetchCoachByIdApiState.coach.data?.activeOn?.allianz}
														backgroundColorChecked={"#7d60ff"}
														disabled={true}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											</FormInputContainer>
										</FormElementContainer>
									</div>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Bookings Per Week</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data?.bookingsPerWeek
											? fetchCoachByIdApiState.coach.data.bookingsPerWeek
											: "-"}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Standard Availability Hours</FormElementLabel>
									<FormInputContainer>
										{fetchCoachByIdApiState.coach.data.availabilityHours}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Introduction</FormElementLabel>
									<FormInputContainer>
										<Wysiwyg
											initialValue={fetchCoachByIdApiState.coach.data.intro?.english}
											editable={false}
										/>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Availability</FormElementLabel>
									<FormInputContainer>
										<AvailabilityTable
											value={fetchCoachByIdApiState.coach.data.hours}
											disabled={true}
										/>
									</FormInputContainer>
								</FormElementContainer>
							</Col>
						</Row>
					</ContainerFluid>
				)}
			</TableContainer>
		</ComponentWrapper>
	)
}

export default CoachDetails
