import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import chevronPurple from "@uprise/icons/icons/png/chevron-purple/chevron-purple.png"
import {H3} from "@uprise/headings"
import {Container, Row, Col, ContainerFluid} from "@uprise/grid"
import {ButtonContainer, ButtonLeft, ButtonRight, ToggleBtn, ToggleBtnRight, InlineP} from "./index.style"
import {P} from "@uprise/text"

const SelectedInfo = props => {
	const {coachSelected, coaches, duration, setSelectedDuration} = props
	const [coachList, setCoachList] = useState([])
	const [active, setActive] = useState(duration) //true: 30, false: 60
	const [isWeek, setIsWeek] = useState(coachSelected ? true : false)

	useEffect(() => {
		if (coachSelected) {
			setCoachList([coaches.find(coach => coach.id === coachSelected)])
			setIsWeek(true)
		} else {
			setCoachList(coaches)
			setIsWeek(false)
		}
	}, [coachSelected, coaches])

	const updateCal = forward => {
		const date = moment(props.date)
		if (forward) {
			if (isWeek) props.onDayClick(date.endOf("week").add(7, "days"))
			else props.onDayClick(date.add(1, "days"))
		} else {
			if (isWeek) props.onDayClick(date.endOf("week").subtract(7, "days"))
			else props.onDayClick(date.subtract(1, "days"))
		}
	}

	return (
		<ContainerFluid>
			<ButtonContainer className='flex mt-4 justify-content-between mb-5'>
				<Row className='d-flex align-items-center justify-content-start col-6'>
					<H3>{`${
						coachList.length === 1 ? `Availability for ${coachList[0].name}` : "All available coaches"
					}`}</H3>
				</Row>

				<Row className='d-flex align-items-center justify-content-end col-6'>
					<InlineP>
						{!coachSelected
							? `${moment(props.date).format("D MMMM YYYY")}`
							: `${moment(props.date).startOf("week").format("D MMMM YYYY")} -
							${moment(props.date).endOf("week").format("D MMMM YYYY")}`}
					</InlineP>
					<ButtonLeft src={chevronPurple} onClick={() => updateCal(false)} />
					<ButtonRight src={chevronPurple} onClick={() => updateCal(true)} />
					<ToggleBtn
						active={active}
						onClick={() => {
							setActive(true)
							setSelectedDuration(30)
						}}>
						30 min
					</ToggleBtn>
					<ToggleBtnRight
						active={!active}
						onClick={() => {
							setActive(false)
							setSelectedDuration(60)
						}}>
						60 min
					</ToggleBtnRight>
				</Row>
			</ButtonContainer>
		</ContainerFluid>
	)
}

SelectedInfo.propTypes = {
	date: PropTypes.object.isRequired,
	dayOffset: PropTypes.number.isRequired,
	duration: PropTypes.bool.isRequired,
	setDate: PropTypes.func.isRequired,
	setShouldReset: PropTypes.func.isRequired
}

export default SelectedInfo
