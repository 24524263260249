import {Button} from "@uprise/button"
import {Container} from "@uprise/grid"
import {H3} from "@uprise/headings"
import React, {Fragment} from "react"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import styled from "styled-components"
const pixelRatio = window.devicePixelRatio || 1

const ReactCropWrapper = styled.div`
	img.ReactCrop__image {
		max-height: 400px;
	}
`

function UploadImageModal({hide, src, onChange}) {
	const previewCanvasRef = React.useRef(null)
	const imgRef = React.useRef(null)

	const [base64PhotoURI, setBase64URI] = React.useState(null)

	const [upImg, setUpImg] = React.useState()

	const [crop, setCrop] = React.useState({unit: "%", width: 30, aspect: 1})
	const [completedCrop, setCompletedCrop] = React.useState(null)

	const _handleSave = async (base64PhotoURI, croppingInfo) => {
		function getCroppedImg(b64image, crop) {
			const image = imgRef.current
			const canvas = previewCanvasRef.current
			const scaleX = image.naturalWidth / image.width
			const scaleY = image.naturalHeight / image.height
			canvas.width = crop.width * pixelRatio
			canvas.height = crop.height * pixelRatio
			const ctx = canvas.getContext("2d")

			ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
			ctx.imageSmoothingQuality = "high"

			ctx.drawImage(
				image,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			)

			// const base64Image = canvas.toDataURL("image/png")
			// return base64Image

			return new Promise((resolve, reject) => {
				canvas.toBlob(
					blob => {
						blob.name = `image-${Date.now()}`
						resolve(blob)
					},
					"image/jpeg",
					0.8
				)
			})
		}

		const blobToBase64 = blob => {
			const reader = new FileReader()
			reader.readAsDataURL(blob)
			return new Promise(resolve => {
				reader.onloadend = () => {
					resolve(reader.result)
				}
			})
		}

		console.log({base64PhotoURI, croppingInfo})
		const croppedImg = await getCroppedImg(base64PhotoURI, croppingInfo)
		var urlCreator = window.URL || window.webkitURL
		const croppedImgFinal = urlCreator.createObjectURL(croppedImg)

		const croppedBase64Img = await blobToBase64(croppedImg)

		onChange({tempImg: croppedImgFinal, base64PhotoURI: croppedBase64Img, croppingInfo})
		hide()
	}

	React.useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return
		}

		const image = imgRef.current
		const canvas = previewCanvasRef.current
		const crop = completedCrop

		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const ctx = canvas.getContext("2d")

		canvas.width = crop.width * pixelRatio
		canvas.height = crop.height * pixelRatio

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
		ctx.imageSmoothingQuality = "high"

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)
	}, [completedCrop])

	const onLoad = React.useCallback(img => {
		imgRef.current = img
	}, [])

	return (
		<Fragment>
			<H3 className='m-b-6'>Upload Photo</H3>
			<Container>
				{upImg && (
					<div style={{display: "none"}}>
						<canvas
							ref={previewCanvasRef}
							// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
							style={{
								width: "200px"
								// width: Math.round(crop?.width ?? 0),
								// height: Math.round(crop?.height ?? 0)
							}}
						/>
					</div>
				)}
				{!upImg && src && <img alt={"coach"} src={src} width='200px' />}

				{upImg && (
					<ReactCropWrapper>
						<ReactCrop
							src={upImg}
							crop={crop}
							onImageLoaded={onLoad}
							minWidth={192}
							minHeight={192}
							ruleOfThirds={true}
							keepSelection={true}
							locked={false}
							onChange={c => setCrop(c)}
							onComplete={c => setCompletedCrop(c)}
						/>
					</ReactCropWrapper>
				)}

				<div className='relative w-full mt-4 flex'>
					<React.Fragment>
						{!upImg && (
							<label>
								<Button
									as='label'
									for='file-upload'
									size='small'
									title={"Browse"}
									variant='tertiary'
									type='button'
									style={{width: "137px", margin: "0 auto"}}
								/>
								<input
									style={{display: "none"}}
									id='file-upload'
									className='w-full btn btn-success absolute block w-full opacity-0'
									type='file'
									accept='image/x-png,image/jpeg'
									onChange={e => {
										if (e.target.files && e.target.files.length > 0) {
											const reader = new FileReader()
											reader.addEventListener("load", () => setUpImg(reader.result))
											reader.readAsDataURL(e.target.files[0])
										}
									}}
									required
								/>

								{/* <button className={`w-full btn btn-success`}>Browse</button> */}
							</label>
						)}
						{upImg && (
							<Button
								as='label'
								for='file-upload'
								size='small'
								title={"Upload photo"}
								variant='tertiary'
								type='button'
								style={{width: "137px", margin: "0 auto"}}
								onClick={() => _handleSave(previewCanvasRef.current.toDataURL("image/png"), crop)}
							/>
						)}
					</React.Fragment>
				</div>

				<Button
					onClick={hide}
					size='small'
					title={"Close"}
					variant='primary'
					type='button'
					fullWidth={true}
					style={{margin: "40px auto 0"}}
				/>
			</Container>
		</Fragment>
	)
}

export default UploadImageModal
