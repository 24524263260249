import React from "react"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import {PhoneInputStyler} from "./index.style"

function PhoneNumberInput({placeholder, value, name, onChange}) {
	return (
		<PhoneInputStyler>
			<PhoneInput
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={phoneNumber => onChange(phoneNumber)}
				data-testid='phoneNumber'
			/>
		</PhoneInputStyler>
	)
}

export default PhoneNumberInput
