import React, {Fragment, useState, useEffect} from "react"
import Moment from "moment"
import styled from "styled-components"
import {useDispatch} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"
import {useHistory} from "react-router-dom"

// UI
import {H4} from "@uprise/headings"
import {Modal} from "@uprise/modal"
import {Button} from "@uprise/button"
import {spacing} from "@uprise/spacing"
// Slices
import {updateEmployerCodeStatus} from "../employerSlice"
// Components
import Table from "components/shared/Table"
import Toggle from "components/shared/Toggle"
// Index
import {ColumnContainer, EmployerCodeColumn} from "../../Users/AllUsers/index.style"

const TableWrap = styled.div`
	padding: ${spacing.s10};
`

const ModalButtonContainer = styled.div`
	display: flex;
`

const ModalButton = styled(Button)`
	margin: 5px;
`

const Code = ({data, capDetails, expiryDate, employerCode}) => {
	const [openModal, setOpenModal] = useState(false)
	const [codeStatus, setCodeStatus] = useState(data?.status === 1 ? true : false)
	const [selectedCodeToDisable, setSelectedCodeToDisable] = useState("")
	const [token, setToken] = useState(null)
	const {getAccessTokenSilently} = useAuth0()
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		async function getToken() {
			const token = await getAccessTokenSilently()
			setToken(token)
		}

		getToken()
	}, [])

	const handleCodeStatusChange = data => {
		setSelectedCodeToDisable(employerCode)
		setCodeStatus(!codeStatus)
		setOpenModal(true)
	}

	const handleDisableConfirm = () => {
		dispatch(
			updateEmployerCodeStatus({
				data: {
					code: employerCode,
					status: codeStatus ? 1 : 0
				},
				token
			})
		)

		setOpenModal(false)
		setSelectedCodeToDisable("")
	}

	const columns = [
		{
			Header: "Employer Code",
			accessor: "employerCode",
			show: true,
			//	headerStyle: {padding: "12px !important"},
			Cell: cellInfo => {
				return (
					<ColumnContainer>
						<EmployerCodeColumn
							id={cellInfo.row.original.id}
							row={cellInfo.row.original}
							onClick={() => {
								if (cellInfo.row.original.employerCode) {
									history.push(`/users/code/${cellInfo.row.original.employerCode}`)
								}
							}}>
							{cellInfo.row.original.employerCode}
						</EmployerCodeColumn>
					</ColumnContainer>
				)
			}
		},
		{
			Header: "Code Status",
			accessor: "codeStatus",
			show: true,
			Cell: e => {
				return (
					<Toggle
						checked={e.row.original.status === 1 ? true : false}
						onChange={() => handleCodeStatusChange(e.row.original)}
						backgroundColorChecked={"#7d60ff"}
						width='42'
						height='24'
						sliderHeight='20.8'
						sliderWidth='20.8'
						translate='18'
					/>
				)
			}
		},
		{
			Header: "Code Type",
			accessor: "codeType",
			show: true
		},
		{
			Header: "Code Label",
			accessor: "codeLabel",
			show: true
		},
		{
			Header: "Max Users",
			accessor: "maxUser",
			show: true
		},
		{
			Header: "Start Date",
			// accessor: "codeStartDate",
			accessor: d => {
				return Moment(d.codeStartDate).local().format("Do MMM YYYY")
			},
			show: true
		},
		{
			Header: "End Date",
			// accessor: "CodeExpiryDate",
			accessor: d => {
				return Moment(expiryDate).local().format("Do MMM YYYY")
			},
			show: true
		},
		{
			Header: "Capped Calls",
			accessor: d => {
				const callCapPeriod = d.caps?.callCapPeriod
				if (!d.caps?.capCalls) return "unlimited"
				else {
					const finalCaps = callCapPeriod && callCapPeriod[callCapPeriod.length - 1]
					const total =
						finalCaps &&
						finalCaps.combinedBookingCaps + finalCaps.therapyBookingCaps + finalCaps.coachingBookingCaps
					return total
				}
			},
			show: true
		},
		{
			Header: "Coaching Calls",
			show: true,
			accessor: d => {
				const callCapPeriod = d.caps?.callCapPeriod
				const finalCaps = callCapPeriod && callCapPeriod[callCapPeriod.length - 1]
				return finalCaps && finalCaps.coachingBookingCaps
			}
		},
		{
			Header: "Therapy Calls",
			show: true,
			accessor: d => {
				const callCapPeriod = d.caps?.callCapPeriod
				const finalCaps = callCapPeriod && callCapPeriod[callCapPeriod.length - 1]
				return finalCaps && finalCaps.therapyBookingCaps
			}
		}
	]

	return (
		<Fragment>
			<TableWrap>
				<Table
					pageCount={Math.ceil(data.allCodes.length / 20)}
					columns={columns}
					loading={false}
					data={[{...data, capCalls: capDetails.callsPerUser, employerCode, caps: capDetails}]}
				/>
			</TableWrap>

			<Modal
				isOpen={openModal}
				handleClose={() => setOpenModal(false)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"500px"}
				textAlign='center'>
				<H4>{`Are you sure want to ${!codeStatus ? "disable" : "enable"} code - ${selectedCodeToDisable}?`}</H4>
				<ModalButtonContainer>
					<ModalButton title='Confirm' onClick={handleDisableConfirm} size='medium' variant='primary' />
					<ModalButton title='Cancel' size='medium' onClick={() => setOpenModal(false)} variant='primary' />
				</ModalButtonContainer>
			</Modal>
		</Fragment>
	)
}

export default Code
