import React, {useState, useRef, useEffect} from "react"
import moment from "moment-timezone"
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import styled from "styled-components"
import constraints from "./validation"

// Constants
import COUNTRY_CODES from "constants/countrycodes"
// UI
import {spacing} from "@uprise/spacing"
import {Container, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {TextInput} from "@uprise/form"
import {Alert} from "@uprise/alert"

// Components
import DropDown from "components/shared/DropDown"

// Slices
import {userDataSelector} from "../../usersSlice"

import {FormElementContainer, FormElementLabel, FormInputContainer} from "../index.style"
import {SubmitButtonContainer} from "shared/Form/index.style"

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	padding: 40px;
`

const FormPage = props => {
	const {
		codes,
		email,
		setEmail,
		name,
		setName,
		phone,
		setPhone,
		timezone,
		setTimezone,
		countryCode,
		countryName,
		employerCode,
		setEmployerCode,
		password,
		setPassword,
		_handleCountryCode,
		onSubmit,
		validation
	} = props
	const history = useHistory()
	const dispatch = useDispatch()
	const firstRender = useRef(true)

	const {createNewUserApiState} = useSelector(userDataSelector)

	const [timezones, setTimezones] = useState()

	useEffect(() => {
		setTimezones(
			moment.tz.names().map(item => {
				return {
					label: item,
					value: item
				}
			})
		)
	}, [])

	const accessCodes = codes
		.map(code => {
			return {label: code, value: code}
		})
		.sort(function (a, b) {
			return a.value.localeCompare(b.value)
		})

	return (
		<Content>
			<Container>
				<Row>
					<Col className='col-md-12'>
						{createNewUserApiState.isError && (
							<Alert className='m-b-5' type='error'>
								{createNewUserApiState.message}
							</Alert>
						)}
					</Col>
				</Row>
			</Container>
			<Container>
				<Row className='justify-content-md-center'>
					<Col className='col-6 col-md-6'>
						{/* User Email */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>User Email</FormElementLabel>
							<FormInputContainer error={validation.email}>
								<TextInput
									data-testid='userEmail'
									name='email'
									id='email'
									padding={spacing.s2}
									placeholder='user@email.com'
									fontSize='12px'
									onChange={e => setEmail(e.target.value)}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Name */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Name</FormElementLabel>
							<FormInputContainer error={validation.name}>
								<TextInput
									name='name'
									data-testid='userName'
									id='name'
									padding={spacing.s2}
									fontSize='12px'
									placeholder='Name'
									onChange={e => setName(e.target.value)}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Phone */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Phone</FormElementLabel>
							<FormInputContainer error={validation.phone}>
								<TextInput
									name='phone'
									data-testid='phone'
									id='phone'
									placeholder='0123456789'
									padding={spacing.s2}
									fontSize='12px'
									onChange={e => setPhone(e.target.value)}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Country Code */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Country Code</FormElementLabel>
							<FormInputContainer error={validation.countryCode}>
								<DropDown
									name='countryCode'
									testId='countryCode'
									id='countryCode'
									variant='primary'
									label='Select Code'
									items={COUNTRY_CODES}
									onChange={value => {
										_handleCountryCode(value)
									}}
									initialSelectedItem={COUNTRY_CODES[12].label}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>
					<Col className='col-6 col-md-6'>
						{/* Access Code */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Access Code</FormElementLabel>
							<FormInputContainer error={validation.employerCode}>
								<DropDown
									name='employerCode'
									testId='employerCode'
									id='employerCode'
									border={true}
									variant='primary'
									items={accessCodes}
									label='Select access code'
									onChange={e => setEmployerCode(e.value)}
									initialSelectedItem={""}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Timezone */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Timezone</FormElementLabel>
							<FormInputContainer error={validation.timezone}>
								<DropDown
									name='timezone'
									testId='timezone'
									border={true}
									variant='primary'
									items={timezones}
									label='Select Timezone'
									initialSelectedItem={{label: moment.tz.guess(), value: moment.tz.guess()}}
									onChange={e => setTimezone(e.value)}
									initialSelectedItem={timezone}
									menuStyle={{position: "absolute", zIndex: 2}}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Password */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Password</FormElementLabel>
							<FormInputContainer error={validation.password}>
								<TextInput
									name='password'
									data-testid='password'
									type='text'
									id='password'
									placeholder='Password123!'
									value={password}
									padding={spacing.s2}
									fontSize='12px'
									onChange={e => setPassword(e.target.value)}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>
				</Row>

				<Row>
					<SubmitButtonContainer>
						<Button
							size='small'
							title={"Cancel"}
							variant='tertiary'
							style={{margin: "10px", padding: "20px 24px"}}
							fullWidth={false}
							onClick={() => history.push("/users/all")}
						/>
						<Button
							isLoading={!createNewUserApiState.isError && createNewUserApiState.isFetching}
							size='small'
							title={"Save"}
							data-testid='createUserButton'
							style={{margin: "10px", padding: "20px 24px"}}
							variant='primary'
							fullWidth={false}
							onClick={onSubmit}
						/>
					</SubmitButtonContainer>
				</Row>
			</Container>
		</Content>
	)
}

export default FormPage
