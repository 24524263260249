const languages = [
	"Mandarin Chinese",
	"Spanish",
	"English",
	"Hindi (sanskritised Hindustani)",
	"Bengali",
	"Portuguese",
	"Russian",
	"Japanese",
	"Western Punjabi",
	"Marathi",
	"Telugu",
	"Wu Chinese",
	"Turkish",
	"Korean",
	"French",
	"German (only Standard German)",
	"Vietnamese",
	"Tamil",
	"Yue Chinese",
	"Urdu (persianised Hindustani)",
	"Javanese",
	"Italian",
	"Egyptian Arabic",
	"Gujarati",
	"Iranian Persian",
	"Bhojpuri",
	"Min Nan Chinese",
	"Hakka Chinese",
	"Jin Chinese",
	"Hausa",
	"Kannada",
	"Indonesian (Indonesian Malay)",
	"Polish",
	"Yoruba",
	"Xiang Chinese",
	"Malayalam",
	"Odia",
	"Maithili",
	"Burmese",
	"Eastern Punjabi",
	"Sunda",
	"Sudanese Arabic",
	"Algerian Arabic",
	"Moroccan Arabic",
	"Ukrainian",
	"Igbo",
	"Northern Uzbek",
	"Sindhi",
	"North Levantine Arabic",
	"Romanian",
	"Tagalog",
	"Dutch",
	"Saʽidi Arabic",
	"Gan Chinese",
	"Amharic",
	"Northern Pashto",
	"Magahi",
	"Thai",
	"Saraiki",
	"Khmer",
	"Chhattisgarhi",
	"Somali",
	"Malaysian (Malaysian Malay)",
	"Cebuano",
	"Nepali",
	"Mesopotamian Arabic",
	"Assamese",
	"Sinhalese",
	"Northern Kurdish",
	"Hejazi Arabic",
	"Nigerian Fulfulde",
	"Bavarian",
	"South Azerbaijani",
	"Greek",
	"Chittagonian",
	"Kazakh",
	"Deccan",
	"Hungarian",
	"Kinyarwanda",
	"Zulu",
	"South Levantine Arabic",
	"Tunisian Arabic",
	"Sanaani Spoken Arabic",
	"Min Bei Chinese",
	"Southern Pashto",
	"Rundi",
	"Czech",
	"Taʽizzi-Adeni Arabic",
	"Uyghur",
	"Min Dong Chinese",
	"Sylheti"
]

const qualifications = [
	{
		label: "Associate/professional degree",
		value: "Associate/professional degree"
	},
	{
		label: "Bachelors",
		value: "bachelors"
	},
	{
		label: "Masters Degree",
		value: "Masters degree"
	},
	{
		label: "Associate/professional degree",
		value: "Associate/professional degree"
	}
]

const designation = [
	{
		label: "Life Coach",
		value: "life coach"
	},
	{
		label: "Social Worker",
		value: "social worker"
	},
	{
		label: "Nurse",
		value: "nurse"
	},
	{
		label: "Psychologist",
		value: "psychologist"
	},
	{
		label: "Clinical Psychologist",
		value: "clinical psychologist"
	},
	{
		label: "Psychiatrist",
		value: "psychiatrist"
	}
]

const age = [
	{
		label: "25 and below",
		value: "25 and below"
	},
	{
		label: "25-35",
		value: "25-35"
	},
	{
		label: "35-45",
		value: "35-45"
	},
	{
		label: "45 and above",
		value: "45 and above"
	}
]

const experience = [
	{
		label: "<5 Years",
		value: "<5 years"
	},
	{
		label: "5-10 Years",
		value: "5-10 years"
	},
	{
		label: "10-20 Years",
		value: "10-20 years"
	},
	{
		label: ">20 Years",
		value: ">20 years"
	}
]

const gender = [
	{
		label: "Male",
		value: "male"
	},
	{
		label: "Female",
		value: "female"
	},
	{
		label: "Other",
		value: "other"
	},
	{
		label: "Prefer not to say",
		value: "prefer not to say"
	}
]

const specializationParentLevel = [
	{
		id: 0,
		label: "Mental Health",
		value: "Mental Health"
	},
	{
		id: 1,
		label: "General Health",
		value: "General Health"
	},
	{
		id: 2,
		label: "Trauma/Harm",
		value: "Trauma/Harm"
	},
	{
		id: 3,
		label: "Personal",
		value: "Personal"
	},
	{
		id: 4,
		label: "Relationships",
		value: "Relationships"
	},
	{
		id: 5,
		label: "Educational",
		value: "Educational"
	},
	{
		id: 6,
		label: "Addictions",
		value: "Addictions"
	},
	{
		id: 7,
		label: "Work/Community",
		value: "Work/Community"
	},
	{
		id: 8,
		label: "Legal",
		value: "Legal"
	}
]

const specializationChildLevel = [
	{
		id: 0,
		parentId: 0,
		value: "Attention Deficit Hyperactivity Disorder (ADHD)"
	},
	{
		id: 1,
		parentId: 0,
		value: "Anxiety and Phobias"
	},
	{
		id: 2,
		parentId: 0,
		value: "Autism Spectrum Disorder (incl. Asperger's)"
	},
	{
		id: 3,
		parentId: 0,
		value: "Bipolar Disorder"
	},
	{
		id: 4,
		parentId: 0,
		value: "Depression"
	},
	{
		id: 5,
		parentId: 0,
		value: "Disassociative Association Disorder"
	},
	{
		id: 6,
		parentId: 0,
		value: "Eating Disorder"
	},
	{
		id: 7,
		parentId: 0,
		value: "Hoarding Disorder"
	},
	{
		id: 8,
		parentId: 0,
		value: "Obsessive-Compulsive Disorder"
	},
	{
		id: 9,
		parentId: 0,
		value: "Panic Disorder"
	},
	{
		id: 10,
		parentId: 0,
		value: "Personality Disorders"
	},
	{
		id: 11,
		parentId: 0,
		value: "Post-natal Depression"
	},
	{
		id: 12,
		parentId: 0,
		value: "Psychosis"
	},
	{
		id: 13,
		parentId: 0,
		value: "Tourette's Syndrone"
	},
	{
		id: 14,
		parentId: 0,
		value: "Trichotillomania"
	},
	{
		id: 15,
		parentId: 0,
		value: "Cancer Support"
	},
	{
		id: 16,
		parentId: 5,
		value: "Academic performance"
	},
	{
		id: 17,
		parentId: 5,
		value: "Dyslexia"
	},
	{
		id: 18,
		parentId: 5,
		value: "Intellectual Disability"
	},
	{
		id: 19,
		parentId: 5,
		value: "Learning difficulties"
	},
	{
		id: 20,
		parentId: 5,
		value: "School issues"
	},
	{
		id: 21,
		parentId: 1,
		value: "Chronic Disease Management"
	},
	{
		id: 22,
		parentId: 1,
		value: "Diabetes"
	},
	{
		id: 23,
		parentId: 1,
		value: "Enuresis/ encopresis"
	},
	{
		id: 24,
		parentId: 1,
		value: "Genetic Counselling"
	},
	{
		id: 25,
		parentId: 1,
		value: "Health related problems"
	},
	{
		id: 26,
		parentId: 1,
		value: "Hearing impaired"
	},
	{
		id: 27,
		parentId: 1,
		value: "Infertility issues"
	},
	{
		id: 28,
		parentId: 1,
		value: "Memory problems"
	},
	{
		id: 29,
		parentId: 1,
		value: "Pain management"
	},
	{
		id: 30,
		parentId: 1,
		value: "Physical Disability"
	},
	{
		id: 31,
		parentId: 1,
		value: "Pre-cosmetic surgery assesment"
	},
	{
		id: 32,
		parentId: 1,
		value: "Rehabilitation/injury counselling"
	},
	{
		id: 33,
		parentId: 1,
		value: "Relaxation"
	},
	{
		id: 34,
		parentId: 1,
		value: "Sleeping Disorders"
	},
	{
		id: 35,
		parentId: 1,
		value: "Stress Management"
	},
	{
		id: 36,
		parentId: 1,
		value: "Terminal Illness"
	},
	{
		id: 37,
		parentId: 1,
		value: "Vision Impaired"
	},
	{
		id: 38,
		parentId: 1,
		value: "Weight Management"
	},
	{
		id: 39,
		parentId: 2,
		value: "Acquired brain injury"
	},
	{
		id: 40,
		parentId: 2,
		value: "Adult survivors of sexual abuse"
	},
	{
		id: 41,
		parentId: 2,
		value: "Bullying"
	},
	{
		id: 42,
		parentId: 2,
		value: "Cult involvement"
	},
	{
		id: 43,
		parentId: 2,
		value: "Disaster Recovery"
	},
	{
		id: 44,
		parentId: 2,
		value: "Family violence"
	},
	{
		id: 45,
		parentId: 2,
		value: "Partners/families of sex offenders"
	},
	{
		id: 46,
		parentId: 2,
		value: "Post trumatic stress disorder (PTSD)"
	},
	{
		id: 47,
		parentId: 2,
		value: "Psychological first aid"
	},
	{
		id: 48,
		parentId: 2,
		value: "Self-harm"
	},
	{
		id: 49,
		parentId: 2,
		value: "Sexual Abuse"
	},
	{
		id: 50,
		parentId: 2,
		value: "Suicide"
	},
	{
		id: 51,
		parentId: 2,
		value: "Victim of crime"
	},
	{
		id: 52,
		parentId: 3,
		value: "Abortion"
	},
	{
		id: 53,
		parentId: 3,
		value: "Adoption"
	},
	{
		id: 54,
		parentId: 3,
		value: "Anger Management"
	},
	{
		id: 55,
		parentId: 3,
		value: "Assertiveness training"
	},
	{
		id: 56,
		parentId: 3,
		value: "Behaviour problems"
	},
	{
		id: 57,
		parentId: 3,
		value: "Body Image"
	},
	{
		id: 58,
		parentId: 3,
		value: "Carer Support"
	},
	{
		id: 59,
		parentId: 3,
		value: "Diverse gender"
	},
	{
		id: 60,
		parentId: 3,
		value: "Diverse sexualities"
	},
	{
		id: 61,
		parentId: 3,
		value: "Gender Dysphoria"
	},
	{
		id: 62,
		parentId: 3,
		value: "Grief and loss"
	},
	{
		id: 63,
		parentId: 3,
		value: "Life/Personal Coaching"
	},
	{
		id: 64,
		parentId: 3,
		value: "Life transition and adjustment issues"
	},
	{
		id: 65,
		parentId: 3,
		value: "Motivation in sport"
	},
	{
		id: 66,
		parentId: 3,
		value: "Orphanages/ Children's home issues"
	},
	{
		id: 67,
		parentId: 3,
		value: "Pregnancy support"
	},
	{
		id: 68,
		parentId: 3,
		value: "Religious issues"
	},
	{
		id: 69,
		parentId: 3,
		value: "Selective Mutism"
	},
	{
		id: 70,
		parentId: 3,
		value: "Self esteem and self development"
	},
	{
		id: 71,
		parentId: 3,
		value: "Sexual difficulties"
	},
	{
		id: 72,
		parentId: 3,
		value: "Sex worker issues"
	},
	{
		id: 73,
		parentId: 3,
		value: "Shyness and social skills deficit"
	},
	{
		id: 74,
		parentId: 3,
		value: "Sports performance anxiety"
	},
	{
		id: 75,
		parentId: 4,
		value: "Conflict resolution"
	},
	{
		id: 76,
		parentId: 4,
		value: "Couples therapy"
	},
	{
		id: 77,
		parentId: 4,
		value: "Cross-cultural"
	},
	{
		id: 78,
		parentId: 4,
		value: "Divorce/separation"
	},
	{
		id: 79,
		parentId: 4,
		value: "Meditation"
	},
	{
		id: 80,
		parentId: 4,
		value: "Meditation-accredited"
	},
	{
		id: 81,
		parentId: 4,
		value: "Parenting"
	},
	{
		id: 82,
		parentId: 4,
		value: "Relationships"
	},
	{
		id: 83,
		parentId: 6,
		value: "Alcohol dependence"
	},
	{
		id: 84,
		parentId: 6,
		value: "Drug dependence"
	},
	{
		id: 85,
		parentId: 6,
		value: "Gambling"
	},
	{
		id: 86,
		parentId: 6,
		value: "Illicit drugs in sports"
	},
	{
		id: 87,
		parentId: 6,
		value: "impulsive behaviours"
	},
	{
		id: 88,
		parentId: 6,
		value: "Internet gaming/social networking"
	},
	{
		id: 89,
		parentId: 6,
		value: "Internet Pornography"
	},
	{
		id: 90,
		parentId: 6,
		value: "Smoking cessation"
	},
	{
		id: 91,
		parentId: 7,
		value: "Human resource management"
	},
	{
		id: 92,
		parentId: 7,
		value: "Leadership"
	},
	{
		id: 93,
		parentId: 7,
		value: "Military, police and emergency work"
	},
	{
		id: 94,
		parentId: 7,
		value: "Organisational restructuring"
	},
	{
		id: 95,
		parentId: 7,
		value: "Performance management"
	},
	{
		id: 96,
		parentId: 7,
		value: "Personnel selection"
	},
	{
		id: 97,
		parentId: 7,
		value: "Work stress"
	},
	{
		id: 98,
		parentId: 7,
		value: "Workplace bullying"
	},
	{
		id: 99,
		parentId: 7,
		value: "Workplace/executive coaching"
	},
	{
		id: 100,
		parentId: 8,
		value: "Criminal behaviour"
	},
	{
		id: 101,
		parentId: 8,
		value: "Expert witness"
	},
	{
		id: 102,
		parentId: 8,
		value: "Family court issues"
	},
	{
		id: 103,
		parentId: 8,
		value: "Legal report"
	},
	{
		id: 104,
		parentId: 8,
		value: "Management of offenders"
	},
	{
		id: 105,
		parentId: 8,
		value: "Sex offenders"
	}
]

export {
	languages,
	qualifications,
	designation,
	age,
	experience,
	gender,
	specializationParentLevel,
	specializationChildLevel
}
