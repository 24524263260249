import {Button} from "@uprise/button"
import {Col, Container, Row} from "@uprise/grid"
import {H3} from "@uprise/headings"
import React, {Fragment} from "react"

function CalendarList({hide, calendarId, extraCalendars, validCals}) {
	return (
		<Fragment>
			<H3 className='m-b-6' data-testid='calendarHeader'>
				Calendars
			</H3>

			<Container>
				<Row style={{marginBottom: "10px"}}>
					<Col className='col-11 col-lg-11 col-md-11 col-sm-11 text-break'>{calendarId}</Col>
					<Col className='col-1 col-lg-1 col-md-1 col-sm-1'>
						<span>
							{validCals[calendarId] === "owner"
								? "📘"
								: validCals[calendarId] === "writer"
								? "📗"
								: validCals[calendarId] === "reader"
								? "📕"
								: "❌"}
						</span>
					</Col>
				</Row>
				{extraCalendars.map(cal => (
					<Row key={cal} style={{marginBottom: "10px"}}>
						<Col className='col-11 col-lg-11 col-md-11 col-sm-11 text-break'>{cal}</Col>
						<Col className='col-1 col-lg-1 col-md-1 col-sm-1'>
							<span>
								{validCals[cal] === "owner"
									? "📘"
									: validCals[cal] === "writer"
									? "📗"
									: validCals[cal] === "reader"
									? "📕"
									: "❌"}
							</span>
						</Col>
					</Row>
				))}

				<Button
					onClick={hide}
					size='small'
					title={"Close"}
					variant='primary'
					type='button'
					fullWidth={true}
					style={{margin: "40px auto 0"}}
				/>
			</Container>
		</Fragment>
	)
}

export default CalendarList
