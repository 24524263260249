import moment from "moment"

export const calculateCaps = code => {
	const callCapPeriod = code?.caps?.callCapPeriod
	//find the current active caps
	const finalCaps = callCapPeriod?.slice().reverse()?.find(caps => {
		return moment().isAfter(moment(caps.startingDate))
	})
	if (!finalCaps) return { companyCallCap: 0, finalCaps: {} }
	else {
		const companyCallCap =
			code?.caps && code?.caps?.capCalls
				? finalCaps.combinedBookingCaps + finalCaps.therapyBookingCaps + finalCaps.coachingBookingCaps
				: 0
		return { companyCallCap, finalCaps }
	}
}