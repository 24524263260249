import React from "react"
import {FormElementLabel} from "../../../shared/Form/index.style"
import {AvailabilityForm, AvailabilityFormContainer, AvailabilityFormTable, AvailableLable} from "./index.style"

function AvailabilityTable({value: hours, onChange, disabled = false}) {
	const DAY_LEN = 48

	const _hoursToArray = hours => {
		// hours => {0: [..., {fromH, toH, fromM, toM}], 1: [...]}
		// return => Array(7) => [[0,0,0 ...x48], [0,0,0 ...x48], ...]

		let dayHourArray = []
		for (var i = 0; i < 7; i++) {
			let day = new Array(DAY_LEN).fill(0)
			if (!hours[i]?.length) {
				dayHourArray.push(day)
				continue
			}

			for (let j = 0, m = hours[i].length; j < m; j++) {
				let elem = hours[i][j]
				let start = elem.fromH * 2 + (elem.fromM ? 1 : 0)
				let end = elem.toH * 2 + (elem.toM ? 1 : 0)

				if (end === 0 && elem.toH === 0 && elem.fromH) {
					end = 48
				}

				let replaceArray = new Array(end - start).fill(1)
				day.splice(start, replaceArray.length, ...replaceArray)
			}

			dayHourArray.push(day)
		}

		return dayHourArray
	}

	const _transMatrix = arr => {
		let m = arr.length,
			n = arr[0].length,
			arrT = []
		for (var i = 0; i < n; i++) {
			arrT[i] = []
			for (var j = 0; j < m; j++) arrT[i][j] = arr[j][i]
		}
		return arrT
	}

	const _tableToArray = () => {
		let table = document.getElementById("calendar")
		let arr = []
		for (let i = 1; i <= 48; i++) {
			let row = new Array(7).fill(0)
			for (let j = 1; j <= 7; j++) {
				if (table.rows.item(i).cells.item(j).className === "bg-primary") {
					row[j - 1] = 1
				}
			}
			arr.push(row)
		}
		return arr
	}

	const _getHours = dayHourArray => {
		// dayHourArray => Array(7) => [[..0, 0, 1], [..0, 0, 1]]

		let weekIntervals = [] // Array(7) => [ [...,{fromH, fromM, toH, toM}], [..., {fromH, fromM, toH, toM}] ]

		const mapIndexToHoursMinutes = index => {
			let fromH = 0
			let toH = 0
			let fromM = 0
			let toM = 30

			if (index === 0) {
				return {fromH, toH, fromM, toM}
			}

			if (index === 1) {
				return {fromH: 0, fromM: 30, toH: 1, toM: 0}
			}

			if (index === 2) {
				return {fromH: 1, fromM: 0, toH: 1, toM: 30}
			}

			// fromH =   	// 0, 1, 1, 2, 2 , 3, 3
			// toH = 			// 1, 1, 2, 2, 3, 3, 4, 4, 5, 5...ns
			if (index > 2 && !Boolean(index % 2)) {
				// is even
				fromH = index / 2
				toH = index / 2
				fromM = 0
				toM = 30
			} else {
				fromH = Math.floor(index / 2)
				toH = Math.round(index / 2)
				toM = 0
				fromM = 30
			}

			if (index === 47) {
				return {fromH: 23, fromM: 30, toH: 24, toM: 0}
			}

			return {fromH, toH, fromM, toM}
		}

		for (let day = 0, l = dayHourArray.length; day < l; day++) {
			for (let time = 0, m = 48; time < m; time++) {
				let isActive = Boolean(dayHourArray[day][time] === 1)
				let isPreviousActive = time > 0 ? Boolean(dayHourArray[day][time - 1] === 1) : false

				if (isActive) {
					let tempSlotObj = mapIndexToHoursMinutes(time)

					if (isPreviousActive && weekIntervals[day]?.length) {
						let lastSlotObj = weekIntervals[day][weekIntervals[day].length - 1]
						tempSlotObj = {
							fromH: lastSlotObj.fromH,
							fromM: lastSlotObj.fromM,
							toM: tempSlotObj.toM,
							toH: tempSlotObj.toH
						}
						weekIntervals[day].splice(-1, 1)
					}

					if (weekIntervals[day]?.length) {
						weekIntervals[day].push(tempSlotObj)
					} else {
						weekIntervals[day] = [tempSlotObj]
					}
				}
			}
			if (!weekIntervals[day]?.length) {
				weekIntervals[day] = []
			}
		}

		return weekIntervals
	}

	const _onSave = () => {
		let hourDayArray = _tableToArray()
		let dayHourArray = _transMatrix(hourDayArray)
		let hoursArray = _getHours(dayHourArray)
		let hours = {}
		hoursArray.forEach((item, i) => {
			hours[i] = item
		})

		return hours
	}

	const _availableHandler = event => {
		if (disabled) return

		if (event.target.className === "bg-primary") {
			event.target.className = ""
		} else {
			event.target.className = "bg-primary"
		}

		const returnVal = _onSave()
		onChange(returnVal)
	}

	const _getRows = () => {
		if (hours) {
			let dayHourArray = _hoursToArray(hours)
			let hourDayArray = _transMatrix(dayHourArray)

			return hourDayArray.map((row, i) => {
				return (
					<tr key={i}>
						<td key={0}>{i % 2 ? null : `${i / 2}:00`}</td>
						{row.map((day, i) => (
							<td key={i + 1} className={day ? "bg-primary" : ""} onClick={_availableHandler} />
						))}
					</tr>
				)
			})
		} else {
			return (
				<tr>
					<td colSpan={8}>No coach data in database please contact tech support</td>
				</tr>
			)
		}
	}

	return (
		<>
			<AvailabilityFormContainer>
				<FormElementLabel>Legend:</FormElementLabel>
				<AvailableLable available />
				<FormElementLabel> - Available; </FormElementLabel>
				<AvailableLable />
				<FormElementLabel> - Unavailable;</FormElementLabel>
			</AvailabilityFormContainer>
			<AvailabilityForm>
				<AvailabilityFormTable id='calendar'>
					<thead>
						<tr>
							<th>Time</th>
							<th>Sun</th>
							<th>Mon</th>
							<th>Tue</th>
							<th>Wed</th>
							<th>Thu</th>
							<th>Fri</th>
							<th>Sat</th>
						</tr>
					</thead>
					<tbody>{_getRows()}</tbody>
				</AvailabilityFormTable>
			</AvailabilityForm>
		</>
	)
}

export default AvailabilityTable
