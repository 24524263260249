import React, {Fragment, useEffect} from "react"
import styled from "styled-components"

import {useForm, useFieldArray, Controller} from "react-hook-form"
import {Container, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H4, H6} from "@uprise/headings"
import {Button} from "@uprise/button"
import {extended, semantic} from "@uprise/colors"
import {spacing} from "@uprise/spacing"

import DropDown from "components/shared/DropDown"
import PlusIcon from "assets/images/icons/svg/plus.svg"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	align-self: center;
`

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const PrimaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
`

const SecondaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
	border-color: transparent;
	background-color: #f6f4fc;
`

const AddContactButton = styled(Button)`
	outline: none;
	padding-right: 12px;
	&:before {
		margin-top: 5px;
		margin-right: 12px;
		content: url("${PlusIcon}");
		position: relative;
	}
`

const ButtonContainerRow = styled(Row)`
	margin-right: 0;
	margin-left: 0;
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
`

const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`

const Contact = ({onComplete, onPrevious, triggerValidation}) => {
	const {register, control, handleSubmit, reset, trigger, setError, errors} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onChange",
		reValidateMode: "onChange"
	})

	const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
		control,
		name: "contact"
	})

	useEffect(() => {
		if (triggerValidation) {
			trigger()
		}
	}, [triggerValidation, trigger])

	useEffect(() => {
		append({
			firstName: "",
			lastName: "",
			positionTitle: "",
			phoneNumber: "",
			emailAddress: "",
			contactFunction: "",
			contactTier: "",
			budgetAlert: ""
		})
	}, [])

	const handleAddContact = () => {
		append({
			firstName: "",
			lastName: "",
			positionTitle: "",
			phoneNumber: "",
			emailAddress: "",
			contactFunction: "",
			contactTier: "",
			budgetAlert: ""
		})
	}

	const validateName = (name) => {
		return !name.trim() ? 'Field can not be left empty' : true
	}

	return (
		<Container>
			<form
				onSubmit={handleSubmit(data => {
					onComplete(data)
				})}>
				{fields.map((el, index) => {
					return (
						<Fragment key={index}>
							<div style={{display: "flex"}}>
								<H4>Contacts {index + 1}</H4>

								<Button
									style={{marginLeft: "1rem"}}
									width={"50px"}
									padding={spacing.s4}
									onClick={() => remove(index)}
									size={"tiny"}
									variant={"secondary"}
									title={"Delete"}
								/>
							</div>
							<Row>
								<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
									<FormElementContainer
										error={
											errors &&
											errors.contact &&
											errors.contact[index]?.firstName?.type == "required"
										}>
										<FormElementLabel isRequired={true}>First Name</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={`contact[${index}].firstName`}
												rules={{
													required: {
														value: true,
														message: "FirstName is Required"
													},
													validate: validateName,
													pattern: {
														value: /^[a-zA-Z '.-]*$/g,
														message: "Only alphabets and the following characters are allowed:  ' - "
													}
												}}
												data-testid='firstName'
												defaultValue={el.firstName}
												control={control}
												as={
													<TextInputHorizontal
														type='text'
														id='firstName'
														placeholder='First Name'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors.contact &&
									errors.contact[index]?.firstName &&
									errors.contact[index]?.firstName.message ? (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors.contact[index]?.firstName}>
											{errors.contact[index]?.firstName &&
												errors.contact[index]?.firstName.message}
										</MessageStyles>
									) : null}

									<FormElementContainer
										error={
											errors &&
											errors.contact &&
											errors.contact[index]?.lastName?.type == "required"
										}>
										<FormElementLabel isRequired={true}>Last Name</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.lastName}
												name={`contact[${index}].lastName`}
												data-testid='lastName'
												rules={{
													required: {
														value: true,
														message: "LastName is Required"
													},
													validate: validateName,
													pattern: {
														value: /^[a-zA-Z '.-]*$/g,
														message: "Only alphabets and the following characters are allowed:  ' - "
													}
												}}
												as={
													<TextInputHorizontal
														id='lastName'
														type='text'
														placeholder='Last Name'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors.contact &&
									errors.contact[index]?.lastName &&
									errors.contact[index]?.lastName.message ? (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors.contact[index]?.lastName}>
											{errors.contact[index]?.lastName && errors.contact[index]?.lastName.message}
										</MessageStyles>
									) : null}

									<FormElementContainer>
										<FormElementLabel>Position title</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.positionTitle}
												name={`contact[${index}].positionTitle`}
												data-testid='positionTitle'
												as={
													<TextInputHorizontal
														id='positionTitle'
														type='text'
														placeholder='Position title'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors.contact &&
									errors.contact[index]?.positionTitle &&
									errors.contact[index]?.positionTitle.message ? (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors.contact[index]?.positionTitle}>
											{errors.contact[index]?.positionTitle &&
												errors.contact[index]?.positionTitle.message}
										</MessageStyles>
									) : null}

									<FormElementContainer
										error={
											errors &&
											errors.contact &&
											errors.contact[index]?.phoneNumber?.type == "required"
										}>
										<FormElementLabel isRequired={true}>Phone Number</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.phoneNumber}
												name={`contact[${index}].phoneNumber`}
												data-testid='phoneNumber'
												rules={{
													required: {
														value: true,
														message: "Phone Number is required"
													},
													pattern: {
														value: /^\+?[\d ]+$/g,
														message: "Phone number should be valid"
													},
													maxLength: {
														value: 15,
														message: "Phone Number can be a maximum of 15 characters"
													}
												}}
												as={
													<TextInputHorizontal
														id='phoneNumber'
														type='tel'
														placeholder='Phone Number'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors.contact &&
									errors.contact[index]?.phoneNumber &&
									errors.contact[index]?.phoneNumber.message ? (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors.contact[index]?.phoneNumber}>
											{errors.contact[index]?.phoneNumber &&
												errors.contact[index]?.phoneNumber.message}
										</MessageStyles>
									) : null}
								</Col>
								<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
									<FormElementContainer
										error={
											errors &&
											errors.contact &&
											errors.contact[index]?.emailAddress?.type == "required"
										}>
										<FormElementLabel isRequired={true}>Email address</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.emailAddress}
												name={`contact[${index}].emailAddress`}
												data-testid='emailAddress'
												rules={{required: "Email is Required"}}
												as={
													<TextInputHorizontal
														id='emailAddress'
														type='email'
														placeholder='Email address'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors.contact &&
									errors.contact[index]?.emailAddress &&
									errors.contact[index]?.emailAddress.message ? (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors.contact[index]?.emailAddress}>
											{errors.contact[index]?.emailAddress &&
												errors.contact[index]?.emailAddress.message}
										</MessageStyles>
									) : null}
									<FormElementContainer
										error={
											errors &&
											errors.contact &&
											errors.contact[index]?.contactFunction?.type == "required"
										}>
										<FormElementLabel isRequired={true}>Contact function</FormElementLabel>
										<FormInputContainer>
											<Controller
												defaultValue={el.contactFunction}
												name={`contact[${index}].contactFunction`}
												rules={{required: "Contact Function is Required"}}
												render={props => (
													<DropDown
														testId='contactFunction'
														isFirst={true}
														variant='primary'
														items={[
															{
																label: "Main POC",
																value: "poc"
															}
														]}
														label='Contact Function'
														initialSelectedItem={""}
														onChange={item => props.onChange(item.value)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
												control={control}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors.contact &&
									errors.contact[index]?.contactFunction &&
									errors.contact[index]?.contactFunction.message ? (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors.contact[index]?.contactFunction}>
											{errors.contact[index]?.contactFunction &&
												errors.contact[index]?.contactFunction.message}
										</MessageStyles>
									) : null}

									<FormElementContainer
										error={
											errors &&
											errors.contact &&
											errors.contact[index]?.contactTier?.type == "required"
										}>
										<FormElementLabel isRequired={true}>Contact tier</FormElementLabel>
										<FormInputContainer>
											<Controller
												defaultValue={el.contactTier}
												name={`contact[${index}].contactTier`}
												rules={{required: "Contact Tier is Required"}}
												render={props => (
													<DropDown
														testId='contactTier'
														isFirst={true}
														variant='primary'
														items={[
															{
																label: "1st Tier",
																value: 1
															},
															{
																label: "2nd Tier",
																value: 2
															},
															{
																label: "3rd Tier",
																value: 3
															}
														]}
														label='Tier'
														onChange={item => props.onChange(item.value)}
														initialSelectedItem={""}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
												control={control}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors.contact &&
									errors.contact[index]?.contactTier &&
									errors.contact[index]?.contactTier.message ? (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors.contact[index]?.contactTier}>
											{errors.contact[index]?.contactTier &&
												errors.contact[index]?.contactTier.message}
										</MessageStyles>
									) : null}
								</Col>
							</Row>
						</Fragment>
					)
				})}

				<ButtonContainerRow className='flex justify-content-between'>
					<ButtonContainer>
						<AddContactButton
							type='button'
							onClick={handleAddContact}
							size={"small"}
							variant={"primary"}
							title={"Add New Contact"}
						/>
					</ButtonContainer>
					<Row className='flex'>
						<ButtonContainer>
							<SecondaryButton
								type='button'
								data-testid='previousButton'
								variant={"secondary"}
								title={"Previous"}
								onClick={onPrevious}
							/>
						</ButtonContainer>

						<ButtonContainer>
							<PrimaryButton type='submit' variant={"primary"} title={"Next"} data-testid='nextButton' />
						</ButtonContainer>
					</Row>
				</ButtonContainerRow>
			</form>
		</Container>
	)
}

export default Contact
