export function getVersionByCourseType(course) {
	let courseType = null
	if (course?.toLowerCase().includes("students")) {
		courseType = "Students"
	} else if (course?.toLowerCase().includes("trial")) {
		courseType = "Corporate-trial"
	} else if (course?.toLowerCase().includes("rtw")) {
		courseType = "Workable"
	} else if (course?.toLowerCase().includes("uprise-3.1")) {
		courseType = "Corporate"
	} else {
		courseType = null
	}

	return courseType
}

export const courseDropdown = [
	{
		label: "All",
		value: "all"
	},
	{
		label: "Corporate",
		value: "corporate"
	},
	{
		label: "Workable",
		value: "rtw"
	}
]
