import React, {Fragment} from "react"
import moment from "moment"

import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"

// UI
import {Accordian} from "@uprise/accordian"
// Components
import {WHO5BHCResult} from "./WHO5BHCResult"

export const WHO5BHCResults = ({results, tasks}) => {
	const result = results.map((result, index) => {
		const survey = retrieveSurvey(result?.surveyId)
		const taskId = result.taskId
		const currentTask = tasks[taskId]
		const title = currentTask?.title || taskId
		const surveyTasks = Object.keys(result?.answers)
		const date = moment.unix(result.timestamp).format("MMMM Do YYYY, h:mm:ss a")

		let analysis

		try {
			const bonus = result.bonus ? result.bonus : result.answers.WHO5[result.answers.length - 1]
			analysis = survey.analysis(result?.answers, bonus)
		} catch (error) {
			console.log(error)
			analysis = {}
		}

		return {
			header: `${title} - ${date}`,
			content: <WHO5BHCResult surveyTasks={surveyTasks} analysis={analysis.WHO5} survey={survey} />
		}
	})

	return (
		<Fragment>
			<Accordian content={result} />
		</Fragment>
	)
}
