import React, {useState, useEffect, useCallback, useRef, useMemo, Fragment} from "react"
import {useDispatch, useSelector} from "react-redux"
import _ from "lodash"
import {primary} from "@uprise/colors"
import {useAuth0} from "@auth0/auth0-react"

// UI
import {Alert} from "@uprise/alert"
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"

import {getVersionByCourseType, courseDropdown} from "../../../utils/courseType"
// Icons
import IconSearch from "assets/images/icons/svg/search.svg"
// Slices
import {
	userDataSelector,
	fetchUsersData,
	clearUserDetailsState,
	clearUsersApiState,
	clearDisableUserState,
	disableUser,
	fetchUsersByCourse
} from "../usersSlice"
import {authSelector} from "../../Auth/authSlice"
// Components
import Toggle from "components/shared/Toggle"
import Preloader from "components/shared/Preloader/Preloader"
import ComponentWrapper from "components/HOC/ComponentWrapper"
import TableComponent from "components/shared/Table"
import TableHeaderDropdown from "components/shared/TableHeaderDropdown"
import Checkbox from "components/shared/Checkbox"
// Styles
import {
	HeaderTableContent,
	TableFirstColumnText,
	TableColumnContainer,
	EmployerCodeColumn,
	EmployerEmailColumn,
	SearchInput,
	SearchInputContainer,
	SearchInputfigure,
	SearchInputImage,
	IconButton,
	DropdownMenu,
	DropdownItem,
	IconRightSvg
} from "./index.style"
import {HeaderText, ColumnContainer} from "shared/Table/index.style.js"

// Components
import MigrateUser from "./MigrateUserPopup"
import ThreeDotsIcon from "components/Icons/ThreeDots"

const BookingsTable = ({history}) => {
	const [pageCount, setPageCount] = React.useState(0)
	const [usersRowData, setUsersRowData] = useState([])
	const [searchTerm, setSearchTerm] = useState("")
	const [showModal, setShowModal] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const [selectedUsers, setSelectedUsers] = useState({})

	// Auth
	const {getAccessTokenSilently, isLoading, user} = useAuth0()
	const [token, setToken] = useState(null)

	const fetchIdRef = useRef(0)
	const dispatch = useDispatch()

	const {users, userDisableUserApiState, usersApiState} = useSelector(userDataSelector)
	const authState = useSelector(authSelector)

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			const fetchId = ++fetchIdRef.current
			if (fetchId === fetchIdRef.current && token && authState.data && authState.data.id) {
				dispatch(
					fetchUsersData({
						coachId: authState.data.id,
						limit: pageSize,
						skip: pageSize * pageIndex,
						search: searchTerm,
						user,
						token
					})
				)
			}
		},
		[token, authState, searchTerm]
	)

	useEffect(() => {
		async function getToken() {
			if (!isLoading) {
				const token = await getAccessTokenSilently()
				setToken(token)
			}
		}

		getToken()
	}, [isLoading, user])

	useEffect(() => {
		return () => {
			dispatch(clearUsersApiState())
		}
	}, [])

	useEffect(() => {
		if (users?.data?.length > 0) {
			let updatedRow = users.data.map(booking => {
				return {...booking, selected: false}
			})

			setUsersRowData(updatedRow)
			setPageCount(users.total)
		}
	}, [users])

	const _handleDisableUser = (email, disabled) => {
		dispatch(
			disableUser({
				email,
				disabled,
				token
			})
		)
	}

	const _handleSelectRow = email => {
		let updatedData = usersRowData.map(item => {
			if (item.email === email) {
				return {...item, selected: !item.selected}
			} else {
				return {...item}
			}
		})

		setUsersRowData(updatedData)

		const selectedRow = updatedData.find(rowData => rowData.email === email)

		setSelectedUsers(prevUser => {
			if (selectedRow.selected) {
				if (prevUser && prevUser[selectedRow.employerCode]) {
					const _pUser = {...prevUser}
					const isRecordExistIndex = _pUser[selectedRow.employerCode].findIndex(
						row => row.email === selectedRow.email
					)
					if (isRecordExistIndex === -1) {
						_pUser[selectedRow.employerCode].push(selectedRow)
					}
					return _pUser
				}
				return {
					...prevUser,
					[selectedRow.employerCode]: [selectedRow]
				}
			} else {
				// remove
				const _pUser = {...prevUser}
				if (_pUser[selectedRow.employerCode]?.length > 1) {
					// remove element
					return {
						...prevUser,
						[selectedRow.employerCode]: _pUser[selectedRow.employerCode].filter(
							user => user.email !== email
						)
					}
				}
				delete _pUser[selectedRow.employerCode]
				return _pUser
			}
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: () => <HeaderText alignText='left'>User Name</HeaderText>,
				accessor: "name",
				show: true,
				width: "300",
				sortType: "string",
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='left'>
							<Checkbox
								testId='checkbox'
								data-testid='checkBox'
								checked={cellInfo.row.original.selected}
								onChange={e => {
									_handleSelectRow(cellInfo.row.original.email)
								}}
							/>
							<TableFirstColumnText
								onClick={() => {
									dispatch(clearUserDetailsState())
									history.push(`/users/${cellInfo.row.original.email}`)
								}}
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}>
								{cellInfo.row.original.name}
							</TableFirstColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "User Email",
				accessor: "email",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center'>
							<EmployerEmailColumn
								onClick={() => {
									dispatch(clearUserDetailsState())
									history.push(`/users/${cellInfo.row.original.email}`)
								}}
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}>
								{cellInfo.row.original.email}
							</EmployerEmailColumn>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Employer Code",
				accessor: "employerCode",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center'>
							<EmployerCodeColumn
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}
								onClick={() => {
									if (cellInfo.row.original.employerCode) {
										history.push(`/users/code/${cellInfo.row.original.employerCode}`)
									}
								}}>
								{cellInfo.row.original.employerCode}
							</EmployerCodeColumn>
						</ColumnContainer>
					)
				}
			},
			{
				Header: () => <HeaderText alignText='center'>Course</HeaderText>,
				accessor: "course",
				show: true,
				disableSortBy: true,
				Cell: cellInfo => {
					return (
						<TableColumnContainer data-testid='courseValue'>
							<TableFirstColumnText id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{getVersionByCourseType(cellInfo.row.original.course)}
							</TableFirstColumnText>
						</TableColumnContainer>
					)
				}
			},
			{
				Header: () => <HeaderText alignText='center'>Status</HeaderText>,
				accessor: "status",
				show: true,
				disableSortBy: true,
				Cell: e => {
					return (
						<Toggle
							checked={!e.row.original.isAccountDisabled}
							onChange={() => _handleDisableUser(e.row.original.email, !e.row.original.isAccountDisabled)}
							backgroundColorChecked={"#7d60ff"}
							width='42'
							height='24'
							sliderHeight='20.8'
							sliderWidth='20.8'
							translate='18'
							testId='toggle-allUser'
						/>
					)
				}
			}
		],
		[usersRowData]
	)

	const _handleSearch = useCallback(() => {
		_.debounce(
			() => {
				fetchData({pageSize: 20, pageIndex: 0})
			},
			1000,
			{maxWait: 100}
		)
	}, [searchTerm])

	useEffect(() => {
		_handleSearch()

		return _handleSearch.cancel
	}, [searchTerm, _handleSearch])

	useEffect(() => {
		if (userDisableUserApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearDisableUserState())
			}, 3000)
		}
		if (userDisableUserApiState.isError) {
			setTimeout(() => {
				dispatch(clearDisableUserState())
			}, 3000)
		}
	}, [userDisableUserApiState])

	return (
		<ComponentWrapper
			menuActiveIndex={3}
			subMenuActiveIndex={11}
			headerTitle='All Users'
			headerRightAlignContent={
				<HeaderTableContent>
					<SearchInputContainer>
						<SearchInputfigure>
							<SearchInputImage src={IconSearch} />
						</SearchInputfigure>
						<SearchInput
							type={"text"}
							label={"email"}
							placeholder={"Search email"}
							data-testid='searchEmail'
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
							isRequired={false}
						/>

						{Object.keys(selectedUsers).length > 0 && (
							<IconButton onClick={() => setShowDropdown(prev => !prev)}>
								<ThreeDotsIcon fill='#fff' />
								{showDropdown && (
									<DropdownMenu>
										<DropdownItem onClick={() => setShowModal(true)}>
											<IconRightSvg fill={primary.purple} width={16} height={16} /> Migrate
										</DropdownItem>
									</DropdownMenu>
								)}
							</IconButton>
						)}
					</SearchInputContainer>
				</HeaderTableContent>
			}>
			<Fragment>
				{userDisableUserApiState.isSuccess && (
					<Alert className='m-b-5' type='success'>
						{userDisableUserApiState.message}
					</Alert>
				)}
				{userDisableUserApiState.isError && (
					<Alert className='m-b-5' type='error'>
						{userDisableUserApiState.message}
					</Alert>
				)}

				<Card backgroundColor='white' padding={spacing.s10} data-testid='allUsers'>
					<TableComponent
						pageCount={pageCount}
						fetchData={fetchData}
						columns={columns}
						data={usersRowData}
						loading={usersApiState.isFetching || !usersApiState.isSuccess}
					/>
				</Card>

				<MigrateUser
					openModal={showModal}
					setOpenModal={setShowModal}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
					fetchData={fetchData}
				/>
			</Fragment>
		</ComponentWrapper>
	)
}

export default BookingsTable
