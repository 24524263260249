import React, {useMemo, useCallback, useState, useEffect, useRef, Fragment} from "react"
import {useDispatch, useSelector} from "react-redux"
import moment from "moment"
import {useAuth0} from "@auth0/auth0-react"
import _orderBy from "lodash/orderBy"

//Redux
import {fetchUserIntentHistory, userDataSelector} from "../usersSlice"

//Uprise
import {H4} from "@uprise/headings"

//components
import TableComponent from "components/shared/Table"
import TableSortOrder from "components/shared/TableSortOrder"

//style
import {TableContent, ColumnText, ColumnContainer, TableTextArea} from "./index.style"

const NotificationHistory = ({userEmail}) => {
	const [dateSortingOrder, setDateSortingOrder] = useState(0)

	const [pageCount, setPageCount] = React.useState(0)
	const [intentHistoryRows, setintentHistoryRows] = useState([])
	const [token, setToken] = useState(null)
	const {intentNotificationHistory, userNotificationState} = useSelector(userDataSelector)
	const fetchIdRef = useRef(0)

	const dispatch = useDispatch()

	const {getAccessTokenSilently, isLoading, user} = useAuth0()

	useEffect(() => {
		async function getToken() {
			if (!isLoading) {
				const token = await getAccessTokenSilently()
				setToken(token)
			}
		}

		getToken()
	}, [isLoading, user])

	useEffect(() => {
		if (intentNotificationHistory?.data?.length > 0) {
			let updatedRow = intentNotificationHistory.data.map(intentHistory => {
				return intentHistory
			})

			setintentHistoryRows(updatedRow)
			// setTableLoading(false)

			setPageCount(Math.ceil(intentNotificationHistory.total / 5))
		}
	}, [intentNotificationHistory])

	const _handleRowSortingDate = () => {
		let sortedData = intentHistoryRows
		let sortOrder = dateSortingOrder === 1 ? 0 : 1

		if (sortOrder === 1) {
			sortedData = _orderBy(
				intentHistoryRows,
				row => {
					return new Date(row.start)
				},
				["asc"]
			)
		} else {
			sortedData = _orderBy(
				intentHistoryRows,
				row => {
					return new Date(row.start)
				},
				["desc"]
			)
		}

		setDateSortingOrder(sortOrder)
		setintentHistoryRows(sortedData)
	}

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			console.log("fetch Intent History Data is being called")
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current
			// setTableLoading(true)

			if (fetchId === fetchIdRef.current && token) {
				dispatch(
					fetchUserIntentHistory({
						email: userEmail,
						limit: pageSize,
						skip: pageSize * pageIndex,
						token
					})
				)
			}
		},
		[token]
	)

	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: d => {
					if (d?.typeDate) {
						return new Date(moment(d.typeDate, ["DD/MM/YYYY", "YYYY-MM-DD"]).toISOString())
					}

					return null
				},
				sortType: "datetime",
				show: true,
				Cell: ({cell: {value}}) => {
					return (
						<ColumnContainer alignText='center'>
							{value ? <div>{moment(value).format("Do MMM YYYY")}</div> : "-"}
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Intent",
				accessor: "type",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center'>
							<ColumnText id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{cellInfo.row.original.type}
							</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Notification Triggered Date",
				accessor: d => {
					if (d?.triggeredDate) {
						return new Date(moment(d.triggeredDate, ["DD/MM/YYYY", "YYYY-MM-DD"]).toISOString())
					}

					return null
				},
				show: true,
				sortType: "datetime",
				Cell: ({cell: {value}}) => {
					return (
						<ColumnContainer alignText='center'>
							{value ? <div>{moment(value).format("Do MMM YYYY")}</div> : "-"}
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Notes",
				accessor: "notes",
				show: true,

				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center'>
							<TableTextArea id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{cellInfo.row.original.notes}
							</TableTextArea>
						</ColumnContainer>
					)
				}
			}
		],
		[intentHistoryRows]
	)

	return (
		<TableContent>
			<H4 className='m-t-3 m-b-6'>Notification History</H4>

			<TableComponent
				pageCount={pageCount}
				fetchData={fetchData}
				columns={columns}
				data={intentHistoryRows}
				pageSize={5}
				loading={
					userNotificationState.isFetching &&
					!userNotificationState.isSuccess &&
					!userNotificationState.hasErrors
				}
			/>
		</TableContent>
	)
}

export default NotificationHistory
