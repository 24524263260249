import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import {useForm, Controller, useFieldArray} from "react-hook-form"
// UI
import {Container, Row, Col} from "@uprise/grid"
import {H5, H6} from "@uprise/headings"
import {Button} from "@uprise/button"
import {TextInputHorizontal} from "@uprise/form"
// Components
import Toggle from "components/shared/Toggle"
// Helpers
import {features} from "helpers/constants"

const PlanContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FeatureInputContainer = styled.div`
	align-self: center;
`

const ButtonContainer = styled.div`
	display: flex;
`

const PrimaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
`

const SecondaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
	border-color: transparent;
	background-color: #f6f4fc;
`

const Plan = ({onComplete, onPrevious}) => {
	const {control, handleSubmit} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		shouldUnregister: false
	})

	const {fields, append, remove} = useFieldArray({
		control,
		name: "features"
	})

	useEffect(() => {
		remove()
		features.map((item, index) => {
			append(item)
			return null
		})
	}, [])

	return (
		<Container>
			<form onSubmit={handleSubmit(data => onComplete(data))}>
				<Row>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<H5>Features</H5>
					</Col>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'></Col>

					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<Fragment>
							{fields.map((el, index) => {
								return (
									<PlanContainer key={index}>
										<div style={{maxWidth: "170px", minWidth: "170px"}}>
											<FormElementLabel>{el.label}</FormElementLabel>
										</div>
										<FeatureInputContainer style={{margin: "0 12px 0 6px"}}>
											<Controller
												name={`features.[${index}].enabled`}
												control={control}
												defaultValue={true}
												render={props => (
													<Toggle
														checked={props.value}
														onChange={() => {
															props.onChange(!props.value)
														}}
														backgroundColorChecked={"#7d60ff"}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												)}
											/>
										</FeatureInputContainer>
									</PlanContainer>
								)
							})}
						</Fragment>
					</Col>
				</Row>

				<Row className='flex-row-reverse mt-4'>
					<ButtonContainer>
						<PrimaryButton type='submit' variant={"primary"} title={"Done"} data-testid='doneButton' />
					</ButtonContainer>
					<ButtonContainer>
						<SecondaryButton
							type='button'
							variant={"secondary"}
							title={"Previous"}
							onClick={onPrevious}
							data-testid='previousButton'
						/>
					</ButtonContainer>
				</Row>
			</form>
		</Container>
	)
}

export default Plan
