import {createSlice, current, createAsyncThunk} from "@reduxjs/toolkit"
import moment from "moment"

import {post, get} from "helpers/api"

export const initialState = {
	loading: false,
	hasErrors: false,
	users: [],
	totalCount: 0,
	coaches: [],

	sendRiskCallbackEmailApiState: {
		isSuccess: false,
		isError: false,
		isFetching: false,
		message: ""
	},
	saveRiskCallbackNotesApiState: {
		isSuccess: false,
		isError: false,
		isFetching: false,
		message: ""
	},
	assignRiskcallbackToUserApiState: {
		isSuccess: false,
		isError: false,
		isFetching: false,
		message: ""
	},
	closeRiskCallBackApiState: {
		isSuccess: false,
		isError: false,
		isFetching: false,
		message: ""
	},
	riskCallBackNoteApiState: {
		isSuccess: false,
		isError: false,
		isFetching: false,
		message: ""
	},
	riskCallBackApiState: {
		isSuccess: false,
		isError: false,
		isFetching: false,
		message: "",
		users: [],
		coaches: [],
		totalCount: 0
	},
	modalState: {
		ismailSend: false,
		noteModalState: false,
		sendEmailModalState: false
	},
	sendRiskCallbackNoteApiState: {
		isError: false,
		isSuccess: false,
		isFetching: false,
		message: ""
	}
}

export const fetchRiskCallbackData = createAsyncThunk(
	"riskcallback/fetchRiskCallbackData",
	async ({limit, skip, searchTerm, token}, thunkAPI) => {
		let response = await get(
			`v2/risk-callback?limit=${limit}&skip=${skip}&search=${searchTerm}`,
			{},
			{
				Authorization: `Bearer ${token}`
			}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response.data
		}
	}
)

export const fetchRiskCallbackNote = createAsyncThunk(
	"riskcallback/fetchRiskCallbackNote",
	async ({id, token}, thunkAPI) => {
		const response = await get(
			`v2/risk-callback/note/${id}`,
			{},
			{
				Authorization: `Bearer ${token}`
			}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const closeRiskCallback = createAsyncThunk(
	"riskcallback/closeRiskCallback",
	async ({id, duration, token}, thunkAPI) => {
		const response = await post(
			"v2/risk-callback/status",
			{
				duration: duration,
				riskcallbackid: id
			},
			{},
			{
				Authorization: `Bearer ${token}`
			}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response.data
		}
	}
)

export const assignRiskcallbackToUser = createAsyncThunk(
	"riskcallback/assignRiskcallbackToUser",
	async ({id, coachId, token}, thunkAPI) => {
		const response = await post(
			"v2/risk-callback/assign",
			{
				coachid: coachId,
				riskcallbackid: id
			},
			{},
			{
				Authorization: `Bearer ${token}`
			}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response.data
		}
	}
)

export const sendRiskCallbackEmail = createAsyncThunk(
	"riskcallback/sendRiskCallbackEmail",
	async ({id, email, subject, body, token}, thunkAPI) => {
		const response = await post(
			"v2/risk-callback/email",
			{
				email: email,
				subject: subject,
				body: body,
				riskcallbackid: id
			},
			{},
			{
				Authorization: `Bearer ${token}`
			}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const saveRiskCallbackNotes = createAsyncThunk(
	"riskcallback/saveRiskCallbackNotes",
	async ({id, coachId, email, note, token}, thunkAPI) => {
		const response = await post(
			"v2/risk-callback/note",
			{
				note,
				email,
				coachId,
				riskCallbackId: id
			},
			{},
			{
				Authorization: `Bearer ${token}`
			}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response.data
		}
	}
)

const riskCallbackSlice = createSlice({
	name: "riskcallback",
	initialState: initialState,
	reducers: {
		openEmailModal: state => {
			state.modalState.sendEmailModalState = true
		},
		closeEmailModal: state => {
			state.modalState.sendEmailModalState = false
		},
		openNoteModal: state => {
			state.modalState.noteModalState = true
		},
		closeNoteModal: state => {
			state.modalState.noteModalState = false
		},
		clearSendRiskCallbackNoteApiState: state => {
			state.sendRiskCallbackNoteApiState.isError = false
			state.sendRiskCallbackNoteApiState.isSuccess = false
			state.sendRiskCallbackNoteApiState.isFetching = false
			state.sendRiskCallbackNoteApiState.message = ""
		},
		clearSendRiskCallbackEmailApiState: state => {
			state.sendRiskCallbackEmailApiState.isError = false
			state.sendRiskCallbackEmailApiState.isSuccess = false
			state.sendRiskCallbackEmailApiState.isFetching = false
			state.sendRiskCallbackEmailApiState.message = ""
		},
		clearAssignRiskcallbackToUserApiState: state => {
			state.assignRiskcallbackToUserApiState.isError = false
			state.assignRiskcallbackToUserApiState.isSuccess = false
			state.assignRiskcallbackToUserApiState.isFetching = false
			state.assignRiskcallbackToUserApiState.message = ""
		},
		clearCloseRiskCallBackState: state => {
			state.closeRiskCallBackApiState.isError = false
			state.closeRiskCallBackApiState.isSuccess = false
			state.closeRiskCallBackApiState.isFetching = false
			state.closeRiskCallBackApiState.message = ""
		},
		clearRiskCallBackApiState: state => {
			state.riskCallBackApiState.isError = false
			state.riskCallBackApiState.isSuccess = false
			state.riskCallBackApiState.isFetching = false
			state.riskCallBackApiState.message = ""
			state.riskCallBackApiState.users = []
			state.riskCallBackApiState.coaches = []
			state.riskCallBackApiState.totalCount = 0
			return state
		}
	},
	extraReducers: {
		[sendRiskCallbackEmail.pending]: state => {
			state.sendRiskCallbackEmailApiState.isFetching = true
			state.sendRiskCallbackEmailApiState.isError = false
			state.sendRiskCallbackEmailApiState.isSuccess = false
			state.sendRiskCallbackEmailApiState.message = ""
		},
		[sendRiskCallbackEmail.rejected]: (state, {payload}) => {
			state.sendRiskCallbackEmailApiState.isError = true
			state.sendRiskCallbackEmailApiState.isFetching = false
			state.sendRiskCallbackEmailApiState.message = payload.message
		},
		[sendRiskCallbackEmail.fulfilled]: (state, {payload}) => {
			state.modalState.sendEmailModalState = false
			state.sendRiskCallbackEmailApiState.isSuccess = true
			state.sendRiskCallbackEmailApiState.isError = false
			state.sendRiskCallbackEmailApiState.isFetching = true
			state.sendRiskCallbackEmailApiState.ismailSend = true

			state.riskCallBackApiState.users = state.riskCallBackApiState.users.map(user => {
				if (user.id === payload.data.id) {
					return {
						...payload.data,
						id: payload.data.id,
						name: payload.data.data.name,
						email: payload.data.data.email,
						date: moment(payload.data.createdAt).format("YYYY-MM-DD hh:mm a"),
						notes: payload.data.data.notes,
						code: payload.data.employerCode,
						coachId: payload.data.data.coachId,
						callCaps: payload.data.data.callCaps,
						statusId: payload.data.status,
						status: payload.data.status ? "Active" : "Closed"
					}
				} else {
					return user
				}
			})
			state.sendRiskCallbackEmailApiState.message = "Email Sent Successfully"
		},

		[saveRiskCallbackNotes.pending]: state => {
			state.saveRiskCallbackNotesApiState.isFetching = true
			state.saveRiskCallbackNotesApiState.isError = false
			state.saveRiskCallbackNotesApiState.isSuccess = false
			state.saveRiskCallbackNotesApiState.message = ""
		},
		[saveRiskCallbackNotes.rejected]: (state, {payload}) => {
			console.log(payload)
			state.saveRiskCallbackNotesApiState.isError = true
			state.saveRiskCallbackNotesApiState.isFetching = false
			state.saveRiskCallbackNotesApiState.message = payload.message
		},
		[saveRiskCallbackNotes.fulfilled]: (state, {payload}) => {
			state.riskCallBackApiState.users = state.riskCallBackApiState.users.map(user => {
				if (user.id === payload.result.id) {
					return {
						...payload.result,
						id: payload.result.id,
						name: payload.result.data.name,
						email: payload.result.data.email,
						date: moment(payload.result.createdAt).format("DD/MM/YYYY hh:mm a"),
						notes: payload.result.data.notes,
						code: payload.result.employerCode,
						coachId: payload.result.data.coachId,
						callCaps: payload.result.data.callCaps,
						statusId: payload.result.status,
						status: payload.result.status ? "Active" : "Closed"
					}
				} else {
					return user
				}
			})

			state.saveRiskCallbackNotesApiState.isError = false
			state.saveRiskCallbackNotesApiState.isSuccess = true
			state.saveRiskCallbackNotesApiState.message = "Risk Callback Notes Saved Successfully"
		},
		[assignRiskcallbackToUser.pending]: state => {
			state.assignRiskcallbackToUserApiState.isFetching = true
			state.assignRiskcallbackToUserApiState.isError = false
			state.assignRiskcallbackToUserApiState.isSuccess = false
			state.assignRiskcallbackToUserApiState.message = ""
		},
		[assignRiskcallbackToUser.rejected]: (state, {payload}) => {
			state.assignRiskcallbackToUserApiState.isError = true
			state.assignRiskcallbackToUserApiState.isFetching = false
			state.assignRiskcallbackToUserApiState.message = payload.message
		},
		[assignRiskcallbackToUser.fulfilled]: (state, {payload}) => {
			state.riskCallBackApiState.users = state.riskCallBackApiState.users.map(user => {
				if (user.id === payload.result.id) {
					return {
						...payload.result,
						id: payload.result.id,
						name: payload.result.data.name,
						email: payload.result.data.email,
						date: moment(payload.result.createdAt).format("DD/MM/YYYY hh:mm a"),
						notes: payload.result.data.notes,
						code: payload.result.employerCode,
						coachId: payload.result.data.coachId,
						callCaps: payload.result.data.callCaps,
						statusId: payload.result.status,
						status: payload.result.status ? "Active" : "Closed"
					}
				} else {
					return user
				}
			})

			state.assignRiskcallbackToUserApiState.isFetching = false
			state.assignRiskcallbackToUserApiState.isError = false
			state.assignRiskcallbackToUserApiState.isSuccess = true
			state.assignRiskcallbackToUserApiState.message = "Risk Callback Assigned Successfully"
		},
		[closeRiskCallback.pending]: state => {
			state.closeRiskCallBackApiState.isFetching = true
			state.closeRiskCallBackApiState.isError = false
			state.closeRiskCallBackApiState.isSuccess = false
			state.closeRiskCallBackApiState.message = ""
		},
		[closeRiskCallback.rejected]: (state, {payload}) => {
			state.closeRiskCallBackApiState.isError = true
			state.closeRiskCallBackApiState.isFetching = false
			state.closeRiskCallBackApiState.isSuccess = false

			if (payload.errors) {
				payload.errors.forEach(error => {
					state.closeRiskCallBackApiState.message = `${error.path[0]} ${error.message}`
				})
			}
		},
		[closeRiskCallback.fulfilled]: (state, {payload}) => {
			state.closeRiskCallBackApiState.isSuccess = true
			state.closeRiskCallBackApiState.isError = false
			state.closeRiskCallBackApiState.isFetching = false

			state.riskCallBackApiState.users = state.riskCallBackApiState.users.map(user => {
				if (user.id === payload.result.id) {
					return {
						...payload.result,
						id: payload.result.id,
						name: payload.result.data.name,
						email: payload.result.data.email,
						date: moment(payload.result.createdAt).format("DD/MM/YYYY hh:mm a"),
						notes: payload.result.data.notes,
						code: payload.result.employerCode,
						coachId: payload.result.data.coachId,
						callCaps: payload.result.data.callCaps,
						statusId: payload.result.status,
						status: payload.result.status ? "Active" : "Closed"
					}
				} else {
					return user
				}
			})

			state.closeRiskCallBackApiState.message = "Risk Callback Closed Successfully"
		},
		[fetchRiskCallbackNote.pending]: state => {
			state.riskCallBackNoteApiState.isFetching = true
			state.riskCallBackNoteApiState.isError = false
			state.riskCallBackNoteApiState.isSuccess = false
			state.riskCallBackNoteApiState.message = ""
		},
		[fetchRiskCallbackNote.rejected]: (state, {payload}) => {
			state.riskCallBackNoteApiState.isError = true
			state.riskCallBackNoteApiState.isFetching = false
			state.riskCallBackNoteApiState.message = payload.message
		},
		[fetchRiskCallbackNote.fulfilled]: (state, {payload}) => {
			state.riskCallBackNoteApiState.isFetching = true
			state.riskCallBackNoteApiState.isError = false
			state.riskCallBackNoteApiState.isSuccess = false

			if (Object.values(payload?.data).length > 0) {
				state.riskCallBackApiState.users = state.riskCallBackApiState.users.map(user => {
					console.log("payload", payload)

					if (user.id === payload.data.riskCallbackId) {
						return {
							...user,
							notes: payload.data.content
						}
					} else {
						return user
					}
				})
			}

			return state
		},
		[fetchRiskCallbackData.pending]: state => {
			state.riskCallBackApiState.isFetching = true
			state.riskCallBackApiState.isError = false
			state.riskCallBackApiState.isSuccess = false
			state.riskCallBackApiState.message = ""
		},

		[fetchRiskCallbackData.rejected]: (state, {payload}) => {
			state.riskCallBackApiState.isError = true
			state.riskCallBackApiState.isFetching = false
			state.riskCallBackApiState.message = "Something went wrong while fetching availability"
		},
		[fetchRiskCallbackData.fulfilled]: (state, {payload}) => {
			const riskUsers = payload.users.map(item => {
				return {
					...item,
					id: item.id,
					name: item.data.name,
					email: item.data.email,
					date: moment(item.createdAt).format("DD/MM/YYYY hh:mm a"),
					notes: item.notes,
					code: item.employerCode,
					coachId: item.data.coachId,
					callCaps: item.data.callCaps,
					statusId: item.status,
					status: item.status ? "Active" : "Closed"
				}
			})

			const coaches = payload.coaches.map(coach => {
				return {
					label: coach.name,
					value: coach.id
				}
			})

			state.riskCallBackApiState.coaches = coaches
			state.riskCallBackApiState.users = riskUsers
			state.riskCallBackApiState.totalCount = payload.totalCount
			state.riskCallBackApiState.isFetching = false
			state.riskCallBackApiState.isError = false
			state.riskCallBackApiState.isSuccess = true

			return state
		}
	}
})

export const {
	clearSendRiskCallbackEmailApiState,
	clearCloseRiskCallBackState,
	clearRiskCallBackApiState,
	clearAssignRiskcallbackToUserApiState,
	clearSendRiskCallbackNoteApiState,
	openEmailModal,
	closeEmailModal,
	openNoteModal,
	closeNoteModal
} = riskCallbackSlice.actions

export const riskCallbackDataSelector = state => state.riskcallback

export default riskCallbackSlice
