import styled from "styled-components"
import {primary, backgrounds, extended} from "@uprise/colors"

const AvailabilityFormContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
`
const AvailableLable = styled.div`
	display: inline-block;
	width: 40px;
	height: 20px;
	border: 1px solid ${extended.charcoal.three};
	margin: 0px 10px -5px;
	background-color: ${props => (props.available ? `${primary.purple}` : `${backgrounds.white}`)};
`

const AvailabilityForm = styled.div`
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
`
const AvailabilityFormTable = styled.table`
	width: 100%;
	text-indent: initial;
	border-spacing: 2px;

	tr {
		height: 37px;
		display: table-row;
		vertical-align: inherit;
	}

	th {
		background: #fafafa;
		border-bottom: 1px solid ${extended.charcoal.three};
		border: 1px solid ${extended.charcoal.four};
		vertical-align: bottom;
		padding: 0.75rem;
		display: table-cell;
		font-weight: bold;
	}

	td {
		padding: 0.75rem;
		vertical-align: top;
		border: 1px solid ${extended.charcoal.four};
	}

	td.bg-primary {
		background-color: ${primary.purple} !important;
	}

	tbody {
		display: table-row-group;
		vertical-align: middle;
	}
`

export {AvailabilityFormContainer, AvailableLable, AvailabilityForm, AvailabilityFormTable}
