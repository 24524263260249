import React, {useEffect} from "react"

import ComponentWrapper from "components/HOC/ComponentWrapper"
import NewEmployerImage from "assets/images/svg/new-employer.svg"

import {
	HeaderTableContent,
	SearchUserInput,
	Container,
	HomeImage,
	HomeTitle,
	EmployerHomeDescription,
	EmployerButton,
	NewEmployerButton
} from "./index.style"

const NewEmployer = ({history}) => {
	return (
		<ComponentWrapper
			menuActiveIndex={2}
			subMenuActiveIndex={8}
			headerTitle='Employer'
			headerRightAlignContent={
				<HeaderTableContent>
					{" "}
					<NewEmployerButton
						size={"small"}
						title={"New employer"}
						onClick={() => history.push("/employers/new/form")}
					/>
				</HeaderTableContent>
			}>
			<Container>
				<HomeImage src={NewEmployerImage} />

				<HomeTitle>New Employer Home</HomeTitle>

				<EmployerHomeDescription>
					Setup new employers here to allow seamless account management and customer service
				</EmployerHomeDescription>

				<EmployerHomeDescription>Go ahead and create a new employer</EmployerHomeDescription>

				<EmployerButton
					onClick={() => history.push("/employers/new/form")}
					data-testid='createNewEmployerButton'
					size={"Medium"}
					title={"Create New Employer"}
				/>
			</Container>
		</ComponentWrapper>
	)
}

export default NewEmployer
