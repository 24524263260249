import React, {useMemo, useCallback, useState, useRef, useEffect} from "react"
import _ from "lodash"
import {useAuth0} from "@auth0/auth0-react"
import {useDispatch, useSelector} from "react-redux"

// UI
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
// Slices
import {authSelector} from "features/Auth/authSlice"
import {
	clearCoachesDataState,
	fetchCoachesCalendarData,
	coachDataSelector,
	fetchCoachesData,
	toggleCoachStatus
} from "../coachesSlice"
// Components
import ToggleModal from "components/shared/Modal/ToggleModal"
import {Alerts} from "./Alerts"
// Modals
import CalendarList from "../Modals/CalendarList"
// Styles
import {HeaderText, ColumnContainer, ColumnIcon, ColumnText} from "shared/Table/index.style"
import {HeaderTableContent} from "../../Employers/index.style"
import {StyledImg} from "../../../shared/Form/index.style"
import {
	SearchInput,
	SearchInputContainer,
	SearchInputfigure,
	SearchInputImage
} from "../../Employers/AllEmployers/index.style"

//Assets
import LogoHeadIcon from "@uprise/icons/icons/png/logo-head/logo-head.png"
import IconSearch from "assets/images/icons/svg/search.svg"
import ComponentWrapper from "components/HOC/ComponentWrapper"
import TableComponent from "components/shared/Table"
import Toggle from "components/shared/Toggle"
import CrossIcon from "assets/images/icons/svg/cross.svg"
import TickIcon from "assets/images/icons/svg/Tick.svg"

const AllCoaches = ({history}) => {
	const dispatch = useDispatch()

	const [token, setToken] = useState(null)
	const [pageCount, setPageCount] = React.useState(0)
	const [validCals, setValidCals] = React.useState(0)
	const [coachSearchTermText, setCoachSearchTermText] = useState("")
	const [sortDirection, setSortDirection] = React.useState(1)

	// Selectors
	const {coachesCalendarApiState, coachStatusApiState, allCoachesApiState} = useSelector(coachDataSelector)
	const authState = useSelector(authSelector)

	const {getAccessTokenSilently} = useAuth0()
	const fetchIdRef = useRef(0)

	useEffect(() => {
		// Clear coach data state on unmount
		return () => dispatch(clearCoachesDataState())
	}, [])

	useEffect(() => {
		;(async () => {
			const token = await getAccessTokenSilently()
			setToken(token)
		})()
	}, [])

	useEffect(() => {
		setValidCals(coachesCalendarApiState.validCals)
	}, [coachesCalendarApiState])

	useEffect(() => {
		if (coachStatusApiState.isSuccess) {
			dispatch(
				fetchCoachesData({
					limit: 20,
					skip: 0,
					searchTerm: "",
					token
				})
			)
		}
	}, [coachStatusApiState])

	useEffect(() => {
		if (allCoachesApiState.totalCount > 0) {
			setPageCount(Math.ceil(allCoachesApiState.totalCount / 20))
		}
	}, [allCoachesApiState])

	const sortingFunction = (a, b) => {
		if (a.name < b.name) {
			return Math.sign(sortDirection) === 1 ? -1 : 1
		}
		if (a.name > b.name) {
			return Math.sign(sortDirection) === 1 ? 1 : -1
		}
		return 0
	}

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current

			if (fetchId === fetchIdRef.current && token && authState.data && authState.data.id) {
				dispatch(
					fetchCoachesData({
						limit: pageSize,
						skip: pageSize * pageIndex,
						searchTerm: coachSearchTermText,
						token
					})
				)

				dispatch(fetchCoachesCalendarData({token}))
			}
		},
		[token, authState, coachSearchTermText]
	)

	const _handleSearchCoachNameChange = useCallback(
		_.debounce(
			value => {
				setCoachSearchTermText(value)
			},
			1500,
			{maxWait: 1500}
		),
		[]
	)

	const _handleChangeCoachStatus = async ({id, disabled}) => {
		if (token) {
			const status = disabled ? false : true
			dispatch(toggleCoachStatus({token, id, status}))
		}
	}

	const columns = useMemo(
		() => [
			{
				Header: () => <HeaderText alignText='left'>Coach name</HeaderText>,
				accessor: "name",
				show: true,
				sortType: "string",
				Cell: cellInfo => {
					const {
						cell: {
							row: {
								original: {name, id, data}
							}
						}
					} = cellInfo
					return (
						<ColumnContainer align='flex-start'>
							<StyledImg
								width='30px'
								height='30px'
								src={data?.photos?.imageURL ? data.photos.imageURL : LogoHeadIcon}
								alt='user-icon'
								noMargin
							/>
							<ColumnText
								onClick={() => {
									history.push(`/coaches/${id}`)
								}}
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}>
								{name}
							</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Coach ID",
				accessor: "id",
				show: true,
				Cell: ({
					cell: {
						row: {
							original: {id}
						}
					}
				}) => {
					return (
						<ColumnContainer align='center'>
							<ColumnText>{`#${id}`}</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Location",
				accessor: "data.location",
				show: true,
				Cell: cellInfo => {
					let location = cellInfo.cell.row.original.data?.location
						? cellInfo.cell.row.original.data.location
						: ""

					return (
						<ColumnContainer align='center'>
							<ColumnText>{location}</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Time Zone",
				accessor: "data.timezone",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer align='center'>
							<ColumnText>{cellInfo.cell.row.original.data.timezone}</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Email",
				accessor: "email",
				show: true,
				style: {overflow: "auto"},
				Cell: cellInfo => {
					return (
						<ColumnContainer align='center'>
							<ColumnText>{cellInfo.cell.row.original.email}</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Phone",
				accessor: c => {
					const phNumber = c?.data?.phoneNumber?.replace("+", "")
					return phNumber || ""
				},
				sortType: "number",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer align='center'>
							<ColumnText>{cellInfo.cell.row.original.data.phoneNumber}</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Status",
				accessor: c => {
					return c.disabled ? 1 : 0
				},
				sortType: "number",
				show: true,
				Cell: ({
					cell: {
						row: {
							original: {id, disabled}
						}
					}
				}) => {
					return (
						<Toggle
							checked={!disabled}
							testId='toggle'
							value={disabled}
							onChange={() => {
								_handleChangeCoachStatus({id, disabled})
							}}
							backgroundColorChecked={"#7d60ff"}
							width='42'
							height='24'
							sliderHeight='20.8'
							sliderWidth='20.8'
							translate='18'
						/>
					)
				}
			},
			{
				Header: "Calendar API Status",
				accessor: c => {
					return validCals?.[c?.data?.calendarId] === "owner" || validCals?.[c?.data?.calendarId] === "writer"
						? 1
						: 0
				},
				sortType: "number",
				Cell: x => {
					const {
						cell: {
							row: {
								original: {
									data: {calendarId, extraCalendars}
								}
							}
						}
					} = x

					return (
						<>
							<ToggleModal
								toggle={show => (
									<ColumnContainer align='center' onClick={show} aria-hidden='true'>
										{validCals?.[calendarId] === "owner" || validCals?.[calendarId] === "writer" ? (
											<ColumnIcon data-testid='apiStatus'>
												<img src={TickIcon} alt={"available"} />
											</ColumnIcon>
										) : (
											<ColumnIcon data-testid='apiStatus'>
												<img src={CrossIcon} alt={"Not available"} />
											</ColumnIcon>
										)}
									</ColumnContainer>
								)}
								content={hide => (
									<CalendarList
										hide={hide}
										calendarId={calendarId}
										extraCalendars={extraCalendars}
										validCals={validCals}
									/>
								)}
							/>
						</>
					)
				}
			}
		],
		[dispatch, getAccessTokenSilently, validCals]
	)

	return (
		<ComponentWrapper
			menuActiveIndex={7}
			subMenuActiveIndex={12}
			headerTitle='All Coaches'
			headerRightAlignContent={
				<HeaderTableContent>
					<SearchInputContainer>
						<SearchInputfigure>
							<SearchInputImage src={IconSearch} />
						</SearchInputfigure>
						<SearchInput
							type={"text"}
							label={"email"}
							data-testid='searchByCoachName'
							placeholder={"Search By Coach Name"}
							isRequired={false}
							onChange={e => _handleSearchCoachNameChange(e.target.value)}
						/>
					</SearchInputContainer>
				</HeaderTableContent>
			}>
			<Alerts
				coachStatusApiState={coachStatusApiState}
				allCoachesApiState={allCoachesApiState}
				coachesCalendarApiState={coachesCalendarApiState}
			/>
			<Card padding={spacing.s10} backgroundColor='white' data-testid='coachData'>
				<TableComponent
					pageCount={pageCount}
					fetchData={fetchData}
					columns={columns}
					loading={allCoachesApiState.isFetching || !allCoachesApiState.isSuccess}
					data={Object.values(allCoachesApiState.coaches).sort(sortingFunction)}
				/>
			</Card>
		</ComponentWrapper>
	)
}

export default AllCoaches
