import styled from "styled-components"
import {Button} from "@uprise/button"
import {H2} from "@uprise/headings"
import {P} from "@uprise/text"
import PlusIcon from "assets/images/icons/svg/plus.svg"

export const HeaderTableContent = styled.div`
	display: flex;
`

export const SearchUserInput = styled.input`
	width: 240px;
	height: 32px;
	align-self: center;
	margin-right: 24px;
	border: 0;
	border-bottom: 1px solid #f6f4fc;
`

export const Container = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
`
export const HomeImage = styled.img`
	margin: auto;
`

export const HomeTitle = styled(H2)`
	text-align: center;
	margin: 12px;
`
export const EmployerHomeDescription = styled(P)`
	margin: 12px;
	text-align: center;
`

export const EmployerButton = styled(Button)`
	width: 50%;
	margin: auto;
`

export const NewEmployerButton = styled(Button)`
	outline: none;
	padding-right: 12px;
	&:before {
		margin-top: 5px;
		margin-right: 12px;
		content: url("${PlusIcon}");
		position: relative;
	}
`
