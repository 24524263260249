import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"
// UI
import {Container} from "@uprise/grid"
import {Alert} from "@uprise/alert"
// Slices
import {clearCancelBookingApiState} from "../bookingSlice"

export const Alerts = ({cancelBookingApiState}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (cancelBookingApiState.isError || cancelBookingApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearCancelBookingApiState())
			}, 3000)
		}
	}, [cancelBookingApiState])

	return (
		<Fragment>
			{cancelBookingApiState.isError && (
				<Alert className='m-b-5' type='error'>
					{cancelBookingApiState.message}
				</Alert>
			)}
			{cancelBookingApiState.isSuccess && (
				<Alert className='m-b-5' type='success'>
					Booking successfully cancelled
				</Alert>
			)}
		</Fragment>
	)
}
