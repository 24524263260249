import React, {Fragment} from "react"
import moment from "moment"
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"

// UI
import {Accordian} from "@uprise/accordian"
// Components
import {SurveyResult} from "./SurveyResult"

export const SurveyResults = ({results, tasks}) => {
	const result = results.map((result, index) => {
		const taskId = result.taskId
		const survey = retrieveSurvey(result?.surveyId)
		const currentTask = tasks[taskId]

		const title = currentTask?.title || taskId
		const date = moment.unix(result.timestamp).format("MMMM Do YYYY, h:mm:ss a")
		const summary = survey.getSummaryResults ? survey.getSummaryResults(result?.answers) : []

		if (summary.length) {
			return {
				header: `${title} - ${date}`,
				content: <SurveyResult summary={summary} survey={survey} />
			}
		} else {
			return {
				header: `${title} - ${date}`,
				content: "No data could be found"
			}
		}
	})

	return (
		<Fragment>
			<Accordian className='m-b-5' content={result} />
		</Fragment>
	)
}
