import {useAuth0} from "@auth0/auth0-react"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router"

// UI
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
import {Alert} from "@uprise/alert"
// Slices
import {fetchUsersDataFiltered, userDataSelector, clearDisableUserState, disableUser} from "../usersSlice"
import {authSelector} from "../../Auth/authSlice"
// Styles
import {HeaderText, ColumnContainer, TableFirstColumnText, EmployerCodeColumn} from "../AllUsers/index.style"
// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import Checkbox from "components/shared/Checkbox"
import TableComponent from "components/shared/Table"
import Toggle from "components/shared/Toggle"

const AllUsersFiltered = ({history, match}) => {
	const params = useParams()

	const [pageCount, setPageCount] = React.useState(0)
	const [usersRowData, setUsersRowData] = useState([])
	const [token, setToken] = useState(null)

	const {getAccessTokenSilently, isLoading, user} = useAuth0()

	const fetchIdRef = useRef(0)
	const dispatch = useDispatch()

	const {allUsersFilteredApiState, userDisableUserApiState} = useSelector(userDataSelector)
	const authState = useSelector(authSelector)

	useEffect(() => {
		async function getToken() {
			if (!isLoading) {
				const token = await getAccessTokenSilently()
				setToken(token)
			}
		}

		getToken()
	}, [isLoading, user, getAccessTokenSilently])

	useEffect(() => {
		if (userDisableUserApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearDisableUserState())
			}, 3000)
		}
		if (userDisableUserApiState.isError) {
			setTimeout(() => {
				dispatch(clearDisableUserState())
			}, 3000)
		}
	}, [userDisableUserApiState])

	useEffect(() => {
		if (allUsersFilteredApiState?.data?.length > 0) {
			let updatedRow = allUsersFilteredApiState.data.map(booking => {
				return {...booking, selected: false}
			})

			setUsersRowData(updatedRow)
			setPageCount(allUsersFilteredApiState.total)
		}
	}, [allUsersFilteredApiState])

	const _handleDisableUser = async (email, disabled) => {
		const token = await getAccessTokenSilently()
		dispatch(
			disableUser({
				email,
				disabled,
				token
			})
		)
	}

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			const fetchId = ++fetchIdRef.current
			if (fetchId === fetchIdRef.current && token && authState.data && authState.data.id) {
				dispatch(
					fetchUsersDataFiltered({
						employerCode: params.employerCode,
						coachId: authState.data.id,
						limit: pageSize,
						skip: pageSize * pageIndex,
						user,
						token
					})
				)
			}
		},
		[token, authState, dispatch, params, user]
	)

	const columns = useMemo(
		() => [
			// { Header: "id", accessor: "id", show: false },
			{
				Header: () => <HeaderText alignText='left'>User Name</HeaderText>,
				accessor: "name",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='left'>
							<Checkbox
								checked={cellInfo.row.original.selected}
								onChange={e => {
									e.preventDefault()
									// onSelectRow(cellInfo.row.original.id)
								}}
							/>
							<TableFirstColumnText
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}
								onClick={() => {
									history.push(`/users/${cellInfo.row.original.email}`)
								}}>
								{cellInfo.row.original.name}
							</TableFirstColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "User Email",
				accessor: "email",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer>
							<TableFirstColumnText id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{cellInfo.row.original.email}
							</TableFirstColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Employer Code",
				accessor: "employerCode",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer>
							<EmployerCodeColumn id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{cellInfo.row.original.employerCode}
							</EmployerCodeColumn>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Status",
				// accessor: "start",
				show: true,
				Cell: e => {
					return (
						<Toggle
							checked={!e.row.original.isAccountDisabled}
							onChange={() => _handleDisableUser(e.row.original.email, !e.row.original.isAccountDisabled)}
							backgroundColorChecked={"#7d60ff"}
							width='42'
							height='24'
							sliderHeight='20.8'
							sliderWidth='20.8'
							translate='18'
						/>
					)
				}
			}
		],
		[]
	)

	return (
		<ComponentWrapper
			menuActiveIndex={3}
			subMenuActiveIndex={11}
			headerTitle={`All Users: ${match.params.employerCode}`}>
			{userDisableUserApiState.isSuccess && (
				<Alert className='m-b-5' type='success'>
					{userDisableUserApiState.message}
				</Alert>
			)}
			{userDisableUserApiState.isError && (
				<Alert className='m-b-5' type='error'>
					{userDisableUserApiState.message}
				</Alert>
			)}

			<Card backgroundColor='white' padding={spacing.s10}>
				<TableComponent
					pageCount={pageCount}
					fetchData={fetchData}
					columns={columns}
					loading={allUsersFilteredApiState.isFetching || !allUsersFilteredApiState.isSuccess}
					data={usersRowData}
				/>
			</Card>
		</ComponentWrapper>
	)
}

export default AllUsersFiltered
