import styled from "styled-components"
import {Button} from "@uprise/button"
import {Container} from "@uprise/grid"
import {Select, TextInputHorizontal} from "@uprise/form"
import {text} from "@uprise/typography"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import {backgrounds, extended, primary, semantic} from "@uprise/colors"

export const Wrap = styled.section`
	width: 100vw;
	/* height: 100vh; */
`

export const TableContainer = styled.div`
	/* max-width: 100vw !important; */
	border-radius: 10px;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #ffffff;
`

export const SearchInputContainer = styled.div`
	position: relative;
	display: flex;
`

export const SearchInputfigure = styled.figure`
	position: absolute;
	top: 7px;
	margin: 0;
`

export const SearchInputImage = styled.img``

export const SearchUserInput = styled.input`
	width: 240px;
	height: 32px;
	padding: 0 20px 0 32px;
	align-self: center;
	margin-right: 24px;
	border: 0;
	border-bottom: 1px solid ${primary.purple};
	&:focus {
		outline: none;
	}
`

export const HeaderTableContent = styled.div`
	display: flex;
`

export const AssignTimeContainer = styled.div`
	display: flex;
	gap: 10px;
	justify-content: space-evenly;
	align-items: center;
`

export const AssignTimeInput = styled.input`
	width: 32px;
	height: 32px;
	border-radius: 10px;
	border: solid 1px #af9efd;
`

export const AssigneTimeCloseButton = styled(Button)`
	width: 100%;
	max-width: 60px;
	height: 32px;
	font-family: "Proxima Nova";
	font-size: ${text.t4};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: center;
	color: #f6f4fc;
`

export const CommunicateButton = styled(Button)`
	align-self: center;
	min-width: 75px;
	max-width: 100px;
	height: 32px;
	font-family: "Proxima Nova";
	font-size: ${text.t3};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	text-align: center;
	color: #7d60ff;
`

export const SendEmailFromInputContainer = styled(Container)`
	display: flex;
	width: 100%;
	max-width: 720px;
	justify-content: left;
	margin: 40px auto 0;
	border-bottom: 1px solid #edeafa;
`

export const SendEmailFromInputText = styled(H6)`
	margin: 0;
	width: 100%;
	max-width: 120px;
	text-align: left;
`

export const SendEmailFromInput = styled(TextInputHorizontal)`
	font-size: ${text.t5};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #6d6c79;
`

export const SendEmailSubjectContainer = styled(Container)`
	display: flex;
	justify-content: left;
	width: 100%;
	max-width: 720px;
	margin: 16px auto 24px;
	border-bottom: 1px solid #edeafa;
`

export const SendEmailSubjectText = styled(H6)`
	margin: 0;
	width: 100%;
	max-width: 120px;
	text-align: left;
`

export const SendEmailSubject = styled(TextInputHorizontal)`
	width: 100%;
	max-width: 250px;
	font-family: "Proxima Nova";
	font-size: ${text.t5};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #6d6c79;
`

export const SendEmailBody = styled.textarea`
	border-radius: 10px;
	border: solid 1px #edeafa;
	background-color: #ffffff;
	width: 100%;
	min-height: 308px;
	padding: 10px;
`

export const SendEmailButton = styled(Button)`
	width: 100%;
	max-width: 120px;
	height: 32px;
	margin: 24px 24px 0 auto;
	padding: 22px 9px;
`

export const StatusRowContainer = styled(Container)`
	display: flex;
`
export const StatusRowSpan = styled.span`
	margin: auto;
`

export const StatusRowTest = styled(P)`
	margin: 0;
`
export const RowClickableText = styled.a`
	text-decoration: none;
	cursor: pointer;
	max-width: 130px;
	word-wrap: break-word;
	font-family: "Proxima Nova";
	font-size: ${text.t5};
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #7d60ff;
`

export const FigureIcon = styled.figure`
	display: flex;
	justify-content: ${props => props.align};
	cursor: pointer;
	margin: 0;
`

export const NotesRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 28px 0 9px 0;
`

export const NoteTextArea = styled.textarea`
	border-radius: 10px;
	border: ${props => (props.error ? `solid 1px ${semantic.error}` : "solid 1px #edeafa")};
	background-color: #ffffff;
	width: 100%;
	min-height: 308px;
	padding: 10px;
`

export const NoteModalButtonContainer = styled.div`
	display: flex;
`

export const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`
