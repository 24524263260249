import React from "react"
import styled from "styled-components"
import {Container, Grid, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H4, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {useHistory} from "react-router-dom"
import error from "assets/images/svg/error.svg"

const PageContainer = styled(Container)``

const Heading = styled(H4)`
	text-align: center;
`

const SubHeading = styled(H6)`
	text-align: center;
`

const ImageFigure = styled.figure`
	text-align: center;
`

const Description = styled(P)`
	text-align: center;
`

const CloseButton = styled(Button)`
	width: 100%;
	max-width: 200px;
	margin: auto;
`

const SuccessPage = () => {
	const history = useHistory()
	return (
		<PageContainer>
			<Heading>Error</Heading>
			<ImageFigure>
				<img src={error} alt={""} />
			</ImageFigure>

			<SubHeading>Something went wrong.Please Contact our support</SubHeading>

			{/* <CloseButton title={"Close"} onClick={() => {}} /> */}
		</PageContainer>
	)
}

export default SuccessPage
