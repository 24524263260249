import React, {Fragment, useEffect, useState} from "react"
import {useAuth0} from "@auth0/auth0-react"
import {useDispatch, useSelector} from "react-redux"
import Styled from "styled-components"
import moment from "moment-timezone"
// Uprise
import {H4} from "@uprise/headings"
import {spacing} from "@uprise/spacing"
import {TextArea} from "@uprise/form"
import {Container, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import constraints from "./validation"

// component
import Datepicker from "components/shared/DatePicker"
import Radio from "components/shared/Radio"
// state management redux slice
import {userDataSelector, notifyUserIntentToWork, fetchUserIntentHistory} from "../usersSlice"
// style
import {Content} from "./index.style"
// UI
import {Alert} from "@uprise/alert"

// Validation
var validate = require("validate.js")

const radioOptions = [
	{label: "Expected to return to work", value: "expected-to-return", date: "", allowPreviousDates: false},
	{label: "Already Returned to work", value: "already-returned", date: "", allowPreviousDates: true},
	{label: "Change in return date", value: "change-in-return", date: "", allowPreviousDates: false},
	{label: "Surgery planned date", value: "surgery-planned", date: "", allowPreviousDates: false}
]

const ButtonWrap = Styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom : 15px;
`

const RadioWrap = Styled.div`
 	display: flex;
	justify-content: flex-start;
	margin-bottom : 15px;
`

const NotificationForm = ({userEmail}) => {
	const {getAccessTokenSilently} = useAuth0()
	const [notes, setNotes] = useState()
	const [selectedValue, setSelectedValue] = useState()
	const [selectedValueDate, setSelectedValueDate] = useState(moment())
	const [validation, setValidation] = useState({})
	const [validationRender, setValidationRender] = useState(false)
	const [validationMessage, setValidationMessage] = useState("")
	const [radioOptionValues, setRadioOptionValues] = useState(radioOptions)

	const {profile, userNotificationState} = useSelector(userDataSelector)
	const dispatch = useDispatch()

	const fetchIntentHistory = async userEmail => {
		const token = await getAccessTokenSilently()
		dispatch(
			fetchUserIntentHistory({
				email: userEmail,
				limit: 5,
				skip: 0,
				token
			})
		)
	}

	// Validation
	useEffect(() => {
		if (validationRender) {
			const validation = validate({notes, notificationIntent: selectedValue}, constraints, {format: "custom"})

			setValidation(validation)
		}
	}, [notes, selectedValue, selectedValueDate, validationRender])

	// useEffect(() => {
	// 	if (userNotificationState.isSuccess) {
	// 		fetchIntentHistory(userEmail)
	// 	}
	// }, [userNotificationState, userEmail])

	useEffect(() => {
		if (userEmail) {
			fetchIntentHistory(userEmail)
		}
	}, [userEmail])

	validate.formatters.custom = function (errors) {
		const data = {}
		errors.forEach(function (error) {
			data[error.attribute] = {message: error.error}
		})
		if (errors.length) {
			data["isValid"] = false
		} else {
			data["isValid"] = true
		}
		return data
	}

	const _handleSubmit = async () => {
		const validated = validate({notes, notificationIntent: selectedValue}, constraints, {format: "custom"})
		setValidation(validated)
		setValidationRender(!validated.isValid)
		if (validated.isValid) {
			const token = await getAccessTokenSilently()

			dispatch(
				notifyUserIntentToWork({
					email: profile.email,
					token,
					type: selectedValue,
					typeDate: selectedValueDate,
					notes: notes
				})
			).then(() => {
				fetchIntentHistory(userEmail)
			})

			setNotes("")
			setSelectedValue()
		}
	}

	const _handleRadioChange = value => {
		setSelectedValue(value)
		setSelectedValueDate(moment())
	}

	const _onDateChange = (index, value) => {
		setSelectedValueDate(value)
		let _radioOptionValues = radioOptionValues
		_radioOptionValues[index].date = value
		setRadioOptionValues(_radioOptionValues)
	}

	useEffect(() => {
		console.log(selectedValue)
	}, [selectedValue])

	return (
		<Fragment>
			<H4 className='m-t-3'>Client Notification</H4>
			<Container>
				<Row>
					<Col className='col-md-12'>
						{userNotificationState.isError && (
							<Alert className='m-b-5' type='error'>
								{userNotificationState.message}
							</Alert>
						)}
					</Col>
				</Row>

				<Row>
					<Col className='col-md-12'>
						{validation?.notes?.message && validation?.notificationIntent?.message ? (
							<Alert textAlign='center' type='error'>
								{validation?.notes?.message}
								<br />
								<br />
								{validation?.notificationIntent?.message}
							</Alert>
						) : validation?.notes?.message ? (
							<Alert textAlign='center' type='error'>
								{validation?.notes?.message}
							</Alert>
						) : validation?.notificationIntent?.message ? (
							<Alert textAlign='center' type='error'>
								{validation?.notificationIntent?.message}
							</Alert>
						) : null}
					</Col>
				</Row>

				<Row>
					<Col className='col-md-12'></Col>
				</Row>

				<Row>
					<Col className='col-md-6'>
						{radioOptionValues.map((option, index) => {
							return (
								<RadioWrap className='m-t-10' key={index}>
									<Radio
										width='275px'
										type='radio'
										checked={option.value === selectedValue}
										onChange={e => _handleRadioChange(e.target.value)}
										value={option.value}
										label={option.label}
									/>

									<Datepicker
										isLast={true}
										date={option.date ? option.date : moment()}
										allowPreviousDates={option.allowPreviousDates}
										onChange={e => _onDateChange(index, e.target.value)}
									/>
								</RadioWrap>
							)
						})}

						<TextArea
							label='Notes'
							id='notes'
							placeholder='Enter Notes Here'
							padding={spacing.s5}
							name='notes'
							value={notes}
							onChange={e => setNotes(e.target.value)}
						/>

						<ButtonWrap>
							<Button
								variant='primary'
								title='Notify Client'
								paddingLeft={spacing.s6}
								paddingRight={spacing.s6}
								fullWidth={false}
								size='small'
								onClick={() => _handleSubmit()}
							/>
						</ButtonWrap>
					</Col>
				</Row>
			</Container>
		</Fragment>
	)
}

export default NotificationForm
