import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {Route, Redirect, useHistory} from "react-router-dom"
import {useAuth0} from "@auth0/auth0-react"
// API
import {get} from "./api"
// Components
import {Loader} from "@uprise/loader"
import {useDispatch, useSelector} from "react-redux"
import {fetchCoaches} from "features/Bookings/bookingSlice"
// import {fetchCoachesData} from "features/Coaches/coachesSlice"
import {fetchCourses} from "features/Employers/employerSlice"
import {authSelector, fetchUserDetails} from "features/Auth/authSlice"
import PreLoader from "components/shared/Preloader/Preloader"

const PrivateRoute = ({location, component, auth, path}) => {
	let history = useHistory()

	const dispatch = useDispatch()

	const {data, hasErrors, loggedIn, loading, loaded} = useSelector(authSelector)

	const {getAccessTokenSilently, isLoading, isAuthenticated, error, user} = useAuth0()

	const [errors, setErrors] = useState({})
	const [nextPath] = useState(location.pathname)

	let Component = component

	useEffect(() => {
		if (user) {
			async function getUser() {
				const token = await getAccessTokenSilently()
				dispatch(fetchUserDetails(token, user.email))
				dispatch(fetchCoaches({token}))
				dispatch(fetchCourses({token}))
			}

			getUser()
		}
	}, [user, dispatch, getAccessTokenSilently])

	if (isLoading) {
		return <PreLoader />
	} else if (!isAuthenticated || (!loading && loaded)) {
		return (
			<Route
				path={path}
				render={props => {
					// console.log("isAuthenticated && !hasErrors && loggedIn", isAuthenticated, !hasErrors, loggedIn)
					return isAuthenticated && !hasErrors && loggedIn ? (
						<Component {...props} userState={data} user={user} />
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: {
									from: nextPath,
									errors: errors
								}
							}}
						/>
					)
				}}
			/>
		)
	} else {
		return <PreLoader />
	}
}

export default withRouter(PrivateRoute)
