import React, {useRef, useState} from "react"
import styled from "styled-components"
import Datetime from "react-datetime"
import {func, bool, instanceOf} from "prop-types"
import moment from "moment"
import Styled from "styled-components"
import "react-datetime/css/react-datetime.css"
import "react-dates/initialize"
// Colors
import {extended, semantic} from "@uprise/colors"

const DatePickerWrapper = styled.div`
	margin-top: ${props => (props.isFirst ? "21px" : "0px")};
	margin-bottom: ${props => (props.isLast ? "0px" : "21px")};
`

const LabelStyles = Styled.label`
      font-size: ${props => (props.focused ? "12px" : "16px")};
      color: ${extended.charcoal.two};
      display: block;
      margin-bottom: 4px;
      transition: font-size 0.2s;
`

const DateWrap = Styled.div`
	padding-bottom: 8px;
	padding: ${props => props.padding};
	border: ${props => {
		if (props.focused) {
			return `1px solid ${extended.blue.one}`
		} else if (props.validation?.errors?.length > 0 || props.validation?.message) {
			return `1px solid ${semantic.error}`
		} else {
			return `1px solid ${extended.purple.four}`
		}
	}};

	input {
		border: 0px;
		outline: 0;
	}
`

const MessageStyles = Styled.label`
      font-size: 12px;
      color:  ${props => {
			if (props.focused) {
				return `${extended.blue.one}`
			} else if (props.validation?.errors?.length > 0 || props.validation?.message) {
				return `${semantic.error}`
			} else {
				return `${extended.purple.five}`
			}
		}};
      display: block;
      margin-top: 8px;
      transition: font-size 0.2s;
`

const DatePicker2 = ({
	id,
	name,
	isFirst,
	isLast,
	label,
	validation,
	isRequired,
	padding,
	timezone,
	date,
	onChange,
	allowPreviousDates,
	...props
}) => {
	const [focused, setFocused] = useState(false)
	let inputRef = useRef(null)

	const _onFocus = event => {
		setFocused(true)
	}

	const _onBlur = event => {
		setFocused(false)
	}

	return (
		<DatePickerWrapper isFirst={isFirst} isLast={isLast}>
			<LabelStyles htmlFor={id} focused={focused}>
				{label} {isRequired ? " * " : ""}
			</LabelStyles>
			<DateWrap validation={validation} focused={focused} validation={validation?.[name]} padding={padding}>
				<Datetime
					ref={elem => {
						inputRef = elem
					}}
					id={id}
					name={name}
					value={date}
					timeFormat={true}
					onChange={value => onChange(value)}
					onOpen={() => _onFocus()}
					onClose={() => _onBlur()}
					timeConstraints={{minutes: {step: 30}}}
					inputProps={{required: true, disabled: false}}
					displayTimeZone={timezone}
					dateFormat='MM/DD/YYYY'
				/>
			</DateWrap>

			{/*
				2 formats of error messages
				1. validate.js - validation?.[name]?.errors?.[0]
				2. react-use-form - validation?.[name]?.message
			*/}

			{(validation?.[name]?.errors || validation?.[name]?.message) && (
				<MessageStyles htmlFor={id} focused={focused} validation={validation?.[name]}>
					{validation?.[name]?.errors?.[0] || validation?.[name]?.message}
				</MessageStyles>
			)}
		</DatePickerWrapper>
	)
}

DatePicker2.propTypes = {
	date: instanceOf(moment).isRequired,
	onChange: func.isRequired,
	allowPreviousDates: bool
}

DatePicker2.defaultProps = {
	date: "",
	padding: "10px",
	onChange: () => {},
	allowPreviousDates: false
}

export default DatePicker2
