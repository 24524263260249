import React, {useState, useEffect} from "react"
import {useHistory} from "react-router-dom"
import moment from "moment-timezone"
import {useDispatch, useSelector} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"

import ComponentWrapper from "components/HOC/ComponentWrapper"

import {
	bookingDataSelector,
	clearBookingApiState,
	fetchAllCoachesAvailability,
	fetchCoachAvailability
} from "../bookingSlice"

import {Alerts} from "./Alerts"
import BookingSearch from "./BookingSearch"

// UI
import {ContainerFluid, Row, Col} from "@uprise/grid"

const CreateBooking = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const {getAccessTokenSilently} = useAuth0()
	const [timezones, setTimezones] = useState([])
	const [selectedTimeZone, setSelectedTimezone] = useState("Australia/Sydney")
	const [selectedDate, setSelectedDate] = useState(moment().startOf("day").add(1, "days"))
	const [token, setToken] = useState(null)
	const [dayOffset, setDayOffset] = useState(0)
	const [coachesDropdown, setCoachesDropdown] = useState([])
	const [coachSelected, setCoachSelected] = useState()
	const [coaches, setCoaches] = useState([])

	const {fetchAllCoachesAvailabilityApiState, createBookingApiState, fetchCoachesApiState} =
		useSelector(bookingDataSelector)

	useEffect(() => {
		setTimezones(moment.tz.names().map(item => ({label: item, value: item})))
		async function getToken() {
			const token = await getAccessTokenSilently()
			setToken(token)
		}
		getToken()
	}, [])

	useEffect(() => {
		const coaches = fetchCoachesApiState.data
		setCoaches(coaches)
		setCoachesDropdown(coaches.map(coach => ({label: coach.name, value: coach.id})))
	}, [fetchCoachesApiState])

	useEffect(() => {
		if (selectedDate) {
			const offset = moment(selectedDate).diff(moment().startOf("day"), "days")
			setDayOffset(offset)
		}
	}, [selectedDate])

	const goToResults = () => {
		dispatch(clearBookingApiState())

		history.push({
			pathname: "/bookings/results",
			state: {
				duration: 30,
				timezone: selectedTimeZone,
				coachId: coachSelected,
				dayOffset: dayOffset
			}
		})
	}

	const _handleSearchAvailability = async () => {
		if (coachSelected) _handleSearchWithCoach()
		else
			dispatch(
				fetchAllCoachesAvailability({
					data: {
						duration: 30,
						timezone: selectedTimeZone,
						coachId: coachSelected,
						dayOffset: dayOffset
					},
					token
				})
			).then(res => {
				goToResults()
			})
	}

	const _handleSearchWithCoach = async () => {
		const coach = coaches.find(coach => coach.id === coachSelected)

		dispatch(
			fetchCoachAvailability({
				data: {
					duration: 30,
					email: coach.email,
					timezone: selectedTimeZone,
					week: moment(selectedDate).isoWeek()
				},
				token
			})
		).then(res => {
			goToResults()
		})
	}

	return (
		<ComponentWrapper menuActiveIndex={4} subMenuActiveIndex={12} headerTitle={"Book a Therapy Call"}>
			<ContainerFluid>
				<Row>
					<Col>
						<Alerts
							fetchAllCoachesAvailabilityApiState={fetchAllCoachesAvailabilityApiState}
							createBookingApiState={createBookingApiState}
						/>
					</Col>
				</Row>
				<Row>
					<Col className='col-md-4'>
						<BookingSearch
							timezones={timezones}
							setSelectedTimezone={setSelectedTimezone}
							selectedTimeZone={selectedTimeZone}
							minDate={moment().startOf("day").add(1, "days")}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
							coachesDropdown={coachesDropdown}
							setCoachSelected={setCoachSelected}
							handleSearchAvailability={_handleSearchAvailability}
							isLoading={fetchAllCoachesAvailabilityApiState.isFetching}
						/>
					</Col>
				</Row>
			</ContainerFluid>
		</ComponentWrapper>
	)
}

export default CreateBooking
