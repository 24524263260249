import React, {useState, useEffect} from "react"
import {useDispatch} from "react-redux"

// Colors
import {Modal} from "@uprise/modal"
import {spacing} from "@uprise/spacing"
import {backgrounds} from "@uprise/colors"
import {H3} from "@uprise/headings"

import {
	SendEmailFromInput,
	SendEmailFromInputContainer,
	SendEmailFromInputText,
	SendEmailSubjectContainer,
	SendEmailSubjectText,
	SendEmailSubject,
	SendEmailBody,
	SendEmailButton
} from "./index.style"

import {sendRiskCallbackEmail} from "./riskCallbackSlice"

const EmailModal = ({isOpen, handleClose, emailContent, token}) => {
	const dispatch = useDispatch()

	const [emailState, setEmailState] = useState(emailContent)

	useEffect(() => {
		setEmailState(emailContent)
	}, [emailContent])

	const _onBodyChange = value => {
		setEmailState({email: emailState.email, id: emailState.id, subject: emailState.subject, body: value})
	}

	const _onSubjectChange = value => {
		setEmailState({email: emailState.email, id: emailState.id, subject: value, body: emailState.body})
	}

	const _handleEmailSend = () => {
		dispatch(
			sendRiskCallbackEmail({
				id: emailState.id,
				email: emailState.email,
				subject: emailState.subject,
				body: emailState.body,
				token
			})
		)
	}

	return (
		<Modal
			isOpen={isOpen}
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='800px'
			textAlign='center'
			handleClose={handleClose}>
			<H3 className='m-0'>Send Email</H3>
			<SendEmailFromInputContainer>
				<SendEmailFromInputText>From</SendEmailFromInputText>
				<SendEmailFromInput
					type={"text"}
					id={"email"}
					label={"email"}
					name={"email"}
					style={{marginRight: "21px", marginBottom: "8px"}}
					placeholder={"us.risksupport@uprise.co"}
					value={"us.risksupport@uprise.co"}
					onChange={() => {}}
					isRequired={true}
					disabled={true}
				/>
			</SendEmailFromInputContainer>
			<SendEmailSubjectContainer>
				<SendEmailSubjectText>Subject</SendEmailSubjectText>
				<SendEmailSubject
					type={"text"}
					label={"email"}
					id={"email"}
					style={{
						marginRight: "21px",
						width: "250px",
						marginBottom: "8px"
					}}
					value={emailState.subject}
					onChange={e => _onSubjectChange(e.target.value)}
					name={"email"}
					isRequired={true}
					disabled={false}
				/>
			</SendEmailSubjectContainer>
			<SendEmailBody onChange={e => _onBodyChange(e.target.value)} />
			<SendEmailButton title={"Send"} onClick={() => _handleEmailSend()} />
		</Modal>
	)
}

export default EmailModal
