import React, {Fragment, useEffect, useState} from "react"
import styled from "styled-components"
import {useSelector, useDispatch} from "react-redux"
import {Container, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H6} from "@uprise/headings"
import {Button} from "@uprise/button"
import {useAuth0} from "@auth0/auth0-react"
import {useForm, Controller} from "react-hook-form"
import {extended, semantic} from "@uprise/colors"

import {salesManager, accountManager, companyIndustry, USStates} from "helpers/constants"
import DropDown from "components/shared/DropDown"
import Datepicker from "components/shared/DatePicker"
import moment from "moment"

import {settingsSelector, fetchEAPCenters} from "../../../Settings/settingsSlice"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	position: relative;
	align-self: center;
`

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const PrimaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
`

const SecondaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
	border-color: transparent;
	background-color: #f6f4fc;
`

const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`

const General = ({onComplete, onCancel, triggerValidation}) => {
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()
	const {eapCenters} = useSelector(settingsSelector)
	const [eapdata, seteapData] = useState([])
	const {register, control, handleSubmit, watch, reset, trigger, setError, errors, setValue} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onChange",
		reValidateMode: "onChange"
	})

	useEffect(() => {
		if (triggerValidation) {
			trigger()
		}
	}, [triggerValidation, trigger])

	const watchEAPStatus = watch("eapStatus")

	const fetchEAPData = async () => {
		const token = await getAccessTokenSilently()
		dispatch(
			fetchEAPCenters({
				token
			})
		)
	}
	useEffect(() => {
		if (watchEAPStatus === "ibh") {
			if (eapCenters && Object.entries(eapCenters.data).length === 0) {
				fetchEAPData()
			}
		}
	}, [watchEAPStatus])

	useEffect(() => {
		if (eapCenters.data) {
			const data = eapCenters.data.map(item => {
				return {
					label: item.name,
					value: item.name,
					phone: item.eapPhone
				}
			})

			seteapData(data)
		}
	}, [eapCenters])

	const _handleEAPNameChange = item => {
		if (item) {
			setValue("eapphone", item.phone)
		}
	}

	return (
		<Container>
			<form
				onSubmit={handleSubmit(data => {
					onComplete(data)
				})}>
				<Row>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer error={errors.companyName?.type == "required"}>
							<FormElementLabel isRequired={true}>Company Name</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"companyName"}
									data-testid='companyName'
									control={control}
									rules={{
										required: {
											value: true,
											message: "Company Name is Required"
										},
										maxLength: {
											value: 100,
											message: "Company name can not exceed 100 characters"
										}
									}}
									defaultValue={""}
									as={<TextInputHorizontal id='companyName' type='text' placeholder='Company Name' />}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["companyName"] && errors["companyName"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["companyName"]}>
								{errors["companyName"] && errors["companyName"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Company Industry</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"companyIndustry"}
									control={control}
									defaultValue={""}
									render={props => (
										<DropDown
											testId='companyIndustry'
											isFirst={true}
											variant='primary'
											items={companyIndustry}
											label='Select Item'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={""}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer error={errors.size?.type == "required"}>
							<FormElementLabel isRequired={true}>Size</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"size"}
									data-testid='companySize'
									control={control}
									defaultValue={""}
									rules={{
										required: {
											value: true,
											message: "Company Size is Required"
										},
										min: {
											value: 1,
											message: "Company size should be at least 1"
										}
									}}
									as={
										<TextInputHorizontal
											id='companySize'
											type='number'
											placeholder='Company Size'
										/>
									}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["size"] && errors["size"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
								{errors["size"] && errors["size"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Phone Number</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"phoneNumber"}
									data-testid='phoneNumber'
									control={control}
									rules={{
										pattern: {
											value: /^\+?[\d ]+$/g,
											message: "Phone number should be valid"
										},
										maxLength: {
											value: 15,
											message: "Phone Number can be a maximum of 15 characters"
										}
									}}
									defaultValue={""}
									as={<TextInputHorizontal id='phoneNumber' type='tel' placeholder='Phone Number'/>}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["phoneNumber"] && errors["phoneNumber"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["phoneNumber"]}>
								{errors["phoneNumber"] && errors["phoneNumber"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Address Line 1</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"address"}
									data-testid='address'
									defaultValue={""}
									control={control}
									as={<FormTextArea></FormTextArea>}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>State</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"state"}
									control={control}
									defaultValue={""}
									render={props => (
										<DropDown
											isFirst={true}
											testId='state'
											items={USStates}
											label='State'
											variant='primary'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={""}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>City/suburb</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"city"}
									data-testid='city'
									defaultValue={""}
									control={control}
									as={<TextInputHorizontal id='city' type='text' placeholder='City' />}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Postcode</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"postCode"}
									data-testid='postCode'
									control={control}
									rules={{
										min: {
											value: 0,
											message: "Post Code can not be negative"
										}
									}}
									defaultValue={""}
									as={<TextInputHorizontal id='postCode' type='number' placeholder='PostCode'/>}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["postCode"] && errors["postCode"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["postCode"]}>
								{errors["postCode"] && errors["postCode"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Description</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"description"}
									data-testid='description'
									defaultValue={""}
									rules={{
										maxLength: {
											value: 500,
											message: "Description can not exceed 500 characters"
										}
									}}
									control={control}
									as={<FormTextArea></FormTextArea>}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["description"] && errors["description"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["description"]}>
								{errors["description"] && errors["description"].message}
							</MessageStyles>
						) : null}
					</Col>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer error={errors.salesManager?.type === "required"}>
							<FormElementLabel isRequired={true}>Sales Manager</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"salesManager"}
									control={control}
									defaultValue={""}
									rules={{required: "Please Select a Sales Manager"}}
									render={props => (
										<DropDown
											isFirst={true}
											testId='salesManager'
											items={salesManager}
											label='Sales Manager'
											variant='primary'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={""}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["salesManager"] && errors["salesManager"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["salesManager"]}>
								{errors["salesManager"] && errors["salesManager"].message}
							</MessageStyles>
						) : null}
						<FormElementContainer error={errors.eapStatus?.type == "required"}>
							<FormElementLabel isRequired={true}>EAP Status</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"eapStatus"}
									control={control}
									defaultValue={""}
									rules={{required: "EAP Status is  Required"}}
									render={props => (
										<DropDown
											testId='eapStatus'
											items={[
												{
													label: "IBH entity is the EAP",
													value: "ibh"
												},
												{
													label: "IBH entity is Not the EAP",
													value: "not_ibh"
												}
											]}
											isFirst={true}
											onChange={item => props.onChange(item.value)}
											label='EAP Status'
											variant='primary'
											initialSelectedItem={""}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["eapStatus"] && errors["eapStatus"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["eapStatus"]}>
								{errors["eapStatus"] && errors["eapStatus"].message}
							</MessageStyles>
						) : null}
						{watchEAPStatus && watchEAPStatus === "ibh" ? (
							<Fragment>
								<FormElementContainer error={errors.salesManager?.type === "required"}>
									<FormElementLabel isRequired={true}>EAP Name</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"eapname"}
											control={control}
											defaultValue={""}
											rules={{required: "Please Select EAP Service"}}
											render={props => (
												<DropDown
													isFirst={true}
													items={eapdata}
													label='EAP Service'
													variant='primary'
													onChange={item => {
														_handleEAPNameChange(item)
														props.onChange(item.value)
													}}
													initialSelectedItem={""}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>EAP Phone</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"eapphone"}
											control={control}
											as={
												<TextInputHorizontal
													type='tel'
													disabled={true}
													placeholder='EAP Phone Number'
												/>
											}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{/* <FormElementContainer>
									<FormElementLabel>EAP website</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"eapwebsite"}
											control={control}
											defaultValue={""}
											as={<TextInputHorizontal type='text' placeholder='EAP website' />}
										/>
									</FormInputContainer>
								</FormElementContainer> */}
							</Fragment>
						) : null}

						<FormElementContainer error={errors.launchDate?.type === "required"}>
							<FormElementLabel isRequired={true}>Launch date</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"launchDate"}
									control={control}
									rules={{required: "Launch Date is Required"}}
									defaultValue={moment()}
									render={props => {
										return (
											<Datepicker
												isFirst={true}
												testId='launchDate'
												date={props.value}
												onChange={e => props.onChange(e.target.value)}
											/>
										)
									}}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["launchDate"] && errors["launchDate"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["launchDate"]}>
								{errors["launchDate"] && errors["launchDate"].message}
							</MessageStyles>
						) : null}
						<FormElementContainer error={errors.accountManager?.type === "required"}>
							<FormElementLabel isRequired={true}>Account Manager</FormElementLabel>

							<FormInputContainer>
								<Controller
									name={"accountManager"}
									control={control}
									variant='primary'
									rules={{required: "Please Select an Account Manager"}}
									defaultValue={""}
									render={props => (
										<DropDown
											isFirst={true}
											items={accountManager}
											testId='accountManager'
											variant='primary'
											label='Select Account Manager'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={""}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["accountManager"] && errors["accountManager"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["accountManager"]}>
								{errors["accountManager"] && errors["accountManager"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Notes</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"notes"}
									data-testid='notes'
									control={control}
									rules={{
										maxLength: {
											value: 500,
											message: "Notes can not exceed 500 characters"
										}
									}}
									defaultValue={""}
									as={<FormTextArea></FormTextArea>}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["notes"] && errors["notes"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["notes"]}>
								{errors["notes"] && errors["notes"].message}
							</MessageStyles>
						) : null}
					</Col>
				</Row>
				<Row className='flex-row-reverse'>
					<ButtonContainer>
						<PrimaryButton type='submit' variant={"primary"} title={"Next"} data-testid='nextButton' />
					</ButtonContainer>
					<ButtonContainer>
						<SecondaryButton
							variant={"secondary"}
							title={"Cancel"}
							onClick={onCancel}
							data-testid='cancelButton'
						/>
					</ButtonContainer>
				</Row>
			</form>
		</Container>
	)
}

export default General
