import styled from "styled-components"
import {spacing} from "@uprise/spacing"

export const NoDataDiv = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
`
export const Content = styled.div`
	padding: ${spacing.s6};
`

export const ButtonWrap = styled.div`
	display: flex;
	flex: 1;
	justify-content: space-between;
	flexdirection: row;
`
