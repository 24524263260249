import React from "react"
import {Container, Row, Col} from "@uprise/grid"
import {H4} from "@uprise/headings"
import {Bold} from "@uprise/text"
import {Button} from "@uprise/button"
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"

import DropDown from "components/shared/DropDown"
import DatePicker from "components/shared/DatePicker"
import {Hr} from "../index.style"

const BookingSearch = ({
	timezones,
	setSelectedTimezone,
	selectedTimeZone,
	selectedDate,
	defaultDate,
	setSelectedDate,
	coachesDropdown,
	setCoachSelected,
	handleSearchAvailability,
	isLoading,
	minDate
}) => {
	return (
		<Card padding={spacing.s10} overflow='visible' backgroundColor='white'>
			<Row>
				<Col className='m-t-2 m-b-10'>
					<label>
						<H4>Search Availability</H4>
					</label>
				</Col>
			</Row>

			<Row className='align-items-center justify-content-between'>
				<Col className='col-4 col-lg-4 col-md-4 col-sm-12'>
					<label>
						<Bold color={"#2f2d40"}>Timezone</Bold>
					</label>
				</Col>
				<Col className='col-4 col-lg-6 col-md-6 col-sm-12'>
					<DropDown
						items={timezones}
						testId='timezone'
						label='Select Timezone'
						onChange={e => setSelectedTimezone(e.value)}
						initialSelectedItem={{label: selectedTimeZone, value: selectedTimeZone}}
						menuStyle={{position: "absolute", zIndex: 2}}
						variant={"primary"}
						isLast={true}
						borderRadius={"4px"}
					/>
				</Col>
				<Col className='col-12'>
					<Hr />
				</Col>
			</Row>

			<Row className='align-items-center justify-content-between'>
				<Col className='col-4 col-lg-4 col-md-4 col-sm-12'>
					<label>
						<Bold color={"#2f2d40"}>Date</Bold>
					</label>
				</Col>
				<Col className='col-4 col-lg-6 col-md-6 col-sm-12'>
					<div>
						<DatePicker
							date={selectedDate}
							minDate={minDate}
							testId='date'
							allowPreviousDates={false}
							onChange={e => setSelectedDate(e.target.value)}
							isLast={true}
							borderRadius={4}
							altIcon={true}
						/>
					</div>
				</Col>
				<Col className='col-12'>
					<Hr />
				</Col>
			</Row>

			<Row className='align-items-center justify-content-between'>
				<Col className='col-4 col-lg-4 col-md-4 col-sm-12'>
					<label>
						<Bold color={"#2f2d40"}>Coach</Bold>
					</label>
				</Col>
				<Col className='col-4 col-lg-6 col-md-6 col-sm-12'>
					<DropDown
						items={coachesDropdown}
						label='Any Coach'
						testId='selectCoach'
						onChange={e => setCoachSelected(e.value)}
						initialSelectedItem={""}
						menuStyle={{position: "absolute", zIndex: 2}}
						variant={"primary"}
						isLast={true}
						borderRadius={"4px"}
					/>
				</Col>
				<Col className='col-12'>
					<Hr />
				</Col>
			</Row>

			<Row className='justify-content-end m-t-10 m-b-2'>
				<Col className='col-12'>
					<Button
						onClick={handleSearchAvailability}
						title={"Search"}
						data-testid='searchButton'
						variant={"primary"}
						isLoading={isLoading}
					/>
				</Col>
			</Row>
		</Card>
	)
}

export default BookingSearch
