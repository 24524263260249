import React, {useState, useEffect, useCallback, useRef, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import _ from "lodash"
import moment from "moment"
import {Modal} from "@uprise/modal"
import {H4} from "@uprise/headings"
import {Alert} from "@uprise/alert"
import {spacing} from "@uprise/spacing"

// Internal Dependancy
import ComponentWrapper from "components/HOC/ComponentWrapper"
import TableComponent from "components/shared/Table"
import MultiLevelDropdown from "components/shared/MultiLevelDropdown"
import Checkbox from "components/shared/Checkbox"
import DropDown from "components/shared/DropDown"
import ErrorPage from "components/shared/ErrorPage"
import CustomModal from "./Modal"
import DeleteModal from "./deleteModal"
import {Card} from "@uprise/card"

import IconSearch from "assets/images/icons/svg/search.svg"

import {
	settingsSelector,
	fetchEAPCenters,
	fetchEAPCenterById,
	updateEAPCenter,
	clearApiState,
	createEAPCenter,
	deleteEAPCenter,
	clearSelectedEAPCenterState
} from "../settingsSlice"

import {useAuth0} from "@auth0/auth0-react"

import {
	HeaderTableContent,
	TableContainer,
	FilterDropdownContainer,
	FilterDropdownMenu,
	FilterDropdownMenuItem,
	FilterDropdownHeaderText,
	FilterDropdownMenuItemSpanText,
	TableFirstColumnText,
	FilterDropdownMenuItemLabel,
	ColumnContainer,
	SearchInput,
	CustomButton,
	SearchInputContainer,
	SearchInputfigure,
	AssigneTimeCloseButton,
	AssigneTimeDeleteButton,
	AssignTimeContainer,
	SearchInputImage,
	ContactContainer,
	ContactValueContainer,
	ElementLabel,
	ModalButtonContainer,
	ModalButton
} from "./index.style"
import {Fragment} from "react"

const EAPCenter = ({history}) => {
	const [tableLoading, setTableLoading] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [pageCount, setPageCount] = useState(0)
	const [token, setToken] = useState(null)
	const [modalType, setModalType] = useState("")
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [selectedIdForDelete, setSelectedIdForDelete] = useState("")
	const fetchIdRef = useRef(0)
	const dispatch = useDispatch()
	const {getAccessTokenSilently, isLoading} = useAuth0()

	const {eapCenters, eapCenter, apiState} = useSelector(settingsSelector)

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			// console.log("fetchData is being called")
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current
			setTableLoading(true)

			if (fetchId === fetchIdRef.current && token) {
				dispatch(
					fetchEAPCenters({
						limit: pageSize,
						skip: pageSize * pageIndex,
						token
					})
				)
			}
		},
		[token]
	)

	useEffect(() => {
		console.log("eapCenter", eapCenters)
		if (eapCenters.data.length > 0) {
			setPageCount(Math.ceil(eapCenters.total / 20))

			setTableLoading(false)
		}
	}, [eapCenters])

	useEffect(() => {
		if (apiState.isSuccess || apiState.isError) {
			setTimeout(() => {
				dispatch(clearApiState())
			}, 3000)
		}
	}, [apiState])

	useEffect(() => {
		async function getToken() {
			const token = await getAccessTokenSilently()

			setToken(token)
		}

		getToken()
	}, [])

	const onHeaderFilterCheckboxChanges = id => {
		setState({
			...state,
			companyNameFilters: state.companyNameFilters.map(item => {
				if (item.id === id) {
					return {...item, checked: !item.checked}
				} else {
					return {...item}
				}
			})
		})
	}

	const handleSearchEmployerCodeChange = useCallback(
		_.debounce(
			value => {
				setEmployerCodeSearchTermText(value)
			},
			1500,
			{maxWait: 1500}
		),
		[]
	)

	const _handleModalOpen = type => {
		if (type) {
			setModalType(type)
		} else {
			setModalType("")
		}
		setOpenModal(!openModal)
	}

	const _handleModalClose = () => {
		setModalType("")
		setOpenModal(!openModal)
		dispatch(clearSelectedEAPCenterState())
	}

	const _handleUpdateData = async data => {
		const token = await getAccessTokenSilently()
		if (modalType === "add") {
			dispatch(
				createEAPCenter({
					data,
					token
				})
			)
		} else if (modalType === "update") {
			dispatch(updateEAPCenter({id: data.id, data, token}))
		}

		_handleModalOpen()
	}

	const _handleDeleteData = async () => {
		const token = await getAccessTokenSilently()
		dispatch(
			deleteEAPCenter({
				id: selectedIdForDelete,
				token
			})
		)
		setDeleteModalOpen(!deleteModalOpen)
	}

	const ActionComponent = ({id, row}) => {
		const onUpdateEAPCenter = () => {
			dispatch(fetchEAPCenterById({id, token}))
			_handleModalOpen("update")
		}

		const onDeleteEAPCenter = () => {
			setSelectedIdForDelete(id)
			setDeleteModalOpen(!deleteModalOpen)
		}

		return (
			<AssignTimeContainer>
				<React.Fragment>
					<AssigneTimeCloseButton
						title={"Update"}
						variant={"primary"}
						onClick={onUpdateEAPCenter}
						data-testid='updateButton'
					/>

					<AssigneTimeDeleteButton
						title={"Delete"}
						variant={"primary"}
						onClick={onDeleteEAPCenter}
						data-testid='deleteButton'
					/>
				</React.Fragment>
			</AssignTimeContainer>
		)
	}

	const columns = useMemo(
		() => [
			// { Header: "id", accessor: "id", show: false },
			{
				Header: () => {
					return <ColumnContainer>Name</ColumnContainer>
				},
				accessor: "name",
				show: true
			},
			{
				Header: "Phone Number",
				accessor: "eapPhone",
				show: true
			},
			{
				Header: "Action",
				disableSortBy: true,
				// accessor: "",
				Cell: cellInfo => <ActionComponent id={cellInfo.row.original.id} row={cellInfo.row.original} />,
				show: true
			}
		],
		[token, eapCenters]
	)

	return (
		<ComponentWrapper
			menuActiveIndex={15}
			subMenuActiveIndex={16}
			headerTitle='EAP Center'
			headerRightAlignContent={
				<HeaderTableContent>
					<CustomButton
						onClick={() => _handleModalOpen("add")}
						title={"Add New"}
						size={"small"}
						data-testid='addNewButton'
					/>
				</HeaderTableContent>
			}>
			{apiState.isSuccess && apiState.message ? (
				<Alert className='m-b-5' textAlign='center' type={"success"}>
					{apiState.message}
				</Alert>
			) : apiState.isError && apiState.message ? (
				<Alert className='m-b-5' textAlign='center' type={"error"}>
					{apiState.message}
				</Alert>
			) : null}
			{apiState.isError ? (
				<div>
					<ErrorPage />
				</div>
			) : (
				<Card padding={spacing.s10} backgroundColor='white'>
					<TableComponent
						pageCount={pageCount}
						fetchData={fetchData}
						columns={columns}
						loading={apiState.isFetching || tableLoading}
						data={eapCenters.data}
					/>
				</Card>
			)}

			<Modal
				isOpen={openModal}
				handleClose={_handleModalClose}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"50%"}
				textAlign='center'>
				<H4>{modalType === "add" ? `New EAP Center` : modalType === "update" ? `Update EAP Center` : ""}</H4>
				<ModalButtonContainer>
					<CustomModal
						data={eapCenter}
						type={modalType}
						onComplete={_handleUpdateData}
						onCancel={_handleModalClose}
					/>
				</ModalButtonContainer>
			</Modal>

			<Modal
				isOpen={deleteModalOpen}
				handleClose={() => setDeleteModalOpen(!deleteModalOpen)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"50%"}
				textAlign='center'>
				<H4>{"Delete Confirmation"}</H4>
				<ModalButtonContainer>
					<DeleteModal onComplete={_handleDeleteData} onCancel={() => setDeleteModalOpen(!deleteModalOpen)} />
				</ModalButtonContainer>
			</Modal>
		</ComponentWrapper>
	)
}

export default EAPCenter
