import styled from "styled-components"
import {Button} from "@uprise/button"
import {Select, TextInputHorizontal} from "@uprise/form"
import {Note} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"

export const Wrap = styled.section`
	width: 100vw;
	/* height: 100vh; */
`
