import styled from "styled-components"
import {Button} from "@uprise/button"
import {TextInputHorizontal, TextInput} from "@uprise/form"
import {H6} from "@uprise/headings"
import {P} from "@uprise/text"

export const SelectedInfoContainer = styled.div`
	width: 100%;
`

export const HeaderInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

export const BookingDetails = styled.div``

export const FormContainer = styled.div`
	display: flex;
`

export const ButtonContainer = styled.div`
	display: flex;
	align-self: center;
`

export const ChangeButton = styled(Button)`
	align-self: center;
	padding: 0 40px;
`

export const Icon = styled.img`
	width: 16px;
	margin-right: 5px;
`

export const Text = styled(P)`
	margin: 0;
`

export const InfoTitle = styled(H6)`
	margin: auto 10px;
`

export const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

export const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

export const FormInputContainer = styled.div`
	display: flex;
	flex-basis: 60%;
	position: relative;
	align-self: center;
`

export const FormTextInput = styled(TextInputHorizontal)``

export const SubmitButton = styled(Button)`
	width: 150px;
	height: 50px;
	margin: 0 6px;
`

export const PhoneInputWrapper = styled.div`
	display: flex;
	flex-basis: 60%;
	position: relative;
	align-self: center;
	justify-content: space-around;
`

export const TextInputWrapper = styled(TextInput)`
	width: 100%;
`
