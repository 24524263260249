import styled from "styled-components"
// UI
import {Button} from "@uprise/button"
import {primary} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
import {P} from "@uprise/text"

export const HeaderTableContent = styled.div`
	display: flex;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #f8f8ff;
	border-radius: 10px;
`

export const ColumnContainer = styled.label`
	display: flex;
	justify-content: ${props => props.alignText};
`

export const ColumnText = styled(P)`
	cursor: ${props => (props.link ? "pointer" : null)};
	padding: 6px 0 6px 12px;
	margin: 0;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
`

export const FilterDropdownContainer = styled.div``

export const FilterDropdownMenu = styled.ul`
	list-style-type: none;
	padding: 0;
`

export const FilterDropdownMenuItem = styled.li`
	display: flex;
	padding: 4px 12px;
	text-align: left;
	list-style-type: none;
	font-family: "Proxima Nova";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	background-color: #fff;
	color: #9796a0;

	&:last-child {
		border-bottom: 0;
	}
`

export const FilterDropdownMenuItemLabel = styled.label`
	display: flex;
`

export const FilterDropdownHeaderText = styled(P)`
	text-align: left;
	margin: 0 0 0 12px;
	font-family: "Proxima Nova";
	font-size: 12px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #9796a0;
`

export const FilterDropdownMenuItemSpanText = styled(P)`
	font-family: "Proxima Nova";
	margin: 0 0 0 20px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #6d6c79;
`

export const SearchInputContainer = styled.div`
	position: relative;
	display: flex;
`

export const SearchInputfigure = styled.figure`
	position: absolute;
	top: 7px;
	margin: 0;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const SearchInputImage = styled.img``

export const SearchInput = styled.input`
	width: 240px;
	height: 32px;
	padding: 0 20px 0 32px;
	align-self: center;
	margin-right: 24px;
	border: 0;
	border-bottom: 1px solid ${primary.purple};
	&:focus {
		outline: none;
	}
`

export const ActionButtonContainer = styled.div`
	display: flex;
	justify-content: ${props => props.alignText};
	cursor: pointer;
`

export const CustomButton = styled(Button)`
	margin: 5px;
`

export const ModalButtonContainer = styled.div``

export const RadioLabel = styled.label`
	margin-bottom: 10px;
	text-align: left;
`

export const RadioDiv = styled.div`
	margin: 10px 0;
	text-align: left;
`

export const F2fIcon = styled.img`
	top: ${spacing.s2};
	display: inline-block;
	position: relative;
`

export const PhoneIcon = styled.img`
	top: ${spacing.s2};
	display: inline-block;
	position: relative;
`

export const VideoIcon = styled.img`
	top: ${spacing.s2};
	display: inline-block;
	position: relative;
`

export const ChatIcon = styled.img`
	top: ${spacing.s2};
	display: inline-block;
	position: relative;
`

export const CancelIcon = styled.img`
	with: 15px;
	height: 15px;
	top: ${spacing.s2};
	display: inline-block;
	position: relative;
`

export const TimeIcon = styled.img`
	with: 15px;
	height: 15px;
	top: ${spacing.s1};
	display: inline-block;
	position: relative;
`

export const TypeText = styled.span`
	margin-left: ${spacing.s3};
	position: relative;
	display: inline-block;
`

export const DurationText = styled.span`
	margin-left: ${spacing.s4};
	position: relative;
	display: inline-block;
`
