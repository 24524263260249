import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {parseEmployerDataForTable} from "./utils"
import {post, get} from "helpers/api"
export const initialState = {
	loading: false,
	hasErrors: false,
	rawData: [],
	employers: {
		total: 0,
		data: []
	},
	masterCodes: [],
	allCodes: [],
	courses: [],
	employerCodeExists: false,
	selectedEmployerDetails: {},
	fetchCodesApiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	employerDataApiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	employerByCodeApiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	utilisationApiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	apiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	createEmployerState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	updateEmployerState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	resetCapsApi: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	}
}

export const fetchEmployerData = createAsyncThunk(
	"employer/fetchEmployerData",
	async ({limit, skip, token, searchTerm}, thunkAPI) => {
		try {
			const response = await get(
				`v2/codes?limit=${limit}&skip=${skip}&search=${searchTerm}`,
				{},
				{Authorization: `Bearer ${token}`}
			)
			if (response.error) {
				return thunkAPI.rejectWithValue(response.error)
			} else {
				return response
			}
		} catch (e) {
			return thunkAPI.rejectWithValue("Something went wrong while fetching data. Please Contact our support")
		}
	}
)

export const fetchEmployerByCode = createAsyncThunk("employer/fetchEmployerByCode", async ({code, token}, thunkAPI) => {
	try {
		const response = await get(`v2/codes/${code}`, {}, {Authorization: `Bearer ${token}`})

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	} catch (e) {
		return thunkAPI.rejectWithValue("Something went wrong, Please try again later")
	}
})

export const fetchMasterCodes = createAsyncThunk("employer/fetchMasterCodes", async ({token}, thunkAPI) => {
	let response = await get("v2/codes/master", {}, {Authorization: `Bearer ${token}`})
	response = response.map(item => item.code)

	return response
})

export const fetchAllCodes = createAsyncThunk("employer/fetchAllCodes", async ({token}, thunkAPI) => {
	let response = await get("v2/codes", {}, {Authorization: `Bearer ${token}`})

	response = response.data.map(item => item.code)

	if (response.error) {
		thunkAPI.rejectWithValue(response.error)
	} else {
		return response
	}
})

export const fetchCourses = createAsyncThunk("employer/fetchCourses", async ({token}, thunkAPI) => {
	let response = await get("v2/crm/courses", {}, {Authorization: `Bearer ${token}`})
	return response
})

export const fetchEmployerCodeUserUtilization = createAsyncThunk(
	"employer/fetchEmployerCodeUserUtilization",
	async ({data, token}, thunkAPI) => {
		try {
			let response = await post("v2/codes/utilisation", data, {}, {Authorization: `Bearer ${token}`})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Something went wrong while fetching data. Please contact our support")
		}
	}
)

export const updateEmployerCodeStatus = createAsyncThunk(
	"employer/updateEmployerCodeStatus",
	async ({data, token}, thunkAPI) => {
		try {
			let response = await post("v2/codes/update/status", data, "application/json", {
				Authorization: `Bearer ${token}`
			})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Something went wrong while updating. Please contact our support")
		}
	}
)

export const updateEmployerDetailsApi = createAsyncThunk(
	"employer/updateEmployerDetailsApi",
	async ({data, token}, thunkAPI) => {
		let response = await post("v2/codes/update", data, "application/json", {Authorization: `Bearer ${token}`})
		if (response.error) {
			thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const resetCapsApi = createAsyncThunk("employer/resetCapsApi", async ({data, token}, thunkAPI) => {
	let response = await post("v2/codes/reset-caps", data, {}, {Authorization: `Bearer ${token}`})

	if (response.error) {
		thunkAPI.rejectWithValue(response.error)
	} else {
		return response
	}
})

export const createEmployer = createAsyncThunk("employer/createEmployer", async ({data, token}, thunkAPI) => {
	let response = await post("v2/codes/create", data, {}, {Authorization: `Bearer ${token}`})

	if (response.status === "OK" && response.status !== 401) {
		return response
	} else {
		thunkAPI.rejectWithValue("Error while Creating Employer")
	}
})

export const findEmployerCodeExists = createAsyncThunk(
	"employer/findEmployerCodeExists",
	async ({code, token}, thunkAPI) => {
		try {
			const response = await get(`v2/codes/${code}`, {}, {Authorization: `Bearer ${token}`})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Something went wrong, Please try again later")
		}
	}
)

const employerSlice = createSlice({
	name: "employer",
	initialState: initialState,
	reducers: {
		clearSelectedEmployerDetails: state => {
			state.selectedEmployerDetails = {}
			return state
		},
		clearEmployerApiState: state => {
			state.employerDataApiState.isFetching = false
			state.employerDataApiState.isError = false
			state.employerDataApiState.isSuccess = false
			state.employerDataApiState.message = ""
			state.createEmployerState.message = ""
			state.createEmployerState.isSuccess = false
			state.createEmployerState.isError = false
			state.createEmployerState.isFetching = false
			state.resetCapsApi.isSuccess = false
			state.resetCapsApi.isError = false
			state.updateEmployerState.isSuccess = false
			state.updateEmployerState.isError = false
			state.updateEmployerState.isFetching = false
			state.updateEmployerState.message = ""
			state.resetCapsApi.isFetching = false

			return state
		},
		clearUpdateEmployerApiState: state => {
			state.updateEmployerState.isSuccess = false
			state.updateEmployerState.isError = false
			state.updateEmployerState.isFetching = false
			state.updateEmployerState.message = ""
			return state
		},
		clearResetCapsApiState: state => {
			state.resetCapsApi.isSuccess = false
			state.resetCapsApi.isError = false
			state.resetCapsApi.isFetching = false
			state.resetCapsApi.message = ""
			return state
		}
	},
	extraReducers: {
		[fetchEmployerData.pending]: state => {
			state.employerDataApiState.isFetching = true
			state.employerDataApiState.isError = false
			state.employerDataApiState.isSuccess = false
		},
		[fetchEmployerData.rejected]: state => {
			state.employerDataApiState.isError = true
			state.employerDataApiState.isFetching = false
			state.employerDataApiState.isSuccess = false
		},
		[fetchEmployerData.fulfilled]: (state, {payload}) => {
			const totalCount = payload.totalCount
			const rawData = payload.data.filter(employer => employer.data.general)

			const employers = rawData.map(employer => {
				return parseEmployerDataForTable(employer)
			})
			state.rawData = rawData
			state.employers.data = employers
			state.employers.total = totalCount
			state.employerDataApiState.isSuccess = true
			state.employerDataApiState.isFetching = false
			state.employerDataApiState.isError = false
		},
		[fetchEmployerByCode.pending]: state => {
			state.employerByCodeApiState.isFetching = true
			state.employerByCodeApiState.isSuccess = false
			state.employerByCodeApiState.isError = false
		},
		[fetchEmployerByCode.rejected]: (state, {payload}) => {
			state.employerByCodeApiState.isError = true
			state.employerByCodeApiState.isFetching = false
			state.employerByCodeApiState.isSuccess = false
			state.employerByCodeApiState.message = payload.message
		},
		[fetchEmployerByCode.fulfilled]: (state, {payload}) => {
			let result = payload.data

			result.data.code.allCodes = [result.code]
			if (result.childCodes && result.childCodes.length > 0) {
				result.data.code = {
					...result.data.code,
					allCodes: [...result.data.code.allCodes, ...result.childCodes.map(childCode => childCode.empCode)],
					subRows: result.childCodes.map(childCode => JSON.parse(childCode.code))
				}
			} else {
				result.data.code = {
					...result.data.code,
					allCodes: [...result.data.code.allCodes, result.data.code.masterCode]
				}
			}
			state.employerByCodeApiState.isFetching = false
			state.employerByCodeApiState.isSuccess = true
			state.employerByCodeApiState.isError = false
			state.employerByCodeApiState.message = payload.message

			state.selectedEmployerDetails = result
		},
		[fetchMasterCodes.pending]: state => {
			state.apiState.isFetching = true
		},
		[fetchMasterCodes.rejected]: state => {
			state.apiState.isError = true
			state.apiState.isFetching = false
		},
		[fetchMasterCodes.fulfilled]: (state, {payload}) => {
			state.apiState.isFetching = false
			state.apiState.isSuccess = true
			const data = payload
			state.masterCodes = data
		},
		[fetchAllCodes.pending]: state => {
			state.fetchCodesApiState.isFetching = true
		},
		[fetchAllCodes.rejected]: state => {
			state.fetchCodesApiState.isError = true
			state.fetchCodesApiState.isFetching = false
		},
		[fetchAllCodes.fulfilled]: (state, {payload}) => {
			state.fetchCodesApiState.isFetching = false
			state.fetchCodesApiState.isSuccess = true
			const data = payload
			state.allCodes = data
		},
		[fetchCourses.pending]: state => {
			state.apiState.isFetching = true
		},
		[fetchCourses.rejected]: state => {
			state.apiState.isError = true
			state.apiState.isFetching = false
		},
		[fetchCourses.fulfilled]: (state, {payload}) => {
			state.apiState.isFetching = false
			// state.apiState.isSuccess = true
			state.courses = payload.data
		},
		[fetchEmployerCodeUserUtilization.pending]: state => {
			state.utilisationApiState.isFetching = true
			state.utilisationApiState.isError = false
		},
		[fetchEmployerCodeUserUtilization.rejected]: state => {
			state.utilisationApiState.isError = true
			state.utilisationApiState.isFetching = false
		},
		[fetchEmployerCodeUserUtilization.fulfilled]: (state, {payload}) => {
			state.utilisationApiState.isSuccess = true
			state.utilisationApiState.isError = false
			state.utilisationApiState.isFetching = false

			state.selectedEmployerDetails = {
				...state.selectedEmployerDetails,
				data: {
					...state.selectedEmployerDetails.data,
					plan: {
						...state.selectedEmployerDetails.data.plan,
						userUtilization: payload.data.results
					}
				}
			}
		},
		[updateEmployerCodeStatus.pending]: state => {
			state.updateEmployerState.isFetching = true
		},
		[updateEmployerCodeStatus.rejected]: state => {
			state.updateEmployerState.isFetching = false
			state.updateEmployerState.isError = true
			state.updateEmployerState.message = "Something went wrong. Please contact our support"
		},
		[updateEmployerCodeStatus.fulfilled]: (state, {payload}) => {
			state.apiState.isSuccess = true
			state.apiState.isFetching = false

			if (state.selectedEmployerDetails.code === payload.code) {
				state.selectedEmployerDetails = {
					...state.selectedEmployerDetails,
					data: {
						...state.selectedEmployerDetails.data,
						code: {
							...state.selectedEmployerDetails.data.code,
							status: payload.data.code.status
						}
					}
				}
			}

			state.updateEmployerState.isFetching = false
			state.updateEmployerState.isSuccess = true
			state.updateEmployerState.message = "Employer Details updated successfully"
		},
		[updateEmployerDetailsApi.pending]: state => {
			state.updateEmployerState.isFetching = true
		},
		[updateEmployerDetailsApi.rejected]: state => {
			state.updateEmployerState.isFetching = false
			state.updateEmployerState.message = "Error while Updating Employer Details"
			state.updateEmployerState.isError = true
		},
		[updateEmployerDetailsApi.fulfilled]: (state, {payload}) => {
			let result = payload.data
			result.data.code.allCodes = [result.code]
			if (result.childCodes && result.childCodes.length > 0) {
				result.data.code = {
					...result.data.code,
					allCodes: [...result.data.code.allCodes, ...result.childCodes.map(childCode => childCode.empCode)],
					subRows: result.childCodes.map(childCode => JSON.parse(childCode.code))
				}
			}
			state.employers.data = state.employers.data.map(employer => {
				if (result.code === employer.code) {
					return parseEmployerDataForTable(result)
				} else {
					return employer
				}
			})
			state.selectedEmployerDetails = result

			state.updateEmployerState.isFetching = false
			state.updateEmployerState.message = "Employer Details Updated Successfully"
			state.updateEmployerState.isSuccess = true
		},
		[createEmployer.pending]: state => {
			state.createEmployerState.isFetching = true
		},
		[createEmployer.rejected]: (state, {payload}) => {
			state.createEmployerState.isFetching = false
			state.createEmployerState.message = payload
			state.createEmployerState.isError = true
		},
		[createEmployer.fulfilled]: state => {
			state.createEmployerState.isFetching = false
			state.createEmployerState.isSuccess = true
		},
		[resetCapsApi.pending]: state => {
			state.resetCapsApi.isFetching = true
		},
		[resetCapsApi.rejected]: (state, {payload}) => {
			state.resetCapsApi.isFetching = false
			state.resetCapsApi.message = payload
			state.resetCapsApi.isError = true
		},
		[resetCapsApi.fulfilled]: (state, {payload}) => {
			state.selectedEmployerDetails.caps = payload.code.caps
			state.resetCapsApi.isFetching = false
			state.resetCapsApi.isSuccess = true
			state.resetCapsApi.message = "Employer Details updated successfully"
		},
		[findEmployerCodeExists.pending]: state => {},
		[findEmployerCodeExists.fulfilled]: (state, {payload}) => {
			state.employerCodeExists = !payload?.error
		},
		[findEmployerCodeExists.rejected]: state => {}
	}
})

export const {
	clearEmployerApiState,
	clearSelectedEmployerDetails,
	clearUpdateEmployerApiState,
	clearResetCapsApiState
} = employerSlice.actions

export const employerSelector = state => state.employer

export default employerSlice
