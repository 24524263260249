import {combineReducers, configureStore} from "@reduxjs/toolkit"
import {createLogger} from "redux-logger"

import riskCallbackSlice from "features/RiskCallBacks/riskCallbackSlice"
import employerSlice from "features/Employers/employerSlice"
import coachSlice from "features/Coaches/coachesSlice"
import bookingSlice from "features/Bookings/bookingSlice"
import authSlice from "features/Auth/authSlice"
import userSlice from "features/Users/usersSlice"
import workableSlice from "features/Workable/workableSlice"
import settingsSlice from "features/Settings/settingsSlice"

const rootReducer = combineReducers({
	riskcallback: riskCallbackSlice.reducer,
	employer: employerSlice.reducer,
	coaches: coachSlice.reducer,
	booking: bookingSlice.reducer,
	auth: authSlice.reducer,
	user: userSlice.reducer,
	workable: workableSlice.reducer,
	setting: settingsSlice.reducer
})

const configureAppStore = (initialState = {}) => {
	const isProduction = Boolean(process.env.NODE_ENV === "production")
	const logger = createLogger({
		collapsed: true
	})
	const middleware = isProduction ? [] : [logger]

	const store = configureStore({
		reducer: rootReducer,
		middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
		devTools: !isProduction,
		preloadedState: initialState
	})

	return {store}
}

export default configureAppStore
