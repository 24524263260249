import React, {Fragment} from "react"
import moment from "moment"

// UI
import {Accordian} from "@uprise/accordian"

import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"
// Components
import {WHO5STRESSResult} from "../WHO5STRESS/WHO5STRESSResult"

export const WHO5STRESSResults = ({results, tasks}) => {
	const result = results.map((result, index) => {
		const survey = retrieveSurvey(result?.surveyId)
		const taskId = result.taskId

		const currentTask = tasks[taskId]
		const title = currentTask?.title || taskId
		const date = moment.unix(result.timestamp).format("MMMM Do YYYY, h:mm:ss a")

		let analysis

		try {
			if (result.answers.WHO5 && result.answers.WHO5.length === 6) {
				const bonus = result.bonus ? result.bonus : result.answers.WHO5[result.answers.length - 1]
				analysis = survey.analysis(result?.answers, bonus)
			} else {
				analysis = survey.analysis(result?.answers)
			}

			const surveyTasks = Object.keys(result?.answers)

			return {
				header: `${title} - ${date}`,
				content: <WHO5STRESSResult key={index} surveyTasks={surveyTasks} analysis={analysis} survey={survey} />
			}
		} catch (error) {
			console.log(error)
			analysis = {}
		}
	})

	return (
		<Fragment>
			<Accordian className='m-b-5' content={result} />
		</Fragment>
	)
}
