import React, {useState, useEffect, Fragment} from "react"
import {useDispatch, useSelector} from "react-redux"

// UI
import {Container} from "@uprise/grid"
import {text} from "@uprise/typography"
import {H4} from "@uprise/headings"
import {P} from "@uprise/text"
import {Alert} from "@uprise/alert"

// Internal Dependancies
import Profile from "./Profile"
import Activity from "./Activity"
import {Notes} from "../Notes"
import Bookings from "./Bookings"

import {useAuth0} from "@auth0/auth0-react"

// Slices
import {
	fetchUsersByEmail,
	fetchBookingsByUserEmail,
	userDataSelector,
	clearUserState,
	clearUserDetailsState,
	clearDisableUserState,
	clearUserNotesApiState,
	clearUserNotificationState,
	clearNewUserApiState
} from "../../Users/usersSlice"
import {clearBookingApiState, bookingDataSelector, clearCancelBookingApiState} from "../../Bookings/bookingSlice"
import {authSelector} from "../../Auth/authSlice"
import {fetchEmployerByCode} from "../../Employers/employerSlice"

import {NotificationPage} from "../ClientNotification"
import ComponentWrapper from "components/HOC/ComponentWrapper"
import Preloader from "components/shared/Preloader/Preloader"
import {Alerts} from "./Alerts"

import {TabsWrapper, Tabs, TabLabel, Tab, ItemContainer, FormItem} from "./index.style"

const UserDetails = ({history, match}) => {
	const dispatch = useDispatch()
	const authState = useSelector(authSelector)

	const [activeIndex, setActiveIndex] = useState(0)

	const [isEdit, setEdit] = useState(false)

	const {getAccessTokenSilently} = useAuth0()
	const {
		userDetails,
		results,
		tasks,
		profile,
		userDetailsState,
		toggleDashboardApiState,
		userBookingsApiState,
		userNotificationState,
		userDisableUserApiState,
		userNotesApiState
	} = useSelector(userDataSelector)

	const {cancelBookingApiState} = useSelector(bookingDataSelector)

	useEffect(() => {
		if (match.params.email) {
			;(async () => {
				const token = await getAccessTokenSilently()

				dispatch(fetchUsersByEmail({email: match.params.email, token}))
				dispatch(fetchBookingsByUserEmail({userEmail: match.params.email, token: token}))
			})()
		}
		return () => {
			dispatch(clearUserState())
			dispatch(clearBookingApiState())
			dispatch(clearUserDetailsState())
			dispatch(clearUserNotificationState())
			dispatch(clearNewUserApiState())
			dispatch(clearUserNotesApiState())
			dispatch(clearDisableUserState())
		}
	}, [])

	useEffect(() => {
		if (userNotesApiState.isError) {
			setTimeout(() => {
				dispatch(clearUserNotesApiState())
			}, 2000)
		}
	}, [userDetailsState, userNotesApiState, dispatch])

	useEffect(() => {
		const getToken = async () => {
			const authToken = await getAccessTokenSilently()
			dispatch(fetchEmployerByCode({code: profile?.employerCode, token: authToken}))
		}
		getToken()
	}, [profile?.employerCode])

	const _disableEdit = () => {
		setEdit(false)
	}

	return (
		<ComponentWrapper
			menuActiveIndex={3}
			subMenuActiveIndex={11}
			headerTitle={`${profile?.name ? ` User: ${profile?.name}` : ``}`}
			headerRightAlignContent={null}>
			<Alerts
				toggleDashboardApiState={toggleDashboardApiState}
				userDetailsState={userDetailsState}
				userBookingsApiState={userBookingsApiState}
				userNotificationState={userNotificationState}
				userNotesApiState={userNotesApiState}
				userDisableUserApiState={userDisableUserApiState}
				cancelBookingApiState={cancelBookingApiState}
				clearCancelBookingApiState={clearCancelBookingApiState}
			/>

			<Container className='m-b-6' data-testid='userDetailsData'>
				{userDetailsState.isFetching && <Preloader />}
				{userDetails && !userDetailsState.isError && userDetailsState.isSuccess && (
					<Fragment>
						<TabsWrapper>
							<Tabs>
								<Tab onClick={() => setActiveIndex(0)} active={activeIndex === 0}>
									<TabLabel firstChild={true} active={activeIndex === 0}>
										{activeIndex === 0 ? <H4>Profile</H4> : <P fontSize={text.t8}>Profile</P>}
									</TabLabel>
								</Tab>

								<Tab onClick={() => setActiveIndex(1)} active={activeIndex === 1}>
									<TabLabel active={activeIndex === 1}>
										{activeIndex === 1 ? <H4>Bookings</H4> : <P fontSize={text.t8}>Bookings</P>}
									</TabLabel>
								</Tab>
								<Tab onClick={() => setActiveIndex(2)} active={activeIndex === 2}>
									<TabLabel active={activeIndex === 2}>
										{activeIndex === 2 ? (
											<H4>Uprise Program Results</H4>
										) : (
											<P fontSize={text.t8}>Uprise Program Results</P>
										)}
									</TabLabel>
								</Tab>
								<Tab onClick={() => setActiveIndex(3)} active={activeIndex === 3}>
									<TabLabel active={activeIndex === 3}>
										{activeIndex === 3 ? <H4>Notes</H4> : <P fontSize={text.t8}>Notes</P>}
									</TabLabel>
								</Tab>

								<Tab onClick={() => setActiveIndex(4)} active={activeIndex === 4}>
									<TabLabel active={activeIndex === 3}>
										{activeIndex === 4 ? (
											<H4>Client Notifications</H4>
										) : (
											<P fontSize={text.t8}>Client Notifications</P>
										)}
									</TabLabel>
								</Tab>
							</Tabs>
						</TabsWrapper>
						<ItemContainer>
							<Fragment>
								<FormItem active={activeIndex === 0}>
									{profile ? (
										<Profile
											isEditEnabled={isEdit && activeIndex === 0}
											onEditCancel={_disableEdit}
											data={profile}
										/>
									) : null}
								</FormItem>
								<FormItem active={activeIndex === 1}>
									<Bookings
										isEditEnabled={isEdit && activeIndex === 2}
										onPrevious={() => setActiveIndex(1)}
									/>
								</FormItem>
								<FormItem active={activeIndex === 2}>
									<Activity
										isEditEnabled={isEdit && activeIndex === 2}
										results={results}
										tasks={tasks}
										onPrevious={() => setActiveIndex(1)}
									/>
								</FormItem>
								<FormItem active={activeIndex === 3}>
									<Notes coachId={authState?.data?.id} userEmail={match.params.email} />
								</FormItem>

								<FormItem active={activeIndex === 4}>
									<NotificationPage userEmail={match.params.email} />
								</FormItem>
							</Fragment>
						</ItemContainer>
					</Fragment>
				)}
			</Container>
		</ComponentWrapper>
	)
}

export default UserDetails
