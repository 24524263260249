const constraints = {
	timezone: {
		presence: {
			message: "^Please select a timezone",
			allowEmpty: false
		}
	},
	name: {
		presence: {
			message: "^Please enter a name",
			allowEmpty: false
		},
		length: {
			maximum: 50,
			message: "^Name exceeds maximum limit."
		}
	},
	email: {
		presence: {
			message: "^Please enter an email address"
		},
		length: {
			maximum: 50,
			message: "^Email exceeds maximum limit."
		},
		email: {
			message: "^Please enter a valid email address",
			allowEmpty: false
		}
	},
	phone: {
		presence: {
			message: "^Please enter a phone number",
			allowEmpty: false
		},
		length: {
    	minimum: 6,
    	message: "^Phone should be minimum 6 characters long"
    },
		format: {
			pattern: "[^a-zA-Z]+",
			message: "can only contain numbers"
		}
	},
	employerCode: {
		presence: {
			message: "^Please select an employer code",
			allowEmpty: false
		}
	},
	countryCode: {
		presence: {
			message: "^Please select a country code",
			allowEmpty: false
		}
	},
	timezone: {
		presence: {
			message: "^Please select users timezone",
			allowEmpty: false
		}
	},
	password: {
		presence: {
			message: "^Please enter a password for the user",
			allowEmpty: false
		},
		length: {
			minimum: 4,
			message: "^Password is too short"
		}
	}
}

export default constraints
