import React, {useState, useEffect, Fragment} from "react"
import moment from "moment-timezone"
import {Container, Grid, Row, Col, ContainerFluid} from "@uprise/grid"
import {P, Bold} from "@uprise/text"
import {H6} from "@uprise/headings"
import {Button} from "@uprise/button"

import DropDown from "components/shared/DropDown"
import AutoComplete from "components/shared/AutoComplete"
import DatePicker from "components/shared/DatePicker"
import {qualifications, languages, gender} from "features/Coaches/NewCoaches/constant"
import {GridRow, StyledBold} from "./index.style.js"

const SearchFilters = ({
	timezones,
	selectedTimeZone,
	setSelectedTimezone,
	selectedDate,
	setSelectedDate,
	coachesDropdown,
	setCoachSelected,
	_handleSearchAvailability,
	setQualification,
	setLanguage,
	setGender,
	coachSelected
}) => {
	const coach = coachesDropdown.find(coach => coach.value === coachSelected)
	return (
		<ContainerFluid>
			<GridRow>
				<H6>Availability for</H6>
				<DropDown
					items={timezones}
					label='Select Timezone'
					onChange={e => setSelectedTimezone(e.value)}
					initialSelectedItem={{label: selectedTimeZone, value: selectedTimeZone}}
					menuStyle={{position: "absolute", zIndex: 2}}
					variant={"white"}
					isLast={true}
					borderRadius={"4px"}
				/>
				<DatePicker
					testId='datePicker'
					date={selectedDate}
					minDate={moment().startOf("day").add(1, "days")}
					onChange={e => setSelectedDate(e.target.value)}
					isLast={true}
					borderRadius={4}
					altIcon={true}
				/>
				<DropDown
					items={coachesDropdown}
					label='Any Coach'
					onChange={e => setCoachSelected(e.value)}
					initialSelectedItem={coach}
					menuStyle={{position: "absolute", zIndex: 2}}
					variant={"white"}
					isLast={true}
					borderRadius={"4px"}
				/>
				<DropDown
					items={gender}
					label='Any Gender'
					onChange={e => setGender(e.value)}
					initialSelectedItem={""}
					isLast={true}
					menuStyle={{position: "absolute", zIndex: 2}}
					variant={"white"}
					borderRadius={"4px"}
				/>
				<DropDown
					items={languages.map(item => ({label: item, value: item}))}
					label='Any Language'
					onChange={e => setLanguage(e.value)}
					initialSelectedItem={""}
					menuStyle={{position: "absolute", zIndex: 2}}
					isLast={true}
					variant={"white"}
					borderRadius={"4px"}
				/>
				<DropDown
					items={qualifications}
					label='Any Qualificaitons'
					onChange={e => setQualification(e.value)}
					initialSelectedItem={""}
					isLast={true}
					menuStyle={{position: "absolute", zIndex: 2}}
					variant={"white"}
					borderRadius={"4px"}
				/>
				<Button
					onClick={_handleSearchAvailability}
					data-testid='searchButton'
					title={"Search"}
					variant={"primary"}
					size={"extra-small"}
					borderRadius={"4px"}
					height={"29px"}
				/>
			</GridRow>
		</ContainerFluid>
	)
}

export default SearchFilters
