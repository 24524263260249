const constraints = {
	notes: {
		presence: {
			message: "^Please Enter Notes",
			allowEmpty: false
		}
	},
	notificationIntent: {
		presence: {
			message: "^Please Select Notification Intent",
			allowEmpty: false
		}
	}
}

export default constraints
