import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"

// UI
import {Container} from "@uprise/grid"
import {Alert} from "@uprise/alert"
// Slices
import {clearDisableUserState, clearToggleDashUserState} from "features/Users/usersSlice"

export const Alerts = ({
	userDetailsState,
	userBookingsApiState,
	userNotificationState,
	userDisableUserApiState,
	toggleDashboardApiState,
	userNotesApiState,
	cancelBookingApiState,
	clearCancelBookingApiState
}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (userDisableUserApiState.isSuccess || userDisableUserApiState.isError) {
			setTimeout(() => {
				dispatch(clearDisableUserState())
			}, 3000)
		}
		if (toggleDashboardApiState.isError || toggleDashboardApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearToggleDashUserState())
			}, 3000)
		}
		if (cancelBookingApiState.isError || cancelBookingApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearCancelBookingApiState())
			}, 3000)
		}
	}, [toggleDashboardApiState, userDisableUserApiState, cancelBookingApiState])

	return (
		<Container className='m-b-6'>
			{!userDetailsState.isError && (
				<Fragment>
					{toggleDashboardApiState.isError && <Alert type='error'>{toggleDashboardApiState.message}</Alert>}
					{toggleDashboardApiState.isSuccess && (
						<Alert type='success'>{toggleDashboardApiState.message}</Alert>
					)}
					{userDisableUserApiState.isError && <Alert type='error'>{userDisableUserApiState.message}</Alert>}
					{userDisableUserApiState.isSuccess && (
						<Alert type='success'>{userDisableUserApiState.message}</Alert>
					)}

					{userBookingsApiState.isError && userBookingsApiState.message && (
						<Alert textAlign='center' type='error'>
							{userBookingsApiState.message}
						</Alert>
					)}

					{userNotificationState.isSuccess && userNotificationState.message ? (
						<Alert textAlign='center' type={"success"}>
							{userNotificationState.message}
						</Alert>
					) : userNotificationState.isError && userNotificationState.message ? (
						<Alert textAlign='center' type={"error"}>
							{userNotificationState.message}
						</Alert>
					) : null}

					{userNotesApiState.isSuccess && userNotesApiState.message ? (
						<Alert textAlign='center' type={"success"}>
							{userNotesApiState.message}
						</Alert>
					) : userNotesApiState.isError && userNotesApiState.message ? (
						<Alert textAlign='center' type={"error"}>
							{userNotesApiState.message}
						</Alert>
					) : null}
				</Fragment>
			)}
			{userDetailsState.isError && (
				<Fragment>
					{userDetailsState.isError && userDetailsState.message && (
						<Alert textAlign='center' type={"error"}>
							{userDetailsState.message}
						</Alert>
					)}
				</Fragment>
			)}
			{cancelBookingApiState.isError && (
				<>
					{cancelBookingApiState.isError && cancelBookingApiState.message && (
						<Alert textAlign='center' type={"error"}>
							{cancelBookingApiState.message}
						</Alert>
					)}
				</>
			)}
			{cancelBookingApiState.isSuccess && (
				<>
					{cancelBookingApiState.isSuccess && cancelBookingApiState.message && (
						<Alert textAlign='center' type={"success"}>
							{cancelBookingApiState.message}
						</Alert>
					)}
				</>
			)}
		</Container>
	)
}
