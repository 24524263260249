import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import moment from "moment"
import {useAuth0} from "@auth0/auth0-react"
import {useForm, Controller, useFieldArray} from "react-hook-form"
import {useSelector, useDispatch} from "react-redux"
// UI
import {Container, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H4, H6, H5} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {extended, semantic} from "@uprise/colors"
// Slices
import {employerSelector, fetchMasterCodes} from "../employerSlice"
import {settingsSelector, fetchEAPCenters} from "../../Settings/settingsSlice"
// Helpers
import {salesManager, accountManager, companyIndustry, australianStates} from "helpers/constants"
// Components
import DropDown from "components/shared/DropDown"
import Datepicker from "components/shared/DatePicker"
import AutoComplete from "components/shared/AutoComplete"
import Radio from "components/shared/Radio"
import Toggle from "components/shared/Toggle"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const NumberInputContainer = styled(FormElementContainer)`
	width: unset;
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormElementPlanLabel = styled(H6)`
	margin: 16px 0 8px 0;
	text-align: left;
	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	position: relative;
	align-self: center;
`

const ToggleContainer = styled(FormInputContainer)`
	display: flex;
	justify-content: flext-start;
`

const FormInputInlineContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const FormTextInput = styled(TextInputHorizontal)``

const FormNumberInputFixedWidth = styled(TextInputHorizontal)`
	width: 80px;
	height: 24px;
	padding: 2px 2px 2px 8px;
	border-radius: 4px;
	border: solid 1px #d4cbfb;
`

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const PrimaryButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
	outline: none;
`

const SecondaryButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
	border-color: transparent;
	background-color: #f6f4fc;
	outline: none;
`

const NavigationButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
`

const FormSpan = styled.span``

const FormSpanText = styled(P)`
	margin: 0 0 0 10px;
`

const FormSpanLabel = styled.label`
	display: flex;
	padding: 10px;
	padding-left: 0;
`

const PlanContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FeaturesInputContainer = styled.div`
	align-self: center;
`

const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`

const MessageStylesCaps = styled(MessageStyles)`
	padding-left: 40%;
	text-align: left;
`

const TextInputWrap = styled.div`
	width: 60%;
	margin-left: 40%;
	display: flex;
`

const EditEmployerDetails = ({data, employerCode, capDetails, expiryDate, onComplete, onCancel}) => {
	const {masterCodes, courses} = useSelector(employerSelector)
	const {eapCenters} = useSelector(settingsSelector)
	const [eapdata, seteapData] = useState([])
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()
	const {register, control, handleSubmit, watch, errors, setValue} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onSubmit",
		reValidateMode: "onChange"
	})

	const {fields, append} = useFieldArray({
		control,
		name: "contact"
	})

	const {fields: featuresFields, append: featuresAppend} = useFieldArray({
		control,
		name: "features"
	})

	const watchEAPStatus = watch("eapStatus")
	const watchCodeType = watch("codeType")

	useEffect(() => {
		if (data.contact && data.contact.length > 0) {
			data.contact.map(item => {
				append(item)
			})
		}

		if (data?.plan?.features?.length > 0) {
			data.plan.features.map(item => {
				console.log(item)
				featuresAppend(item)
				return null
			})
			const isCareNavigatorExists = data.plan.features.some(feature => feature.id === "care-navigator")

			if (!isCareNavigatorExists) {
				featuresAppend({
					enabled: true,
					label: "Care Navigator",
					id: "care-navigator"
				})
			}
		}
	}, [data])

	const fetchData = async () => {
		const token = await getAccessTokenSilently()
		dispatch(fetchMasterCodes({token}))
	}

	const fetchEAPData = async () => {
		const token = await getAccessTokenSilently()
		dispatch(
			fetchEAPCenters({
				token
			})
		)
	}
	useEffect(() => {
		if (watchEAPStatus === "ibh") {
			if (eapCenters && Object.entries(eapCenters.data).length === 0) {
				fetchEAPData()
			}
		}
	}, [watchEAPStatus])

	useEffect(() => {
		if (eapCenters.data) {
			const eapData = eapCenters.data.map(item => {
				return {
					label: item.name,
					value: item.name,
					phone: item.eapPhone
				}
			})

			seteapData(eapData)
		}
	}, [eapCenters])

	const _handleEAPNameChange = item => {
		if (item) {
			setValue("eapphone", item.phone)
		}
	}

	const handleAddContact = e => {
		e.preventDefault()
		append({
			firstName: "",
			lastName: "",
			positionTitle: "",
			phoneNumber: "",
			emailAddress: "",
			contactFunction: "",
			contactTier: "",
			budgetAlert: ""
		})
	}

	const callCapPeriod = capDetails.callCapPeriod

	useEffect(() => {
		const latestCaps = callCapPeriod[callCapPeriod.length - 1]

		if (latestCaps.combinedBookingCaps > 0) {
			setValue("coachingType", "combined", {shouldValidate: true})
		} else {
			setValue("coachingType", "individual", {shouldValidate: true})
		}

		fetchData()
	}, [])

	const watchSessionCap = watch(["coachingType"])
	const watchPlanDates = watch(["codeStartDate", "codeExpiryDate"])
	const watchCapCalls = watch(["capCalls"])

	const planDateCheck = () => {
		return !!(
			watchPlanDates.codeStartDate && (watchPlanDates?.codeStartDate).diff(watchPlanDates.codeExpiryDate) > 0
		)
			? "Code Start Date cant exceed Code Expiry Date"
			: true
	}
	const planDateCheckExpiry = () => {
		return !!(
			watchPlanDates.codeExpiryDate && (watchPlanDates?.codeExpiryDate).diff(watchPlanDates.codeStartDate) < 0
		)
			? "Code Expiry Date cant be before Code Start Date"
			: true
	}

	const validateName = name => {
		return !name.trim() ? "Field can not be left empty" : true
	}

	return (
		<Container>
			<form
				onSubmit={handleSubmit(formData => {
					let latestCaps = callCapPeriod[callCapPeriod.length - 1]
					latestCaps = {
						...latestCaps,
						combinedBookingCaps: parseInt(formData.combinedBookingCaps) || 0,
						therapyBookingCaps: parseInt(formData.therapyBookingCaps) || 0,
						coachingBookingCaps: parseInt(formData.coachingBookingCaps) || 0
					}
					let updatedCaps = [...callCapPeriod]
					updatedCaps[updatedCaps.length - 1] = latestCaps

					let finalData = {
						code: formData.employerCode,
						expiryDate: formData.codeExpiryDate,
						data: {
							general: {
								companyName: formData.companyName,
								companyIndustry: formData.companyIndustry,
								size: formData.size,
								phoneNumber: formData.phoneNumber,
								address: formData.address,
								state: formData.state,
								city: formData.city,
								postCode: formData.postCode,
								description: formData.description,
								salesManager: formData.salesManager,
								eapStatus: formData.eapStatus,
								eapname: formData.eapname,
								eapphone: formData.eapphone,
								eapwebsite: formData.eapwebsite,
								launchDate: formData.launchDate,
								accountManager: formData.accountManager,
								notes: formData.notes
							},
							contact: formData.contact,
							code: {
								codeType: formData.codeType,
								masterCode: formData.masterCode,
								codeLabel: formData.codeLabel,
								codeStartDate: formData.codeStartDate,
								course: formData.course,
								maxUser: formData.maxUser,
								status: data.code.status,
								coachingCalls: formData.coachingCalls,
								therapyCalls: formData.therapyCalls
							},
							plan: {
								features: formData.features
							}
						},
						caps: {
							capCalls: formData.capCalls,
							callCapPeriod: [...updatedCaps]
						}
					}

					onComplete(finalData)
				})}>
				<Row>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer error={errors.companyName?.type === "required"}>
							<FormElementLabel isRequired={true}>Company Name</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"companyName"}
									control={control}
									rules={{
										required: {
											value: true,
											message: "Company Name is Required"
										},
										maxLength: {
											value: 100,
											message: "Company name can not exceed 100 characters"
										}
									}}
									defaultValue={data.general.companyName}
									as={<FormTextInput id={"companyName"} type='text' placeholder='Company Name' />}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["companyName"] && errors["companyName"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["companyName"]}>
								{errors["companyName"] && errors["companyName"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Company Industry</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"companyIndustry"}
									control={control}
									defaultValue={data.general.companyIndustry}
									render={props => (
										<DropDown
											isFirst={true}
											items={companyIndustry}
											label='Select Item'
											variant='primary'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={
												data
													? companyIndustry.filter(industry => {
															return (
																(industry.value &&
																	industry.value.includes(
																		data.general.companyIndustry
																	)) ||
																(data.general.companyIndustry &&
																	data.general.companyIndustry.includes(
																		industry.value
																	))
															)
													  })[0]
													: ""
											}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer error={errors.size?.type === "required"}>
							<FormElementLabel isRequired={true}>Size</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"size"}
									control={control}
									defaultValue={data.general.size}
									rules={{
										required: {
											value: true,
											message: "Company Size is Required"
										},
										min: {
											value: 1,
											message: "Company size should be at least 1"
										}
									}}
									as={<FormTextInput id={"size"} type='number' placeholder='Company Size' />}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["size"] && errors["size"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
								{errors["size"] && errors["size"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Phone Number</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"phoneNumber"}
									control={control}
									rules={{
										pattern: {
											value: /^\+?[\d ]+$/g,
											message: "Phone number should be valid"
										},
										maxLength: {
											value: 15,
											message: "Phone Number can be a maximum of 15 characters"
										}
									}}
									defaultValue={data.general.phoneNumber}
									as={<FormTextInput id={"phoneNumber"} type='tel' placeholder='Phone Number' />}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["phoneNumber"] && errors["phoneNumber"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["phoneNumber"]}>
								{errors["phoneNumber"] && errors["phoneNumber"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Address Line 1</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"address"}
									defaultValue={data.general.address}
									control={control}
									as={<FormTextArea></FormTextArea>}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>State</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"state"}
									control={control}
									defaultValue={data.general.state}
									render={props => (
										<DropDown
											isFirst={true}
											variant='primary'
											items={australianStates}
											label='State'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={
												data
													? australianStates.filter(
															state => state.value === data.general.state
													  )[0]
													: ""
											}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>City/suburb</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"city"}
									defaultValue={data.general.city}
									control={control}
									as={<FormTextInput id={"city"} type='text' placeholder='City' />}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Postcode</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"postCode"}
									control={control}
									rules={{
										min: {
											value: 0,
											message: "Post Code can not be negative"
										}
									}}
									defaultValue={data.general.postCode}
									as={<FormTextInput id={"postCode"} type='number' placeholder='PostCode' />}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["postCode"] && errors["postCode"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["postCode"]}>
								{errors["postCode"] && errors["postCode"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Description</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"description"}
									defaultValue={data.general.description}
									control={control}
									rules={{
										maxLength: {
											value: 500,
											message: "Description can not exceed 500 characters"
										}
									}}
									as={<FormTextArea></FormTextArea>}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["description"] && errors["description"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["description"]}>
								{errors["description"] && errors["description"].message}
							</MessageStyles>
						) : null}
					</Col>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer error={errors.salesManager?.type === "required"}>
							<FormElementLabel isRequired={true}>Sales Manager</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"salesManager"}
									control={control}
									defaultValue={data.general.salesManager}
									rules={{required: true}}
									render={props => (
										<DropDown
											isFirst={true}
											variant='primary'
											items={salesManager}
											label='Sales Manager'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={
												data
													? salesManager.filter(
															state => state.value == data.general.salesManager
													  )[0]
													: ""
											}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["salesManager"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
								{errors["salesManager"]?.message}
							</MessageStyles>
						)}

						<FormElementContainer error={errors.eapStatus?.type === "required"}>
							<FormElementLabel isRequired={true}>EAP Status</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"eapStatus"}
									control={control}
									defaultValue={data.general.eapStatus}
									rules={{required: true}}
									render={props => (
										<DropDown
											isFirst={true}
											variant='primary'
											items={[
												{
													label: "IBH entity is the EAP",
													value: "ibh"
												},
												{
													label: "IBH entity is Not the EAP",
													value: "not_ibh"
												}
											]}
											onChange={item => props.onChange(item.value)}
											label='EAP Status'
											initialSelectedItem={
												data
													? [
															{
																label: "IBH entity is the EAP",
																value: "ibh"
															},
															{
																label: "IBH entity is Not the EAP",
																value: "not_ibh"
															}
													  ].filter(state => state.value === data.general.eapStatus)[0]
													: ""
											}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["eapStatus"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
								{errors["eapStatus"]?.message}
							</MessageStyles>
						)}

						{watchEAPStatus && watchEAPStatus === "ibh" ? (
							<Fragment>
								<FormElementContainer error={errors.salesManager?.type === "required"}>
									<FormElementLabel isRequired={true}>EAP Name</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"eapname"}
											control={control}
											defaultValue={data.general.eapname}
											rules={{required: "Please Select EAP Service"}}
											render={props => (
												<DropDown
													isFirst={true}
													name={props.name}
													items={eapdata}
													label='EAP Service'
													variant='primary'
													initialSelectedItem={
														eapdata.length > 0
															? eapdata.filter(
																	state => state.value === data.general.eapname
															  )[0]
															: {label: data.general.eapname, value: data.general.eapname}
													}
													onChange={item => {
														_handleEAPNameChange(item)
														props.onChange(item.value)
													}}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>
								{errors["eapname"] && errors["eapname"].message ? (
									<MessageStyles htmlFor={1} focused={false} validation={errors["eapname"]}>
										{errors["eapname"] && errors["eapname"].message}
									</MessageStyles>
								) : null}

								{errors["eapname"]?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
										{errors["eapname"]?.message}
									</MessageStyles>
								)}

								<FormElementContainer>
									<FormElementLabel>EAP Phone</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"eapphone"}
											control={control}
											defaultValue={data.general.eapphone}
											as={
												<FormTextInput
													type='tel'
													id={"eapphone"}
													disabled={true}
													placeholder='EAP Phone Number'
												/>
											}
										/>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>EAP website</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"eapwebsite"}
											control={control}
											defaultValue={data.general.eapwebsite}
											as={
												<FormTextInput
													id={"eapwebsite"}
													type='text'
													placeholder='EAP website'
												/>
											}
										/>
									</FormInputContainer>
								</FormElementContainer>
							</Fragment>
						) : null}

						<FormElementContainer error={errors.launchDate?.type === "required"}>
							<FormElementLabel isRequired={true}>Launch date</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"launchDate"}
									control={control}
									rules={{required: true}}
									defaultValue={moment(data.general.launchDate)}
									render={props => {
										return (
											<Datepicker
												isFirst={true}
												date={props.value}
												onChange={e => props.onChange(e.target.value)}
											/>
										)
									}}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["launchDate"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
								{errors["launchDate"]?.message}
							</MessageStyles>
						)}

						<FormElementContainer error={errors.accountManager?.type === "required"}>
							<FormElementLabel isRequired={true}>Account Manager</FormElementLabel>

							<FormInputContainer>
								<Controller
									name={"accountManager"}
									control={control}
									defaultValue={data.general.accountManager}
									render={props => (
										<DropDown
											isFirst={true}
											variant='primary'
											items={accountManager}
											label='Select Account Manager'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={
												data
													? accountManager.filter(
															state => state.value === data.general.accountManager
													  )[0]
													: ""
											}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["accountManager"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
								{errors["accountManager"]?.message}
							</MessageStyles>
						)}

						<FormElementContainer>
							<FormElementLabel>Notes</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"notes"}
									control={control}
									rules={{
										maxLength: {
											value: 500,
											message: "Notes can not exceed 500 characters"
										}
									}}
									defaultValue={data.general.notes}
									as={<FormTextArea></FormTextArea>}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["notes"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["size"]}>
								{errors["notes"]?.message}
							</MessageStyles>
						)}
					</Col>
				</Row>

				{fields.map((el, index) => {
					return (
						<div key={index}>
							<H4>Contacts {index + 1}</H4>
							<Row>
								<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
									<FormElementContainer
										error={errors?.contact?.[index]?.firstName?.type === "required"}>
										<FormElementLabel isRequired={true}>First Name</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={`contact[${index}].firstName`}
												rules={{
													required: {
														value: true,
														message: "First name is Required"
													},
													validate: validateName,
													pattern: {
														value: /^[a-zA-Z '.-]*$/g,
														message:
															"Only alphabets and the following characters are allowed: , ' -"
													}
												}}
												defaultValue={el.firstName}
												control={control}
												as={
													<FormTextInput
														type='text'
														data-testid='firstName'
														placeholder='First Name'
														ref={register({required: true})}
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors["contact"]?.[index]?.firstName?.message && (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors["contact"]?.[index]?.firstName}>
											{errors["contact"]?.[index]?.firstName?.message}
										</MessageStyles>
									)}

									<FormElementContainer
										error={errors?.contact?.[index]?.lastName?.type === "required"}>
										<FormElementLabel isRequired={true}>Last Name</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.lastName}
												name={`contact[${index}].lastName`}
												rules={{
													required: {
														value: true,
														message: "LastName is Required"
													},
													validate: validateName,
													pattern: {
														value: /^[a-zA-Z '.-]*$/g,
														message:
															"Only alphabets and the following characters are allowed: , ' -"
													}
												}}
												as={<FormTextInput type='text' placeholder='Last Name' />}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors["contact"]?.[index]?.lastName?.message && (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors["contact"]?.[index]?.lastName}>
											{errors["contact"]?.[index]?.lastName?.message}
										</MessageStyles>
									)}

									<FormElementContainer>
										<FormElementLabel>Position title</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.positionTitle}
												name={`contact[${index}].positionTitle`}
												as={<FormTextInput type='text' placeholder='Position title' />}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors["contact"]?.[index]?.positionTitle?.message && (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors["contact"]?.[index]?.positionTitle}>
											{errors["contact"]?.[index]?.positionTitle?.message}
										</MessageStyles>
									)}

									<FormElementContainer
										error={errors?.contact?.[index]?.phoneNumber?.type === "required"}>
										<FormElementLabel isRequired={true}>Phone Number</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.phoneNumber}
												name={`contact[${index}].phoneNumber`}
												rules={{
													required: {
														value: true,
														message: "Contact phone number is Required"
													},
													pattern: {
														value: /^\+?[\d ]+$/g,
														message: "Phone number should be valid"
													},
													maxLength: {
														value: 15,
														message: "Phone Number can be a maximum of 15 characters"
													}
												}}
												as={<FormTextInput type='tel' placeholder='Phone Number' />}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors["contact"]?.[index]?.phoneNumber?.message && (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors?.contact?.[index]?.phoneNumber}>
											{errors["contact"]?.[index]?.phoneNumber?.message}
										</MessageStyles>
									)}
								</Col>
								<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
									<FormElementContainer
										error={errors?.contact?.[index]?.emailAddress?.type === "required"}>
										<FormElementLabel isRequired={true}>Email address</FormElementLabel>
										<FormInputContainer>
											<Controller
												control={control}
												defaultValue={el.emailAddress}
												name={`contact[${index}].emailAddress`}
												rules={{required: true}}
												as={<FormTextInput type='email' placeholder='Email address' />}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors["contact"]?.[index]?.emailAddress?.message && (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors["contact"]?.[index]?.emailAddress}>
											{errors["contact"]?.[index]?.emailAddress?.message}
										</MessageStyles>
									)}

									<FormElementContainer
										error={errors?.contact?.[index]?.contactFunction?.type === "required"}>
										<FormElementLabel isRequired={true}>Contact function</FormElementLabel>
										<FormInputContainer>
											<Controller
												defaultValue={el.contactFunction}
												name={`contact[${index}].contactFunction`}
												rules={{required: true}}
												render={props => (
													<DropDown
														isFirst={true}
														variant='primary'
														items={[
															{
																label: "Main POC",
																value: "poc"
															},
															{
																label: "Budget Alert",
																value: "budget_alert"
															},
															{
																label: "TCA",
																value: "TCA"
															},
															{
																label: "MHFA(Mental Health First Aiders)",
																value: "MHFA"
															}
														]}
														label='Contact Function'
														initialSelectedItem={
															data
																? [
																		{
																			label: "Main POC",
																			value: "poc"
																		},
																		{
																			label: "Budget Alert",
																			value: "budget_alert"
																		},
																		{
																			label: "TCA",
																			value: "TCA"
																		},
																		{
																			label: "MHFA(Mental Health First Aiders)",
																			value: "MHFA"
																		}
																  ].filter(
																		state => state.value === el.contactFunction
																  )[0]
																: ""
														}
														onChange={item => props.onChange(item.value)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
												control={control}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors["contact"]?.[index]?.contactFunction?.message && (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors["contact"]?.[index]?.contactFunction}>
											{errors["contact"]?.[index]?.contactFunction?.message}
										</MessageStyles>
									)}

									<FormElementContainer
										error={errors?.contact?.[index]?.contactTier?.type === "required"}>
										<FormElementLabel isRequired={true}>Contact tier</FormElementLabel>
										<FormInputContainer>
											<Controller
												defaultValue={el.contactTier}
												name={`contact[${index}].contactTier`}
												rules={{required: true}}
												render={props => (
													<DropDown
														isFirst={true}
														variant='primary'
														items={[
															{
																label: "1st Tier",
																value: 1
															},
															{
																label: "2nd Tier",
																value: 2
															},
															{
																label: "3rd Tier",
																value: 3
															}
														]}
														label='Tier'
														onChange={item => props.onChange(item.value)}
														initialSelectedItem={
															data
																? [
																		{
																			label: "1st Tier",
																			value: 1
																		},
																		{
																			label: "2nd Tier",
																			value: 2
																		},
																		{
																			label: "3rd Tier",
																			value: 3
																		}
																  ].filter(state => state.value === el.contactTier)[0]
																: ""
														}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
												control={control}
											/>
										</FormInputContainer>
									</FormElementContainer>
								</Col>
							</Row>
						</div>
					)
				})}
				<Row>
					<ButtonContainer>
						<NavigationButton onClick={handleAddContact} variant={"primary"} title={"Add Contact"} />
					</ButtonContainer>
				</Row>

				{/* ========= Contact Section - End =============== */}

				{/* ============================= Code & Caps Section ===================================== */}
				<Row>
					<Col className='col-12 col-lg-12 col-md-6 col-sm-12'>
						<H5>Code</H5>
					</Col>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer error={errors?.employerCode?.type === "required"}>
							<FormElementLabel isRequired={true}>Employer Code</FormElementLabel>

							<FormInputContainer>
								<Controller
									name={"employerCode"}
									control={control}
									rules={{required: true}}
									defaultValue={employerCode}
									as={
										<FormTextInput
											type='text'
											disabled='true'
											placeholder='Employer Code'
											// name='employerCode'
											// value={state.employerCode}
											// onChange={onChange}
										/>
									}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer error={errors.codeType?.type === "required"}>
							<FormElementLabel isRequired={true}>Code Type</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"codeType"}
									control={control}
									rules={{required: true}}
									defaultValue={data.code.codeType}
									render={props => (
										<DropDown
											isFirst={true}
											variant='primary'
											items={[
												{
													label: "Regular",
													value: "regular"
												},
												{
													label: "Master",
													value: "master"
												},
												{
													label: "Family",
													value: "family"
												}
											]}
											label='Code Type'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={
												data
													? [
															{
																label: "Regular",
																value: "regular"
															},
															{
																label: "Master",
																value: "master"
															},
															{
																label: "Family",
																value: "family"
															}
													  ].filter(state => state.value === data.code.codeType)[0]
													: ""
											}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{watchCodeType && watchCodeType !== "master" ? (
							<FormElementContainer>
								<FormElementLabel>Link to master code</FormElementLabel>
								<FormInputContainer>
									<Controller
										name={"masterCode"}
										control={control}
										defaultValue={data.code.masterCode}
										// as={<FormTextInput type='text' placeholder='' />}
										render={props => (
											<AutoComplete
												items={masterCodes}
												initialSelectedItem={
													data
														? masterCodes &&
														  masterCodes.filter(state =>
																state.includes(data.code.masterCode)
														  )[0]
														: ""
												}
												onChange={value => props.onChange(value)}
												menuStyle={{position: "absolute", zIndex: 2}}
											/>
										)}
									/>
								</FormInputContainer>
							</FormElementContainer>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Code Label</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"codeLabel"}
									control={control}
									defaultValue={data.code.codeLabel}
									as={
										<FormTextInput
											type='text'
											placeholder='Label'
											// name='codeLabel'
											// value={state.codeLabel}
											// onChange={onChange}
										/>
									}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer error={errors.codeStartDate?.type === "required"}>
							<FormElementLabel isRequired={true}>Code start date</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"codeStartDate"}
									control={control}
									rules={{required: true, validate: planDateCheck}}
									defaultValue={moment(data.code.codeStartDate)}
									// valueName='selected' // DateSelect value's name is selected
									render={props => {
										return (
											<Datepicker
												isFirst={true}
												date={props.value}
												onChange={e => props.onChange(e.target.value)}
											/>
										)
									}}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["codeStartDate"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["codeStartDate"]}>
								{errors["codeStartDate"]?.message}
							</MessageStyles>
						)}

						<FormElementContainer error={errors.codeExpiryDate?.type === "required"}>
							<FormElementLabel isRequired={true}>Code expiry date</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"codeExpiryDate"}
									control={control}
									rules={{required: true, validate: planDateCheckExpiry}}
									defaultValue={moment(expiryDate)}
									render={props => {
										return (
											<Datepicker
												isFirst={true}
												date={props.value}
												onChange={e => props.onChange(e.target.value)}
											/>
										)
									}}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["codeExpiryDate"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["codeExpiryDate"]}>
								{errors["codeExpiryDate"]?.message}
							</MessageStyles>
						)}

						<FormElementContainer error={errors.course?.type === "required"}>
							<FormElementLabel isRequired={true}>Course</FormElementLabel>
							<FormInputContainer>
								<Controller
									control={control}
									name={"course"}
									rules={{required: true}}
									defaultValue={data.code.course}
									render={props => (
										<DropDown
											isFirst={true}
											variant='primary'
											items={courses.map(course => {
												return {
													label: course,
													value: course
												}
											})}
											label='Select Course'
											onChange={item => {
												props.onChange(item.value)
											}}
											initialSelectedItem={
												data
													? courses
															.map(course => {
																return {
																	label: course,
																	value: course
																}
															})
															.filter(state => state.value === data.code.course)[0]
													: ""
											}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>

					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer error={errors.maxUser?.type === "required"}>
							<FormElementLabel isRequired={true}>Max users</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"maxUser"}
									control={control}
									rules={{
										required: {
											value: true,
											message: "Max Users is Required"
										},
										min: {
											value: 1,
											message: "Max Users should be at least 1"
										}
									}}
									defaultValue={data.code.maxUser}
									as={
										<FormTextInput
											type='number'
											placeholder='Max users'
											// name='maxUser'
											// value={state.maxUser}
											// onChange={onChange}
										/>
									}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["maxUser"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["maxUser"]}>
								{errors["maxUser"]?.message}
							</MessageStyles>
						)}

						<FormElementContainer>
							<FormElementLabel>Cap calls</FormElementLabel>
							<ToggleContainer>
								<Controller
									name={"capCalls"}
									control={control}
									defaultValue={capDetails?.capCalls}
									render={({onChange, ref, value}) => (
										<Toggle
											checked={value}
											onChange={() => {
												onChange(!value)
											}}
											backgroundColorChecked={"#7d60ff"}
											width='42'
											height='24'
											InputRef={ref}
											sliderHeight='20.8'
											sliderWidth='20.8'
											translate='18'
										/>
									)}
								/>
							</ToggleContainer>
						</FormElementContainer>

						<FormElementContainer error={errors.coachingType?.type === "required"}>
							<FormElementLabel isRequired={true}>Session Cap</FormElementLabel>
							<FormInputInlineContainer>
								<Controller
									name={"coachingType"}
									control={control}
									rules={{required: "Session Cap is required"}}
									defaultValue={"combined"}
									render={props => (
										<>
											<Radio
												checked={watchSessionCap.coachingType === "combined"}
												label={"Total Calls"}
												value={"combined"}
												onChange={e =>
													setValue("coachingType", "combined", {shouldValidate: true})
												}
												name={"coachingType"}
												width={"120px"}
											/>
											<Radio
												checked={watchSessionCap.coachingType === "individual"}
												label={"Coaching and Therapy"}
												value={"individual"}
												onChange={e =>
													setValue("coachingType", "individual", {shouldValidate: true})
												}
												name={"coachingType"}
												width={"180px"}
											/>
										</>
									)}
								/>
							</FormInputInlineContainer>
						</FormElementContainer>

						{errors["coachingType"]?.message && (
							<MessageStyles htmlFor={1} focused={false} validation={errors["coachingType"]}>
								{errors["coachingType"].message}
							</MessageStyles>
						)}

						{watchSessionCap.coachingType === "combined" ? (
							<>
								<TextInputWrap>
									<NumberInputContainer error={errors.combinedBookingCaps?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"combinedBookingCaps"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Sessions is required"
													},
													min: {
														value: 0,
														message: "Sessions should be at least 0"
													},
													maxLength: {
														value: 5,
														message: "Sessions cant exceed 99,999"
													}
												}}
												defaultValue={
													callCapPeriod?.[callCapPeriod?.length - 1]?.combinedBookingCaps
												}
												as={
													<FormNumberInputFixedWidth
														type='number'
														disabled={!watchCapCalls.capCalls}
													/>
												}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													sessions
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>
								</TextInputWrap>

								{errors?.["combinedBookingCaps"]?.message && (
									<MessageStylesCaps
										htmlFor={1}
										focused={false}
										validation={errors["combinedBookingCaps"]}>
										{errors["combinedBookingCaps"]?.message}
									</MessageStylesCaps>
								)}
							</>
						) : (
							<>
								<TextInputWrap>
									<NumberInputContainer error={errors["coachingBookingCaps"]?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"coachingBookingCaps"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Coaching is required"
													},
													min: {
														value: 0,
														message: "Coaching sessions should be at least 0"
													},
													maxLength: {
														value: 5,
														message: "Coaching Sessions cant exceed 99,999"
													}
												}}
												defaultValue={
													callCapPeriod?.[callCapPeriod?.length - 1]?.coachingBookingCaps
												}
												as={
													<FormNumberInputFixedWidth
														type='number'
														disabled={!watchCapCalls.capCalls}
													/>
												}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													coaching
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>

									<NumberInputContainer error={errors.therapyBookingCaps?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"therapyBookingCaps"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Therapy is required"
													},
													min: {
														value: 0,
														message: "Therapy sessions should be at least 0"
													},
													maxLength: {
														value: 5,
														message: "Therapy Sessions cant exceed 99,999"
													}
												}}
												defaultValue={
													callCapPeriod?.[callCapPeriod?.length - 1]?.therapyBookingCaps
												}
												as={
													<FormNumberInputFixedWidth
														type='number'
														disabled={!watchCapCalls.capCalls}
													/>
												}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													therapy
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>
								</TextInputWrap>

								{errors["coachingBookingCaps"]?.message && (
									<MessageStylesCaps
										htmlFor={1}
										focused={false}
										validation={errors["coachingBookingCaps"]}>
										{errors["coachingBookingCaps"]?.message}
									</MessageStylesCaps>
								)}
							</>
						)}

						<FormElementContainer>
							<FormElementLabel>Email Exercising Results</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"emailResults"}
									control={control}
									defaultValue={data.code.emailResults}
									render={({onChange, ref, value}) => (
										<Toggle
											checked={value}
											// onChange={() => SetState({...state, emailResults: !state.emailResults})}
											backgroundColorChecked={"#7d60ff"}
											width='42'
											height='24'
											InputRef={ref}
											sliderHeight='20.8'
											sliderWidth='20.8'
											translate='18'
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>
				</Row>
				{/* ============================= Code & Caps Section - END ===================================== */}

				{/* ============================= Plan Section - Start  =====================*/}
				<Row>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<H5>Features</H5>
					</Col>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'></Col>

					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<Fragment>
							{featuresFields.map((el, index) => {
								return (
									<PlanContainer key={index}>
										<Fragment>
											<div style={{maxWidth: "170px", minWidth: "170px"}}>
												<FormElementPlanLabel>
													<FormInputContainer style={{flexBasis: "20%"}}>
														<Controller
															name={`features.${index}.label`}
															control={control}
															defaultValue={el.label}
															render={props => <Fragment>{el.label}</Fragment>}
														/>
													</FormInputContainer>
												</FormElementPlanLabel>
											</div>

											<FormElementLabel style={{display: "hidden"}}>
												<Controller
													name={`features.${index}.id`}
													control={control}
													defaultValue={el.id}
													render={props => null}
												/>
											</FormElementLabel>

											<FeaturesInputContainer style={{margin: "0 12px 0 6px"}}>
												<Controller
													name={`features.${index}.enabled`}
													control={control}
													defaultValue={el.enabled}
													render={props => (
														<Toggle
															checked={props.value}
															onChange={() => {
																props.onChange(!props.value)
															}}
															backgroundColorChecked={"#7d60ff"}
															width='42'
															height='24'
															sliderHeight='20.8'
															sliderWidth='20.8'
															translate='18'
														/>
													)}
												/>
											</FeaturesInputContainer>
										</Fragment>
									</PlanContainer>
								)
							})}
						</Fragment>
					</Col>
				</Row>
				{/* =================== Plan Section - End ====================== */}
				<Row className='flex-row-reverse'>
					<ButtonContainer>
						<PrimaryButton
							type='submit'
							variant={"primary"}
							title={"Update"}
							data-testid='updateEmployerButton'
						/>
					</ButtonContainer>
					<ButtonContainer>
						<SecondaryButton
							onClick={e => {
								e.preventDefault()
								onCancel()
							}}
							variant={"secondary"}
							title={"Cancel"}
							data-testid='cancelUpdateEmployerButton'
						/>
					</ButtonContainer>
				</Row>
			</form>
		</Container>
	)
}

export default EditEmployerDetails
