import {backgrounds, extended} from "@uprise/colors"
import styled from "styled-components"

export const TagStyler = styled.div`
	position: relative;
	display: inline-block;
	vertical-align: top;
	min-width: 100%;
	margin: 5px 0;

	.muted {
		display: none;
	}

	.react-tagsinput {
		border: 0;
		width: 100%;
		margin-top: 0;
		margin-left: -8px;

		.react-tagsinput-tag ~ .react-autosuggest__container {
			margin-top: 4px;
		}

		.react-autosuggest__container {
			border: solid 1px #d4cbfb;
			border-radius: 4px;
		}

		span,
		input[type="text"] {
			margin-top: 0px;
			margin-bottom: 0px;
			width: 100%;
			&::placeholder {
				font-family: "Proxima Nova";
				font-size: 13px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.55;
				color: ${extended.charcoal.one};
				padding: 2px 22px 2px 8px;
				opacity: 0.9;
			}
		}

		.react-tagsinput-tag {
			height: 24px;
			margin: 0 8px 2px 0;
			padding: 2px 8px;
			border-radius: 5px;
			border: solid 1px ${extended.purple.four};
			background-color: ${backgrounds.white};
			font-family: inherit;
			color: ${extended.purple.one};
			width: fit-content;
		}
	}

	.react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
	}

	.react-autosuggest__suggestion {
		padding: 4px;
		text-align: left;
		list-style-type: none;
		border-bottom: 1px solid ${extended.charcoal.five};
		font-family: "Proxima Nova";
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.85;
		-webkit-letter-spacing: normal;
		-moz-letter-spacing: normal;
		-ms-letter-spacing: normal;
		letter-spacing: normal;
		background-color: ${backgrounds.white};
		color: #9796a0;
	}

	.react-autosuggest__suggestion--highlighted {
		background-color: ${backgrounds.fadedPurple};
		color: ${extended.charcoal.one};
	}

	.react-autosuggest__suggestions-container--open {
		position: absolute;
		background-color: ${backgrounds.white};
		left: 0;
		top: auto;
		z-index: 1;
		overflow: auto;
		max-width: 100%;
		max-height: 200px;
		width: 99%;
		border-top: 0;
		outline: none;
		padding: 0;
		border-radius: 0 0 10px 10px;
		box-shadow: 0 2px 4px 0 ${extended.charcoal.five};
	}
`
