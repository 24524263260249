import React from "react"
import {Modal} from "@uprise/modal"

// UI
import {H5} from "@uprise/headings"
import {Button} from "@uprise/button"

// Styles
import {ButtonWrap} from "./index.style"

export const ConfirmModal = ({children, openModal, handleSubmit, handleClose}) => {
	return (
		<Modal
			isOpen={openModal}
			handleClose={() => handleClose()}
			padding={"24px"}
			backgroundColor={"#ffffff"}
			width={"20%"}
			textAlign='center'>
			<H5 className='m-b-0'>{children}</H5>
			<ButtonWrap>
				<Button
					className='m-t-10'
					title='No'
					width='45%'
					variant='primary'
					size='small'
					onClick={() => handleClose()}
				/>
				<Button
					className='m-t-10'
					title='Yes'
					width='45%'
					variant='primary'
					size='small'
					onClick={() => handleSubmit()}
				/>
			</ButtonWrap>
		</Modal>
	)
}
