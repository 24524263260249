import React, {Fragment} from "react"

import Styled from "styled-components"
// Colors
import {extended} from "@uprise/colors"

// Components
import {Medium} from "@uprise/text"
import {H3, H5} from "@uprise/headings"

// spacing
import {spacing} from "@uprise/spacing"

const SummaryStyles = Styled.section`
      background-color: #fff;
`

const Content = Styled.div`
	 
`

const Ul = Styled.ul`
	margin-top: 0;
      margin-bottom: ${spacing.s3};
      list-style-type: none;
      padding-left: 0;
`

const Li = Styled.li`
      display: flex;
	flex-direction: column;
      margin-bottom: ${spacing.s6};
	padding-bottom: ${spacing.s4};
	border: 1px solid ${extended.purple.four};
	border-right: 0;
	border-left: 0;
	border-top: 0;

	&:last-child {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
`

export const SurveyResult = ({index, survey, summary}) => {
	return (
		<SummaryStyles>
			<Content>
				<Ul>
					{summary.map((item, index) => {
						return (
							<Li key={index}>
								<H5 className='m-t-0 m-b-3'>{item.question?.meta?.summaryTitle}</H5>
								<Medium>{item.answer}</Medium>
							</Li>
						)
					})}
				</Ul>
			</Content>

			{survey.exerciseGoal ? (
				<Fragment>
					<H3 className='m-b-4 m-t-0'>Exercise Goal</H3>
					<Content>
						<Medium>{survey.exerciseGoal}</Medium>
					</Content>
				</Fragment>
			) : null}
		</SummaryStyles>
	)
}
