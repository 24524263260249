import React, {useState, useEffect, useRef, useCallback, useMemo, createRef, Fragment} from "react"
import {useAuth0} from "@auth0/auth0-react"
import {useDispatch, useSelector} from "react-redux"
import {CSVLink} from "react-csv"
import moment from "moment-timezone"

// UI
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
import {H4} from "@uprise/headings"
import {Modal} from "@uprise/modal"
import {P} from "@uprise/text"
import {text} from "@uprise/typography"
import {Button} from "@uprise/button"

// Slice
import {
	fetchReferralUploads,
	workableSelector,
	downloadRefferalList,
	clearWorkableState,
	downloadAllProspectiveUsers
} from "../workableSlice"
// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import TableComponent from "components/shared/Table"
import MultiLevelDropdown from "components/shared/MultiLevelDropdown"
import Checkbox from "components/shared/Checkbox"
import DropDown from "components/shared/DropDown"
import {Alerts} from "features/Workable/Referrals/Alerts"
// Modals
import UploadModal from "../UploadModal"
// Styles
import {HeaderTableContent, UploadButton, ModalButtonContainer, ButtonWrap} from "./index.style"
import {HeaderText, ColumnContainer} from "shared/Table/index.style"

const Referrals = () => {
	const [openUploadModal, setOpenUploadModal] = useState(false)
	const [pageCount, setPageCount] = useState(0)
	const [token, setToken] = useState(null)
	const [csvActive, setCSVActive] = useState(false)
	const [referralType, setReferralType] = useState({
		label: "Workable",
		value: "workable"
	})

	const csvLink = createRef()
	const fetchIdRef = useRef(0)
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()

	useEffect(() => {
		return () => {
			dispatch(clearWorkableState())
		}
	}, [])

	const {fetchReferralUploadsApiState, uploadApiState, downloadRefferalListApiState, downloadProspectiveUsers} =
		useSelector(workableSelector)

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			// console.log("fetchData is being called")
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current

			if (fetchId === fetchIdRef.current && token) {
				dispatch(
					fetchReferralUploads({
						limit: pageSize,
						skip: pageSize * pageIndex,
						token,
						type: referralType.value
					})
				)
			}
		},
		[token, referralType]
	)

	useEffect(() => {
		if (fetchReferralUploadsApiState.data.length > 0) {
			setPageCount(Math.ceil(fetchReferralUploadsApiState.total / 20))
		}
	}, [fetchReferralUploadsApiState])

	useEffect(() => {
		async function getToken() {
			const token = await getAccessTokenSilently()

			setToken(token)
		}

		getToken()
	}, [])

	useEffect(() => {
		if (downloadProspectiveUsers.data && downloadProspectiveUsers.data.length > 0) {
			setCSVActive(true)
		}
	}, [downloadProspectiveUsers])

	useEffect(() => {
		if (csvActive) {
			setCSVActive(false)
			csvLink.current.link.click()
		}
	}, [csvActive])

	const Download = ({id, row}) => {
		const _handleDownload = row => {
			dispatch(downloadRefferalList({fileId: row.fileId, type: referralType.value, token}))
		}
		return (
			<ButtonWrap>
				<Button
					title={"Download"}
					variant={"secondary"}
					size='small'
					fullWidth={false}
					paddingLeft={spacing.s5}
					paddingRight={spacing.s5}
					onClick={() => _handleDownload(row)}
				/>
			</ButtonWrap>
		)
	}

	const _handleDownloadAll = () => {
		dispatch(downloadAllProspectiveUsers({type: referralType.value, token}))
	}

	const _handleActionClick = value => {
		setReferralType(value)
	}

	const columns = useMemo(
		() => [
			// { Header: "id", accessor: "id", show: false },
			{
				Header: () => <HeaderText alignText='flex-start'>Filename</HeaderText>,
				accessor: "fileName",
				show: true,
				Cell: cellInfo => (
					<ColumnContainer alignText='center'>
						<P fontSize={text.t4}>{cellInfo.row.original.fileName}</P>
					</ColumnContainer>
				),
				sortType: "string"
			},
			{
				Header: "Uploaded Date",
				accessor: "referralDate",
				show: true,
				Cell: cellInfo => {
					return (
						<div>
							{cellInfo.row.original.referralDate
								? moment(cellInfo.row.original.referralDate).local().format("Do MMM YYYY")
								: null}
						</div>
					)
				}
			},
			{
				Header: "No of Records",
				accessor: "Records",
				show: true
			},
			{
				Header: () => <HeaderText alignText='center'>Action</HeaderText>,
				accessor: "action",
				Cell: cellInfo => <Download id={cellInfo.row.original.id} row={cellInfo.row.original} />,
				show: true
			}
		],
		[token, fetchReferralUploadsApiState]
	)

	return (
		<ComponentWrapper
			menuActiveIndex={8}
			subMenuActiveIndex={14}
			headerTitle={`Referrals - ${referralType.label}`}
			headerRightAlignContent={
				<HeaderTableContent>
					<DropDown
						containerStyle={{
							listStyleType: "none",
							alignSelf: "flex-start",
							marginRight: "12px",
							outline: "none",
							position: "relative",
							zIndex: "2"
						}}
						testId='dropdownReferrals'
						buttonStyles={{width: "200"}}
						label='Select Company'
						initialSelectedItem={referralType}
						onChange={item => _handleActionClick(item)}
						menuStyle={{position: "absolute", width: "200px"}}
						items={[
							{
								label: "Workable",
								value: "workable"
							},
							{
								label: "DHC",
								value: "DHC"
							}
						]}
					/>
					<UploadButton
						className='m-l-5'
						size={"small"}
						width='150px'
						data-testid='uploadButton'
						paddingLeft={spacing.s5}
						paddingRight={spacing.s5}
						title={"Upload"}
						onClick={() => {
							setOpenUploadModal(true)
						}}
					/>
					<Button
						className='m-l-5'
						size={"small"}
						width='175px'
						data-testid='downloadButton'
						paddingLeft={spacing.s6}
						paddingRight={spacing.s6}
						title={"Download All"}
						onClick={_handleDownloadAll}
					/>
				</HeaderTableContent>
			}>
			<Alerts
				className='m-b-5'
				uploadApiState={uploadApiState}
				downloadRefferalListApiState={downloadRefferalListApiState}
			/>

			<Card padding={spacing.s10} backgroundColor='white'>
				<TableComponent
					pageCount={pageCount}
					fetchData={fetchData}
					columns={columns}
					loading={fetchReferralUploadsApiState.isFetching && !fetchReferralUploadsApiState.isSuccess}
					data={fetchReferralUploadsApiState.data}
				/>
			</Card>

			<CSVLink
				data={downloadProspectiveUsers.data}
				filename={downloadProspectiveUsers.file ? downloadProspectiveUsers.file : "Data.csv"}
				ref={csvLink}
				target='_blank'
			/>

			<Modal
				isOpen={openUploadModal}
				handleClose={() => setOpenUploadModal(false)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"20%"} // scroll={true}
				textAlign='center'>
				<H4>{`Upload Referral - ${referralType.label}`}</H4>
				<ModalButtonContainer>
					<UploadModal
						referralType={referralType}
						onFileUpload={() => setOpenUploadModal(false)}
						onCancel={() => setOpenUploadModal(false)}
					/>
				</ModalButtonContainer>
			</Modal>
		</ComponentWrapper>
	)
}

export default Referrals
