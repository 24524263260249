import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"
import Styled from "styled-components"

// UI
import {Alert} from "@uprise/alert"
// Slices
import {
	clearCloseRiskCallBackState,
	clearSendRiskCallbackEmailApiState,
	clearSendRiskCallbackNoteApiState,
	clearAssignRiskcallbackToUserApiState
} from "./riskCallbackSlice"

const AlertWrap = Styled.div``

export const Alerts = ({
	className,
	closeRiskCallBackApiState,
	assignRiskcallbackToUserApiState,
	saveRiskCallbackNotes,
	saveRiskCallbackNotesApiState,
	sendRiskCallbackEmailApiState
}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (closeRiskCallBackApiState.isError || closeRiskCallBackApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearCloseRiskCallBackState())
			}, 3000)
		}
		if (assignRiskcallbackToUserApiState.isError || assignRiskcallbackToUserApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearAssignRiskcallbackToUserApiState())
			}, 3000)
		}

		if (saveRiskCallbackNotesApiState.isError || saveRiskCallbackNotesApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearSendRiskCallbackNoteApiState())
			}, 3000)
		}
		if (saveRiskCallbackNotesApiState.isError || saveRiskCallbackNotesApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearSendRiskCallbackEmailApiState())
			}, 3000)
		}
	}, [
		closeRiskCallBackApiState,
		assignRiskcallbackToUserApiState,
		saveRiskCallbackNotes,
		saveRiskCallbackNotesApiState,
		sendRiskCallbackEmailApiState
	])

	return (
		<AlertWrap className={className}>
			{closeRiskCallBackApiState.isError && <Alert type='error'>{closeRiskCallBackApiState.message}</Alert>}
			{closeRiskCallBackApiState.isSuccess && <Alert type='success'>Risk callback successfully closed</Alert>}

			{assignRiskcallbackToUserApiState.isError && (
				<Alert type='error'>{assignRiskcallbackToUserApiState.message}</Alert>
			)}
			{assignRiskcallbackToUserApiState.isError && (
				<Alert type='error'>{assignRiskcallbackToUserApiState.message}</Alert>
			)}
			{saveRiskCallbackNotesApiState.isError && (
				<Alert type='error'>{saveRiskCallbackNotesApiState.message}</Alert>
			)}
			{sendRiskCallbackEmailApiState.isError && (
				<Alert type='error'>{sendRiskCallbackEmailApiState.message}</Alert>
			)}

			{sendRiskCallbackEmailApiState.isSuccess && (
				<Alert type='success'>Risk callback email successfully saved</Alert>
			)}

			{saveRiskCallbackNotesApiState.isSuccess && (
				<Alert type='success'>Risk callback note successfully saved</Alert>
			)}
			{assignRiskcallbackToUserApiState.isSuccess && (
				<Alert type='success'>Risk callback successfully assigned to a coach</Alert>
			)}
		</AlertWrap>
	)
}
