import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"
// UI
import {Alert} from "@uprise/alert"
// Slices
import {clearUpdateEmployerApiState, clearResetCapsApiState} from "features/Employers/employerSlice"

export const Alerts = ({updateEmployerState, resetCapsApi}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (updateEmployerState?.isError || updateEmployerState?.isSuccess) {
			setTimeout(() => {
				dispatch(clearUpdateEmployerApiState())
			}, 3000)
		}
		if (resetCapsApi?.isError || resetCapsApi?.isSuccess) {
			setTimeout(() => {
				dispatch(clearResetCapsApiState())
			}, 3000)
		}
}, [updateEmployerState, resetCapsApi])

	return (
		<div className='m-b-5'>
			{updateEmployerState?.isSuccess && updateEmployerState?.message && (
				<Alert type='success'>{updateEmployerState.message} </Alert>
			)}
			{updateEmployerState?.isError && updateEmployerState?.message && (
				<Alert type='error'>{updateEmployerState.message} </Alert>
			)}
			{resetCapsApi?.isSuccess && (
        <Alert type='success'>{resetCapsApi.message} </Alert>
      )}
      {resetCapsApi?.isError && (
				<Alert type='error'>{resetCapsApi.message} </Alert>
			)}
		</div>
	)
}