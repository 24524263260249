import React, {useState, useEffect, useCallback, useRef, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import moment from "moment"
import _ from "lodash"
import {useAuth0} from "@auth0/auth0-react"

// UI
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import TableComponent from "components/shared/Table"
import DropDown from "components/shared/DropDown"
import CancelBookingModal from "./CancelBookingModal"
import {Alerts} from "./Alerts"

// Assets
import IconSearch from "assets/images/icons/svg/search.svg"
// Slices
import {bookingDataSelector, fetchBookingsData, clearBookingApiState, cancelBooking} from "../bookingSlice"
import {authSelector} from "../../Auth/authSlice"
import {clearUserDetailsState} from "../../Users/usersSlice"

// Icons
import phoneIcon from "assets/images/icons/svg/icon-phone-call.svg"
import chatIcon from "assets/images/icons/svg/icon-chat.svg"
import videoIcon from "assets/images/icons/svg/icon-video-call.svg"
import f2fIcon from "assets/images/icons/svg/icon-face-to-face.svg"
import cancelIcon from "assets/images/icons/svg/icon-cancel.svg"
import timeIcon from "assets/images/icons/svg/icon-time.svg"
// Styles
import {
	HeaderTableContent,
	ColumnText,
	SearchInput,
	SearchInputContainer,
	SearchInputfigure,
	SearchInputImage,
	PhoneIcon,
	VideoIcon,
	ChatIcon,
	F2fIcon,
	CancelIcon,
	TimeIcon,
	ActionButtonContainer,
	CustomButton,
	DurationText,
	TypeText
} from "./index.style"
import {HeaderText, ColumnContainer} from "shared/Table/index.style.js"

const AllBookingsTable = ({history}) => {
	const [pageCount, setPageCount] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const [selectedUserBookingToDelete, setSelectedUserBookingToDelete] = useState(null)
	const [cancellingBookingBefore24Hours, setCancellingBookingBefore24Hours] = useState(false)
	const [cancelReasonState, setCancelReasonState] = useState("within24")
	const [bookingRowData, setBookingsRowData] = useState([])
	const [token, setToken] = useState(null)
	const [searchByCode, setSearchByCode] = useState("")
	const [filterCoachId, setFilterCoachId] = useState(null)
	const [coachesFilterLists, setCoachesFilterList] = useState([{label: "All", value: "all", id: "all"}])
	// Sorting
	const {getAccessTokenSilently, isLoading, user} = useAuth0()
	const [status, setStatus] = useState("coach_cancelled")

	const fetchIdRef = useRef(0)
	const dispatch = useDispatch()

	const {bookings, coaches, fetchBookingsApiState, cancelBookingApiState} = useSelector(bookingDataSelector)
	const authState = useSelector(authSelector)

	useEffect(() => {
		// Clear bookings state on unmount
		return () => {
			dispatch(clearBookingApiState())
		}
	}, [])

	useEffect(() => {
		async function getToken() {
			if (!isLoading) {
				const token = await getAccessTokenSilently()
				setToken(token)
			}
		}

		getToken()
	}, [isLoading, user])

	useEffect(() => {
		let updatedRow = []

		if (bookings?.data?.length) {
			updatedRow = bookings?.data?.map(booking => {
				return {...booking, selected: false}
			})
		}

		setBookingsRowData(updatedRow)
		setPageCount(Math.ceil(bookings.total / 20))
	}, [bookings])

	useEffect(() => {
		if (coaches && coaches.length > 0) {
			let formatedCoaches = coaches.map(item => {
				return {label: item.name, value: item.name, id: item.id}
			})
			setCoachesFilterList([...coachesFilterLists, ...formatedCoaches])
		}
	}, [coaches])

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current
			if (fetchId === fetchIdRef.current && token && authState.data && authState.data.id) {
				dispatch(
					fetchBookingsData({
						coachId: authState.data.id,
						limit: pageSize,
						skip: pageSize * pageIndex,
						search: searchByCode,
						filter: filterCoachId,
						user,
						token
					})
				)
			}
		},
		[token, authState, searchByCode, filterCoachId]
	)

	const _handleCancelBookingModal = data => {
		setSelectedUserBookingToDelete(data)
		setOpenModal(true)

		let bookingStart = moment(data.start).utc().subtract(1, "days")
		let currentTime = moment().utc()

		if (bookingStart > currentTime) {
			setCancellingBookingBefore24Hours(true)
		}
	}

	const _handleCancelBooking = () => {
		let data = {
			bookingId: selectedUserBookingToDelete.id,
			userEmail: selectedUserBookingToDelete.email,
			coachId: selectedUserBookingToDelete.coachId,
			eventId: selectedUserBookingToDelete.eventId,
			cancelReason: cancelReasonState,
			status: status === "coach_cancelled"
		}

		dispatch(cancelBooking({...data, token}))

		setOpenModal(false)
	}

	const _checkCancelOption = value => {
		setCancelReasonState(value)
	}

	const _checkCancelStatus = value => {
		setStatus(value)
	}

	const onSelectRow = id => {
		let updatedRow = bookingRowData.map(booking => {
			if (booking.id === id) {
				return {...booking, selected: !booking.selected}
			} else {
				return booking
			}
		})
		setBookingsRowData(updatedRow)
	}

	const _handleSearchEmployerCodeChange = useCallback(
		_.debounce(
			value => {
				setSearchByCode(value)
			},
			1500,
			{maxWait: 1500}
		),
		[]
	)

	const _handleFilterCoachChange = e => {
		setFilterCoachId(e.id)
	}

	useEffect(() => {
		if (cancelBookingApiState.isSuccess) {
			dispatch(
				fetchBookingsData({
					coachId: authState.data.id,
					limit: 20,
					skip: 0,
					user,
					token
				})
			)
		}
	}, [cancelBookingApiState])

	const columns = useMemo(
		() => [
			{
				Header: () => <HeaderText alignText='left'>Date</HeaderText>,
				accessor: "start",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer>
							<ColumnText id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{moment(cellInfo.row.original.start).format("Do MMMM YYYY")}
							</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: () => <HeaderText alignText='center'>Time</HeaderText>,
				accessor: "time",
				show: true,
				disableSortBy: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center'>
							<ColumnText id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{moment(cellInfo.row.original.start).format("HH:mm a")}
							</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: () => <HeaderText alignText='center'>Client Email</HeaderText>,
				accessor: "email",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer
							alignText='center'
							onClick={() => {
								dispatch(clearUserDetailsState())
								history.push(`/users/${cellInfo.row.original.email}`)
							}}>
							<ColumnText link={true} id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{cellInfo.row.original.email}
							</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: () => <HeaderText alignText='center'>Type</HeaderText>,
				accessor: "type",
				show: true,
				Cell: cellInfo => {
					let text
					if (cellInfo.row.original.type.toLowerCase() === "call") {
						text = "Phone call"
					} else if (cellInfo.row.original.type.toLowerCase() === "chat") {
						text = "Chat"
					}

					return (
						<ColumnContainer alignText='center'>
							<ColumnText id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{cellInfo.row.original.type.toLowerCase() === "call" && <PhoneIcon src={phoneIcon} />}
								{cellInfo.row.original.type.toLowerCase() === "chat" && <ChatIcon src={chatIcon} />}
								{cellInfo.row.original.type.toLowerCase() === "f2f" && <F2fIcon src={f2fIcon} />}
								{cellInfo.row.original.type.toLowerCase() === "video" && <VideoIcon src={videoIcon} />}

								<TypeText>{text}</TypeText>
							</ColumnText>
						</ColumnContainer>
					)
				}
			},
			{
				Header: () => <HeaderText alignText='center'>Call duration</HeaderText>,
				accessor: "duration",
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center'>
							<ColumnText id={cellInfo.row.original.id} row={cellInfo.row.original}>
								{<TimeIcon src={timeIcon} />}
								<DurationText>
									{cellInfo.row.original.duration ? `${cellInfo.row.original.duration} mins` : `-`}
								</DurationText>
							</ColumnText>
						</ColumnContainer>
					)
				},
				show: true
			},
			{
				Header: () => <HeaderText alignText='center'>Coach</HeaderText>,
				accessor: "coachName",
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center' data-testid='coachName'>
							<ColumnText
								link={true}
								onClick={() => {
									history.push(`/coaches/${cellInfo.row.original.coachId}`)
								}}
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}>
								{cellInfo.row.original.coachName}
							</ColumnText>
						</ColumnContainer>
					)
				},
				show: true,
				style: {overflow: "auto"}
			},
			{
				Header: () => <HeaderText alignText='center'>Action</HeaderText>,
				accessor: "utilization",
				show: true,
				disableSortBy: true,
				Cell: cellInfo => {
					return (
						<ActionButtonContainer
							alignText='center'
							data-testid='cancelBookingButton'
							onClick={() => _handleCancelBookingModal(cellInfo.row.original)}>
							<CancelIcon src={cancelIcon} />
						</ActionButtonContainer>
					)
				}
			}
		],
		[bookingRowData]
	)

	return (
		<ComponentWrapper
			menuActiveIndex={4}
			subMenuActiveIndex={11}
			headerTitle='All active bookings'
			headerRightAlignContent={
				<HeaderTableContent>
					<SearchInputContainer>
						<SearchInputfigure>
							<SearchInputImage src={IconSearch} />
						</SearchInputfigure>
						<SearchInput
							type={"text"}
							label={"email"}
							data-testid='searchEmployeeCode'
							placeholder={"Search by Employee Code"}
							onChange={e => _handleSearchEmployerCodeChange(e.target.value)}
							isRequired={false}
						/>
						<DropDown
							containerStyle={{
								listStyleType: "none",
								alignSelf: "center",
								marginRight: "12px",
								position: "relative",
								minWidth: "125px",
								zIndex: "2"
							}}
							label='Select Coach'
							testId='selectCoach'
							onChange={_handleFilterCoachChange}
							isLast={true}
							menuStyle={{position: "absolute"}}
							items={coachesFilterLists}
						/>
					</SearchInputContainer>

					<CustomButton
						onClick={() => history.push("/bookings/new")}
						title={"New Booking"}
						size={"small"}
						data-testid='newBookingButton'
					/>
				</HeaderTableContent>
			}>
			<Alerts cancelBookingApiState={cancelBookingApiState} />

			<Card backgroundColor='white' padding={spacing.s10} data-testid='allBookingData'>
				<TableComponent
					loading={fetchBookingsApiState.isFetching || !fetchBookingsApiState.isSuccess}
					pageCount={pageCount}
					fetchData={fetchData}
					columns={columns}
					data={bookingRowData}
				/>
			</Card>

			<CancelBookingModal
				isOpen={openModal}
				cancellingBookingBefore24Hours={cancellingBookingBefore24Hours}
				handleCancelBooking={_handleCancelBooking}
				handleClose={() => setOpenModal(false)}
				cancelReasonState={cancelReasonState}
				checkCancelOption={e => _checkCancelOption(e)}
				checkCancelStatus={e => _checkCancelStatus(e)}
				status={status}
				selectedUserBookingToDelete={selectedUserBookingToDelete}
			/>
		</ComponentWrapper>
	)
}

export default AllBookingsTable
