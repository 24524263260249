import React, {Fragment} from "react"
import moment from "moment"
import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"

// UI
import {Accordian} from "@uprise/accordian"
// Components
import {STRESSBHCResult} from "./STRESSBHCResult"

export const STRESSBHCResults = ({results, tasks}) => {
	const result = results.map((result, index) => {
		const taskId = result.taskId
		const survey = retrieveSurvey(result?.surveyId)
		const currentTask = tasks[taskId]
		const title = currentTask?.title || taskId
		const surveyTasks = Object.keys(result?.answers)
		const date = moment.unix(result.timestamp).format("MMMM Do YYYY, h:mm:ss a")

		let analysis

		try {
			analysis = survey.analysis(result?.answers)
			return {
				header: `${title} - ${date}`,
				content: <STRESSBHCResult surveyTasks={surveyTasks} analysis={analysis} survey={survey} />
			}
		} catch (error) {
			console.log(error)
			analysis = {}
		}
	})

	return (
		<Fragment>
			<Accordian className='m-b-5' content={result} />
		</Fragment>
	)
}
