import React, {useEffect, useState} from "react"
import {useSelect} from "downshift"
import {func, object, string, array} from "prop-types"

import {
	DropDownWrap,
	MessageStyles,
	LabelStyles,
	Container,
	TriggerButton,
	MenuList,
	MenuListItem,
	ArrowIcon
} from "./index.style"

const DropDown = ({
	id,
	isRequired,
	isFirst,
	isLast,
	items,
	variant,
	name,
	title,
	label,
	onChange,
	validation,
	containerStyle,
	menuStyle,
	buttonStyles,
	initialSelectedItem,
	borderRadius,
	wrapperProps,
	testId
}) => {
	let itemsSorted = [...items]

	itemsSorted = itemsSorted?.sort(function (a, b) {
		return a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : 1
	})

	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		setHighlightedIndex,
		getMenuProps,
		highlightedIndex,
		getItemProps
	} = useSelect({
		items: itemsSorted,
		onSelectedItemChange: e => {
			onChange(e.selectedItem)
		},
		itemToString: item => {
			return item ? item.value : ""
		},
		initialSelectedItem: initialSelectedItem
	})

	const [focused, setFocused] = useState(false)

	const _onFocus = event => {
		setFocused(true)
	}

	const _onBlur = event => {
		setFocused(false)
	}

	useEffect(() => {
		if (isOpen) {
			_onFocus()
		} else {
			_onBlur()
		}
	}, [isOpen])

	return (
		<DropDownWrap isFirst={isFirst} isLast={isLast} {...wrapperProps}>
			{title?.length && (
				<LabelStyles htmlFor={id} focused={focused}>
					{title} {isRequired ? " * " : ""}
				</LabelStyles>
			)}
			<Container style={containerStyle}>
				<TriggerButton
					type='button'
					data-testid={testId}
					focused={focused}
					variant={variant}
					validation={validation?.[name]}
					isOpen={isOpen}
					borderRadius={borderRadius}
					{...buttonStyles}
					{...getToggleButtonProps()}>
					{(selectedItem && selectedItem.label) || label}
					<ArrowIcon />
				</TriggerButton>

				<MenuList
					{...getMenuProps({
						onKeyDown: event => {
							event.nativeEvent.preventDownshiftDefault = true
							// your custom keyDown handler here.
							itemsSorted.forEach((item, index) => {
								if (item.label.charAt(0).toLowerCase() === event.key) {
									setHighlightedIndex(index)
								}
							})
						}
					})}
					style={menuStyle}
					name={name}>
					{isOpen &&
						itemsSorted.map((item, index) => {
							return (
								<MenuListItem
									selectedItem={highlightedIndex === index}
									data-testid='menuItem'
									variant={variant}
									key={`${item}${index}`}
									{...getItemProps({item: item.value, index})}>
									{item.CustomMenuItem ? item.CustomMenuItem(item.lbael) : item.label}
								</MenuListItem>
							)
						})}
				</MenuList>
			</Container>

			{/*
				2 formats of error messages
          			1. validate.js - validation?.[name]?.errors?.[0]
          			2. react-use-form - validation?.[name]?.message
      		*/}

			{(validation?.[name]?.errors || validation?.[name]?.message) && (
				<MessageStyles htmlFor={id} focused={focused} validation={validation?.[name]}>
					{validation?.[name]?.errors?.[0] || validation?.[name]?.message}
				</MessageStyles>
			)}
		</DropDownWrap>
	)
}

DropDown.propTypes = {
	onChange: func.isRequired,
	items: array.isRequired,
	label: string.isRequired,
	containerStyle: object,
	menuStyle: object
}

DropDown.defaultProps = {
	items: [],
	label: "",
	onChange: () => {},
	containerStyle: {},
	menuStyle: {}
}

export default DropDown
