import React, {useState, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"
import {useHistory} from 'react-router-dom'
import moment from "moment-timezone"
// UI
import {Card} from "@uprise/card"
import {ContainerFluid} from "@uprise/grid"
import {spacing} from "@uprise/spacing"
import {Button} from "@uprise/button"

// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import SuccessPage from "./SuccessPage"
import FormPage from "./FormPage"
import Preloader from "components/shared/Preloader/Preloader"

// Slices
import {createNewUser, userDataSelector} from "../usersSlice"
import {fetchAllCodes, employerSelector} from "../../Employers/employerSlice"
import {useAuth0} from "@auth0/auth0-react"
import { HeaderTableContent } from "../AllUsers/index.style"
import constraints from "./FormPage/validation"

// Validation
var validate = require("validate.js")

const NewUser = () => {
	const dispatch = useDispatch()
	const {createNewUserApiState} = useSelector(userDataSelector)
	const {fetchCodesApiState, allCodes} = useSelector(employerSelector)
	const {getAccessTokenSilently} = useAuth0()
	const history = useHistory()

	// states
	const [email, setEmail] = useState()
	const [name, setName] = useState()
	const [phone, setPhone] = useState()
	const [timezone, setTimezone] = useState(moment.tz.guess())
	const [countryCode, setCountryCode] = useState()
	const [countryName, setCountryName] = useState()
	const [employerCode, setEmployerCode] = useState()
	const [password, setPassword] = useState("Password123!")

	// Validation
	const [validationRender, setValidationRender] = useState(false)
	const [submitted, setSubmitted] = useState()
	const [validation, setValidation] = useState({})

	useEffect(() => {
		async function fetchData() {
			const token = await getAccessTokenSilently()
			dispatch(fetchAllCodes({token}))
		}

		fetchData()
	}, [])

	// Validation
	useEffect(() => {
		if (validationRender) {
			const validation = validate(
				{email, name, phone, countryCode, countryName, employerCode, timezone, password},
				constraints,
				{format: "custom"}
			)
			setValidation(validation)
		}
	}, [email, name, phone, countryCode, countryName, employerCode, timezone, password, validationRender])

	validate.formatters.custom = function (errors) {
		const data = {}
		errors.forEach(function (error) {
			data[error.attribute] = {message: error.error}
		})
		if (errors.length) {
			data["isValid"] = false
		} else {
			data["isValid"] = true
		}
		return data
	}

	const _handleCountryCode = value => {
		setCountryCode(value.label)
		setCountryName(value.name)
	}

	const onSubmit = async () => {
		setSubmitted(true)

		const token = await getAccessTokenSilently()

		const validated = validate(
			{email, name, phone, countryCode, countryName, employerCode, timezone, password},
			constraints,
			{format: "custom"}
		)
		setValidation(validated)
		setValidationRender(!validated.isValid)
		if (validated.isValid) {
			try {
				await dispatch(
					createNewUser({
						email,
						name,
						phone,
						countryCode,
						countryName,
						employerCode,
						timezone,
						password,
						token
					})
				)
			} catch (err) {
				console.log(err)
			}
		}
	}

	return (
		<ComponentWrapper menuActiveIndex={3} subMenuActiveIndex={14} headerTitle='New User' headerRightAlignContent={
			<HeaderTableContent>
				<Button
					onClick={() => history.push("/users/all")}
					size='small'
					title={"Cancel"}
					variant='tertiary'
					style={{margin: "10px", padding: "20px 24px"}}
				/>
				<Button
					onClick={onSubmit}
					size='small'
					title={"Save"}
					style={{margin: "10px", padding: "20px 24px"}}
					variant='primary'
					type='submit'
					isLoading={!createNewUserApiState.isError && createNewUserApiState.isFetching}
				/>
			</HeaderTableContent>
		}>
			<ContainerFluid>
				<Card
					style={{overflow: "visible"}}
					backgroundColor='white'
					padding={`${spacing.s5} 0px`}
					className='m-t-5 m-b-10'>
					{fetchCodesApiState.isFetching && <Preloader />}
					{createNewUserApiState.isSuccess && <SuccessPage />}
					{!createNewUserApiState.isSuccess && fetchCodesApiState.isSuccess && (
						<FormPage
							codes={allCodes}
							validation={validation}
							onSubmit={onSubmit}
							{...{
								email,
								setEmail,
								name,
								setName,
								phone,
								setPhone,
								timezone,
								setTimezone,
								countryCode,
								setCountryCode,
								countryName,
								setCountryName,
								employerCode,
								setEmployerCode,
								password,
								setPassword,
								_handleCountryCode,
						}} />
					)}
				</Card>
			</ContainerFluid>
		</ComponentWrapper>
	)
}

export default NewUser