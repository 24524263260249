import {createSlice} from "@reduxjs/toolkit"

import {post} from "helpers/api"

export const initialState = {
	loading: false,
	loaded: false,
	hasErrors: false,
	loggedIn: false,
	data: {}
}

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		login: state => {},
		onUserFetching: state => {
			state.loading = true

			return state
		},
		onLoggedIn: (state, {payload}) => {
			state.loggedIn = true
			state.hasErrors = false
			state.loading = false
			state.loaded = true
			state.data = payload.data

			return state
		},
		onLoggedOut: state => {
			state.loggedIn = false
			state.hasErrors = true
			state.loading = false
			state.loaded = true
			state.data = {}

			return state
		}
	},
	extraReducers: {}
})

export const {login, onLoggedIn, onLoggedOut, onUserFetching} = authSlice.actions

export const authSelector = state => state.auth

export function logoutUser(jwt, idClaims, auth0) {
	return async dispatch => {
		try {
			const response = await post("v2/crm/logout", {jwt, idClaims})

			if (response.status === 200) {
				dispatch(onLoggedOut(response))
				const {logout} = auth0
				logout({returnTo: `${window.location.origin}/login`})
			}
		} catch (error) {
			console.log("Error", error)
		}
	}
}

export function fetchUserDetails(token, email) {
	return async dispatch => {
		try {
			dispatch(onUserFetching())
			const response = await post(
				"v2/crm/state",
				{
					email: email
				},
				{},
				{Authorization: `Bearer ${token}`}
			)

			if (response.error) {
				dispatch(onLoggedOut())
			} else {
				dispatch(onLoggedIn(response))
			}
		} catch (error) {
			console.log("Error", error)
		}
	}
}
export default authSlice
