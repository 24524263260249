import React, {useState, useRef, useEffect} from "react"
import moment from "moment-timezone"
import {useDispatch, useSelector} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"
import styled from "styled-components"
import constraints from "./validation"
// UI
import {spacing} from "@uprise/spacing"
import {Container, Row, Col} from "@uprise/grid"
import {Button} from "@uprise/button"
import {TextInput} from "@uprise/form"
import {Alert} from "@uprise/alert"
// Constants
import {DURATIONS, TYPES} from "./constant"
// Components
import DropDown from "components/shared/DropDown"
import DatePicker2 from "components/shared/DatePicker2"

// Slices
import {bookingDataSelector, createBackDateBooking} from "../../bookingSlice"
// Validation
var validate = require("validate.js")

import {FormElementContainer, FormElementLabel, FormInputContainer} from "../index.style"
import {SubmitButtonContainer} from "shared/Form/index.style"

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	padding: 40px;
`

const FormPage = () => {
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()
	const {createBackDateBookingApiState, fetchCoachesApiState} = useSelector(bookingDataSelector)
	const firstRender = useRef(true)

	const [coachList, setCoachList] = useState([])
	const [timezones, setTimezones] = useState([])
	const [timezone, setTimezone] = useState(moment.tz.guess())
	const [email, setEmail] = useState()
	const [date, setDate] = useState(moment().set("hour", 0).set("minutes", 0))
	const [coach, setCoach] = useState()
	const [type, setType] = useState()
	const [duration, setDuration] = useState()
	// Validation
	const [submitted, setSubmitted] = useState()
	const [validation, setValidation] = useState({})

	useEffect(() => {
		const coachList = fetchCoachesApiState?.data?.map(coach => {
			return {
				label: coach.name,
				value: coach.id
			}
		})

		setCoachList(coachList)
	}, [fetchCoachesApiState])

	useEffect(() => {
		setTimezones(
			moment.tz.names().map(item => {
				return {
					label: item,
					value: item
				}
			})
		)
	}, [timezone])

	// Validation
	useEffect(() => {
		if (!firstRender.current) {
			const validation = validate({timezone, email, date, coach, type, duration}, constraints, {format: "custom"})
			setValidation(validation)
		}

		firstRender.current = false
	}, [timezone, email, date, type, coach, duration])

	validate.formatters.custom = function (errors) {
		const data = {}
		errors.forEach(function (error) {
			data[error.attribute] = {message: error.error}
		})
		if (errors.length) {
			data["isValid"] = false
		} else {
			data["isValid"] = true
		}
		return data
	}

	const _handleSubmit = async () => {
		setSubmitted(true)

		const token = await getAccessTokenSilently()

		const currentTime = moment.tz(timezone)
		const startTime = moment.tz(date, timezone)
		const backDate = moment(startTime).isBefore(currentTime)

		let data = {
			backDate: backDate,
			bookingType: type,
			callDuration: duration,
			userEmail: email,
			coachId: coach,
			startTime: startTime,
			timeZone: timezone
		}

		const validated = validate({timezone, email, date, coach, type, duration}, constraints, {format: "custom"})
		setValidation(validated)

		if (validated.isValid) {
			try {
				await dispatch(createBackDateBooking({data, token}))
			} catch (err) {
				console.log(err)
			}
		}
	}

	return (
		<Content>
			<Container>
				<Row>
					<Col className='col-md-12'>
						{createBackDateBookingApiState.isError && (
							<Alert className='m-b-5' type='error'>
								{createBackDateBookingApiState.message}
							</Alert>
						)}
					</Col>
				</Row>
			</Container>
			<Container>
				<Row className='justify-content-md-center'>
					<Col className='col-6 col-md-6'>
						{/* User Email */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>User Email</FormElementLabel>
							<FormInputContainer error={validation.email}>
								<TextInput
									name='email'
									id='email'
									data-testid='email'
									// border={true}
									padding={spacing.s2}
									// label='User email'
									fontSize='12px'
									onChange={e => setEmail(e.target.value)}
									validation={validation}
									placeholder='user@email.com'
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Date, time */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Date, time</FormElementLabel>
							<FormInputContainer error={validation.date}>
								<DatePicker2
									name='date'
									id='date'
									// label='Select Date'
									padding={spacing.s2}
									timezone={timezone}
									date={date}
									onChange={value => setDate(value)}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Coach */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Coach</FormElementLabel>
							<FormInputContainer error={validation.coach}>
								<DropDown
									name='coach'
									id='coach'
									testId='selectCoach'
									border={true}
									items={coachList}
									variant='primary'
									label='Choose a coach'
									padding={spacing.s2}
									onChange={e => setCoach(e.value)}
									initialSelectedItem={""}
									menuStyle={{position: "absolute", zIndex: 2}}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>
					<Col className='col-6 col-md-6'>
						{/* Type */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Type</FormElementLabel>
							<FormInputContainer error={validation.type}>
								<DropDown
									name='type'
									testId='type'
									id='type'
									border={true}
									items={TYPES}
									variant='primary'
									label='Select Type'
									onChange={e => setType(e.value)}
									initialSelectedItem={""}
									menuStyle={{position: "absolute", zIndex: 2}}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Duration */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Duration</FormElementLabel>
							<FormInputContainer error={validation.duration}>
								<DropDown
									name='duration'
									id='duration'
									testId='duration'
									border={true}
									variant='primary'
									items={DURATIONS}
									label='Select Duration'
									onChange={e => setDuration(e.value)}
									initialSelectedItem={""}
									menuStyle={{position: "absolute", zIndex: 2}}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
						{/* Timezone */}
						<FormElementContainer>
							<FormElementLabel isRequired={true}>Timezone</FormElementLabel>
							<FormInputContainer error={validation.timezone}>
								<DropDown
									name='timezone'
									testId='timezone'
									border={true}
									variant='primary'
									items={timezones}
									// title='Timezone'
									label='Select Timezone'
									initialSelectedItem={{label: moment.tz.guess(), value: moment.tz.guess()}}
									onChange={e => setTimezone(e.value)}
									menuStyle={{position: "absolute", zIndex: 2}}
									validation={validation}
									isLast
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>
				</Row>
				<Row>
					<SubmitButtonContainer>
						<Button
							isLoading={
								!createBackDateBookingApiState.isError && createBackDateBookingApiState.isFetching
							}
							size='small'
							disabled={!validation.isValid || createBackDateBookingApiState.isFetching}
							data-testid='submitButton'
							title={"Create"}
							style={{margin: "10px", padding: "20px 24px"}}
							variant='primary'
							fullWidth={false}
							onClick={() => _handleSubmit()}
						/>
					</SubmitButtonContainer>
				</Row>
			</Container>
		</Content>
	)
}

export default FormPage
