import React, {Fragment} from "react"
import Moment from "moment"
import styled from "styled-components"
import {useForm, Controller} from "react-hook-form"

// UI
import {Container, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {P} from "@uprise/text"
import {H6} from "@uprise/headings"
import {Button} from "@uprise/button"
// helpers
import {salesManager, accountManager, companyIndustry, australianStates} from "helpers/constants"
// Components
import DropDown from "components/shared/DropDown"
import Datepicker from "components/shared/DatePicker"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	position: relative;
	align-self: center;
`

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const NavigationButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
`
const PStyled = styled(P)`
	word-break: break-all
`

const General = ({data, isEditEnabled, onEditCancel}) => {
	const {register, control, handleSubmit, watch, reset, trigger, setError, errors} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onSubmit",
		reValidateMode: "onChange"
	})

	const onSubmit = data => {}
	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer>
							<FormElementLabel>Company Name</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"companyName"}
										control={control}
										rules={{required: true}}
										defaultValue={data && data.companyName}
										render={() => {
											return (
												<TextInputHorizontal
													id='companyName'
													type='text'
													placeholder='Company Name'
													data-testid='companyName'
												/>
											)
										}}
									/>
								) : (
									<PStyled>{data && data.companyName}</PStyled>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Company Industry</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"companyIndustry"}
										control={control}
										defaultValue={data && data.companyIndustry}
										render={props => (
											<DropDown
												items={companyIndustry}
												data-testid='companyName'
												label='Select Item'
												variant='primary'
												onChange={item => props.onChange(item.value)}
												initialSelectedItem={
													data
														? companyIndustry.filter(
																industry => industry.value === data.companyIndustry
														  )[0]
														: ""
												}
												menuStyle={{position: "absolute", zIndex: 2}}
											/>
										)}
									/>
								) : (
									<P>{data && data.companyIndustry}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Size</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"size"}
										control={control}
										defaultValue={""}
										rules={{required: true}}
										as={
											<TextInputHorizontal
												id='companySize'
												type='number'
												placeholder='Company Size'
											/>
										}
									/>
								) : (
									<P>{data && data.size}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Phone Number</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"phoneNumber"}
										control={control}
										defaultValue={data && data.phoneNumber}
										as={
											<TextInputHorizontal
												id='phoneNumber'
												type='tel'
												placeholder='Phone Number'
											/>
										}
									/>
								) : (
									<P>{data && data.phoneNumber}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Address Line 1</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"address"}
										defaultValue={data && data.address}
										control={control}
										as={<FormTextArea></FormTextArea>}
									/>
								) : (
									<PStyled>{data && data.address}</PStyled>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>State</FormElementLabel>

							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"state"}
										control={control}
										defaultValue={""}
										render={props => (
											<DropDown
												items={australianStates}
												label='State'
												variant='primary'
												onChange={item => props.onChange(item.value)}
												initialSelectedItem={
													data
														? australianStates.filter(state => state.value == data.state)[0]
														: ""
												}
												menuStyle={{position: "absolute", zIndex: 2}}
											/>
										)}
									/>
								) : (
									<P>{data && data.state}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>City/suburb</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"city"}
										defaultValue={data && data.city}
										control={control}
										as={<TextInputHorizontal id='city' type='text' placeholder='City' />}
									/>
								) : (
									<P>{data && data.city}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Postcode</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"postCode"}
										control={control}
										defaultValue={data && data.postCode}
										as={<TextInputHorizontal id='postCode' type='number' placeholder='PostCode' />}
									/>
								) : (
									<P>{data && data.postCode}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Description</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"description"}
										defaultValue={data && data.description}
										control={control}
										as={<FormTextArea></FormTextArea>}
									/>
								) : (
									<PStyled>{data && data.description}</PStyled>
								)}
							</FormInputContainer>
						</FormElementContainer>
					</Col>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer>
							<FormElementLabel>Sales Manager</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"salesManager"}
										control={control}
										defaultValue={""}
										rules={{required: true}}
										render={props => (
											<DropDown
												items={salesManager}
												label='Sales Manager'
												variant='primary'
												onChange={item => props.onChange(item.value)}
												initialSelectedItem={
													data
														? salesManager.filter(
																state => state.value == data.salesManager
														  )[0]
														: ""
												}
												menuStyle={{position: "absolute", zIndex: 2}}
											/>
										)}
									/>
								) : (
									<P>{data && data.salesManager}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>
						<FormElementContainer>
							<FormElementLabel>EAP Status</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"eapStatus"}
										control={control}
										defaultValue={""}
										rules={{required: true}}
										render={props => (
											<DropDown
												items={[
													{
														label: "Uprise is the EAP",
														value: "uprise"
													},
													{
														label: "Uprise is Not the EAP",
														value: "not-uprise"
													}
												]}
												onChange={item => props.onChange(item.value)}
												label='EAP Status'
												variant='primary'
												initialSelectedItem={
													data
														? [
																{
																	label: "Uprise is the EAP",
																	value: "uprise"
																},
																{
																	label: "Uprise is Not the EAP",
																	value: "not-uprise"
																}
														  ].filter(state => state.value == data.eapStatus)[0]
														: ""
												}
												menuStyle={{position: "absolute", zIndex: 2}}
											/>
										)}
									/>
								) : (
									<P>{data && data.eapStatus}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>
						{data && data.eapStatus && data.eapStatus === "not-uprise" ? (
							<Fragment>
								<FormElementContainer>
									<FormElementLabel>EAP name</FormElementLabel>
									<FormInputContainer>
										{isEditEnabled ? (
											<Controller
												name={"eapname"}
												defaultValue={data && data.eapname}
												control={control}
												as={
													<TextInputHorizontal
														id='eapName'
														type='text'
														placeholder='EAP Name'
													/>
												}
											/>
										) : (
											<P>{data && data.eapname}</P>
										)}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>EAP Phone</FormElementLabel>
									<FormInputContainer>
										{isEditEnabled ? (
											<Controller
												name={"eapphone"}
												control={control}
												defaultValue={data && data.eapphone}
												as={
													<TextInputHorizontal
														id='eapPhoneNumber'
														type='tel'
														placeholder='EAP Phone Number'
													/>
												}
											/>
										) : (
											<P>{data && data.eapphone}</P>
										)}
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>EAP website</FormElementLabel>
									<FormInputContainer>
										{isEditEnabled ? (
											<Controller
												name={"eapwebsite"}
												control={control}
												defaultValue={data && data.eapwebsite}
												as={
													<TextInputHorizontal
														id='eapWebsite'
														type='text'
														placeholder='EAP website'
													/>
												}
											/>
										) : (
											<P>{data && data.eapwebsite}</P>
										)}
									</FormInputContainer>
								</FormElementContainer>
							</Fragment>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Launch date</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"launchDate"}
										control={control}
										rules={{required: true}}
										defaultValue={Moment(data.launchDate)}
										render={props => {
											return (
												<Datepicker
													date={props.value}
													onChange={e => props.onChange(e.target.value)}
												/>
											)
										}}
									/>
								) : (
									<P>{data && Moment(data.launchDate).format("Do MMM YYYY")}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>
						<FormElementContainer>
							<FormElementLabel>Account Manager</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"accountManager"}
										control={control}
										defaultValue={""}
										render={props => (
											<DropDown
												items={accountManager}
												label='Select Account Manager'
												variant='primary'
												onChange={item => props.onChange(item.value)}
												initialSelectedItem={
													data
														? accountManager.filter(
																state => state.value == data.accountManager
														  )[0]
														: ""
												}
												menuStyle={{position: "absolute", zIndex: 2}}
											/>
										)}
									/>
								) : (
									<P>{data && data.accountManager}</P>
								)}
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer>
							<FormElementLabel>Notes</FormElementLabel>
							<FormInputContainer>
								{isEditEnabled ? (
									<Controller
										name={"notes"}
										control={control}
										defaultValue={data && data.notes}
										as={<FormTextArea></FormTextArea>}
									/>
								) : (
									<PStyled>{data && data.notes}</PStyled>
								)}
							</FormInputContainer>
						</FormElementContainer>
					</Col>
				</Row>
				{isEditEnabled ? (
					<Row className='flex-row-reverse'>
						<ButtonContainer>
							<NavigationButton type='submit' variant={"primary"} title={"Update"} />
						</ButtonContainer>
						<ButtonContainer>
							<NavigationButton variant={"secondary"} onClick={onEditCancel} title={"Cancel"} />
						</ButtonContainer>
					</Row>
				) : null}
			</form>
		</Container>
	)
}

export default General
