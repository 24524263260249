import React from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
// UI
import {Container, Grid, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H4, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
// Slices
import {clearBookingApiState} from "../../bookingSlice"
import success from "assets/images/svg/success.svg"

const PageContainer = styled(Container)``

const Heading = styled(H4)`
	text-align: center;
`

const SubHeading = styled(H6)`
	text-align: center;
`

const ImageFigure = styled.figure`
	text-align: center;
`

const Description = styled(P)`
	text-align: center;
`

const CloseButton = styled(Button)`
	width: 100%;
	max-width: 200px;
	margin: auto;
`

const SuccessPage = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	return (
		<PageContainer>
			<Heading>Success</Heading>
			<ImageFigure>
				<img src={success} alt={""} />
			</ImageFigure>

			<SubHeading data-testid='bookingCreated'>Booking has been created successfully</SubHeading>

			<CloseButton
				title={"Make another"}
				onClick={() => {
					dispatch(clearBookingApiState())
				}}
			/>
		</PageContainer>
	)
}

export default SuccessPage
