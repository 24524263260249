import React from "react"
import Styled from "styled-components"
// all menu icons import
import {IconEmployers} from "../../Icons/IconEmployers"
import {IconUsers} from "../../Icons/IconUsers"
import {IconBookings} from "../../Icons/IconBookings"
import {IconCoaches} from "../../Icons/IconCoaches"
// all submenu icons import
import {IconNewEmployer} from "../../Icons/IconNewEmployer"
import {IconAllEmployers} from "../../Icons/IconAllEmployers"

const IconContainer = Styled.div`
	width: 12px;
	height: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const SVGIcon = ({icon = "", ...rest}) => {
	let IconComponent = <></>

	switch (icon.toLowerCase()) {
		// main menus
		case "icon-employers":
			IconComponent = <IconEmployers {...rest} />
			break
		case "icon-users":
			IconComponent = <IconUsers {...rest} />
			break
		case "icon-bookings":
			IconComponent = <IconBookings {...rest} />
			break
		case "icon-coaches":
			IconComponent = <IconCoaches {...rest} />
			break

		// sub menus
		case "icon-newemployers":
			IconComponent = <IconNewEmployer {...rest} />
			break
		case "icon-allemployers":
			IconComponent = <IconAllEmployers {...rest} />
			break

		default:
			IconComponent = <></>
	}
	return <IconContainer>{IconComponent}</IconContainer>
}

export default SVGIcon
