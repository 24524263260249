import React, {useState} from "react"
import {bool, string} from "prop-types"
import Styled from "styled-components"
import {useHistory} from "react-router-dom"
import {useDispatch} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"

// Navigation
import left from "navigation/left.json"
// Colors
import {backgrounds, extended, primary} from "@uprise/colors"
// spacing
import {spacing} from "@uprise/spacing"
import {logoutUser} from "features/Auth/authSlice"
import SVGIcon from "components/shared/SVGIcon"

const Arrow = Styled.i`
	border: solid #6d6c79;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 3px;
`

const ArrowIcon = Styled(Arrow)`
  	transform: ${props => (!props.toggle ? "rotate(45deg)" : "rotate(-135deg)")};
      transition: transform 0.1s linear;
			margin-top: ${props => (!props.toggle ? "0px" : "10px")};
`

const LeftMenuStyles = Styled.div`
	max-width : 200px;
	@media (max-width: 1024px) {
		width: 100%
	}

	@media (max-width: 475px) {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
`

const ItemTitle = Styled.span`
      font-family: ${props => (props.active ? "Proxima Nova Semibold" : "Proxima Nova")};
      top: 2px;
      font-size: ${spacing.s5};
      position: relative;
	color: ${props => (props.active ? "#7d60ff" : "#6d6c79")};
	flex: 1;
	display: flex;
	justify-content: flex-start;

	@media (max-width: 1024px) {
		text-align: center
		display: none;
	}
`

const MenuContainer = Styled.div``

const MenuItem = Styled.button`
	display: flex;
	align-items: center;
	outline: none;
      border: 0;
      width: 100%;
      text-align: center;
      font-size: ${spacing.s6};
      border-right: 1px solid ${extended.purple.five};
      background-color: ${props => (props.active ? "#edeafa" : backgrounds.white)};
      color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
      margin-top: 1rem;
	padding: 12px 0 12px 24px;


      &:after {
            content: " ";
		position: absolute;
            display: ${props => (props.active ? "inline-block" : "none")};
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            right: 0;
            width: 6px;
            height: 21px;
            background-color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
      }

      &:hover {
            cursor: pointer
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding-bottom: 1rem;
		height: auto;
		width: 100%;

		&:after {
			content: " ";
			display: none;
		}
	}

	@media (max-width: 1024px) {
 		padding-top: ${spacing.s5};
 	}

	@media (max-width: 475px) {
		width: 50px;
		margin-top: 0;
		padding-top: ${spacing.s5};
		border-right: 0;
		background-color: ${backgrounds.white};
	}
`

const SubMenuWrap = Styled.div`
      max-height: ${props => (props.toggle ? "100%" : "0")};
      overflow: hidden;
      transition: padding 0.3s, opacity 0.3s linear;
	background-color : #f8f8ff;
      opacity: ${props => (props.toggle ? 1 : 0)};
      /* padding-top: ${props => (props.toggle ? spacing.s6 : "0px")}; */
      /* padding-bottom: ${props => (props.toggle ? spacing.s6 : "0px")}; */
`

const SubMenuItem = Styled.button`
	display: flex;
	position : relative;
	align-items: center;
	outline: none;
      border: 0;
      width: 100%;
      text-align: center;
      font-size: ${spacing.s6};
      border-right: 1px solid ${extended.purple.five};
      background-color: ${props => (props.active ? "#edeafa" : "#f8f8ff")};
      color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
	padding: 12px 0 12px 44px;
	height: 44px;

      &:after {
            content: " ";
			position: absolute;
            display: ${props => (props.active ? "inline-block" : "none")};
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            right: 0;
            width: 6px;
            height: 21px;
            background-color: ${props => (props.active ? primary.purple : extended.charcoal.one)};
      }

      &:hover {
            cursor: pointer
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding-bottom: 1rem;
		height: auto;
		width: 100%;

		&:after {
			content: " ";
			display: none;
		}
	}

	@media (max-width: 1024px) {
 		padding-top: ${spacing.s5};
 	}

	@media (max-width: 475px) {
		width: 50px;
		margin-top: 0;
		padding-top: ${spacing.s5};
		border-right: 0;
		background-color: ${backgrounds.white};
	}
`

const Collapse = Styled.div`
	flex: 0.15;
	display: flex;
`

const ToggleIcon = Styled.img`
      height: 9px;
      width: 16px;
      margin-top: ${spacing.s5};
      margin-right: ${spacing.s7};
      transform: ${props => (props.toggle ? "rotate(180deg)" : "none")};
      transition: transform 0.1s linear;
`

const Icon = Styled.img`
      width: 19px;
      height: 21px;
      margin-left: 40px;
	margin-right: 16px;

	@media (max-width: 1024px) {
		margin: 0 0 5px 0
	}
`
const MenuItemContainer = Styled.div`
	flex: 0.85;
	display: flex;
	align-items: center;
`

const MenuItemIconFigure = Styled.figure`
	margin : 2px 8px 0 0;
`

MenuItem.propTypes = {
	active: bool.isRequired
}

MenuItem.defaultProps = {
	active: false
}

// Set active menu item
const setActive = (items, activeId, subMenuActive) => {
	let menuItems = items
	menuItems = menuItems.map(item => {
		if (item.id === activeId) {
			item.active = true

			if (item.children) {
				item.children = item.children.map(subitem => {
					if (subitem.id === subMenuActive) {
						subitem.active = true
						item.active = false
						return subitem
					} else {
						subitem.active = false

						return subitem
					}
				})
			}
			return item
		} else {
			item.active = false
			return item
		}
	})

	return menuItems
}

const LeftMenu = ({activeId, subMenuActiveId, ...props}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const auth0 = useAuth0()

	const [navItems, setNavItems] = useState(setActive(left, activeId, subMenuActiveId))
	const [toggledIndex, setToggledIndex] = useState(activeId)

	const _onLogOutClick = async () => {
		const token = await auth0.getAccessTokenSilently()
		const idClaims = await auth0.getIdTokenClaims()
		dispatch(logoutUser(token, idClaims, auth0))
	}

	const _handleClick = (item, activeId) => {
		// setToggle(!toggle)
		setNavItems(setActive(left, activeId, subMenuActiveId))
		_handleToggle(item, activeId)
		history.push(`/${item.path}`)
	}

	const _handleSubItemClick = (item, subMenuActiveId) => {
		setNavItems(setActive(left, activeId, subMenuActiveId))
		history.push(`/${item.path}`)
	}

	const _handleToggle = (item, activeId) => {
		if (toggledIndex === activeId) {
			setToggledIndex(null)
		} else {
			setToggledIndex(activeId)
		}
	}

	return (
		<LeftMenuStyles {...props}>
			{navItems
				.filter(_ => _.enabled)
				.map((item, index) => (
					<MenuContainer key={`container-${index + 1}`}>
						<MenuItem
							data-testid={`${item.title}-button`}
							key={index}
							active={item.active}
							onClick={() => _handleClick(item, item.id)}>
							<MenuItemContainer>
								<MenuItemIconFigure>
									<SVGIcon icon={item.icon} fill={item.active && primary.purple} />
								</MenuItemIconFigure>
								<ItemTitle active={item.active}>{item.title}</ItemTitle>
							</MenuItemContainer>
							<Collapse>
								{item.children && item.children.length > 0 ? (
									<ArrowIcon
										toggle={toggledIndex && toggledIndex === item.id}
										// onClick={() => _handleToggle(index)}
										// src={Icons.chevronPurple}
									/>
								) : null}
							</Collapse>
						</MenuItem>
						<SubMenuWrap toggle={toggledIndex && toggledIndex === item.id}>
							{item.children &&
								item.children.map((subitem, subindex) => {
									return (
										<SubMenuItem
											data-testid={`${subitem.title}-button`}
											key={subindex}
											active={subitem.active}
											onClick={() => _handleSubItemClick(subitem, subitem.id)}>
											<MenuItemIconFigure>
												<SVGIcon icon={subitem.icon} fill={subitem.active && primary.purple} />
											</MenuItemIconFigure>
											<ItemTitle active={subitem.active}>{subitem.title}</ItemTitle>
										</SubMenuItem>
									)
								})}
						</SubMenuWrap>
					</MenuContainer>
				))}
			<MenuItem data-testid={`logout-button`} active={false} onClick={() => _onLogOutClick()}>
				<ItemTitle active={false}>Logout</ItemTitle>
			</MenuItem>
		</LeftMenuStyles>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
LeftMenu.propTypes = {
	className: string,
	color: string,
	width: string,
	textAlign: string
}

LeftMenu.defaultProps = {
	className: "",
	color: primary.charcoal,
	width: "",
	textAlign: ""
}

export default LeftMenu
