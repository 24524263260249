import React from "react"
import styled from "styled-components"
import {Container, Grid, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H5, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"

import {useForm} from "react-hook-form"

const ElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #edeafa;
`

const ElementLabel = styled(H6)`
	margin: 16px 0 8px 0;
	width: 150px;
	max-width: 150px;
`

const ItemValueContainer = styled.div`
	/* flex-basis: 60%; */
	color: #6d6c79;
	min-width: 100px;
	text-align: center;
	align-self: center;
`

const ButtonContainer = styled.div`
	display: flex;
`

const NavigationButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
`

const Plan = ({data, isEditEnabled, onEditCancel}) => {
	const {register, control, handleSubmit, watch, reset, trigger, setError, errors} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onSubmit",
		reValidateMode: "onChange"
	})

	const onSubmit = () => {}

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col className='col-12 col-lg-12 col-md-6 col-sm-12'>
						<H5 className='m-t-0 m-b-4'>Plan Features</H5>
					</Col>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						{data.plan.features &&
							data.plan.features.slice(0, 5).map((item, index) => {
								return (
									<ElementContainer key={index}>
										<ElementLabel>{item.label}</ElementLabel>
										<ItemValueContainer>
											{item.enabled ? "Included" : "Excluded"}
										</ItemValueContainer>
									</ElementContainer>
								)
							})}
					</Col>

					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						{data.plan.features &&
							data.plan.features.slice(5).map((item, index) => {
								return (
									<ElementContainer key={index}>
										<ElementLabel>{item.label}</ElementLabel>
										<ItemValueContainer>
											{item.enabled ? "Included" : "Excluded"}
										</ItemValueContainer>
									</ElementContainer>
								)
							})}
					</Col>
				</Row>

				{isEditEnabled ? (
					<Row className='flex-row-reverse'>
						<ButtonContainer>
							<NavigationButton type='submit' variant={"primary"} title={"Update"} />
						</ButtonContainer>
						<ButtonContainer>
							<NavigationButton variant={"secondary"} onClick={onEditCancel} title={"Cancel"} />
						</ButtonContainer>
					</Row>
				) : null}
			</form>
		</Container>
	)
}

export default Plan
