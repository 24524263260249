import React, {Fragment} from "react"
import Autosuggest from "react-autosuggest"
import TagsInput from "react-tagsinput"
import "react-tagsinput/react-tagsinput.css"
import {TagStyler} from "./TagsWithAutoComplete.style"

const InputComponent = React.forwardRef(
	({onChange, suggestions, onSelected, value, className, placeholder, onKeyDown}, ref) => {
		const handleOnChange = (e, {newValue, method}) => {
			if (method === "enter") {
				e.preventDefault()
			} else {
				onChange(e)
			}
		}
		return (
			<Autosuggest
				ref={ref}
				suggestions={suggestions}
				// shouldRenderSuggestions={value => value && value.trim().length >= 0}  // if you want users to type something before rendering suggestions
				shouldRenderSuggestions={value => true}
				getSuggestionValue={suggestion => suggestion}
				renderSuggestion={suggestion => (
					<Fragment>
						<span>{suggestion}</span> <span className='muted'>{suggestion}</span>
					</Fragment>
				)}
				inputProps={{onKeyDown, value, className, placeholder, onChange: handleOnChange}}
				onSuggestionSelected={onSelected}
				onSuggestionsClearRequested={() => {}}
				onSuggestionsFetchRequested={() => {}}
			/>
		)
	}
)

const TagsInputWithAutoComplete = ({
	inputRef,
	onChange,
	initialTags = [],
	placeholder = "",
	name,
	allSuggestions: suggestions = []
}) => {
	const [inputValue, setInputValue] = React.useState("")

	return (
		<TagStyler>
			<TagsInput
				inputValue={inputValue}
				onChangeInput={value => {
					const inputValue = value || ""
					setInputValue(inputValue)
				}}
				name={name}
				value={initialTags}
				onChange={tags => {
					onChange(tags)
				}}
				ref={inputRef}
				focusedClassName='focused'
				inputProps={{placeholder}}
				renderInput={props => (
					<InputComponent
						value={inputValue}
						setInputValue={setInputValue}
						suggestions={suggestions.filter(state => {
							return state?.toLowerCase().indexOf(inputValue) >= 0
						})}
						changeSelectedTags={onChange}
						initialTags={initialTags}
						onSelected={(e, {suggestion}) => {
							setInputValue("")
							onChange(
								initialTags.indexOf(suggestion) === -1 ? [...initialTags, suggestion] : initialTags
							)
						}}
						{...props}
					/>
				)}
				addOnPaste
				pasteSplit={data => {
					const separators = [",", ";", "\\(", "\\)", "\\*", "/", ":", "\\?", "\n", "\r"]
					return data.split(new RegExp(separators.join("|"))).map(d => d.trim())
				}}
				renderLayout={(tagComponents, inputComponent) => {
					return (
						<Fragment>
							{tagComponents}
							{inputComponent}
						</Fragment>
					)
				}}
			/>
		</TagStyler>
	)
}

export default TagsInputWithAutoComplete
