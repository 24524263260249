import React, {Fragment, useState} from "react"
import styled from "styled-components"
import {SingleDatePicker, isInclusivelyAfterDay} from "react-dates"
import {func, bool, instanceOf} from "prop-types"
import moment from "moment"
import Styled from "styled-components"

import "react-dates/initialize"

// UI
import {extended, primary} from "@uprise/colors"

import "react-dates/lib/css/_datepicker.css"
import CalendarIcon from "assets/images/icons/svg/icon-bookings.svg"
import CalendarIconPurple from "assets/images/png/calendar-2x.png"

const DatePickerWrapper = styled.div`
	margin-top: ${props => (props.isFirst ? "21px" : "0px")};
	margin-bottom: ${props => (props.isLast ? "0px" : "21px")};
`

const LabelStyles = Styled.label`
      font-size: ${props => (props.isFocussed ? "12px" : "16px")};
      color: ${extended.charcoal.two};
      display: block;
      margin-bottom: 4px;
      transition: font-size 0.2s;
`

const SingleDatePickerElementWrapper = styled.div`
	display: flex;
	justify-content: space-around;

	.DateInput_input {
		// height: 30px;
		font-size: 12px !important;
	}

	.DateInput_input__focused {
		border: 0px;
		font-size: 12px !important;
	}

	.SingleDatePicker {
		width: 100%;
	}

	.SingleDatePicker_picker .SingleDatePicker_picker_1 {
		border-radius: 10px;
		box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	}

	.DayPicker {
		border-radius: 10px;
		box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	}

	.DateInput_fang {
		bottom: 53px !important;
	}

	.SingleDatePickerInput {
		display: flex;
		justify-content: space-between;
		border: solid 1px #d4cbfb;
		padding: 2px 4px 2px 8px;
		border-radius: ${props => (props.borderRadius ? `${props.borderRadius}px` : 0)};
	}

	.DateInput {
		/* width: 80%; */
	}

	.DateInput_input {
		font-family: "Proxima Nova";
		font-size: 15px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #6d6c79;
		padding: 0;
	}

	.SingleDatePickerInput_calendarIcon {
		padding: 0;
	}

	.CalendarDay__selected_4 {
		background: ${primary.purple};
	}
`

const DatePicker = ({
	id,
	isFirst,
	isLast,
	isFocused,
	label,
	isRequired,
	date,
	onChange,
	allowPreviousDates,
	borderRadius,
	altIcon,
	testId,
	minDate = moment()
}) => {
	const [focused, setFocused] = useState(false)

	return (
		<DatePickerWrapper isFirst={isFirst} isLast={isLast}>
			{label && (
				<LabelStyles htmlFor={id} isFocused={isFocused}>
					{label} {isRequired ? " * " : ""}
				</LabelStyles>
			)}
			<SingleDatePickerElementWrapper borderRadius={borderRadius}>
				<SingleDatePicker
					onDateChange={date => onChange({target: {value: date}})}
					onFocusChange={({focused}) => setFocused(focused)}
					focused={focused}
					numberOfMonths={1}
					noBorder={true}
					date={date}
					isOutsideRange={allowPreviousDates ? () => false : day => !isInclusivelyAfterDay(day, minDate)}
					displayFormat='Do MMM YYYY'
					hideKeyboardShortcutsPanel
					customInputIcon={
						<figure style={{margin: 0, width: "20px", height: "20px"}} data-testid={testId}>
							<img
								style={{width: "100%"}}
								alt='calendar'
								src={altIcon ? CalendarIconPurple : CalendarIcon}
							/>
						</figure>
					}
					inputIconPosition='after'
					withFullScreenPortal={window.innerWidth < 400}
				/>
			</SingleDatePickerElementWrapper>{" "}
		</DatePickerWrapper>
	)
}

DatePicker.propTypes = {
	date: instanceOf(moment).isRequired,
	onChange: func.isRequired,
	allowPreviousDates: bool
}

DatePicker.defaultProps = {
	date: "",
	onChange: () => {},
	allowPreviousDates: false
}

export default DatePicker
