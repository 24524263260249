import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"
// UI
import {Alert} from "@uprise/alert"
// Slices
import {clearBookingApiState} from "features/Bookings/bookingSlice"

export const Alerts = ({createBookingApiState}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (createBookingApiState.isError) {
			setTimeout(() => {
				dispatch(clearBookingApiState())
			}, 5000)
		}
	}, [createBookingApiState])

	return (
		<Fragment>
			{createBookingApiState.isError && (
				<Alert className='m-b-5' type='error'>
					{createBookingApiState.message}
				</Alert>
			)}
		</Fragment>
	)
}
