import React, {useState} from "react"
import {Modal} from "@uprise/modal"
import {spacing} from "@uprise/spacing"
import {backgrounds, extended, primary} from "@uprise/colors"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {Container} from "@uprise/grid"
import {Button} from "@uprise/button"

import {FigureIcon, NotesRow, NoteTextArea, NoteModalButtonContainer, MessageStyles} from "./index.style"
import EditIcon from "assets/images/icons/svg/edit.svg"

const NotesModal = ({user, isOpen, onClose, notes, onSave}) => {
	const [value, setValue] = useState("")
	const [isReadOnly, setReadOnly] = useState(true)
	const [error, setError] = useState(null)
	const disableReadOnly = () => {
		setValue(notes)
		setReadOnly(false)
	}

	const enableReadOnly = () => {
		setValue(notes)
		setReadOnly(true)
	}

	const handleClose = () => {
		setReadOnly(true)
		setValue("")
		setError(null)
		onClose()
	}

	const handleSave = data => {
		if (value) {
			setReadOnly(true)
			setValue("")
			onSave(data)
		} else {
			setError("Notes cannot be empty")
		}
	}
	return (
		<Modal
			isOpen={isOpen}
			padding={spacing.s10}
			backgroundColor={backgrounds.white}
			width='800px'
			textAlign='center'
			handleClose={handleClose}>
			<H3 className='m-0'>{user.name}</H3>
			<Container>
				<NotesRow>
					<H4 className='m-0'>Notes</H4>
					{isReadOnly ? (
						<FigureIcon onClick={() => disableReadOnly()}>
							<img src={EditIcon} alt={""} />
						</FigureIcon>
					) : (
						<NoteModalButtonContainer>
							<Button
								style={{marginRight: "10px"}}
								onClick={() => {
									setError(null)
									enableReadOnly()
								}}
								size='small'
								title={"Cancel"}
								variant='primary'
							/>

							<Button
								onClick={() => handleSave({...user, value})}
								size='small'
								title={"Save"}
								variant='primary'
							/>
						</NoteModalButtonContainer>
					)}
				</NotesRow>
				{isReadOnly ? (
					<NoteTextArea readOnly value={notes} />
				) : (
					<NoteTextArea
						minLength={1}
						maxLength={2000}
						error={error}
						onChange={e => {
							setValue(e.target.value)
							if (error) {
								setError(null)
							}
						}}
						value={value}
					/>
				)}

				{error ? (
					<MessageStyles htmlFor={1} focused={false} validation={error}>
						{error}
					</MessageStyles>
				) : null}
			</Container>
		</Modal>
	)
}

export default NotesModal
