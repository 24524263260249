import React, {Fragment, useEffect, useState} from "react"
import TagsInput from "react-tagsinput"
import "react-tagsinput/react-tagsinput.css"
import TagsMultiLevelDropdown from "../TagsMultiLevelDropdown"
import {TagStyler} from "./index.style"

const MultiLevelDropdownComponent = props => {
	const {
		onSelected,
		leftNavItems,
		rightNavItems,
		initialSelectedItems,
		maxSelectedItemCount,
		onValidationError,
		placeholder,
		name,
		inputRef
	} = props

	return (
		<TagsMultiLevelDropdown
			name={name}
			initialSelectedItems={initialSelectedItems}
			buttonWidth='100%'
			leftNavItems={leftNavItems}
			rightNavItems={rightNavItems}
			label={placeholder}
			onChange={items => {
				onSelected(items)
			}}
			menuStyle={{position: "absolute", zIndex: 2}}
			maxSelectedItemCount={maxSelectedItemCount}
			onValidationError={onValidationError}
		/>
	)
}

function defaultRenderInput(props) {
	let {onChange, value, addTag, ...other} = props
	return <input type='text' onChange={onChange} value={value} {...other} />
}

const SelectTags = ({
	onChange,
	initialTags = [],
	placeholder = "",
	name,
	leftNavItems,
	rightNavItems,
	inputRef,
	maxSelectedItemCount,
	onValidationError
}) => {
	const [inputValue, setInputValue] = React.useState("")
	const [initalValues, setInitialValues] = useState([])

	useEffect(() => {
		if (initialTags) {
			setInitialValues(initialTags.filter(n => n))
		}
	}, [initialTags])

	return (
		<TagStyler>
			<TagsInput
				inputRef={inputRef}
				inputValue={inputValue}
				onChangeInput={value => {
					const inputValue = value || ""
					setInputValue(inputValue)
				}}
				value={initalValues}
				onChange={tags => {
					onChange(tags)
				}}
				focusedClassName='focused'
				inputProps={{placeholder, name}}
				renderInput={props => {
					return (
						<MultiLevelDropdownComponent
							value={inputValue}
							maxSelectedItemCount={maxSelectedItemCount}
							setInputValue={setInputValue}
							changeSelectedTags={onChange}
							initialSelectedItems={initalValues}
							leftNavItems={leftNavItems}
							rightNavItems={rightNavItems}
							onSelected={selectedItems => {
								setInputValue("")
								onChange(selectedItems)
							}}
							name={name}
							placeholder={placeholder}
							onValidationError={onValidationError}
							inputValue={inputValue}
						/>
					)
				}}
				addOnPaste
				pasteSplit={data => {
					const separators = [",", ";", "\\(", "\\)", "\\*", "/", ":", "\\?", "\n", "\r"]
					return data.split(new RegExp(separators.join("|"))).map(d => d.trim())
				}}
				renderLayout={(tagComponents, inputComponent) => {
					return (
						<Fragment>
							{tagComponents}
							{inputComponent}
						</Fragment>
					)
				}}
			/>
		</TagStyler>
	)
}

export default SelectTags
