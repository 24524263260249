import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"
// UI
import {Alert} from "@uprise/alert"
// Slices
import {clearCoachStatusApiState} from "features/Coaches/coachesSlice"

export const Alerts = ({coachStatusApiState, coachesCalendarApiState, allCoachesApiState}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (coachStatusApiState.isError || coachStatusApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearCoachStatusApiState())
			}, 3000)
		}
	}, [coachStatusApiState])

	return (
		<Fragment>
			{coachesCalendarApiState.isError && <Alert type='error'>{coachesCalendarApiState.message}</Alert>}
			{allCoachesApiState.isError && <Alert type='error'>{allCoachesApiState.message}</Alert>}

			{coachStatusApiState.isError && (
				<Alert className='m-b-5' type='error'>
					{coachStatusApiState.message}
				</Alert>
			)}
			{coachStatusApiState.isSuccess && (
				<Alert className='m-b-5' type='success'>
					Status of coach successfully updated
				</Alert>
			)}
		</Fragment>
	)
}
