import React, {Fragment, useEffect} from "react"
import {useForm, Controller} from "react-hook-form"
import moment from "moment-timezone"
import {useDispatch, useSelector} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"
import {useHistory} from "react-router"
// UI
import {Button} from "@uprise/button"
import {Col, ContainerFluid, Row} from "@uprise/grid"
import {Alert} from "@uprise/alert"

import ComponentWrapper from "components/HOC/ComponentWrapper"
// Styles
import {HeaderTableContent} from "../../RiskCallBacks/index.style"
import {TableContainer} from "./../../Employers/AllEmployers/index.style"
import {
	FormElementContainer,
	FormElementLabel,
	FormInputContainer,
	FormTextInput,
	SubmitButtonContainer,
	StyledImg,
	HiddenTextArea,
	MessageStyles
} from "shared/Form/index.style"
// Slices
import {createCoach, createCoachSelector} from "../coachesSlice"

import Toggle from "components/shared/Toggle"
import TagsInputWithAutoComplete from "components/shared/TagsWithAutoComplete/TagsWithAutoComplete"
import {
	languages,
	qualifications,
	designation,
	age,
	experience,
	gender,
	specializationParentLevel,
	specializationChildLevel
} from "./constant"
import DropDown from "components/shared/DropDown"
import UploadImageModal from "components/shared/UploadImageModal"
import ToggleModal from "components/shared/Modal/ToggleModal"
import Wysiwyg from "components/shared/Wysiwyg/index"
import AvailabilityTable from "components/shared/AvailabilityTable"
import PhoneNumberInput from "components/shared/PhoneNumberInput"
import SelectTags from "components/shared/SelectTags"

function NewCoaches() {
	const dispatch = useDispatch()
	const history = useHistory()
	const {getAccessTokenSilently} = useAuth0()

	const {createCoachApiState} = useSelector(createCoachSelector)

	const {register, control, handleSubmit, errors, trigger, getValues, formState} = useForm({
		reValidateMode: "onChange"
	})

	useEffect(() => {
		/**
		 * Here, we scroll to the error input to focus.
		 */
		if (errors) {
			const errorsvalues = Object.values(errors)
			if (errorsvalues.length > 0) {
				let firstErrorElement = document.getElementsByName(errorsvalues[0].ref.name)[0]
				firstErrorElement.scrollIntoView({behavior: `smooth`, block: "center"})
			}
		}
	}, [errors])

	const _submitClick = handleSubmit(data => {
		data.degree = typeof data.degree === "string" ? [data.degree] : data.degree
		data.bookingsPerWeek = Number(data.bookingsPerWeek)

		if (data.photos?.base64PhotoURI) {
			data.base64PhotoURI = data.photos.base64PhotoURI
		}

		console.log("data", data)

		const submit = async () => {
			const token = await getAccessTokenSilently()
			dispatch(createCoach({token, history, data}))
		}
		submit()
	})

	return (
		<ComponentWrapper
			menuActiveIndex={7}
			subMenuActiveIndex={11}
			headerTitle='Add New Coach'
			headerRightAlignContent={
				<HeaderTableContent>
					<Button
						onClick={() => history.push("/coaches/all")}
						size='small'
						title={"Cancel"}
						data-testid='cancelButton'
						variant='tertiary'
						style={{margin: "10px", padding: "20px 24px"}}
					/>
					<Button
						onClick={() => _submitClick()}
						size='small'
						title={"Save"}
						data-testid='saveButton'
						style={{margin: "10px", padding: "20px 24px"}}
						variant='primary'
						type='submit'
						isLoading={createCoachApiState.isFetching}
					/>
				</HeaderTableContent>
			}>
			{createCoachApiState.isError && (
				<Alert className='m-b-10' type={"error"}>
					{createCoachApiState.message}
				</Alert>
			)}

			<TableContainer>
				<form onSubmit={_submitClick}>
					<ContainerFluid style={{padding: "40px"}}>
						<Row>
							<Col className='col-2 col-lg-2 col-md-2 col-sm-12'>
								<Controller
									name='photos'
									control={control}
									render={props => (
										<>
											<StyledImg src={props.value?.tempImg} />

											<ToggleModal
												toggle={show => (
													<Button
														onClick={show}
														size='small'
														title={"Upload photo"}
														variant='tertiary'
														type='button'
														fullWidth={false}
														style={{width: "137px", margin: "0 auto"}}
													/>
												)}
												content={hide => (
													<UploadImageModal
														hide={hide}
														src={props.value?.tempImg}
														onChange={({tempImg, base64PhotoURI}) => {
															props.onChange({tempImg, base64PhotoURI})
														}}
													/>
												)}
											/>
										</>
									)}
								/>
							</Col>
							<Col className='col-10 col-lg-10 col-md-10 col-sm-12'>
								<FormElementContainer error={errors.name}>
									<FormElementLabel isRequired={true}>Coach Name</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"name"}
											data-testid='coachName'
											control={control}
											rules={{
												required: {value: true, message: "Coach name is required"},
												maxLength: {
													value: 100,
													message: "Name can be a maximum of 100 characters"
												}
											}}
											defaultValue={""}
											as={<FormTextInput type='text' placeholder='name' />}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.name?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.name}>
										{errors?.name?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.location}>
									<FormElementLabel>Location</FormElementLabel>
									<FormInputContainer isRequired={true}>
										<Controller
											name={"location"}
											data-testid='location'
											control={control}
											rules={{
												required: {value: true, message: "Location is required"},
												maxLength: {
													value: 100,
													message: "Location can be a maximum of 100 characters"
												}
											}}
											defaultValue={""}
											as={<FormTextInput type='text' placeholder='eg. Sydney, Australia' />}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.location?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.location}>
										{errors?.location?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.timezone}>
									<FormElementLabel>Time Zone</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"timezone"}
											control={control}
											rules={{
												maxLength: {
													value: 100,
													message: "Time Zone can be a maximum of 100 characters"
												}
											}}
											render={props => (
												<DropDown
													isFirst={true}
													name={props.name}
													buttonWidth='100%'
													testId='timezone'
													variant='primary'
													items={moment.tz.names().map(x => ({label: x, value: x}))}
													label='Select'
													onChange={item => props.onChange(item.value)}
													initialSelectedItem={""}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.timezone?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.timezone}>
										{errors?.timezone?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.email}>
									<FormElementLabel isRequired={true}>Email Address</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"email"}
											data-testid='email'
											control={control}
											rules={{
												required: {value: true, message: "Email Address is required"},
												pattern: {
														value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
														message: "Email address must be a valid email"
												},
												validate: {
													value: value => {
														let strings = value
														let invalid = false

														for (let i = 0, l = strings.length; i < l; i++) {
															let character = strings.charAt(i)
															if (
																!isNaN(character * 1) ||
																character === character.toLowerCase()
															) {
																continue
															} else {
																if (character === character.toUpperCase()) {
																	invalid = true
																}
															}
														}

														return invalid ? "Email address must be lowercase" : true
													}
												}
											}}
											defaultValue={""}
											as={<FormTextInput type='email' placeholder='eg. john.doe@xyz.com' />}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.email?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.email}>
										{errors?.email?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.phoneNumber}>
									<FormElementLabel>Phone Number</FormElementLabel>
									<FormInputContainer isRequired={true}>
										<Controller
											name={"phoneNumber"}
											control={control}
											rules={{
												required: {value: true, message: "Phone Number is required"},
												maxLength: {
													value: 100,
													message: "Phone Number can be a maximum of 100 characters"
												}
											}}
											defaultValue={""}
											render={props => (
												<PhoneNumberInput
													name={props.name}
													value={props.value}
													onChange={val => props.onChange(val)}
													placeholder='eg. +61 2 9876 5432'
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.phoneNumber?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.phoneNumber}>
										{errors?.phoneNumber?.message}
									</MessageStyles>
								)}

								<FormElementContainer>
									<FormElementLabel>Status</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"disabled"}
											control={control}
											defaultValue={true}
											render={props => (
												<div style={{width: "50px"}}>
													<Toggle
														name={props.name}
														testId='newCoachToggle'
														checked={!props.value}
														onChange={e => props.onChange(!props.value)}
														backgroundColorChecked={"#7d60ff"}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer error={errors.degree}>
									<FormElementLabel>Degree</FormElementLabel>
									<FormInputContainer isRequired={true} data-testid='degree'>
										<Controller
											name={"degree"}
											control={control}
											rules={{required: {value: true, message: "Degree is required"}}}
											defaultValue={[]}
											render={props => (
												<TagsInputWithAutoComplete
													inputRef={props.ref}
													name={props.name}
													allSuggestions={[]}
													initialTags={
														typeof props.value === "string" ? [props.value] : props.value
													}
													placeholder='eg. Bachelor of Psychology (BA), Masters of Counselling Psychology (MEd.)'
													onChange={tags => props.onChange(tags)}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.degree?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.degree}>
										{errors?.degree && errors?.degree?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.license}>
									<FormElementLabel>License</FormElementLabel>
									<FormInputContainer isRequired={true}>
										<Controller
											name={"license"}
											control={control}
											defaultValue={""}
											rules={{
												required: {value: true, message: "License is required"},
												maxLength: {
													value: 100,
													message: "License can be a maximum of 100 characters"
												}
											}}
											as={
												<FormTextInput
													type='text'
													data-testid='license'
													placeholder='eg. British Columbia Association of Clinical Counsellors (BCACC)'
												/>
											}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.license?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.license}>
										{errors?.license?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.qualification}>
									<FormElementLabel>Highest Professional Qualification</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"qualification"}
											control={control}
											rules={{required: {value: true, message: "Qualification is required"}}}
											render={props => (
												<DropDown
													name={props.name}
													testId='qualification'
													isFirst={true}
													variant='primary'
													buttonWidth='100%'
													items={qualifications}
													label='Qualification'
													onChange={item => props.onChange(item.value)}
													initialSelectedItem={""}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.qualification?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.qualification}>
										{errors?.qualification?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.designation}>
									<FormElementLabel>Designation</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"designation"}
											control={control}
											rules={{required: {value: true, message: "Designation is required"}}}
											render={props => (
												<DropDown
													name={props.name}
													testId='designation'
													isFirst={true}
													variant='primary'
													buttonWidth='100%'
													items={designation}
													label='Designation'
													onChange={item => props.onChange(item.value)}
													initialSelectedItem={""}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.designation?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.designation}>
										{errors?.designation?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.age}>
									<FormElementLabel>Age</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"age"}
											rules={{required: {value: true, message: "Age is required"}}}
											control={control}
											render={props => (
												<DropDown
													name={props.name}
													testId='age'
													isFirst={true}
													variant='primary'
													buttonWidth='100%'
													items={age}
													label='Age'
													onChange={item => props.onChange(item.value)}
													initialSelectedItem={""}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.age?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.age}>
										{errors?.age?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.experience}>
									<FormElementLabel>Years of Experience</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"experience"}
											rules={{required: {value: true, message: "Experience is required"}}}
											control={control}
											render={props => (
												<DropDown
													name={props.name}
													isFirst={true}
													testId='experience'
													variant='primary'
													buttonWidth='100%'
													items={experience}
													label='Years of Experience'
													onChange={item => props.onChange(item.value)}
													initialSelectedItem={""}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.experience?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.experience}>
										{errors?.experience?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.gender}>
									<FormElementLabel>Gender</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"gender"}
											control={control}
											rules={{required: {value: true, message: "Gender is required"}}}
											render={props => (
												<DropDown
													name={props.name}
													isFirst={true}
													variant='primary'
													testId='gender'
													buttonWidth='100%'
													items={gender}
													label='Select Gender'
													onChange={item => props.onChange(item.value)}
													initialSelectedItem={""}
													menuStyle={{position: "absolute", zIndex: 2}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.gender?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.gender}>
										{errors?.gender?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.interest}>
									<FormElementLabel>Area of Interest</FormElementLabel>
									<FormInputContainer isRequired={true} data-testid='interest'>
										<Controller
											name={"interest"}
											rules={{required: {value: true, message: "Interest is required"}}}
											control={control}
											defaultValue={""}
											render={props => (
												<SelectTags
													inputRef={props.ref}
													name={props.name}
													allSuggestions={[]}
													initialTags={
														typeof props.value === "string" ? [props.value] : props.value
													}
													leftNavItems={specializationParentLevel}
													rightNavItems={specializationChildLevel}
													placeholder='Select Area of Interest'
													onChange={items => {
														return props.onChange(items)
													}}
													maxSelectedItemCount={null}
													onValidationError={message => {}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.interest?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.interest}>
										{errors?.interest?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.specialization}>
									<FormElementLabel>
										Specialization<span>(up to 3)</span>
									</FormElementLabel>
									<FormInputContainer isRequired={true} data-testid='specialization'>
										<Controller
											name={"specialization"}
											control={control}
											rules={{required: {value: true, message: "Specialization is required"}}}
											defaultValue={""}
											render={props => (
												<SelectTags
													inputRef={props.ref}
													name={props.name}
													allSuggestions={[]}
													initialTags={
														typeof props.value === "string" ? [props.value] : props.value
													}
													leftNavItems={specializationParentLevel}
													rightNavItems={specializationChildLevel}
													placeholder='Select Specialization'
													onChange={items => {
														return props.onChange(items)
													}}
													maxSelectedItemCount={3}
													onValidationError={message => {}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.specialization?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.specialization}>
										{errors?.specialization?.message}
									</MessageStyles>
								)}

								<FormElementContainer>
									<FormElementLabel>Language(s)</FormElementLabel>
									<FormInputContainer data-testid='languages'>
										<Controller
											name={"languages"}
											control={control}
											defaultValue={[]}
											// as={<FormTextInput type='text' placeholder='English' />}
											render={props => (
												<TagsInputWithAutoComplete
													name={props.name}
													allSuggestions={languages}
													initialTags={props.value || []}
													placeholder='Select a language'
													onChange={tags => props.onChange(tags)}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>F2F Sessions</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"f2fSessions"}
											control={control}
											defaultValue={false}
											render={props => (
												<div style={{width: "50px"}}>
													<Toggle
														name={props.name}
														testId='f2fSessions'
														checked={props.value}
														onChange={e => props.onChange(e.target.value)}
														backgroundColorChecked={"#7d60ff"}
														width='42'
														height='24'
														sliderHeight='20.8'
														sliderWidth='20.8'
														translate='18'
													/>
												</div>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Practice Address</FormElementLabel>
									<div style={{width: "80%"}}>
										<FormElementContainer>
											<FormElementLabel>Address Line 1</FormElementLabel>
											<FormInputContainer>
												<Controller
													name='address.line1'
													data-testid='addressLine1'
													control={control}
													as={<FormTextInput type='text' placeholder='Address line 1' />}
												/>
											</FormInputContainer>
										</FormElementContainer>
										<FormElementContainer>
											<FormElementLabel>Address Line 2</FormElementLabel>
											<FormInputContainer>
												<Controller
													name='address.line2'
													control={control}
													as={<FormTextInput type='text' placeholder='Address line 2' />}
												/>
											</FormInputContainer>
										</FormElementContainer>
										<FormElementContainer>
											<FormElementLabel>State</FormElementLabel>
											<FormInputContainer>
												<Controller
													name='address.state'
													control={control}
													as={<FormTextInput type='text' placeholder='State' />}
												/>
											</FormInputContainer>
										</FormElementContainer>
										<FormElementContainer>
											<FormElementLabel>Country</FormElementLabel>
											<FormInputContainer>
												<Controller
													name='address.country'
													control={control}
													as={<FormTextInput type='text' placeholder='Country' />}
												/>
											</FormInputContainer>
										</FormElementContainer>
										<FormElementContainer>
											<FormElementLabel>Postcode</FormElementLabel>
											<FormInputContainer>
												<Controller
													name='address.postcode'
													control={control}
													as={<FormTextInput type='text' placeholder='01234' />}
												/>
											</FormInputContainer>
										</FormElementContainer>
									</div>
								</FormElementContainer>

								<FormElementContainer error={errors.calendarId}>
									<FormElementLabel isRequired={true}>Main Calendar ID</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"calendarId"}
											data-testid='calendarId'
											control={control}
											rules={{
												required: {value: true, message: "Main Calendar ID is required"},
												pattern: {
														value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
														message: "Email address must be a valid email"
												},
												validate: {
													value: value => {
														let strings = value
														let invalid = false

														for (let i = 0, l = strings.length; i < l; i++) {
															let character = strings.charAt(i)
															if (
																!isNaN(character * 1) ||
																character === character.toLowerCase()
															) {
																continue
															} else {
																if (character === character.toUpperCase()) {
																	invalid = true
																}
															}
														}

														return invalid ? "Main Calendar ID must be lowercase" : true
													}
												}
											}}
											defaultValue={""}
											as={<FormTextInput type='email' placeholder='eg. john.doe@xyz.com' />}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.calendarId?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.calendarId}>
										{errors?.calendarId?.message}
									</MessageStyles>
								)}

								<FormElementContainer>
									<FormElementLabel>Extra Calendar ID</FormElementLabel>
									<FormInputContainer>
										<Controller
											name={"extraCalendars"}
											control={control}
											defaultValue={[]}
											// as={<FormTextInput type='text' placeholder='English' />}
											render={props => (
												<TagsInputWithAutoComplete
													name={props.name}
													allSuggestions={[]}
													initialTags={props.value || []}
													placeholder='Type extra calendar ID'
													onChange={tags => props.onChange(tags)}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								<FormElementContainer>
									<FormElementLabel>Active On</FormElementLabel>
									<div style={{width: "80%"}}>
										<FormElementContainer>
											<FormElementLabel>Corporate version</FormElementLabel>
											<FormInputContainer>
												<Controller
													name={"activeOn.corporate"}
													control={control}
													defaultValue={false}
													render={props => (
														<div style={{width: "50px"}}>
															<Toggle
																name={props.name}
																checked={props.value}
																value={props.checked}
																onChange={value => {
																	props.onChange(value)
																}}
																backgroundColorChecked={"#7d60ff"}
																width='42'
																height='24'
																sliderHeight='20.8'
																sliderWidth='20.8'
																translate='18'
															/>
														</div>
													)}
												/>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>RTW version</FormElementLabel>
											<FormInputContainer>
												<Controller
													name={"activeOn.rtw"}
													control={control}
													defaultValue={false}
													render={props => (
														<div style={{width: "50px"}}>
															<Toggle
																name={props.name}
																checked={props.value}
																value={props.checked}
																onChange={value => {
																	props.onChange(value)
																}}
																backgroundColorChecked={"#7d60ff"}
																width='42'
																height='24'
																sliderHeight='20.8'
																sliderWidth='20.8'
																translate='18'
															/>
														</div>
													)}
												/>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>Risk callback</FormElementLabel>
											<FormInputContainer>
												<Controller
													name={"activeOn.riskcallback"}
													control={control}
													defaultValue={false}
													render={props => (
														<div style={{width: "50px"}}>
															<Toggle
																name={props.name}
																checked={props.value}
																value={props.checked}
																onChange={value => {
																	props.onChange(value)
																}}
																backgroundColorChecked={"#7d60ff"}
																width='42'
																height='24'
																sliderHeight='20.8'
																sliderWidth='20.8'
																translate='18'
															/>
														</div>
													)}
												/>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>AUS</FormElementLabel>
											<FormInputContainer>
												<Controller
													name={"activeOn.aus"}
													control={control}
													defaultValue={false}
													render={props => (
														<div style={{width: "50px"}}>
															<Toggle
																name={props.name}
																checked={props.value}
																value={props.checked}
																onChange={value => {
																	props.onChange(value)
																}}
																backgroundColorChecked={"#7d60ff"}
																width='42'
																height='24'
																sliderHeight='20.8'
																sliderWidth='20.8'
																translate='18'
															/>
														</div>
													)}
												/>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>US</FormElementLabel>
											<FormInputContainer>
												<Controller
													name={"activeOn.us"}
													control={control}
													defaultValue={false}
													render={props => (
														<div style={{width: "50px"}}>
															<Toggle
																name={props.name}
																checked={props.value}
																value={props.checked}
																onChange={value => {
																	props.onChange(value)
																}}
																backgroundColorChecked={"#7d60ff"}
																width='42'
																height='24'
																sliderHeight='20.8'
																sliderWidth='20.8'
																translate='18'
															/>
														</div>
													)}
												/>
											</FormInputContainer>
										</FormElementContainer>

										<FormElementContainer>
											<FormElementLabel>Students</FormElementLabel>
											<FormInputContainer>
												<Controller
													name={"activeOn.students"}
													control={control}
													defaultValue={false}
													render={props => {
														console.log(props)
														return (
															<div style={{width: "50px"}}>
																<Toggle
																	name={props.name}
																	checked={props.value}
																	value={props.value}
																	onChange={value => {
																		props.onChange(value)
																	}}
																	backgroundColorChecked={"#7d60ff"}
																	width='42'
																	height='24'
																	sliderHeight='20.8'
																	sliderWidth='20.8'
																	translate='18'
																/>
															</div>
														)
													}}
												/>
											</FormInputContainer>
										</FormElementContainer>
									</div>
								</FormElementContainer>

								<FormElementContainer error={errors.bookingsPerWeek}>
									<FormElementLabel>Bookings Per Week</FormElementLabel>
									<FormInputContainer isRequired={true}>
										<Controller
											name={"bookingsPerWeek"}
											data-testid='bookingsPerWeek'
											control={control}
											defaultValue={0}
											rules={{
												required: {
													value: true,
													message: "Number of Bookings Per Week is Required"
												},
												min: {
													value: 1,
													message: "Minimum Value is Required"
												},
												max: {
													value: 1000,
													message: "Value Exceeds Maximum Bookings Count"
												}
											}}
											as={
												<FormTextInput
													type='number'
													placeholder='Number of Bookings Per week'
												/>
											}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.bookingsPerWeek?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.bookingsPerWeek}>
										{errors?.bookingsPerWeek?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.availabilityHours}>
									<FormElementLabel>Standard Availability Hours</FormElementLabel>
									<FormInputContainer isRequired={true}>
										<Controller
											name={"availabilityHours"}
											data-testid='availabilityHours'
											control={control}
											defaultValue={""}
											rules={{
												required: {
													value: true,
													message: "Standard Availability Hours is required"
												},
												maxLength: {
													value: 100,
													message:
														"Standard Availability Hours can be a maximum of 100 characters"
												}
											}}
											as={
												<FormTextInput
													type='text'
													placeholder='eg. Monday 9:00 - 1:00 pm AEST and Thursday 7:00 - 10:00 am & 2:00 pm - 5:00 pm AEST'
												/>
											}
										/>
									</FormInputContainer>
								</FormElementContainer>
								{errors?.availabilityHours && errors?.availabilityHours?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.availabilityHours}>
										{errors?.availabilityHours && errors?.availabilityHours?.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.intro}>
									<FormElementLabel isRequired={false}>Introduction</FormElementLabel>
									<FormInputContainer data-testid='intro'>
										<Controller
											name={"intro-english"}
											control={control}
											rules={{required: {value: false, message: "Introduction is required"}}}
											defaultValue={""}
											render={props => (
												<Fragment>
													{/* Need to find a fix to add name prop here for validation */}
													<Wysiwyg
														name={props.name}
														value={props.value}
														placeholder='eg. Introduce yourself here'
														onChange={val => props.onChange(val)}
													/>
												</Fragment>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>

								{errors?.intro?.english.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.intro?.english}>
										{errors?.intro?.english.message}
									</MessageStyles>
								)}

								<FormElementContainer error={errors.hours}>
									<FormElementLabel isRequired={true}>Availability</FormElementLabel>
									<FormInputContainer data-testid='availabilityTable'>
										<Controller
											name={"hours"}
											control={control}
											defaultValue={{}}
											rules={{
												required: {value: true, message: "Coach availability is required"},
												validate: {
													value: value => {
														return Object.keys(value)
															.map(x => value[x])
															.flat().length
															? true
															: "Coach availability can't be empty"
													}
												}
											}}
											render={props => (
												<AvailabilityTable
													value={props.value}
													onChange={value => {
														props.onChange(value)
													}}
												/>
											)}
										/>
									</FormInputContainer>
								</FormElementContainer>
								{errors?.hours && errors?.hours?.message && (
									<MessageStyles htmlFor={1} focused={false} validation={errors?.hours}>
										{errors?.hours && errors?.hours?.message}
									</MessageStyles>
								)}
							</Col>
						</Row>
						<Row>
							<SubmitButtonContainer>
								<Button
									size='small'
									title={"Cancel"}
									variant='tertiary'
									style={{margin: "10px", padding: "20px 24px"}}
									fullWidth={false}
								/>
								<Button
									size='small'
									title={"Save"}
									style={{margin: "10px", padding: "20px 24px"}}
									variant='primary'
									fullWidth={false}
									type='submit'
								/>
							</SubmitButtonContainer>
						</Row>
					</ContainerFluid>
				</form>
			</TableContainer>
		</ComponentWrapper>
	)
}

export default NewCoaches
