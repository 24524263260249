import styled from "styled-components"
// colors
import {backgrounds} from "uprise-uikit/colors"

export const FormContainer = styled.div`
	display: flex;
`

export const Hr = styled.div`
	height: 1px;
	background: #edeafa;
	width: 100%;
	margin: 10px 0;
`
