import React, {useState, useEffect} from "react"
import axios from "axios"
import {useDispatch, useSelector} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"

// UI
import {Button} from "@uprise/button"
import {Container, Grid, Row, Col} from "@uprise/grid"
import {spacing} from "@uprise/spacing"
// Slices
import {uploadReferral, workableSelector} from "../workableSlice"
// Styles
import {
	FileInputContainer,
	FileInputLabel,
	FileInput,
	UploadContainer,
	SelectedFileText,
	UploadProgressBar,
	ButtonContainer
} from "./index.style"

const UploadModal = ({referralType, onFileUpload, onCancel}) => {
	const [uploadPercentage, setUploadPercentage] = useState(0)
	const [token, setToken] = useState(null)
	const [selectedFileName, setSelectedFileName] = useState("")
	const [selectedFile, setSelectedFile] = useState(null)

	const {getAccessTokenSilently} = useAuth0()

	const {uploadApiState} = useSelector(workableSelector)

	const dispatch = useDispatch()

	useEffect(() => {
		async function getToken() {
			const token = await getAccessTokenSilently()
			setToken(token)
		}

		getToken()
	}, [])

	useEffect(() => {
		if (uploadApiState.isSuccess || uploadApiState.isError) {
			setUploadPercentage(100)
			onFileUpload()
		}
	}, [uploadApiState])

	const handleFileUpload = () => {
		const options = {
			headers: {Authorization: `Bearer ${token}`},
			onUploadProgress: progressEvent => {
				const {loaded, total} = progressEvent
				let percent = Math.floor((loaded * 100) / total)
				console.log(`${loaded}kb of ${total}kb | ${percent}%`)

				if (percent < 100) {
					setUploadPercentage(percent)
				}
			}
		}

		dispatch(uploadReferral({data: selectedFile, token, optionsArg: options}))
	}

	const onFileUploadChange = ({target: {files}}) => {
		console.log(files[0])

		let data = new FormData()
		data.append("type", referralType.value)
		data.append("referralList", files[0])

		setSelectedFile(data)
		setSelectedFileName(files[0].name)
	}

	return (
		<UploadContainer>
			<FileInputContainer className='m-t-10 m-b-5'>
				<Button
					className='m-b-5'
					as='label'
					paddingLeft={spacing.s10}
					paddingRight={spacing.s10}
					htmlFor='file-upload'
					size='large'
					title={"Browse"}
					data-testid='browseButton'
					variant='tertiary'
					type='button'
				/>
				<input
					style={{display: "none"}}
					name='users'
					data-testid='fileUpload'
					id='file-upload'
					type='file'
					accept='.csv'
					onChange={onFileUploadChange}
					required
				/>

				{selectedFileName && <SelectedFileText>{selectedFileName}</SelectedFileText>}
			</FileInputContainer>

			{uploadPercentage > 0 && <UploadProgressBar percentage={uploadPercentage} radius={10} />}

			<Row>
				<ButtonContainer>
					<Button
						size='small'
						onClick={e => {
							e.preventDefault()
							onCancel()
						}}
						variant={"secondary"}
						title={"Cancel"}
						data-testid='cancelButton'
					/>
					<Button
						className='m-l-3'
						size='small'
						variant={"primary"}
						title={"Upload"}
						data-testid='uploadButton'
						onClick={handleFileUpload}
					/>
				</ButtonContainer>
			</Row>
		</UploadContainer>
	)
}

export default UploadModal
