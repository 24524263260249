import styled from "styled-components"
import {Button} from "@uprise/button"
import {Select, TextInputHorizontal} from "@uprise/form"
import {Note} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {primary, extended, backgrounds} from "uprise-uikit/colors"
import {P} from "@uprise/text"

export const SelectedInfoContainer = styled.div`
	width: 100%;
`

export const HeaderInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

export const FormContainer = styled.div`
	display: flex;
`

export const ButtonContainer = styled.div`
	display: flex;
	align-self: center;
`

export const InlineP = styled(P)`
	display: inline-block;
	margin-right: 10px;
	font-size: 15px;
`

export const ButtonLeft = styled.img`
	height: 11px;
	transform: rotate(90deg);
	cursor: pointer;
`

export const ButtonRight = styled.img`
	height: 11px;
	margin-right: 10px;
	transform: rotate(-90deg);
	cursor: pointer;
`

export const ToggleBtn = styled.div`
	background: ${props => (props.active ? primary.purple : "white")};
	color: ${props => (props.active ? "white" : primary.purple)};
	width: 61px;
	height: 30px;
	cursor: pointer;
	display: block;
	border: 1px solid ${primary.purple};
	text-align: center;
	font-family: "Proxima Nova";
	font-size: 14px;
	border-radius: 5px;
	padding: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`

export const ToggleBtnRight = styled(ToggleBtn)`
	border-radius: 5px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`
