import React, {Fragment} from "react"
import {useSelect} from "downshift"
import {bool, func, object, string, array, element} from "prop-types"
import Styled from "styled-components"

const Container = Styled.div``

const TriggerButtonContainer = Styled.div`

`

const TriggerButton = Styled.button`
	display: flex;
	justify-items: flex-start;
	padding: 6px 22px 6px 12px;
	border-radius: 10px;
	text-align: left;
	outline: none;
	border: 0;
	background-color: transparent;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: #2f2d40;
	position: relative;
`

const ArrowIcon = Styled.span`
	border: solid #7d60ff;
	border-width: 0 1px 1px 0;
	padding: 3px;
	display: inline-block;
	transform: ${props => (props.isOpen ? "rotate(225deg)" : "rotate(45deg)")};
	transition: transform 0.1s linear;
	margin-left: 24px;
	top: 7px;
    	position: relative;

	&:hover {
		cursor: pointer;
	}
  `

const MenuHeader = Styled.div`
  padding : 0 12px;
  text-align: left;
  font-family: "Proxima Nova";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #9796a0;

`

const MenuList = Styled.ul`
  max-height: 200px;
  width: 100%;
  max-width : 400px;
  overflow-y: auto;
  margin: 0 auto;
  border-top: 0;
  outline: none;
  padding: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px 0 rgba(219, 221, 227, 0.5);
  background-color: #ffffff;
`
const MenuPrimaryItemContainer = Styled.div`
position: relative;
 &:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #edeafa;
  width: 70%;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
}
`

const MenuListItem = Styled.li`
  padding: 8px 12px;
  text-align: left;
  list-style-type: none;
  /* border-bottom: 1px solid #edeafa; */
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  background-color: ${props => (props.selectedItem ? "#edeafa" : props.highlightedIndex ? "#f8f8ff" : "#fffff")};
  color: ${props => (props.selectedItem ? "#7d60ff" : "#9796a0")};

  &:last-child {
    border-bottom: 0;
  }
`

const MultiLevelDropdown = ({
	items,
	label,
	onChange,
	containerStyle,
	menuStyle,
	initialSelectedItem,
	customMenuAddOn
}) => {
	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		highlightedIndex,
		getItemProps
	} = useSelect({
		items,
		onSelectedItemChange: e => {
			onChange(e.selectedItem)
		},
		initialSelectedItem: initialSelectedItem
	})
	return (
		<Container style={containerStyle}>
			<TriggerButtonContainer>
				<TriggerButton {...getToggleButtonProps()}>
					{label}
					<ArrowIcon isOpen={isOpen} />
				</TriggerButton>
			</TriggerButtonContainer>
			<MenuList {...getMenuProps()} style={menuStyle}>
				{isOpen ? (
					<Fragment>
						<MenuHeader>Sort</MenuHeader>
						<MenuPrimaryItemContainer>
							{items.map((item, index) => {
								return (
									<MenuListItem
										highlightedIndex={highlightedIndex === index}
										selectedItem={selectedItem.value === item.value}
										key={`${item}${index}`}
										{...getItemProps({item: item.value, index})}>
										{item.icon ? item.icon : null}
										{item.label}
									</MenuListItem>
								)
							})}
						</MenuPrimaryItemContainer>
						{customMenuAddOn()}
					</Fragment>
				) : null}
			</MenuList>
		</Container>
	)
}

MultiLevelDropdown.propTypes = {
	onChange: func.isRequired,
	items: array.isRequired,
	label: string.isRequired,
	containerStyle: object,
	menuStyle: object,
	customMenuAddOn: func
}

MultiLevelDropdown.defaultProps = {
	items: [],
	label: "",
	onChange: () => {},
	containerStyle: {},
	menuStyle: {},
	customMenuAddOn: () => {}
}

export default MultiLevelDropdown
