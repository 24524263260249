import React, {Fragment, useState, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"
import styled from "styled-components"
import {useForm, Controller} from "react-hook-form"
import {useAuth0} from "@auth0/auth0-react"

// UI
import {Modal} from "@uprise/modal"
import {H3, H5, H4, H6} from "@uprise/headings"
import {Row} from "@uprise/grid"
import {Button} from "@uprise/button"
import {primary} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
// Components
import AutoComplete from "components/shared/AutoComplete"
// Slices
import {fetchAvailableMigrationCodes, createUserMigration, userDataSelector} from "../../Users/usersSlice"
// Styles
import {ModalButtonContainer} from "./index.style"

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`

const Container = styled.div`
	padding: 0px 30px;
`

const MigrateDetailsWrap = styled.div`
	display: flex;
	flex-direction: column;
`

const MigrateDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

const MigrateEmails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

const ItemContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;

	border-bottom: 1px solid rgb(125, 96, 255, 0.4);
	padding-bottom: 6px;
	margin-bottom: 10px;
`

const Input = styled.input`
	border: solid 1px #d4cbfb;
	padding: 6px 16px;
	border-radius: 4px;
	font-size: 13px;
	font-family: Proxima Nova;
	color: #6d6c79;

	&:focus {
		outline: none;
	}
`
const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
		color: red;
	}
`

const MigrateUserSection = ({
	code = "",
	totalUsers = 0,
	codeList = [],
	allCodes = [],
	selectedUsers = {},
	onSelect = () => {}
}) => {
	const [migrateData, setMigrateData] = useState({
		from: "",
		to: ""
	})

	const {control, setError, errors} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onBlur",
		reValidateMode: "onChange"
	})

	const onCodeSelection = newSelectedCode => {
		setMigrateData({...migrateData, to: newSelectedCode})

		const _apiData = Object.entries(selectedUsers)
			.map(element => {
				const [key, users] = element
				if (key === code) {
					const singleCode = allCodes.find(element => element.code === code)
					const course = singleCode?.data?.code?.course || ""
					return {
						oldCode: code,
						newCourse: course,
						newEmployerCode: newSelectedCode,
						users: users.map(user => ({
							email: user.email,
							course: user.course,
							employerCode: user.employerCode
						}))
					}
				}
				return null
			})
			.filter(el => el)
		onSelect(_apiData)
	}

	const userList = Object.values(selectedUsers[code]).map(user => {
		return user.email
	})

	return (
		<Fragment>
			<MigrateDetailsWrap>
				<MigrateDetails className='m-b-2 m-t-5'>
					<H6 className='m-t-0 m-b-0'>Total: {userList.length} Users</H6>
				</MigrateDetails>
				<MigrateEmails className='m-b-5'>
					{userList.map(email => {
						return <H6 className='m-t-0 m-b-0'>{email}</H6>
					})}
				</MigrateEmails>
			</MigrateDetailsWrap>

			<ItemContainer className='row'>
				<H6 textAlign='left' className='m-t-0 m-b-0 col-12 col-md-3'>
					From
				</H6>
				<Input
					readOnly
					value={code}
					onChange={item => setMigrateData({...migrateData, from: item})}
					menuStyle={{position: "absolute", zIndex: 2}}
				/>
			</ItemContainer>
			<ItemContainer className='row'>
				<FormElementLabel isRequired textAlign='left' className='m-t-0 m-b-0 col-12 col-md-3'>
					To
				</FormElementLabel>
				<Controller
					name='migrateTo'
					rules={{required: true}}
					control={control}
					render={props => (
						<AutoComplete
							items={codeList}
							onChange={item => {
								onCodeSelection(item)
							}}
							menuStyle={{position: "absolute", zIndex: 2}}
							inputStyle={{padding: "4px 22px 4px 0", maxWidth: "100%"}}
							// containerStyle={isInvalid ? {border: "1px solid #ff9999"} : null}
						/>
					)}
				/>
			</ItemContainer>
		</Fragment>
	)
}

const MigrateUser = ({openModal, setOpenModal, selectedUsers, setSelectedUsers, fetchData}) => {
	const {getAccessTokenSilently, isLoading} = useAuth0()
	const [apiData, setApiData] = useState([])
	const {migrationCodes, userMigrationApiState, createUserMigrationApiState} = useSelector(userDataSelector)
	const dispatch = useDispatch()

	const fetchCodes = async () => {
		const token = await getAccessTokenSilently()
		dispatch(fetchAvailableMigrationCodes({token, limit: 999999999, skip: 0, search: ""}))
	}

	useEffect(() => {
		if (openModal) {
			fetchCodes()
		}
	}, [openModal])

	const codeList = migrationCodes && migrationCodes.map(data => data.code)

	const onSelect = (data, code) => {
		setApiData(prevData => {
			const oldIndex = prevData.findIndex(d => d.oldCode === code)
			if (oldIndex === -1) {
				return prevData.concat(data)
			}
			const prevDataCopy = [...prevData]
			prevDataCopy[oldIndex] = data
			return prevDataCopy
		})
	}

	return (
		<Modal
			isOpen={openModal}
			handleClose={() => setOpenModal(false)}
			padding={"24px"}
			backgroundColor={"#ffffff"}
			width='25%'
			height='auto'
			scroll={true}
			textAlign='center'
			style={{overflowY: "auto", maxWidth: "100%"}}>
			<H4>Migrate Users</H4>
			<Container>
				<form
					onSubmit={async event => {
						event.preventDefault()
						const token = await getAccessTokenSilently()
						dispatch(createUserMigration({data: apiData, token}))
						setApiData([])
						setSelectedUsers({})
						fetchData({pageSize: 20, pageIndex: 0})
						setOpenModal(false)
					}}>
					<ModalButtonContainer>
						{Object.entries(selectedUsers).map((singleCode, index) => {
							const [code, users] = singleCode
							return (
								<MigrateUserSection
									key={code}
									onSelect={selectedData => onSelect(selectedData, code, index)}
									code={code}
									totalUsers={users.length}
									codeList={codeList}
									allCodes={migrationCodes}
									selectedUsers={selectedUsers}
								/>
							)
						})}
						<Row className='m-t-10'>
							<ButtonContainer>
								<Button
									onClick={e => {
										e.preventDefault()
										setOpenModal(false)
									}}
									size='small'
									paddingLeft={spacing.s6}
									paddingRight={spacing.s6}
									variant={"secondary"}
									title={"Cancel"}
								/>

								<Button
									className='m-l-5'
									type='submit'
									variant={"primary"}
									size='small'
									paddingLeft={spacing.s6}
									paddingRight={spacing.s6}
									title='Migrate'
									isLoading={createUserMigrationApiState.isFetching}
								/>
							</ButtonContainer>
						</Row>
					</ModalButtonContainer>
				</form>
			</Container>
		</Modal>
	)
}

export default MigrateUser
