const constraints = {
	timezone: {
		presence: {
			message: "^Please select a timezone",
			allowEmpty: false
		}
	},
	date: {
		presence: {
			message: "^Please select a date/time",
			allowEmpty: false
		}
	},
	coach: {
		presence: {
			message: "^Please select a coach",
			allowEmpty: false
		}
	},
	type: {
		presence: {
			message: "^Please select a type",
			allowEmpty: false
		}
	},
	duration: {
		presence: {
			message: "^Please select a duration",
			allowEmpty: false
		}
	},
	email: {
		presence: {
			message: "^Please enter an email address"
		},
		email: {
			message: "^Please enter a valid email address",
			allowEmpty: false
		}
	}
}

export default constraints
