import React, {Fragment} from "react"
import {useSelect} from "downshift"
import {bool, func, object, string, array, element} from "prop-types"
import Styled from "styled-components"

const StylesWrapper = Styled.div`
	position: relative;
	width: ${props => props.width};
`

const TriggerButton = Styled.button`
	display: flex;
	justify-content: ${props => props.alignText};
	width: 100%;
	padding: 6px 0 6px 0;
	border-radius: 10px;
	text-align: left;
	outline: none;
	border: 0;
	background-color: transparent;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: #2f2d40;
	position: relative;
`

const ArrowIcon = Styled.span`
	border: solid #7d60ff;
	border-width: 0 1px 1px 0;
	padding: 3px;
	display: inline-block;
	transform: ${props => (props.direction === 1 ? "rotate(225deg)" : "rotate(45deg)")};
	transition: transform 0.1s linear;
	margin-left: 24px;
	top: ${props => (props.direction === 1 ? "8px" : "6px")};
    	position: relative;

	&:hover {
		cursor: pointer;
	}
`

const TableSortOrder = ({label, onClick, direction, alignText, width}) => {
	return (
		<StylesWrapper width={width}>
			<TriggerButton onClick={() => onClick()} alignText={alignText}>
				{label}
				<ArrowIcon direction={direction} />
			</TriggerButton>
		</StylesWrapper>
	)
}

TableSortOrder.propTypes = {
	onChange: func.isRequired,
	label: string.isRequired,
	containerStyle: object
}

TableSortOrder.defaultProps = {
	label: "",
	onChange: () => {},
	containerStyle: {},
	direction: 1,
	alignText: "flex-start"
}

export default TableSortOrder
