import React, {useEffect, useState, Fragment} from "react"
import moment from "moment-timezone"
import {object, func, string, array} from "prop-types"

// UI
import {Container, ContainerFluid, Row} from "@uprise/grid"
import {P, Bold} from "@uprise/text"
import {H5, H6} from "@uprise/headings"
import LogoHeadIcon from "@uprise/icons/icons/png/logo-head/logo-head.png"
// Styles
import {Table, TableRow, TableBody, TableData, SlotsRow} from "./index.style"
import {ProfileImageWeek} from "./index.style"
// Components
import BookingSlotsBlock from "../BookingSlotsBlock"

const SlotsWeekView = ({coachAvailability, timeZone, coach, setSelectedCoach, setTime, coachSelected}) => {
	const [slotsByDay, setSlotsByDay] = useState({})

	useEffect(() => {
		let weekList = {}
		coachAvailability?.data?.map(slot => {
			switch (moment(slot.start).tz(timeZone).weekday()) {
				case 1:
					if (weekList["Monday"]) weekList["Monday"] = [...weekList["Monday"], slot]
					else weekList["Monday"] = [slot]
					break
				case 2:
					if (weekList["Tuesday"]) weekList["Tuesday"] = [...weekList?.["Tuesday"], slot]
					else weekList["Tuesday"] = [slot]
					break
				case 3:
					if (weekList["Wednesday"]) weekList["Wednesday"] = [...weekList?.["Wednesday"], slot]
					else weekList["Wednesday"] = [slot]
					break
				case 4:
					if (weekList["Thursday"]) weekList["Thursday"] = [...weekList?.["Thursday"], slot]
					else weekList["Thursday"] = [slot]
					break
				case 5:
					if (weekList["Friday"]) weekList["Friday"] = [...weekList?.["Friday"], slot]
					else weekList["Friday"] = [slot]
					break
				case 6:
					if (weekList["Saturday"]) weekList["Saturday"] = [...weekList?.["Saturday"], slot]
					else weekList["Saturday"] = [slot]
					break
				case 0:
					console.log("SUNDAY", moment(slot.start))
					console.log("SUNDAY", moment(slot.start).format("DD/MM/YYYY"))

					if (weekList["Sunday"]) weekList["Sunday"] = [...weekList?.["Sunday"], slot]
					else weekList["Sunday"] = [slot]
					break
				default:
			}
		})
		setSlotsByDay(weekList)
	}, [coachAvailability])

	return (
		<ContainerFluid className='mb-5'>
			{coachAvailability.data.length ? (
				<Table>
					<TableBody>
						{Object.keys(slotsByDay)?.map((day, index) => {
							return (
								<TableRow key={index}>
									<SlotsRow>
										<TableData className='m-l-1'>
											<Row className=' ' style={{padding: "12px"}}>
												<ProfileImageWeek
													src={coach?.data?.photos?.imageURL || LogoHeadIcon}
													alt='user-icon'
												/>
											</Row>
											<H5 className='m-b-0 m-t-0'>{slotsByDay[`${day}`].length} slots</H5>
											<H6 className='m-t-0 m-b-0' lineHeight='1.5' w>
												{day}
											</H6>
											<H6 className='m-b-10 m-t-0'>
												{moment(slotsByDay?.[day]?.[0].start).format("Do")}
											</H6>
										</TableData>
										<BookingSlotsBlock
											data={slotsByDay[`${day}`]}
											timeZone={timeZone}
											coach={coach}
											setTime={setTime}
											setSelectedCoach={setSelectedCoach}
										/>
									</SlotsRow>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			) : (
				<P>No Slots</P>
			)}
		</ContainerFluid>
	)
}

SlotsWeekView.propTypes = {
	coachAvailability: object.isRequired,
	timeZone: string.isRequired,
	// coachesData: array.isRequired,
	setSelectedCoach: func.isRequired,
	setTime: func.isRequired
}

SlotsWeekView.defaultProps = {}

export default SlotsWeekView
