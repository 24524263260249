import React, {Fragment} from "react"
import moment from "moment-timezone"
import {P} from "@uprise/text"
import {text} from "@uprise/typography"
import {SlotTextContainer, SlotText, SlotsContainer} from "./index.style"

const BoookingSlotsBlock = ({data, setSelectedCoach, setTime, timeZone, coach}) => {
	const day = data.filter(slot => {
		const time1 = moment(slot.start).startOf("day").add(9, "hours")
		const time2 = moment(slot.start).startOf("day").add(17, "hours")

		if (moment(slot.start).isBetween(time1, time2, "undefined", "[)")) {
			return slot
		}
	})

	const evening = data.filter(slot => {
		const time1 = moment(slot.start).startOf("day").add(17, "hours")
		const time2 = moment(slot.start).startOf("day").add(24, "hours")

		if (moment(slot.start).isBetween(time1, time2, "undefined", "[)")) {
			return slot
		}
	})

	const morning = data.filter(slot => {
		const time1 = moment(slot.start).startOf("day").add(0, "hours")
		const time2 = moment(slot.start).startOf("day").add(9, "hours")

		if (moment(slot.start).isBetween(time1, time2, "undefined", "[)")) {
			return slot
		}
	})

	return (
		<>
			{morning.length ? (
				<SlotsContainer>
					{morning?.map((slot, index) => (
						<Fragment key={index}>
							<SlotTextContainer
								data-testid={moment(slot.start).tz(timeZone).format("DD-MM-YYYY-HH:mm")}
								onClick={() => {
									setSelectedCoach(coach)
									setTime(slot)
								}}>
								<SlotText>{moment(slot.start).format("hh:mm A")}</SlotText>
							</SlotTextContainer>
						</Fragment>
					))}
				</SlotsContainer>
			) : (
				<SlotsContainer>
					<P className='m-t-2' fontSize={text.t4}>
						No Slots
					</P>
				</SlotsContainer>
			)}

			{day.length ? (
				<SlotsContainer>
					{day?.map((slot, index) => (
						<Fragment key={index}>
							<SlotTextContainer
								data-testid={moment(slot.start).tz(timeZone).format("DD-MM-YYYY-HH:mm")}
								onClick={() => {
									setSelectedCoach(coach)
									setTime(slot)
								}}>
								<SlotText>{moment(slot.start).format("hh:mm A")}</SlotText>
							</SlotTextContainer>
						</Fragment>
					))}
				</SlotsContainer>
			) : (
				<SlotsContainer>
					<P className='m-t-2' fontSize={text.t4}>
						No Slots
					</P>
				</SlotsContainer>
			)}

			{evening.length ? (
				<SlotsContainer>
					{evening?.map((slot, index) => (
						<Fragment key={index}>
							<SlotTextContainer
								data-testid={moment(slot.start).tz(timeZone).format("DD-MM-YYYY-HH:mm")}
								onClick={() => {
									setSelectedCoach(coach)
									setTime(slot)
								}}>
								<SlotText>{moment(slot.start).format("hh:mm A")}</SlotText>
							</SlotTextContainer>
						</Fragment>
					))}
				</SlotsContainer>
			) : (
				<SlotsContainer>
					<P className='m-t-2' fontSize={text.t4}>
						No Slots
					</P>
				</SlotsContainer>
			)}
		</>
	)
}

export default BoookingSlotsBlock
