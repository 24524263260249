import React, {Fragment, useState, useEffect} from "react"
import moment from "moment-timezone"
import {useAuth0} from "@auth0/auth0-react"
import {Controller, useForm} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {useHistory, useParams} from "react-router"

// UI
import {Alert} from "@uprise/alert"
import {Button} from "@uprise/button"
import {Col, ContainerFluid, Row} from "@uprise/grid"
// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import Preloader from "components/shared/Preloader/Preloader"
import DropDown from "components/shared/DropDown"
import ToggleModal from "components/shared/Modal/ToggleModal"
import TagsInputWithAutoComplete from "components/shared/TagsWithAutoComplete/TagsWithAutoComplete"
import Toggle from "components/shared/Toggle"
import UploadImageModal from "components/shared/UploadImageModal"

// Slices
import {fetchCoachById, updateCoach, coachDataSelector} from "../coachesSlice"
import {
	languages,
	qualifications,
	designation,
	age,
	experience,
	gender,
	specializationParentLevel,
	specializationChildLevel
} from "../NewCoaches/constant"
// Styles
import {
	FormElementContainer,
	FormElementLabel,
	FormInputContainer,
	FormTextInput,
	StyledImg,
	SubmitButtonContainer,
	MessageStyles
} from "../../../shared/Form/index.style"
import {HeaderTableContent} from "../../RiskCallBacks/index.style"
import {TableContainer} from "./../../Employers/AllEmployers/index.style"

// Components
import Wysiwyg from "components/shared/Wysiwyg/index"
import AvailabilityTable from "components/shared/AvailabilityTable"
import PhoneNumberInput from "components/shared/PhoneNumberInput/index"
import SelectTags from "components/shared/SelectTags"

const EditCoach = () => {
	const params = useParams()
	const history = useHistory()
	const dispatch = useDispatch()
	const [token, setToken] = useState()

	const {getAccessTokenSilently} = useAuth0()

	const {coachUpdateApiState, fetchCoachByIdApiState} = useSelector(coachDataSelector)

	React.useEffect(() => {
		const _fetchCoach = async () => {
			const token = await getAccessTokenSilently()
			const id = params.id

			/* 
			make a fetch api only when there is no data. this case happens when user refreshes the browser. otherwise
			we don't need to make an api call here.
			*/
			if (fetchCoachByIdApiState?.coach?.length === 0) {
				dispatch(fetchCoachById({token, id}))
			}
		}
		_fetchCoach()
	}, [dispatch, getAccessTokenSilently, params])

	const {reset, getValues, control, handleSubmit, errors} = useForm({
		defaultValues: fetchCoachByIdApiState.coach,
		reValidateMode: "onChange"
	})

	useEffect(() => {
		console.log(fetchCoachByIdApiState)
		console.log("get", getValues())
	}, [fetchCoachByIdApiState])

	useEffect(() => {
		;(async () => {
			const token = await getAccessTokenSilently()
			setToken(token)
		})()
	})

	React.useEffect(() => {
		reset(fetchCoachByIdApiState.coach.data)
	}, [reset, fetchCoachByIdApiState])

	useEffect(() => {
		/**
		 * Here, we scroll to the error input to focus.
		 */
		if (errors) {
			const errorsvalues = Object.values(errors)

			if (errorsvalues.length > 0) {
				let firstErrorElement = document.getElementsByName(errorsvalues[0].ref.name)[0]
				firstErrorElement?.scrollIntoView({behavior: `smooth`, block: "center"})
			}
		}
	}, [errors])

	const _submitClick = handleSubmit(data => {
		data.id = Number(params.id)

		data.activeOn = data.activeOn
		data.address = data.address
		data.availabilityHours = data.availabilityHours
		data.calendarId = data.calendarId
		data.degree = typeof data.degree === "string" ? [data.degree] : data.degree
		data.disabled = data.disabled
		data.email = data.email
		data.qualification = data.qualification
		data.designation = data.designation
		data.age = data.age
		data.gender = data.gender
		data.experience = data.experience
		data.extraCalendars = data.extraCalendars
		data.f2fSessions = data.f2fSessions
		data.intro = data.intro
		data.languages = data.languages
		data.license = data.license
		data.location = data.location
		data.name = data.name
		data.phoneNumber = data.phoneNumber
		data.bookingsPerWeek = Number(data.bookingsPerWeek)
		data.specialization = data.specialization
		data.interest = data.interest

		if (data?.photos?.base64PhotoURI) {
			data.base64PhotoURI = data.photos.base64PhotoURI
		}

		data.profession = data.profession
		data.timezone = data.timezone
		data.hours = data.hours

		dispatch(updateCoach({token, history, data}))
	})

	return (
		<ComponentWrapper
			menuActiveIndex={7}
			subMenuActiveIndex={12}
			headerTitle='Edit Coach'
			headerRightAlignContent={
				<HeaderTableContent>
					<Button
						onClick={() => history.push(`/coaches/${params.id}`)}
						size='small'
						title={"Cancel"}
						variant='tertiary'
						style={{margin: "10px", padding: "20px 24px"}}
					/>
					<Button
						onClick={_submitClick}
						size='small'
						title={"Save"}
						style={{margin: "10px", padding: "20px 24px"}}
						variant='primary'
						type='submit'
						isLoading={coachUpdateApiState.isFetching}
					/>
				</HeaderTableContent>
			}>
			{coachUpdateApiState.isError && (
				<Alert className='m-b-10' type={"error"}>
					{coachUpdateApiState.message}
				</Alert>
			)}

			{coachUpdateApiState.isSuccess && (
				<Alert className='m-b-10' type={"success"}>
					{coachUpdateApiState.message}
				</Alert>
			)}

			{fetchCoachByIdApiState.isError && (
				<Alert className='m-b-10' type={"error"}>
					{fetchCoachByIdApiState.message}
				</Alert>
			)}

			<TableContainer>
				{fetchCoachByIdApiState.isFetching && !fetchCoachByIdApiState.isSuccess && <Preloader />}
				{fetchCoachByIdApiState.isSuccess && (
					<form onSubmit={_submitClick}>
						<ContainerFluid style={{padding: "40px"}}>
							<Row>
								<Col className='col-2 col-lg-2 col-md-2 col-sm-12'>
									<Controller
										name='photos'
										control={control}
										defaultValue={""}
										render={props => {
											return (
												<Fragment>
													<StyledImg src={props.value.imageURL || props.value.tempImg} />
													<ToggleModal
														toggle={show => (
															<Button
																onClick={show}
																size='small'
																title={"Upload photo"}
																variant='tertiary'
																type='button'
																fullWidth={false}
																style={{width: "137px", margin: "0 auto"}}
															/>
														)}
														content={hide => (
															<UploadImageModal
																hide={hide}
																src={props.value.imageURL || props.value.tempImg}
																onChange={({tempImg, base64PhotoURI}) => {
																	props.onChange({tempImg, base64PhotoURI})
																}}
															/>
														)}
													/>
												</Fragment>
											)
										}}
									/>
								</Col>
								<Col className='col-10 col-lg-10 col-md-10 col-sm-12'>
									<FormElementContainer error={errors?.name}>
										<FormElementLabel isRequired={true}>Coach Name</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"name"}
												control={control}
												defaultValue={fetchCoachByIdApiState?.coach?.name}
												rules={{
													required: {
														value: true,
														message: "Coach name is required"
													},
													maxLength: {
														value: 100,
														message: "Name can be a maximum of 100 characters"
													}
												}}
												render={props => (
													<FormTextInput
														type='text'
														placeholder='name'
														id='name'
														{...props}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.name?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.name}>
											{errors?.name?.message}
										</MessageStyles>
									)}

									<FormElementContainer>
										<FormElementLabel>Coach ID</FormElementLabel>
										<FormInputContainer>{fetchCoachByIdApiState.coach.id}</FormInputContainer>
									</FormElementContainer>

									<FormElementContainer error={errors?.location}>
										<FormElementLabel>Location</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"location"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Location is required"
													},
													maxLength: {
														value: 100,
														message: "Location can be a maximum of 100 characters"
													}
												}}
												defaultValue={""}
												as={<FormTextInput type='text' placeholder='eg. Sydney, Australia' />}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.location?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.location}>
											{errors?.location?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.timezone}>
										<FormElementLabel>Timezone</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"timezone"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Timezone is required"
													},
													maxLength: {
														value: 100,
														message: "Time Zone can be a maximum of 100 characters"
													}
												}}
												defaultValue={""}
												render={props => (
													<DropDown
														name={props.name}
														isFirst={true}
														variant='primary'
														buttonWidth='100%'
														initialSelectedItem={moment.tz
															.names()
															.map(x => ({label: x, value: x}))
															.find(item =>
																item.value.includes(
																	fetchCoachByIdApiState.coach.data.timezone
																)
															)}
														items={moment.tz.names().map(x => ({label: x, value: x}))}
														label='Select'
														onChange={item => props.onChange(item.value)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.timezone?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.timezone}>
											{errors?.timezone?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.email}>
										<FormElementLabel isRequired={true}>Email Address</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"email"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Email Address is required"
													},
													maxLength: {
														value: 100,
														message: "Email can be a maximum of 100 characters"
													},
													pattern: {
														value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
														message: "Email address must be a valid email"
													},
													validate: {
														value: value => {
															let strings = value
															let invalid = false

															for (let i = 0, l = strings.length; i < l; i++) {
																let character = strings.charAt(i)
																if (
																	!isNaN(character * 1) ||
																	character === character.toLowerCase()
																) {
																	continue
																} else {
																	if (character === character.toUpperCase()) {
																		invalid = true
																	}
																}
															}

															return invalid ? "Email address must be lowercase" : true
														}
													}
												}}
												defaultValue={fetchCoachByIdApiState.coach.email}
												as={<FormTextInput type='email' placeholder='eg. john.doe@xyz.com' />}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors?.email?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.email}>
											{errors?.email?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.phoneNumber}>
										<FormElementLabel>Phone Number</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"phoneNumber"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Phone Number is required"
													},
													maxLength: {
														value: 100,
														message: "Phone Number can be a maximum of 100 characters"
													}
												}}
												defaultValue={""}
												render={props => (
													<PhoneNumberInput
														name={props.name}
														value={props.value}
														onChange={val => props.onChange(val)}
														placeholder='eg. +61 2 9876 5432'
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors?.phoneNumber?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.phoneNumber}>
											{errors?.phoneNumber?.message}
										</MessageStyles>
									)}

									<FormElementContainer>
										<FormElementLabel>Status</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"disabled"}
												control={control}
												defaultValue={fetchCoachByIdApiState.coach.disabled}
												render={props => (
													<div style={{width: "50px"}}>
														<Toggle
															checked={!props.value}
															onChange={e => props.onChange(!props.value)}
															backgroundColorChecked={"#7d60ff"}
															width='42'
															height='24'
															sliderHeight='20.8'
															sliderWidth='20.8'
															translate='18'
														/>
													</div>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									<FormElementContainer error={errors?.degree}>
										<FormElementLabel>Degree</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"degree"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Degree is required"
													}
												}}
												defaultValue={[]}
												render={props => (
													<TagsInputWithAutoComplete
														allSuggestions={[]}
														initialTags={
															typeof props.value === "string"
																? [props.value]
																: props.value
														}
														placeholder='eg. Bachelor of Psychology (BA), Masters of Counselling Psychology (MEd.)'
														onChange={tags => props.onChange(tags)}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors?.degree?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.degree}>
											{errors?.degree?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.qualification}>
										<FormElementLabel>Highest Professional Qualification</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"qualification"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Qualifications is required"
													}
												}}
												defaultValue={fetchCoachByIdApiState?.coach?.data?.qualification}
												render={props => (
													<DropDown
														isFirst={true}
														name={props.name}
														variant='primary'
														buttonWidth='100%'
														items={qualifications}
														label='Qualification'
														onChange={item => props.onChange(item.value)}
														initialSelectedItem={qualifications.find(item =>
															item.value.includes(
																fetchCoachByIdApiState.coach.data.qualification
															)
														)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.qualification?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.qualification}>
											{errors?.qualification?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.designation}>
										<FormElementLabel>Designation</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"designation"}
												control={control}
												rules={{required: {value: true, message: "Designation is required"}}}
												render={props => (
													<DropDown
														isFirst={true}
														variant='primary'
														buttonWidth='100%'
														items={designation}
														name={props.name}
														label='Designation'
														onChange={item => props.onChange(item.value)}
														initialSelectedItem={designation.find(item =>
															item.value.includes(
																fetchCoachByIdApiState.coach.data.designation
															)
														)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.designation?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.designation}>
											{errors?.designation?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.age}>
										<FormElementLabel>Age</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"age"}
												rules={{required: {value: true, message: "Age is required"}}}
												control={control}
												render={props => (
													<DropDown
														isFirst={true}
														variant='primary'
														buttonWidth='100%'
														items={age}
														name={props.name}
														label='Age'
														onChange={item => props.onChange(item.value)}
														initialSelectedItem={age.find(
															item => item.value === fetchCoachByIdApiState.coach.data.age
														)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.age?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.age}>
											{errors?.age?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.experience}>
										<FormElementLabel>Years of Experience</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"experience"}
												control={control}
												rules={{required: {value: true, message: "Experience is required"}}}
												render={props => (
													<DropDown
														isFirst={true}
														variant='primary'
														buttonWidth='100%'
														items={experience}
														name={props.name}
														label='Years of Experience'
														onChange={item => props.onChange(item.value)}
														initialSelectedItem={experience.find(
															item =>
																item.value ===
																fetchCoachByIdApiState.coach.data.experience
														)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.experience?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.experience}>
											{errors?.experience?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.gender}>
										<FormElementLabel>Gender</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"gender"}
												rules={{required: {value: true, message: "Gender is required"}}}
												control={control}
												render={props => (
													<DropDown
														isFirst={true}
														variant='primary'
														buttonWidth='100%'
														items={gender}
														name={props.name}
														label='Select Gender'
														onChange={item => props.onChange(item.value)}
														initialSelectedItem={gender.find(
															item =>
																item.value === fetchCoachByIdApiState.coach.data.gender
														)}
														menuStyle={{position: "absolute", zIndex: 2}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.gender?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.gender}>
											{errors?.gender?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.license}>
										<FormElementLabel>License</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"license"}
												control={control}
												defaultValue={""}
												rules={{
													required: {
														value: true,
														message: "License is required"
													},
													maxLength: {
														value: 100,
														message: "License can be a maximum of 100 characters"
													}
												}}
												as={
													<FormTextInput
														type='text'
														placeholder='eg. British Columbia Association of Clinical Counsellors (BCACC)'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.license?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors["license"]}>
											{errors?.license?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.interest}>
										<FormElementLabel>Area of Interest</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"interest"}
												rules={{required: {value: true, message: "Interest is required"}}}
												control={control}
												defaultValue={""}
												render={props => (
													<SelectTags
														allSuggestions={[]}
														name={props.name}
														initialTags={
															typeof props.value === "string"
																? [props.value]
																: props.value
														}
														leftNavItems={specializationParentLevel}
														rightNavItems={specializationChildLevel}
														placeholder='Select Area of Interest'
														onChange={items => {
															return props.onChange(items)
														}}
														maxSelectedItemCount={null}
														onValidationError={message => {}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.interest?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.interest}>
											{errors?.interest?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.specialization}>
										<FormElementLabel>Specialization</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"specialization"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Specialization is required"
													}
												}}
												defaultValue={""}
												render={props => (
													<SelectTags
														allSuggestions={[]}
														name={props.name}
														initialTags={
															typeof props.value === "string"
																? [props.value]
																: props.value
														}
														leftNavItems={specializationParentLevel}
														rightNavItems={specializationChildLevel}
														placeholder='Select Specialization'
														onChange={items => {
															return props.onChange(items)
														}}
														maxSelectedItemCount={3}
														onValidationError={message => {}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.specialization?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.specialization}>
											{errors?.specialization?.message}
										</MessageStyles>
									)}

									<FormElementContainer>
										<FormElementLabel>Language(s)</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"languages"}
												control={control}
												defaultValue={[]}
												// as={<FormTextInput type='text' placeholder='English' />}
												render={props => (
													<TagsInputWithAutoComplete
														name={props.name}
														allSuggestions={languages}
														initialTags={props.value || []}
														placeholder='Select a language'
														onChange={tags => props.onChange(tags)}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									<FormElementContainer>
										<FormElementLabel>F2F Sessions</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"f2fSessions"}
												control={control}
												defaultValue={fetchCoachByIdApiState.coach?.data?.f2fSessions}
												render={props => (
													<div style={{width: "50px"}}>
														<Toggle
															checked={props.value}
															onChange={value => {
																props.onChange(value)
															}}
															backgroundColorChecked={"#7d60ff"}
															width='42'
															height='24'
															sliderHeight='20.8'
															sliderWidth='20.8'
															translate='18'
														/>
													</div>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									<FormElementContainer>
										<FormElementLabel>Practice Address</FormElementLabel>
										<div style={{width: "80%"}}>
											<FormElementContainer>
												<FormElementLabel>Address Line 1</FormElementLabel>
												<FormInputContainer>
													<Controller
														name='address.line1'
														control={control}
														as={<FormTextInput type='text' placeholder='Address line 1' />}
													/>
												</FormInputContainer>
											</FormElementContainer>
											<FormElementContainer>
												<FormElementLabel>Address Line 2</FormElementLabel>
												<FormInputContainer>
													<Controller
														name='address.line2'
														control={control}
														as={<FormTextInput type='text' placeholder='Address line 2' />}
													/>
												</FormInputContainer>
											</FormElementContainer>
											<FormElementContainer>
												<FormElementLabel>State</FormElementLabel>
												<FormInputContainer>
													<Controller
														name='address.state'
														control={control}
														as={<FormTextInput type='text' placeholder='State' />}
													/>
												</FormInputContainer>
											</FormElementContainer>
											<FormElementContainer>
												<FormElementLabel>Country</FormElementLabel>
												<FormInputContainer>
													<Controller
														name='address.country'
														control={control}
														as={<FormTextInput type='text' placeholder='Country' />}
													/>
												</FormInputContainer>
											</FormElementContainer>
											<FormElementContainer>
												<FormElementLabel>Postcode</FormElementLabel>
												<FormInputContainer>
													<Controller
														name='address.postcode'
														control={control}
														as={<FormTextInput type='text' placeholder='01234' />}
													/>
												</FormInputContainer>
											</FormElementContainer>
										</div>
									</FormElementContainer>

									<FormElementContainer error={errors?.calendarId}>
										<FormElementLabel isRequired={true}>Main Calendar ID</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"calendarId"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Main Calendar ID is required"
													},
													maxLength: {
														value: 100,
														message: "Email can be a maximum of 100 characters"
													},
													pattern: {
														value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i,
														message: "Email address must be a valid email"
													},
													validate: {
														value: value => {
															let strings = value
															let invalid = false

															for (let i = 0, l = strings.length; i < l; i++) {
																let character = strings.charAt(i)
																if (
																	!isNaN(character * 1) ||
																	character === character.toLowerCase()
																) {
																	continue
																} else {
																	if (character === character.toUpperCase()) {
																		invalid = true
																	}
																}
															}

															return invalid ? "Main Calendar ID must be lowercase" : true
														}
													}
												}}
												defaultValue={""}
												as={<FormTextInput type='email' placeholder='eg. john.doe@xyz.com' />}
											/>
										</FormInputContainer>
									</FormElementContainer>

									{errors?.calendarId?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.calendarId}>
											{errors?.calendarId?.message}
										</MessageStyles>
									)}

									<FormElementContainer>
										<FormElementLabel>Extra Calendar ID</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"extraCalendars"}
												control={control}
												defaultValue={[]}
												// as={<FormTextInput type='text' placeholder='English' />}
												render={props => (
													<TagsInputWithAutoComplete
														allSuggestions={[]}
														initialTags={props.value || []}
														placeholder='Type extra calendar ID'
														onChange={tags => props.onChange(tags)}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>

									<FormElementContainer>
										<FormElementLabel>Active On</FormElementLabel>
										<div style={{width: "80%"}}>
											<FormElementContainer>
												<FormElementLabel>Corporate version</FormElementLabel>
												<FormInputContainer>
													<Controller
														name={"activeOn.corporate"}
														control={control}
														defaultValue={
															fetchCoachByIdApiState.coach.data?.activeOn?.corporate
														}
														render={props => (
															<div style={{width: "50px"}}>
																<Toggle
																	checked={props.value}
																	onChange={value => {
																		props.onChange(value)
																	}}
																	backgroundColorChecked={"#7d60ff"}
																	width='42'
																	height='24'
																	sliderHeight='20.8'
																	sliderWidth='20.8'
																	translate='18'
																/>
															</div>
														)}
													/>
												</FormInputContainer>
											</FormElementContainer>

											<FormElementContainer>
												<FormElementLabel>RTW version</FormElementLabel>
												<FormInputContainer>
													<Controller
														name={"activeOn.rtw"}
														control={control}
														defaultValue={fetchCoachByIdApiState.coach.data?.activeOn?.rtw}
														render={props => (
															<div style={{width: "50px"}}>
																<Toggle
																	checked={props.value}
																	onChange={value => {
																		props.onChange(value)
																	}}
																	backgroundColorChecked={"#7d60ff"}
																	width='42'
																	height='24'
																	sliderHeight='20.8'
																	sliderWidth='20.8'
																	translate='18'
																/>
															</div>
														)}
													/>
												</FormInputContainer>
											</FormElementContainer>

											<FormElementContainer>
												<FormElementLabel>Risk callback</FormElementLabel>
												<FormInputContainer>
													<Controller
														name={"activeOn.riskcallback"}
														control={control}
														defaultValue={
															fetchCoachByIdApiState.coach.data?.activeOn?.riskcallback
														}
														render={props => (
															<div style={{width: "50px"}}>
																<Toggle
																	checked={props.value}
																	onChange={value => {
																		props.onChange(value)
																	}}
																	backgroundColorChecked={"#7d60ff"}
																	width='42'
																	height='24'
																	sliderHeight='20.8'
																	sliderWidth='20.8'
																	translate='18'
																/>
															</div>
														)}
													/>
												</FormInputContainer>
											</FormElementContainer>

											<FormElementContainer>
												<FormElementLabel>AUS</FormElementLabel>
												<FormInputContainer>
													<Controller
														name={"activeOn.aus"}
														control={control}
														defaultValue={fetchCoachByIdApiState.coach.data?.activeOn?.aus}
														render={props => (
															<div style={{width: "50px"}}>
																<Toggle
																	checked={props.value}
																	onChange={value => {
																		props.onChange(value)
																	}}
																	backgroundColorChecked={"#7d60ff"}
																	width='42'
																	height='24'
																	sliderHeight='20.8'
																	sliderWidth='20.8'
																	translate='18'
																/>
															</div>
														)}
													/>
												</FormInputContainer>
											</FormElementContainer>

											<FormElementContainer>
												<FormElementLabel>US</FormElementLabel>
												<FormInputContainer>
													<Controller
														name={"activeOn.us"}
														control={control}
														defaultValue={fetchCoachByIdApiState.coach.data?.activeOn?.us}
														render={props => (
															<div style={{width: "50px"}}>
																<Toggle
																	checked={props.value}
																	value={props.value}
																	onChange={value => {
																		props.onChange(value)
																	}}
																	backgroundColorChecked={"#7d60ff"}
																	width='42'
																	height='24'
																	sliderHeight='20.8'
																	sliderWidth='20.8'
																	translate='18'
																/>
															</div>
														)}
													/>
												</FormInputContainer>
											</FormElementContainer>

											<FormElementContainer>
												<FormElementLabel>Students</FormElementLabel>
												<FormInputContainer>
													<Controller
														name={"activeOn.students"}
														control={control}
														defaultValue={
															fetchCoachByIdApiState.coach.data?.activeOn?.students
														}
														render={props => (
															<div style={{width: "50px"}}>
																<Toggle
																	checked={props.value}
																	value={props.value}
																	onChange={value => {
																		props.onChange(value)
																	}}
																	backgroundColorChecked={"#7d60ff"}
																	width='42'
																	height='24'
																	sliderHeight='20.8'
																	sliderWidth='20.8'
																	translate='18'
																/>
															</div>
														)}
													/>
												</FormInputContainer>
											</FormElementContainer>
										</div>
									</FormElementContainer>

									<FormElementContainer error={errors?.bookingsPerWeek}>
										<FormElementLabel>Bookings Per Week</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"bookingsPerWeek"}
												control={control}
												defaultValue={""}
												rules={{
													required: {
														value: true,
														message: "Number of Bookings Per Week is Required"
													},
													min: {
														value: 1,
														message: "Minimum Value is Required"
													},
													max: {
														value: 1000,
														message: "Value Exceeds Maximum Bookings Count"
													}
												}}
												as={
													<FormTextInput
														type='number'
														placeholder='Number of Bookings Per week'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors?.bookingsPerWeek?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.bookingsPerWeek}>
											{errors?.bookingsPerWeek?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.availabilityHours}>
										<FormElementLabel>Standard Availability Hours</FormElementLabel>
										<FormInputContainer isRequired={true}>
											<Controller
												name={"availabilityHours"}
												control={control}
												defaultValue={""}
												rules={{
													required: {
														value: true,
														message: "Standard Availability Hours is required"
													},
													maxLength: {
														value: 100,
														message:
															"Standard Availability Hours can be a maximum of 100 characters"
													}
												}}
												as={
													<FormTextInput
														type='text'
														placeholder='eg. Monday 9:00 - 1:00 pm AEST and Thursday 7:00 - 10:00 am & 2:00 pm - 5:00 pm AEST'
													/>
												}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors?.availabilityHours?.message && (
										<MessageStyles
											htmlFor={1}
											focused={false}
											validation={errors?.availabilityHours}>
											{errors?.availabilityHours?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.intro}>
										<FormElementLabel isRequired={false}>Introduction</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"intro.english"}
												control={control}
												rules={{
													required: {
														value: false,
														message: "Introduction is required"
													}
												}}
												defaultValue={""}
												render={props => (
													<Wysiwyg
														initialValue={props.value}
														placeholder='eg. Introduce yourself here'
														onChange={val => props.onChange(val)}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors?.intro?.english?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.intro.english}>
											{errors?.intro?.english?.message}
										</MessageStyles>
									)}

									<FormElementContainer error={errors?.hours}>
										<FormElementLabel isRequired={true}>Availability</FormElementLabel>
										<FormInputContainer>
											<Controller
												name={"hours"}
												control={control}
												defaultValue={{}}
												rules={{
													required: {
														value: true,
														message: "Coach availability is required"
													},
													validate: {
														value: value => {
															return Object.keys(value)
																.map(x => value[x])
																.flat().length
																? true
																: "Coach availability can't be empty"
														}
													}
												}}
												render={props => (
													<AvailabilityTable
														value={props.value}
														onChange={value => {
															props.onChange(value)
														}}
													/>
												)}
											/>
										</FormInputContainer>
									</FormElementContainer>
									{errors?.hours?.message && (
										<MessageStyles htmlFor={1} focused={false} validation={errors?.hours}>
											{errors?.hours?.message}
										</MessageStyles>
									)}
								</Col>
							</Row>
							<Row>
								<SubmitButtonContainer>
									<Button
										onClick={() => history.push(`/coaches/${params.id}`)}
										size='small'
										title={"Cancel"}
										variant='tertiary'
										style={{margin: "10px", padding: "20px 24px"}}
										fullWidth={false}
									/>
									<Button
										size='small'
										title={"Save"}
										style={{margin: "10px", padding: "20px 24px"}}
										variant='primary'
										fullWidth={false}
										type='submit'
									/>
								</SubmitButtonContainer>
							</Row>
						</ContainerFluid>
					</form>
				)}
			</TableContainer>
		</ComponentWrapper>
	)
}

export default EditCoach
