import React, {useState, useEffect, useRef} from "react"
import {useHistory} from "react-router-dom"
import {Loader} from "@uprise/loader"
import moment from "moment-timezone"
import {Container} from "@uprise/grid"
import {useDispatch, useSelector} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"

// UI
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"

import ComponentWrapper from "components/HOC/ComponentWrapper"

import {
	fetchCoachAvailability,
	fetchCoaches,
	fetchAllCoachesAvailability,
	bookingDataSelector,
	clearCoachAvailability,
	clearAllBookings
} from "../../bookingSlice"
import {fetchCoachesData} from "../../../Coaches/coachesSlice"
import AvailableSlots from "../AvailableSlots"
import SlotsWeekView from "../AvailableSlots/SlotsWeekView"
import SelectedInfo from "../SelectedInfo"
import SearchFilters from "../SearchFilters"
import ConfirmBooking from "../ConfirmBooking"

import {Alerts} from "./Alerts"

const BookingResults = props => {
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()
	const history = useHistory()

	const {
		coachesAvailability,
		fetchAllCoachesAvailabilityApiState,
		createBookingApiState,
		fetchCoachesApiState,
		coachAvailability
	} = useSelector(bookingDataSelector)

	const [timezones, setTimezones] = useState([])
	const [selectedTimeZone, setSelectedTimezone] = useState(props?.location?.state?.timezone || "Australian/Sydney")
	const [coachesDropdown, setCoachesDropdown] = useState(null)
	const [coachSelected, setCoachSelected] = useState(props?.location?.state?.coachId)
	const [selectedDate, setSelectedDate] = useState(moment())
	const [selectedDuration, setSelectedDuration] = useState(30)
	const [token, setToken] = useState(null)
	const [dayOffset, setDayOffset] = useState(0)
	const [shouldReset, setShouldReset] = useState(false)
	const [selectedCoach, setSelectedCoach] = useState(null)
	const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)
	const [gender, setGender] = useState("")
	const [coaches, setCoaches] = useState([])
	const [language, setLanguage] = useState("")
	const [qualification, setQualification] = useState("")
	const [timezone, setTimezone] = useState(props?.location?.state?.timezone || "Australian/Sydney")
	const [showCreateBooking, setShowCreateBooking] = useState(false)

	const coachesAvailabilityCount = Object.values(coachesAvailability).length
	const coachAvailabilityCount = Object.values(coachAvailability).length

	useEffect(() => {
		setTimezones(moment.tz.names().map(item => ({label: item, value: item})))

		dispatch(fetchCoaches())
		;(async () => {
			const token = await getAccessTokenSilently()
			setToken(token)
		})()

		return () => {
			dispatch(clearCoachAvailability())
			dispatch(clearAllBookings())
		}
	}, [])

	useEffect(() => {
		if (!props?.location?.state) history.push("/bookings/new")
		else {
			const {state} = props.location
			setSelectedTimezone(state?.timezone)
			setDayOffset(state?.dayOffset)
			setCoachSelected(state?.coachId)
			setSelectedDate(moment().add(state?.dayOffset, "days"))
		}
	}, [props?.state])

	useEffect(() => {
		setCoaches(fetchCoachesApiState.data)
		setCoachesDropdown(coaches.map(coach => ({label: coach.name, value: coach.id})))
	}, [fetchCoachesApiState])

	useEffect(() => {
		if (selectedTimeZone && selectedDate) {
			// + 1 to include the start, in case its less < 24 hours diff
			const offset = moment(selectedDate).diff(moment().startOf("day"), "days") // + 1
			setDayOffset(offset)
		}
	}, [selectedTimeZone, selectedDate])

	useEffect(() => {
		if (shouldReset) {
			setShouldReset(false)
			setCoachSelected()
		}
	}, [shouldReset])

	const notInitialRender = useRef(false)

	useEffect(() => {
		if (notInitialRender.current) _handleSearchAvailability()
		else notInitialRender.current = true
	}, [selectedDuration, coachSelected, token, selectedDate])

	const initialRender = useRef(true)
	useEffect(() => {
		if (!initialRender.current) setCoachSelected()
		else initialRender.current = false
	}, [gender, language, qualification])

	const _handleReset = value => {
		dispatch(clearCoachAvailability())
		dispatch(clearAllBookings())
		setShouldReset(value)
	}

	const _handleDayClick = value => {
		const offset = moment(value).diff(moment().startOf("day"), "days")
		setSelectedDate(value)

		if (coachSelected) {
			const coach = coaches.find(coach => coach.id === coachSelected)
			dispatch(
				fetchCoachAvailability({
					data: {
						duration: selectedDuration,
						email: coach.email,
						timezone: selectedTimeZone,
						week: value.isoWeek()
					},
					token
				})
			)
		} else {
			dispatch(
				fetchAllCoachesAvailability({
					data: {
						duration: selectedDuration,
						timezone: selectedTimeZone,
						coachId: coachSelected,
						dayOffset: offset,
						qualification,
						language,
						gender
					},
					token
				})
			)
		}
	}

	const _handleSearchWithCoach = () => {
		if (coaches.length) {
			const offset = moment(selectedDate).diff(moment().startOf("day"), "days")
			const coach = coaches.find(coach => coach.id === coachSelected)

			dispatch(
				fetchCoachAvailability({
					data: {
						duration: selectedDuration,
						dayOffset: offset,
						email: coach.email,
						timezone: selectedTimeZone,
						week: moment(selectedDate).isoWeek()
					},
					token
				})
			)
		} else {
			dispatch(
				fetchCoachesData({
					limit: 10000,
					skip: 0,
					token
				})
			)
		}
	}

	const _handleSlotSetTime = value => {
		setShowCreateBooking(false)
		setSelectedTimeSlot(value)
	}

	const _handleSearchAvailability = () => {
		if (coachSelected) _handleSearchWithCoach()
		else {
			setTimezone(selectedTimeZone)
			dispatch(clearAllBookings())
			dispatch(
				fetchAllCoachesAvailability({
					data: {
						duration: selectedDuration,
						timezone: selectedTimeZone,
						coachId: coachSelected,
						dayOffset: dayOffset,
						qualification,
						language,
						gender
					},
					token
				})
			)
		}
	}

	const coach = coachSelected ? coaches.find(coach => coach.id === coachSelected) : selectedCoach

	useEffect(() => {
		if ((coachesAvailabilityCount || coachAvailabilityCount) && !selectedTimeSlot?.start) {
			setShowCreateBooking(true)
		}
	}, [coachAvailability, coachesAvailability])

	useEffect(() => {
		if (createBookingApiState.isSuccess) {
			history.push("/bookings/new")
		}
	}, [createBookingApiState])

	return (
		<ComponentWrapper
			menuActiveIndex={4}
			subMenuActiveIndex={12}
			headerTitle={"Book a Therapy Call: Search Results"}
			backBtn={true}
			justifyHeader={"unset"}>
			<Alerts createBookingApiState={createBookingApiState} />

			{showCreateBooking && (
				<SearchFilters
					timezones={timezones}
					setSelectedTimezone={setSelectedTimezone}
					selectedTimeZone={selectedTimeZone}
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
					coachesDropdown={coachesDropdown}
					coachSelected={coachSelected}
					setCoachSelected={setCoachSelected}
					_handleSearchAvailability={_handleSearchAvailability}
					setQualification={setQualification}
					setLanguage={setLanguage}
					setGender={setGender}
				/>
			)}

			<Card padding={spacing.s10} backgroundColor='white'>
				{showCreateBooking && (
					<>
						<SelectedInfo
							duration={selectedDuration === 30}
							setSelectedDuration={setSelectedDuration}
							date={selectedDate}
							dayOffset={dayOffset}
							setDate={setSelectedDate}
							onDayClick={_handleDayClick}
							setShouldReset={_handleReset}
							coaches={coaches}
							coachSelected={coachSelected}
						/>

						{fetchAllCoachesAvailabilityApiState.isFetching && <Loader />}

						{coachAvailabilityCount > 0 && coachSelected && (
							<SlotsWeekView
								coachAvailability={coachAvailability}
								coach={coaches.find(coach => coach.id === coachSelected)}
								coachSelected={coachSelected}
								timeZone={timezone}
								setTime={_handleSlotSetTime}
								setSelectedCoach={value => setSelectedCoach(value)}
							/>
						)}

						{coachesAvailabilityCount > 0 && !coachSelected && (
							<AvailableSlots
								coachesAvailability={coachesAvailability}
								coaches={coaches}
								coachSelected={coachSelected}
								timeZone={timezone}
								setTime={_handleSlotSetTime}
								setSelectedCoach={value => setSelectedCoach(value)}
							/>
						)}
					</>
				)}

				{!showCreateBooking && selectedCoach && (
					<Container>
						<ConfirmBooking
							coach={coach}
							timeslot={selectedTimeSlot}
							setTime={setSelectedTimeSlot}
							duration={selectedDuration}
							setSelectedCoach={setSelectedCoach}
							isBookingDone={false}
							timeZone={selectedTimeZone}
							loading={createBookingApiState.isFetching}
						/>
					</Container>
				)}
			</Card>
		</ComponentWrapper>
	)
}

export default BookingResults
