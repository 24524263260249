import styled from "styled-components"
import {Button} from "@uprise/button"
import {Select, TextInputHorizontal} from "@uprise/form"
import {Note} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import PlusIcon from "assets/images/icons/svg/plus.svg"
import {text} from "@uprise/typography"

export const HeaderTableContent = styled.div`
	display: flex;
`

export const SearchUserInput = styled.input`
	width: 240px;
	height: 32px;
	align-self: center;
	margin-right: 24px;
	border: 0;
	border-bottom: 1px solid #f6f4fc;
`

export const Container = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
`
export const HomeImage = styled.img`
	margin: auto;
`

export const HomeTitle = styled(H2)`
	text-align: center;
	margin: 12px;
`
export const EmployerHomeDescription = styled(P)`
	margin: 12px;
	text-align: center;
`

export const EmployerButton = styled(Button)`
	width: 50%;
	margin: auto;
`
export const UploadButton = styled(Button)`
	outline: none;
	padding-right: 12px;
	&:before {
		margin-top: 5px;
		margin-right: 12px;
		content: url("${PlusIcon}");
		position: relative;
	}
`

export const DownloadButton = styled(Button)`
	outline: none;
	padding-right: 12px;
	margin-left: 20px;
	&:before {
		margin-top: 5px;
		margin-right: 12px;
		content: url("${PlusIcon}");
		position: relative;
	}
`

export const ButtonWrap = styled.div`
	display: flex;
	justify-content: center;
`

export const ModalButtonContainer = styled.div`
	display: flex;
`

export const ColumnContainer = styled.label`
	display: flex;
`

export const TableFirstColumnText = styled(P)`
	padding: 6px 0 6px 12px;
	margin: 0;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	cursor: pointer;
	letter-spacing: normal;
	color: #7d60ff;
`

export const TableContainer = styled.div`
	border-radius: 10px;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #ffffff;
`
