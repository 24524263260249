import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {post, get, put} from "helpers/api"

export const initialState = {
	eapCenters: {
		total: 0,
		data: []
	},
	eapCenter: {},
	apiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	}
}

export const fetchEAPCenters = createAsyncThunk("setting/fetchEAPCenters", async ({limit, skip, token}, thunkAPI) => {
	try {
		const response = await get(`v2/settings/eap-centers`, {}, {Authorization: `Bearer ${token}`})
		console.log("response", response)
		return response
	} catch (e) {
		return thunkAPI.rejectWithValue("Something went wrong, Please try again later")
	}
})

export const fetchEAPCenterById = createAsyncThunk("setting/fetchEAPCenterById", async ({id, token}, thunkAPI) => {
	try {
		const response = await get(`v2/settings/eap-center/${id}`, {}, {Authorization: `Bearer ${token}`})
		return response
	} catch (e) {
		return thunkAPI.rejectWithValue("Something went wrong, Please try again later")
	}
})

export const updateEAPCenter = createAsyncThunk("setting/updateEAPCenter", async ({id, data, token}, thunkAPI) => {
	try {
		const response = await post(`v2/settings/eap-center/${id}`, data, {}, {Authorization: `Bearer ${token}`})

		return response
	} catch (e) {
		return thunkAPI.rejectWithValue("Something went wrong, Please try again later")
	}
})

export const createEAPCenter = createAsyncThunk("setting/createEAPCenter", async ({data, token}, thunkAPI) => {
	try {
		const response = await post(`v2/settings/eap-center`, data, {}, {Authorization: `Bearer ${token}`})
		return response
	} catch (e) {
		return thunkAPI.rejectWithValue("Something went wrong, Please try again later")
	}
})

export const deleteEAPCenter = createAsyncThunk("setting/deleteEAPCenter", async ({id, token}, thunkAPI) => {
	try {
		const response = await post(`v2/settings/eap-center/delete/${id}`, {}, {}, {Authorization: `Bearer ${token}`})
		return response
	} catch (e) {
		return thunkAPI.rejectWithValue("Something went wrong, Please try again later")
	}
})

const settingsSlice = createSlice({
	name: "setting",
	initialState: initialState,
	reducers: {
		clearApiState: state => {
			state.apiState.isSuccess = false
			state.apiState.isError = false
			state.apiState.isFetching = false
			state.apiState.message = ""

			return state
		},
		clearSelectedEAPCenterState: state => {
			state.eapCenter = {}

			return state
		}
	},
	extraReducers: {
		[fetchEAPCenters.fulfilled]: (state, {payload}) => {
			console.log("payload", payload)
			state.eapCenters = payload
			state.apiState.isFetching = false
			state.apiState.isSuccess = true
		},
		[fetchEAPCenters.rejected]: state => {
			state.apiState.isError = true
			state.apiState.isFetching = false
		},
		[fetchEAPCenters.pending]: state => {
			state.apiState.isFetching = true
		},
		[fetchEAPCenterById.pending]: state => {},
		[fetchEAPCenterById.rejected]: state => {},
		[fetchEAPCenterById.fulfilled]: (state, {payload}) => {
			console.log("payload", payload)
			state.eapCenter = payload
		},
		[updateEAPCenter.pending]: state => {},
		[updateEAPCenter.rejected]: state => {
			state.apiState.isFetching = false
			state.apiState.isError = true
		},
		[updateEAPCenter.fulfilled]: (state, {payload}) => {
			const data = payload.data
			state.eapCenters.data = state.eapCenters.data.map(eapCenter => {
				if (eapCenter.id === data.id) {
					return data
				} else {
					return eapCenter
				}
			})

			state.apiState.isSuccess = true
			state.apiState.message = "EAP Center details updated successfully"
		},
		[createEAPCenter.rejected]: state => {
			state.apiState.isError = true
			state.apiState.message = "Error while creating EAP center"
		},
		[createEAPCenter.pending]: state => {},
		[createEAPCenter.fulfilled]: (state, {payload}) => {
			state.apiState.isSuccess = true
			state.apiState.message = "EAP Center added successfully"
			state.eapCenters.data = [...state.eapCenters.data, payload.data]
		},
		[deleteEAPCenter.pending]: state => {
			state.apiState.isFetching = true
		},
		[deleteEAPCenter.fulfilled]: (state, {payload}) => {
			state.eapCenters.data = state.eapCenters.data.filter(item => item.id.toString() !== payload.data)
			state.apiState.isSuccess = true
			state.apiState.message = "Deleted EAP Center Successfully"
		},
		[deleteEAPCenter.rejected]: state => {
			state.apiState.isError = true
			state.apiState.message = "Something went wrong"
		}
	}
})

export const {clearApiState, clearSelectedEAPCenterState} = settingsSlice.actions

export const settingsSelector = state => state.setting

export default settingsSlice
