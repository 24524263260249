import moment from "moment"

export const parseEmployerDataForTable = employer => {
	let mainContactInfo = null

	mainContactInfo = employer?.data?.contact?.find(element => element.contactFunction === "poc")

	return {
		code: employer.code,
		companyName: employer?.data?.general?.companyName,
		pricingType: employer?.data?.plan?.pricingType,
		companySize: employer?.data?.general?.size,
		userSignups: employer.userSignups ? employer.userSignups : 0,
		accountManager: employer?.data?.general?.accountManager,
		mainContact: mainContactInfo
			? {
					label: `${mainContactInfo.firstName} ${mainContactInfo.lastName}`,
					value: mainContactInfo.emailAddress
			  }
			: {
					label: "blank",
					value: null
			  },
		contacts: employer?.data?.contact,
		contractStart: moment(employer?.data?.contract?.dateSigned).format("DD/MM/YYYY"),
		contractEnd: moment(employer?.data?.contract?.expiryDate).format("DD/MM/YYYY"),
		utilization: 0,
		selected: false
	}
}
