import styled from "styled-components"
import {Button} from "@uprise/button"
import {Select, TextInputHorizontal} from "@uprise/form"
import {Note} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {P} from "@uprise/text"
export const HeaderTableContent = styled.div`
	display: flex;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #f8f8ff;
	border-radius: 10px;
`
export const HomeImage = styled.img`
	margin: auto;
`

export const HomeTitle = styled(H2)`
	text-align: center;
	margin: 12px;
`
export const EmployerHomeDescription = styled(P)`
	margin: 12px;
	text-align: center;
`

export const EmployerButton = styled(Button)`
	width: 50%;
	height: 32px;
	margin: auto 12px auto auto;
	padding: 7px 12px;
`

export const FormInputInlineContainer = styled.div`
	display: flex;
	flex-direction: row;
`

export const FormLevelContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`
export const FormLevel = styled.div`
	display: flex;
	flex-basis: 25%;
	min-height: 100px;
	justify-content: center;
	border-radius: 10px 10px 0 0;
	cursor: pointer;
	flex-direction: column;
	background-color: ${props => (props.active ? "#edeafa" : "")};
`
export const FormLevelLabelContainer = styled.div`
	position: relative;
	text-align: center;
	margin-top: 15px;
	display: flex;
	justify-content: center;
`

export const FormLevelLabelHeader = styled(H3)`
	margin: 0;
`

export const FormLevelLabelText = styled(P)`
	margin: 0;
`

export const ItemContainer = styled.div`
	background-color: #fff;
	width: 100%;
	padding: 20px;
	border-radius: 10px;
`
export const FormItem = styled.div`
	display: ${props => (props.active ? "block" : "none")};
`

export const Icon = styled.img`
	margin-right: 10px;
`

export const TimelineDotIcon = styled.div`
	width: 9px;
	height: 9px;
	margin: 0 60px 17px;
	transform: rotate(-90deg);
	background-color: ${props => (props.active ? "#7d60ff" : "#c1c0c5")};
	border-radius: 50%;
	top: ${props => (props.active ? "-42%" : "-50%")};
	z-index: 1;
	position: absolute;
`

export const PrimaryButton = styled(Button)`
	margin: 0 6px;
	padding: 7px 12px;
`

export const SecondaryButton = styled(Button)`
	padding: 7px 12px;
	margin: 0 6px;
	border-color: transparent;
	background-color: #f6f4fc;
`
