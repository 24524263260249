import styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {TextArea, TextInput} from "@uprise/form"
import {Button} from "@uprise/button"
import {P} from "@uprise/text"
export const Content = styled.div`
	padding: ${spacing.s6};
`

export const PrimaryButton = styled(Button)`
	max-width: 150px;
	margin: 0 6px;
`

export const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`

export const FormInputContainer = styled.div`
	flex-basis: 80%;
	position: relative;
	align-self: center;
`

export const HiddenInput = styled.input`
	display: none;
`

export const TableContent = styled.div`
	width: 100%;
`

export const TableContainer = styled.div`
	border-radius: 10px;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #ffffff;
`

export const ColumnContainer = styled.label`
	display: flex;
	justify-content: ${props => props.alignText};
`

export const ColumnText = styled(P)`
	padding: 6px 0 6px 12px;
	margin: 0;
	cursor: pointer;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
`

export const TableTextArea = styled(P)`
	padding: 6px 0 6px 12px;
	margin: 0;
	cursor: pointer;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5; /* number of lines to show */
	-webkit-box-orient: vertical;
`
