import React, {useState, Fragment, useRef} from "react"
import {useCombobox} from "downshift"
import {useVirtual} from "react-virtual"
import Styled from "styled-components"
import SearchIcon from "assets/images/icons/svg/search.svg"
const Container = Styled.div`
 border :  solid 1px #d4cbfb;
 border-radius : 4px;
 position: relative;
`

const TriggerButtonContainer = Styled.div`
width: 100%;
position:relative;
`

const TriggerButton = Styled.input`
  width: 100%;
  max-width :400px;
  padding: 2px 22px 2px 12px;
  border-radius: 10px;
  text-align: left;
  outline: none;
  border: 0;
  background-color: transparent;
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #6d6c79;
  position: relative;
`

const SearchIconFigure = Styled.figure`
 position : absolute;
 top: 5px;
 right: 5px;
 margin : 0;
`

const Label = Styled.label`
	position : absolute;
	top: 2px;
	left: 12px;
	margin : 0;
	font-family: "Proxima Nova";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: #6d6c79;
`

const MenuList = Styled.ul`
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  margin: 0 auto;
  border-top: 0;
  outline: none;
  padding: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px 0 rgba(219, 221, 227, 0.5);
  background-color: #ffffff;
`

const MenuListItem = Styled.li`
  padding: 14px;
  text-align: left;
  list-style-type: none;
  border-bottom: 1px solid #edeafa;
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  background-color: ${props => (props.selectedItem ? "#edeafa" : props.highlightedIndex ? "#f8f8ff" : "#fffff")};
  color: ${props => (props.selectedItem ? "#7d60ff" : "#9796a0")};

  &:last-child {
    border-bottom: 0;
  }
`

const AutoComplete = ({
	items,
	onChange,
	containerStyle,
	inputStyle,
	menuStyle,
	initialSelectedItem,
	customMenuAddOn,
	label,
	className = ""
}) => {
	const listRef = useRef()
	const [inputValue, setInputValue] = useState(initialSelectedItem || "")

	function getItems(search) {
		return items.filter(n => n && n.toLowerCase().includes(search ? search.toLowerCase() : search))
	}

	const inputItems = getItems(inputValue)

	const rowVirtualizer = useVirtual({
		size: inputItems.length,
		parentRef: listRef,
		estimateSize: React.useCallback(() => 20, []),
		overscan: 2
	})
	const {
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		selectedItem,
		getMenuProps,
		getInputProps,
		getComboboxProps,
		highlightedIndex,
		getItemProps
	} = useCombobox({
		items: inputItems,
		inputValue,
		onInputValueChange: ({inputValue: newValue}) => setInputValue(newValue),
		onSelectedItemChange: e => {
			onChange(e.selectedItem)
		},
		onHighlightedIndexChange: ({highlightedIndex}) => rowVirtualizer.scrollToIndex(highlightedIndex)
	})
	return (
		<Container className={className} style={containerStyle}>
			<TriggerButtonContainer {...getComboboxProps()}>
				{!inputValue && <Label {...getLabelProps()}>{label || ""}</Label>}
				<TriggerButton {...getInputProps()} style={inputStyle} />
				<SearchIconFigure {...getToggleButtonProps()}>
					<img alt='' src={SearchIcon} />
				</SearchIconFigure>
			</TriggerButtonContainer>
			<MenuList
				{...getMenuProps({
					ref: listRef,
					style: {
						maxHeight: 200,
						height: 200,
						width: "100%",
						position: "relative"
					}
				})}
				style={menuStyle}>
				{isOpen && (
					<Fragment>
						{rowVirtualizer.virtualItems.map(virtualRow => {
							return (
								<MenuListItem
									highlightedIndex={highlightedIndex === virtualRow.index}
									selectedItem={selectedItem === inputItems[virtualRow.index].id}
									key={inputItems[virtualRow.index].id}
									{...getItemProps({
										item: inputItems[virtualRow.index],
										index: virtualRow.index,

										style: {
											backgroundColor:
												highlightedIndex === virtualRow.index ? "#f8f8ff" : "#ffffff",
											height: virtualRow.size + 5,
											padding: "10px",
											transform: `translateY(${virtualRow.start}px)`,
											borderBottom: "1px solid #edeafa"
										}
									})}>
									{inputItems[virtualRow.index]}
								</MenuListItem>
							)
						})}
						{customMenuAddOn && customMenuAddOn()}
					</Fragment>
				)}
			</MenuList>
		</Container>
	)
}

export default AutoComplete
