import React, {useEffect, useState, Fragment} from "react"
import {object, func, string, array} from "prop-types"
// UI
import {ContainerFluid, Row} from "@uprise/grid"
import {P} from "@uprise/text"
import {text} from "@uprise/typography"
// Styles
import {Table, TableRow, TableBody, TableData, SlotsRow} from "./index.style"
import {ProfileImage} from "./index.style"
// Assets
import LogoHeadIcon from "@uprise/icons/icons/png/logo-head/logo-head.png"
// Components
import BoookingSlotsBlock from "../BookingSlotsBlock"

const AvailableSlots = ({coachesAvailability, timeZone, coaches, setSelectedCoach, setTime, coachSelected}) => {
	const [coachList, setCoachList] = useState([])

	useEffect(() => {
		if (coachSelected) {
			setCoachList([coaches.find(coach => coach.id === coachSelected)])
		} else {
			setCoachList(coaches)
		}
	}, [coachSelected, coaches])

	return (
		<ContainerFluid className='mb-5'>
			{coachesAvailability && (
				<Table>
					<TableBody>
						{coachList?.map((coach, index) => {
							if (coachesAvailability?.data[coach.id])
								return (
									<TableRow key={index}>
										<SlotsRow>
											<TableData style={{width: "15rem"}}>
												<Row className=' ' style={{padding: "12px"}}>
													<ProfileImage
														className='m-l-3'
														width='35px'
														height='35px'
														src={coach?.data?.photos?.imageURL || LogoHeadIcon}
														alt='user-icon'
													/>
													<P fontSize={text.t4} className='m-l-3'>
														{coach?.name.length > 15
															? `${coach?.name.substring(0, 14)}...`
															: coach.name}
													</P>
												</Row>
											</TableData>
											<BoookingSlotsBlock
												data={coachesAvailability.data[coach.id]}
												timeZone={timeZone}
												coach={coach}
												setTime={setTime}
												setSelectedCoach={setSelectedCoach}
											/>
										</SlotsRow>
									</TableRow>
								)
						})}
					</TableBody>
				</Table>
			)}
		</ContainerFluid>
	)
}

AvailableSlots.propTypes = {
	coachesAvailability: object.isRequired,
	timeZone: string.isRequired,
	setSelectedCoach: func.isRequired,
	setTime: func.isRequired
}

AvailableSlots.defaultProps = {}

export default AvailableSlots
