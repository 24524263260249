import React, {Fragment} from "react"
import styled from "styled-components"
// UI
import {Container, Row, Col} from "@uprise/grid"

import {NoDataDiv} from "./index.style"
import {RTWUSWHO5Results} from "../Results/WorkableUSWHO5/RTWUSWHO5Results"
import {WHO5BHCResults} from "../Results/WHO5/WHO5BHCResults"
import {STRESSBHCResults} from "../Results/STRESS/STRESSBHCResults"
import {WHO5STRESSResults} from "../Results/WHO5STRESS/WHO5STRESSResults"
import {SurveyResults} from "../Results/Exercises/SurveyResults"

const Wrap = styled.div``

const Activity = ({results, tasks}) => {
	const resultsRTWUSWHO5 = results?.filter(result => {
		return result.answers.RTW_US_ONBOARDING || result.answers.WHO5RTWUS
	})

	const resultsWHO5BHC = results?.filter(result => {
		return (
			result.taskId.includes("wellbeing") &&
			!result.taskId.includes("stress") &&
			!result.taskId.includes("exercise")
		)
	})

	const resultsSTRESSBHC = results?.filter(result => {
		return (
			result.taskId.includes("stress") &&
			!result.taskId.includes("wellbeing") &&
			!result.taskId.includes("exercise")
		)
	})

	const resultsWHO5STRESS = results?.filter(result => {
		return (
			(!result.taskId.includes("pre-onboarding") && result.taskId.includes("survey-onboarding")) ||
			(result.taskId.includes("stress") && result.taskId.includes("wellbeing")) ||
			(result.taskId.includes("progress") && !result.taskId.includes("exercise"))
		)
	})

	const resultsSurvey = results?.filter(result => {
		return result.taskId.includes("exercise")
	})

	if (results) {
		return (
			<Container>
				<Row>
					<Col>
						<Wrap className='m-t-5'>
							{resultsSurvey && <SurveyResults className='m-b-5' results={resultsSurvey} tasks={tasks} />}
							{resultsWHO5STRESS && (
								<WHO5STRESSResults className='m-b-5' results={resultsWHO5STRESS} tasks={tasks} />
							)}
							{resultsSTRESSBHC && (
								<STRESSBHCResults className='m-b-5' results={resultsSTRESSBHC} tasks={tasks} />
							)}
							{resultsWHO5BHC && (
								<WHO5BHCResults className='m-b-5' results={resultsWHO5BHC} tasks={tasks} />
							)}
							{resultsRTWUSWHO5 && (
								<RTWUSWHO5Results className='m-b-5' results={resultsRTWUSWHO5} tasks={tasks} />
							)}
							{!results?.length && <NoDataDiv>No Results found</NoDataDiv>}
						</Wrap>
					</Col>
				</Row>
			</Container>
		)
	} else {
		return null
	}
}

export default Activity
