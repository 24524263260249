export const DURATIONS = [
	{
		label: "30 mins",
		value: 30
	},
	{
		label: "60 mins",
		value: 60
	}
]

export const TYPES = [
	{
		label: "Call",
		value: "call"
	},
	{
		label: "Chat",
		value: "chat"
	}
]
