import styled from "styled-components"
import {P} from "@uprise/text"
import {extended} from "@uprise/colors"

export const Table = styled.div`
	display: block;
	width: 100%;
	height: 500px;
	overflow-y: scroll;
	padding-top: 5px;
`

export const TableHeader = styled.div`
	display: block;
	border-radius: 5px;
`

export const TableHeading = styled.div`
	width: 15rem;
`

export const TableRow = styled.div`
	display: block;
	padding: 0 5px;
	border: 1px solid ${extended.purple.five};
	border-bottom: 0;

	&:last-child {
		border-bottom: 1px solid ${extended.purple.five};
	}
`

export const TableBody = styled.div`
	display: block;
`

export const TableData = styled.div`
	 
`

export const SlotsContainer = styled.div`
	width: 100%;
	padding: 12px;
	border-left: 1px solid ${extended.purple.five};
}
`
export const SlotsRow = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 15% 28.33% 28.33% 28.33%;
`

export const SlotsRowWeekly = styled.div`
	display: grid;
	width: 500px;
	grid-template-columns: 30% 70% 0 0;
`

export const SlotTextContainer = styled.span`
	display: inline-block;
	border-radius: 5px;
	background-color: #f6f4fc;
	width: 70px;
	margin-right: 5px;
	margin-bottom: 4px;
	height: 24px;

	&:hover {
		background-color: #7d60ff;
		color: #ffffff;
	}
`

export const SlotText = styled(P)`
	font-family: "Proxima Nova";
	margin: 0;
	padding: 0 5px;
	cursor: pointer;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	text-align: center;
	color: #7d60ff;
	&:hover {
		color: #ffffff;
	}
`

export const ProfileImage = styled.div`
	width: 30px;
	height: 30px;
 	border-radius: 50%;
	background-image: ${props => (props.src ? `url(${props.src})` : `url(${UserIcon})`)};
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-color: ${extended.charcoal.five};
`

export const ProfileImageWeek = styled.div`
	width: 30px;
	height: 30px;
 	border-radius: 50%;
	background-image: ${props => (props.src ? `url(${props.src})` : `url(${UserIcon})`)};
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-color: ${extended.charcoal.five};
`