import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"

import {get, upload} from "helpers/api"

export const initialState = {
	loading: false,
	hasErrors: false,
	downloadRefferalListApiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false
	},
	fetchReferralUploadsApiState: {
		isSuccess: false,
		isFetching: false,
		message: "",
		isError: false,
		total: 0,
		data: []
	},
	uploadApiState: {
		isSuccess: false,
		isFetching: false,
		isError: false,
		message: ""
	},
	downloadProspectiveUsers: {
		data: [],
		file: ""
	}
}

export const uploadReferral = createAsyncThunk(
	"workable/uploadReferral",
	async ({data, token, optionsArg}, thunkAPI) => {
		try {
			const response = await upload(
				`v2/prospective-users/upload`,
				data,
				{},
				{Authorization: `Bearer ${token}`},
				optionsArg
			)

			if (response.error) {
				return thunkAPI.rejectWithValue(response)
			} else {
				return response
			}
		} catch (e) {
			return thunkAPI.rejectWithValue("Something went wrong while fetching data. Please Contact our support")
		}
	}
)

export const fetchReferralUploads = createAsyncThunk(
	"workable/fetchReferralUploads",
	async ({limit, skip, token, type}, thunkAPI) => {
		const response = await get(
			`v2/prospective-users/${type}?limit=${limit}&skip=${skip}`,
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	}
)

export const downloadRefferalList = createAsyncThunk(
	"workable/downloadRefferalList",
	async ({fileId, type, token}, thunkAPI) => {
		try {
			const response = await get(
				`v2/prospective-users/download/${type}?file=${fileId}`,
				{},
				{Authorization: `Bearer ${token}`}
			)

			if (response.error) {
				return thunkAPI.rejectWithValue(response)
			} else {
				return response
			}
		} catch (e) {
			return thunkAPI.rejectWithValue("Something went wrong while fetching data. Please Contact our support")
		}
	}
)

export const downloadAllProspectiveUsers = createAsyncThunk(
	"workable/downloadAllProspectiveUsers",
	async ({type, token}, thunkAPI) => {
		try {
			const response = await get(
				`v2/prospective-users/download/${type}/all`,
				{},
				{Authorization: `Bearer ${token}`}
			)

			if (response.error) {
				return thunkAPI.rejectWithValue(response)
			} else {
				return response
			}
		} catch (e) {
			return thunkAPI.rejectWithValue("Something went wrong while fetching data. Please Contact our support")
		}
	}
)

const workableSlice = createSlice({
	name: "workable",
	initialState: initialState,
	reducers: {
		clearWorkableState: state => {
			state.uploadApiState.isSuccess = false
			state.uploadApiState.isError = false
			state.uploadApiState.isFetching = false
			state.uploadApiState.message = ""
			return state
		},
		clearDownloadedListData: state => {
			state.downloadRefferalListApiState.isSuccess = false
			state.downloadRefferalListApiState.isError = false
			state.downloadRefferalListApiState.isFetching = false
			state.downloadRefferalListApiState.message = ""
			state.downloadProspectiveUsers.data = []
			state.downloadProspectiveUsers.file = ""
			return state
		}
	},
	extraReducers: {
		[fetchReferralUploads.pending]: state => {
			state.fetchReferralUploadsApiState.isFetching = true
			state.fetchReferralUploadsApiState.isError = false
			state.fetchReferralUploadsApiState.isSuccess = false
		},
		[fetchReferralUploads.rejected]: state => {
			state.fetchReferralUploadsApiState.isError = true
			state.fetchReferralUploadsApiState.isSuccess = false
			state.fetchReferralUploadsApiState.isFetching = false
		},
		[fetchReferralUploads.fulfilled]: (state, {payload}) => {
			state.fetchReferralUploadsApiState.data = payload.data
			state.fetchReferralUploadsApiState.total = payload.totalCount
			state.fetchReferralUploadsApiState.isError = false
			state.fetchReferralUploadsApiState.isSuccess = true
			state.fetchReferralUploadsApiState.isFetching = false
		},
		[uploadReferral.fulfilled]: (state, {payload}) => {
			state.uploadApiState.data = payload?.data
			state.uploadApiState.isSuccess = true
			state.uploadApiState.isFetching = false
			state.uploadApiState.message = "Uploaded Successfully"
		},
		[uploadReferral.pending]: state => {
			state.uploadApiState.isFetching = true
		},
		[uploadReferral.rejected]: (state, {payload}) => {
			state.uploadApiState.isError = true
			state.uploadApiState.isFetching = false
			state.uploadApiState.message = payload.message
		},
		[downloadRefferalList.pending]: state => {
			state.downloadRefferalListApiState.isFetching = true
			state.downloadRefferalListApiState.isSuccess = false
			state.downloadRefferalListApiState.isError = false
		},
		[downloadRefferalList.rejected]: (state, {payload}) => {
			state.downloadRefferalListApiState.isError = true
			state.downloadRefferalListApiState.isSuccess = false
			state.downloadRefferalListApiState.isFetching = false
			state.downloadRefferalListApiState.message = payload.message
		},
		[downloadRefferalList.fulfilled]: (state, {payload}) => {
			state.downloadProspectiveUsers.data = payload.data
			state.downloadProspectiveUsers.file = payload.file
			state.downloadRefferalListApiState.isSuccess = true
			state.downloadRefferalListApiState.isFetching = false
			state.downloadRefferalListApiState.isError = false
			state.downloadRefferalListApiState.message = "Generated csv successfully"
		},
		[downloadAllProspectiveUsers.pending]: state => {
			state.downloadRefferalListApiState.isFetching = true
			state.downloadRefferalListApiState.isSuccess = false
			state.downloadRefferalListApiState.isError = false
		},
		[downloadAllProspectiveUsers.rejected]: (state, {payload}) => {
			state.downloadRefferalListApiState.isError = true
			state.downloadRefferalListApiState.isSuccess = false
			state.downloadRefferalListApiState.isFetching = false
			state.downloadRefferalListApiState.message = payload.message
		},
		[downloadAllProspectiveUsers.fulfilled]: (state, {payload}) => {
			state.downloadRefferalListApiState.isSuccess = true
			state.downloadRefferalListApiState.isFetching = false
			state.downloadRefferalListApiState.isError = false
			state.downloadRefferalListApiState.message = "Generated csv successfully"
			state.downloadProspectiveUsers.data = payload.data
		}
	}
})

export const {clearWorkableState, clearDownloadedListData} = workableSlice.actions

export const workableSelector = state => state.workable

export default workableSlice
