import React, {Fragment} from "react"
import moment from "moment"

import {retrieveSurvey} from "uprise-surveys/lib/surveys/index"

// UI
import {Accordian} from "@uprise/accordian"
// Components
import {RTWUSWHO5Result} from "./RTWUSWHO5Result"

export const RTWUSWHO5Results = ({results, tasks}) => {
	const result = results.map((result, index) => {
		const survey = retrieveSurvey(result?.surveyId)
		const taskId = result.taskId
		const currentTask = tasks[taskId]
		const title = currentTask?.title || taskId
		const date = moment.unix(result.timestamp).format("MMMM Do YYYY, h:mm:ss a")
		const surveyTasks = Object.keys(result?.answers)

		let analysis

		try {
			const bonus = result.bonus ? result.bonus : result.answers.WHO5RTWUS[result.answers.length - 1]
			analysis = survey.analysis(result?.answers, bonus)
		} catch (error) {
			console.log(error)
			analysis = {}
		}
		return {
			header: `${title ? title : "Onboarding survey"} - ${date}`,
			content: <RTWUSWHO5Result surveyTasks={surveyTasks} analysis={analysis} survey={survey} />
		}
	})

	return (
		<Fragment>
			<Accordian className='m-b-5' content={result} />
		</Fragment>
	)
}
