import styled from "styled-components"
import {P, Bold} from "@uprise/text"

export const GridRow = styled.div`
	display: grid;
	grid-template-columns: 100px 1fr 200px 1fr 1fr 1fr 1fr 60px;
	grid-template-rows: 30px;
	justify-content: center center !important;
	align-items: space-between !important;
	justify-content: space-around;
	grid-column-gap: 10px;
	width: 100%;
	margin-bottom: 50px;
	align-items: center;
`
 
