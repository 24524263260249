import React from "react"

export const IconAllEmployers = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
		<g fill='none' fillRule='evenodd'>
			<path d='M0 0h12v12H0z' />
			<path
				fill={props?.fill ? props?.fill : "#D5D5D8"}
				d='M10.857.571c.631 0 1.143.512 1.143 1.143v8.572c0 .63-.512 1.143-1.143 1.143H1.143A1.143 1.143 0 010 10.286V1.714C0 1.084.512.571 1.143.571h9.714zm-8 6.858H1.714A.57.57 0 001.143 8v1.714c0 .316.256.572.571.572h1.143a.572.572 0 00.572-.572V8a.571.571 0 00-.572-.571zm4 0H5.143A.571.571 0 004.571 8v1.714c0 .316.256.572.572.572h1.714a.572.572 0 00.572-.572V8a.571.571 0 00-.572-.571zm3.429 0H9.143A.571.571 0 008.571 8v1.714c0 .316.256.572.572.572h1.143a.572.572 0 00.571-.572V8a.57.57 0 00-.571-.571zm-7.429-4H1.714A.57.57 0 001.143 4v1.714c0 .316.256.572.571.572h1.143a.572.572 0 00.572-.572V4a.571.571 0 00-.572-.571zm4 0H5.143A.571.571 0 004.571 4v1.714c0 .316.256.572.572.572h1.714a.572.572 0 00.572-.572V4a.571.571 0 00-.572-.571zm3.429 0H9.143A.571.571 0 008.571 4v1.714c0 .316.256.572.572.572h1.143a.572.572 0 00.571-.572V4a.57.57 0 00-.571-.571z'
			/>
		</g>
	</svg>
)
