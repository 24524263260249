import styled from "styled-components"
import {extended} from "@uprise/colors"
export const GlobalContainer = styled.label`
	display: flex;
	justify-content: ${props => (props.justifyContent ? props.justifyContent : "center")};
	align-items: center;
`

export const InputContainer = styled.label`
	position: relative;
	display: inline-block;
	width: ${props => (props.width ? `${props.width}px` : "100%")};
	height: ${props => (props.height ? `${props.height}px` : "auto")};
	> input {
		display: none;
	}
`

export const Input = styled.input`
	&:checked + span {
		background-color: ${props => (props.backgroundColorChecked ? props.backgroundColorChecked : "")};
	}
	&:disabled + span {
		background-color: ${props => (props.backgroundColorUnchecked ? props.backgroundColorUnchecked : "")};
		opacity: 0.4;
		cursor: not-allowed;
	}
	&:disabled:checked + span {
		background-color: ${props => (props.backgroundColorChecked ? props.backgroundColorChecked : "")};
		opacity: 0.4;
		cursor: not-allowed;
	}
	&:focus + span {
		box-shadow: 0 0 1px #2196f3;
	}
	&:checked + span:before {
		-webkit-transform: translateX(${({translate}) => translate}px);
		-ms-transform: translateX(${({translate}) => translate}px);
		transform: translateX(${({translate}) => translate}px);
	}
`

export const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${props => (props.backgroundColorUnchecked ? props.backgroundColorUnchecked : "")};
	-webkit-transition: 0.2s;
	transition: 0.2s;
	border-radius: 34px;
	&:before {
		position: relative;
		border-radius: 50%;
		content: "";
		height: ${props => (props.sliderHeight ? `${props.sliderHeight}px` : "100%")};
		width: ${props => (props.sliderWidth ? `${props.sliderWidth}px` : "100%")};
		left: 1.6px;
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.23);
		background-color: ${props => (props.backgroundColorButton ? props.backgroundColorButton : "")};
		-webkit-transition: 0.2s;
		transition: 0.2s;
	}
`

export const Label = styled.span`
	color: #777;
	font-size: 15px;
	font-family: helvetica;
	padding: 0 10px;
`
