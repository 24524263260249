import {createSlice, createAsyncThunk, current} from "@reduxjs/toolkit"

import {post, get} from "helpers/api"

export const initialState = {
	users: {
		loading: true,
		total: 0,
		data: []
	},
	notes: [],
	migrationCodes: [],
	userDetails: {},
	allUsersFiltered: {
		loading: true,
		total: 0,
		data: []
	},
	toggleDashboardApiState: {
		isFetching: true,
		isSuccess: false,
		isError: false,
		message: ""
	},
	intentNotificationHistory: {
		loading: true,
		total: 0,
		data: []
	},
	userDisableUserApiState: {
		isFetching: true,
		isSuccess: false,
		isError: false,
		message: ""
	},
	usersApiState: {
		isFetching: true,
		isSuccess: false,
		isError: false,
		message: ""
	},
	allUsersFilteredApiState: {
		isFetching: true,
		isSuccess: false,
		isError: false,
		message: ""
	},
	userDetailsState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	userNotificationState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	userBookingsApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	createNewUserApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	userNotesApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	userMigrationApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	createUserMigrationApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	}
}

export const fetchUsersData = createAsyncThunk(
	"users/fetchUsersData",
	async ({coachId, limit, skip, search, user, token}, thunkAPI) => {
		let response = await get(
			`v2/users/${coachId}?limit=${limit}&skip=${skip}&search=${search}&isAdmin=${user["https://app.uprise.co/app_metadata"].isAdmin}`,
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	}
)

export const fetchUserIntentHistory = createAsyncThunk(
	"users/fetchUserIntentHistory",
	async ({email, limit, skip, token}, thunkAPI) => {
		let response = await get(
			`v2/user/workable/intent-history/${email}?limit=${limit}&skip=${skip}`,
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	}
)

export const fetchUsersByEmail = createAsyncThunk("users/fetchUsersByEmail", async ({email, token}, thunkAPI) => {
	const response = await get(`v2/user/${email}`, {}, {Authorization: `Bearer ${token}`})

	if (response.error) {
		return thunkAPI.rejectWithValue(response)
	} else {
		return response
	}
})

export const fetchBookingsByUserEmail = createAsyncThunk(
	"users/fetchBookingsByUserEmail",
	async ({userEmail, token}, thunkAPI) => {
		try {
			const response = await get(`v2/bookings/user/${userEmail}`, {}, {Authorization: `Bearer ${token}`})

			if (response.error) {
				return thunkAPI.rejectWithValue(response)
			} else {
				return response
			}
		} catch (error) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const fetchUsersDataFiltered = createAsyncThunk(
	"users/fetchUsersDataFiltered",
	async ({coachId, employerCode, limit, skip, user, token}, thunkAPI) => {
		let response = await get(
			`v2/users/${coachId}/${employerCode}?limit=${limit}&skip=${skip}&isAdmin=${user["https://app.uprise.co/app_metadata"].isAdmin}`,
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	}
)

export const disableUser = createAsyncThunk("users/disableUser", async ({email, disabled, token}, thunkAPI) => {
	const response = await post(`v2/user/status`, {email, disabled}, {}, {Authorization: `Bearer ${token}`})

	if (response.error) {
		return thunkAPI.rejectWithValue(response)
	} else {
		return response
	}
})

export const toggleDashboardViewForUser = createAsyncThunk(
	"users/toggleDashboardViewForUser",
	async ({email, isDashboardViewEnabled, token}, thunkAPI) => {
		const response = await post(
			`v2/user/hr/status`,
			{email, isDashboardViewEnabled},
			{},
			{Authorization: `Bearer ${token}`}
		)

		return response
	}
)

export const fetchUsersByCourse = createAsyncThunk(
	"users/fetchUsersByCourse",
	async ({coachId, course, limit, skip, user, token}, thunkAPI) => {
		let response = await get(
			`v2/users/${coachId}/course/${course}?limit=${limit}&skip=${skip}&isAdmin=${user["https://app.uprise.co/app_metadata"].isAdmin}`,
			{},
			{Authorization: `Bearer ${token}`}
		)

		return response
	}
)

export const fetchUserNotes = createAsyncThunk(
	"users/fetchUserNotes",
	async ({userEmail, coachId, token}, thunkAPI) => {
		let response = await get(`v2/notes/${userEmail}/${coachId}`, {}, {Authorization: `Bearer ${token}`})
		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	}
)

export const createUserNotes = createAsyncThunk(
	"users/createUserNotes",
	async ({userEmail, coachId, title, content, token}, thunkAPI) => {
		let response = await post(
			"v2/notes/create",
			{
				client: userEmail,
				coachId: coachId,
				title: title,
				content: content
			},
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const deleteUserNote = createAsyncThunk("users/deleteUserNotes", async ({id, token}, thunkAPI) => {
	let response = await post("v2/notes/delete", {id}, {}, {Authorization: `Bearer ${token}`})

	if (response.error) {
		return thunkAPI.rejectWithValue(response.error)
	} else {
		return response
	}
})

export const updateUserNote = createAsyncThunk(
	"users/updateUserNotes",
	async ({id, userEmail, coachId, title, content, token}, thunkAPI) => {
		let response = await post(
			"v2/notes/update",
			{
				id,
				client: userEmail,
				coachId: coachId,
				title: title,
				content: content
			},
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const notifyUserIntentToWork = createAsyncThunk(
	"user/notify-interest-to-work",
	async ({email, type, typeDate, notes, token}, thunkAPI) => {
		const response = await post(
			`v2/user/workable/notify`,
			{email, type, typeDate, notes},
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.status === 500) {
			return thunkAPI.rejectWithValue(response.message)
		} else {
			return response
		}
	}
)

export const createNewUser = createAsyncThunk(
	"user/new",
	async ({email, name, phone, countryCode, countryName, employerCode, timezone, password, token}, thunkAPI) => {
		const response = await post(
			`v2/user/create`,
			{email, name, phone, countryCode, countryName, employerCode, timezone, password},
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	}
)

export const fetchAvailableMigrationCodes = createAsyncThunk(
	"users/fetchAvailableMigrationCodes",
	async ({limit, skip, search, token}, thunkAPI) => {
		let response = await get(
			`v2/codes?limit=${limit}&skip=${skip}&search=${search}`,
			{},
			{Authorization: `Bearer ${token}`}
		)
		if (response.error) {
			return thunkAPI.rejectWithValue("Error while fetching Migration Codes")
		} else {
			return response
		}
	}
)

export const createUserMigration = createAsyncThunk("users/createUserMigration", async ({data, token}, thunkAPI) => {
	let response = await post("v2/user/migrate", data, {}, {Authorization: `Bearer ${token}`})

	if (response.error) {
		return thunkAPI.rejectWithValue(response.error)
	} else {
		return response
	}
})

export const userSlice = createSlice({
	name: "user",
	initialState: initialState,
	reducers: {
		clearToggleDashUserState: state => {
			state.toggleDashboardApiState.isSuccess = false
			state.toggleDashboardApiState.isError = false
			state.toggleDashboardApiState.isFetching = false
			state.toggleDashboardApiState.message = ""
			return state
		},
		clearDisableUserState: state => {
			state.userDisableUserApiState.isSuccess = false
			state.userDisableUserApiState.isError = false
			state.userDisableUserApiState.isFetching = false
			state.userDisableUserApiState.message = ""
			return state
		},
		clearUserState: state => {
			state.profile = null
			return state
		},
		clearUserDetailsState: state => {
			state.userDetailsState.isSuccess = false
			state.userDetailsState.isError = false
			state.userDetailsState.isFetching = false
			state.userDetailsState.message = ""
			return state
		},
		clearUserNotificationState: state => {
			state.userNotificationState.isSuccess = false
			state.userNotificationState.isError = false
			state.userNotificationState.isFetching = false
			state.userNotificationState.message = ""
			return state
		},
		clearUsersApiState: state => {
			state.usersApiState.isSuccess = false
			state.usersApiState.isError = false
			state.usersApiState.isFetching = true
			state.usersApiState.message = ""

			return state
		},
		clearNewUserApiState: state => {
			state.createNewUserApiState.isSuccess = false
			state.createNewUserApiState.isError = false
			state.createNewUserApiState.isFetching = false
			state.createNewUserApiState.message = ""
			return state
		},
		clearUserNotesApiState: state => {
			state.userNotesApiState.isSuccess = false
			state.userNotesApiState.isError = false
			state.userNotesApiState.isFetching = false
			state.userNotesApiState.message = ""
			return state
		}
	},
	extraReducers: {
		[disableUser.pending]: state => {
			state.userDisableUserApiState.isFetching = true
		},
		[disableUser.rejected]: (state, {payload}) => {
			state.userDisableUserApiState.isFetching = false
			state.userDisableUserApiState.isError = true

			if (payload?.error) {
				state.userDisableUserApiState.message = payload?.message
			} else {
				state.userDisableUserApiState.message = "Something went wrong. Please try after sometime"
			}
		},
		[disableUser.fulfilled]: (state, {payload}) => {
			state.userDisableUserApiState.isFetching = false
			state.userDisableUserApiState.isSuccess = true
			state.userDisableUserApiState.message = "User status updated"

			const updateToggle = data => {
				let id
				const item = data.find((el, idx) => {
					if (el?.email === payload.email) {
						id = idx
						return true
					}
				})
				if (item) data[id].isAccountDisabled = payload.disabled
			}
			if (state.allUsersFiltered.data) {
				updateToggle(state.allUsersFiltered.data)
			}
			if (state.users.data) updateToggle(state.users.data)
			if (state.profile)
				// user profile
				state.profile.disabled = payload.disabled
		},
		[toggleDashboardViewForUser.pending]: state => {
			state.toggleDashboardApiState.isFetching = true
			state.toggleDashboardApiState.isError = false
			state.toggleDashboardApiState.isSuccess = false
		},
		[toggleDashboardViewForUser.rejected]: (state, {payload}) => {
			state.toggleDashboardApiState.isFetching = false
			state.toggleDashboardApiState.isError = true
			state.toggleDashboardApiState.isSuccess = false

			if (payload?.error) {
				state.toggleDashboardApiState.message = payload?.message
			} else {
				state.toggleDashboardApiState.message = "Something went wrong. Please try after sometime"
			}
		},
		[toggleDashboardViewForUser.fulfilled]: (state, {payload}) => {
			state.toggleDashboardApiState.isFetching = false
			state.toggleDashboardApiState.isSuccess = true
			state.toggleDashboardApiState.isError = false
			state.toggleDashboardApiState.message = "Successfully updated dashboard status"

			const updateToggle = data => {
				let id
				const item = data.find((el, idx) => {
					if (el?.email === payload.email) {
						id = idx
						return true
					}
				})
				if (item) data[id].isDashboardViewEnabled = payload.isDashboardViewEnabled
			}
			if (state.allUsersFiltered.data) {
				updateToggle(state.allUsersFiltered.data)
			}
			if (state.users.data) updateToggle(state.users.data)
			if (state.profile) state.profile.isDashboardViewEnabled = payload.isDashboardViewEnabled
		},
		[fetchUserNotes.pending]: state => {
			state.userNotesApiState.isFetching = true
		},
		[fetchUserNotes.fulfilled]: (state, {payload}) => {
			const {data} = payload
			state.userNotesApiState.isFetching = false

			state.notes = data || []
		},
		[fetchUserNotes.rejected]: (state, {payload}) => {
			state.userNotesApiState.isFetching = false
			state.userNotesApiState.isError = true

			if (payload?.error) {
				state.userNotesApiState.message = payload?.message
			} else {
				state.userNotesApiState.message = "Something went wrong. Please try after sometime"
			}
		},
		[createUserNotes.fulfilled]: (state, {payload}) => {
			const {data} = payload

			state.userNotesApiState.isFetching = false
			state.userNotesApiState.isSuccess = true
			state.userNotesApiState.message = "User Notes created successfully"

			state.notes = [...state.notes, data]
		},
		[createUserNotes.rejected]: state => {
			state.userNotesApiState.isFetching = false
			state.userNotesApiState.isError = true
			state.userNotesApiState.isSuccess = false
		},
		[createUserNotes.pending]: state => {
			state.userNotesApiState.isFetching = true
		},
		[updateUserNote.fulfilled]: (state, {payload}) => {
			state.userNotesApiState.isFetching = false
			state.userNotesApiState.isSuccess = true
			state.userNotesApiState.message = "Notes Updated successfully"
		},
		[updateUserNote.rejected]: state => {
			state.userNotesApiState.isError = true
			state.userNotesApiState.isFetching = false
		},
		[updateUserNote.pending]: state => {
			state.userNotesApiState.isFetching = true
		},
		[deleteUserNote.fulfilled]: (state, {payload}) => {
			const {id} = payload
			state.userNotesApiState.isFetching = false
			state.userNotesApiState.isSuccess = true
			state.notes = state.notes.filter(note => note.id !== id)
			state.userNotesApiState.message = "Note Deleted Successfully"
			return state
		},
		[deleteUserNote.rejected]: state => {
			state.userNotesApiState.isError = true
			state.userNotesApiState.message = "Something went wrong on deleting notes"
		},
		[deleteUserNote.pending]: state => {
			state.userNotesApiState.isFetching = true
		},
		[fetchUsersData.pending]: state => {
			state.usersApiState.isError = false
			state.usersApiState.isFetching = true
			state.usersApiState.isSuccess = false
		},
		[fetchUsersData.rejected]: state => {
			state.usersApiState.isFetching = false
			state.usersApiState.isError = true
			state.usersApiState.isSuccess = false
		},
		[fetchUsersData.fulfilled]: (state, {payload}) => {
			state.usersApiState.isFetching = false
			state.usersApiState.isSuccess = true
			state.users.data = payload.data
			state.users.total = payload.totalCount
		},
		[fetchBookingsByUserEmail.pending]: state => {
			state.userBookingsApiState.loading = true
		},
		[fetchBookingsByUserEmail.rejected]: state => {
			state.userBookingsApiState.isError = true
			state.userBookingsApiState.message = "Error retrieving bookings"
			state.userBookingsApiState.isSuccess = false
			state.userBookingsApiState.isFetching = false
		},
		[fetchBookingsByUserEmail.fulfilled]: (state, {payload}) => {
			state.userBookingsApiState.isFetching = false
			state.userBookingsApiState.isSuccess = true
			state.bookings = payload?.data
		},
		[fetchUsersByEmail.pending]: state => {
			state.userDetailsState.isFetching = true
		},
		[fetchUsersByEmail.rejected]: (state, {payload}) => {
			state.userDetailsState.isError = true
			state.userDetailsState.isFetching = false

			if (payload?.error) {
				state.userDetailsState.message = payload?.message
			} else {
				state.userDetailsState.message = "Something went wrong. Please try after sometime"
			}
		},
		[fetchUsersByEmail.fulfilled]: (state, {payload}) => {
			const {
				name,
				email,
				countryCode,
				phone,
				companyName,
				employerCode,
				disableCallCap,
				version,
				disable,
				surveyResults,
				tasks,
				companyCallCap,
				isDashboardViewEnabled
			} = payload.data

			state.results = surveyResults
			state.tasks = tasks

			state.profile = {
				name,
				email,
				countryCode,
				phone,
				companyName,
				employerCode,
				disabled: disable,
				version: version ? version : "AU",
				disableCallCap,
				companyCallCap,
				isDashboardViewEnabled: isDashboardViewEnabled
			}

			state.userDetailsState.isFetching = false
			state.userDetailsState.isSuccess = true
			state.userDetailsState.isError = false
		},

		[fetchUsersDataFiltered.pending]: state => {
			state.allUsersFilteredApiState.isFetching = true
			state.userDetailsState.isSuccess = false
			state.userDetailsState.isError = false
		},
		[fetchUsersDataFiltered.rejected]: (state, {payload}) => {
			state.allUsersFilteredApiState.isFetching = false
			state.allUsersFilteredApiState.isError = true
			state.allUsersFilteredApiState.message = payload?.message
		},
		[fetchUsersDataFiltered.fulfilled]: (state, {payload}) => {
			state.allUsersFilteredApiState.isFetching = false
			state.allUsersFilteredApiState.isSuccess = true
			state.allUsersFilteredApiState.data = payload.data
			state.allUsersFilteredApiState.total = payload.totalCount
		},
		[fetchUsersByCourse.fulfilled]: (state, {payload}) => {
			state.apiState.isFetching = false
			state.apiState.isSuccess = true
			state.users.data = payload.data
			state.users.total = payload.totalCount
		},
		[fetchUsersByCourse.rejected]: state => {},
		[fetchUsersByCourse.pending]: state => {
			state.apiState.isFetching = false
			state.apiState.isError = true
		},
		[notifyUserIntentToWork.pending]: state => {
			state.userNotificationState.isFetching = true
		},
		[notifyUserIntentToWork.rejected]: (state, {payload}) => {
			state.userNotificationState.isFetching = false
			state.userNotificationState.isError = true
			state.userNotificationState.message = "Something went wrong. Please try after sometime"
		},
		[notifyUserIntentToWork.fulfilled]: state => {
			state.userNotificationState.isFetching = false
			state.userNotificationState.isSuccess = true
			state.userNotificationState.message = "Notification sent successfully"
		},
		[createNewUser.pending]: state => {
			state.createNewUserApiState.isFetching = true
		},
		[createNewUser.rejected]: (state, {payload}) => {
			state.createNewUserApiState.isFetching = false
			state.createNewUserApiState.isError = true

			if (payload?.error) {
				if (payload?.errors?.length > 0) {
					state.createNewUserApiState.message = payload?.errors[0]?.stack
				} else {
					state.createNewUserApiState.message = payload?.message
				}
			} else {
				state.createNewUserApiState.message = "Something went wrong. Please try after sometime"
			}
		},
		[createNewUser.fulfilled]: state => {
			state.createNewUserApiState.isFetching = false
			state.createNewUserApiState.isSuccess = true
			state.createNewUserApiState.message = "User created successfully"
		},
		[fetchAvailableMigrationCodes.pending]: state => {
			state.userMigrationApiState.isFetching = true
		},
		[fetchAvailableMigrationCodes.fulfilled]: (state, {payload}) => {
			const {data} = payload
			state.userMigrationApiState.isFetching = false
			state.migrationCodes = data || []
		},
		[fetchAvailableMigrationCodes.rejected]: (state, {payload}) => {
			state.userMigrationApiState.isFetching = false
			state.userMigrationApiState.isError = true
			if (payload?.error) {
				state.userMigrationApiState.message = payload?.message
			} else {
				state.userMigrationApiState.message = "Something went wrong. Please try after sometime"
			}
		},
		[createUserMigration.pending]: state => {
			state.createUserMigrationApiState.isFetching = true
		},
		[createUserMigration.fulfilled]: (state, {payload}) => {
			const {data} = payload
			state.createUserMigrationApiState.isFetching = false
		},
		[createUserMigration.rejected]: (state, {payload}) => {
			state.createUserMigrationApiState.isFetching = false
			state.createUserMigrationApiState.isError = true
			if (payload?.error) {
				state.createUserMigrationApiState.message = payload?.message
			} else {
				state.createUserMigrationApiState.message = "Something went wrong. Please try after sometime"
			}
		},
		[fetchUserIntentHistory.pending]: state => {
			state.intentNotificationHistory.loading = true
		},
		[fetchUserIntentHistory.fulfilled]: (state, {payload}) => {
			state.userNotificationState.isFetching = false
			state.userNotificationState.isSuccess = true
			state.intentNotificationHistory.loading = false
			state.intentNotificationHistory.data = payload.data
			state.intentNotificationHistory.total = payload.totalCount
		},
		[fetchUserIntentHistory.rejected]: state => {
			state.userNotificationState.isFetching = false
			state.intentNotificationHistory.loading = false
			state.userNotificationState.isError = true
		}
	}
})

export const {
	clearUserState,
	clearUsersApiState,
	clearUserDetailsState,
	clearToggleDashUserState,
	clearUserNotificationState,
	clearNewUserApiState,
	clearUserNotesApiState,
	clearDisableUserState
} = userSlice.actions

export const userDataSelector = state => state.user

export default userSlice
