import styled from "styled-components"
import {P} from "@uprise/text"

export const HeaderText = styled.label`
	display: flex;
	justify-content: ${props => {
		if (props.alignText === "left") {
			return "flex-start"
		} else if (props.alignText === "center") {
			return "center"
		} else if (props.alignText === "flex-end") {
			return "flex-end"
		} else {
			return "center"
		}
	}};
`

export const ColumnText = styled(P)`
	display: flex;
	justify-content: ${props => props.alignText};
	cursor: ${props => (props.link ? "pointer" : null)};
	padding: 6px 0 6px 12px;
	margin: 0;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
`

export const ColumnIcon = styled.div`
	cursor: pointer;
`

export const ColumnContainer = styled.div`
	display: flex;
	justify-content: ${props => props.align};
`
