import styled from "styled-components"
import {P} from "@uprise/text"

export const SlotsContainer = styled.div`
 	width: auto;
	padding: 12px;
	border-left: 1px solid #edeafa;

	&:last-child {
		outline: none;
	}
}
`

export const SlotText = styled(P)`
	font-family: "Proxima Nova";
	margin: 0;
	padding: 0 5px;
	cursor: pointer;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	text-align: center;
	color: #7d60ff;
	&:hover {
		color: #ffffff;
	}
`

export const SlotTextContainer = styled.span`
	display: inline-block;
	border-radius: 5px;
	background-color: #f6f4fc;
	width: 70px;
	margin-right: 5px;
	margin-bottom: 4px;
	height: 24px;
	&:hover {
		background-color: #7d60ff;
		color: #ffffff;
	}
`
