import React from "react"

export const IconUsers = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
		<g fill='none' fillRule='evenodd'>
			<path d='M0 0h12v12H0z' />
			<path
				fill={props?.fill ? props?.fill : "#D5D5D8"}
				d='M4 4.914c1.048 0 2.002.437 2.715 1.122l.022.022.11.11-.132-.131a4.123 4.123 0 01.315.336l.037.044.104.134.036.049c.03.04.059.081.087.123l.048.073.053.083.054.09.005.01c.033.058.066.117.096.176l.036.072c.024.047.046.094.067.142l.026.06.049.118.03.081c.02.053.038.106.055.16l.016.05c.044.146.08.29.106.435l.011.061c.009.05.016.098.022.147l.007.057.003.028c.008.07.013.14.017.21L8 8.969v1.733a.728.728 0 01-.727.728H.727A.728.728 0 010 10.702V8.968l.005-.191C.109 6.74 1.858 4.914 4 4.914zm5.143-.228c1.46 0 2.666 1.139 2.836 2.455l.016.158.005.16v1.164a.519.519 0 01-.426.511l-.093.009H8.727v-.175l-.005-.21-.017-.222a4.876 4.876 0 00-1.526-3.06 2.867 2.867 0 011.964-.79zM4.028.57a1.846 1.846 0 110 3.69 1.846 1.846 0 010-3.689zm5.225 1.143a1.269 1.269 0 11.002 2.538 1.269 1.269 0 01-.002-2.538z'
			/>
		</g>
	</svg>
)
