import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"
import Styled from "styled-components"

// UI
import {Alert} from "@uprise/alert"
// Slices
import {clearWorkableState, clearDownloadedListData} from "../workableSlice"

const AlertWrap = Styled.div``

export const Alerts = ({className, uploadApiState, downloadRefferalListApiState}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (uploadApiState.isError || uploadApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearWorkableState())
			}, 3000)
		}

		if (downloadRefferalListApiState.isError || downloadRefferalListApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearDownloadedListData())
			}, 3000)
		}
	}, [uploadApiState, downloadRefferalListApiState])

	return (
		<AlertWrap className={className}>
			{downloadRefferalListApiState.isError && (
				<Alert type={"error"}>{downloadRefferalListApiState.message}</Alert>
			)}
			{downloadRefferalListApiState.isSuccess && (
				<Alert type={"success"}>{downloadRefferalListApiState.message}</Alert>
			)}
			{uploadApiState.isSuccess && <Alert type={"success"}>{uploadApiState.message}</Alert>}
			{uploadApiState.isError && <Alert type={"error"}>{uploadApiState.message}</Alert>}
		</AlertWrap>
	)
}
