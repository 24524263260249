import React from "react"

export const IconNewEmployer = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
		<g fill='none' fillRule='evenodd'>
			<path d='M0 0h12v12H0z' />
			<path
				fill={props?.fill ? props?.fill : "#D5D5D8"}
				d='M6 0a6 6 0 110 12A6 6 0 016 0zm0 2.25a.75.75 0 00-.75.75v2.249L3 5.25a.75.75 0 000 1.5h2.25V9a.75.75 0 001.5 0V6.75H9a.75.75 0 000-1.5H6.75V3A.75.75 0 006 2.25z'
			/>
		</g>
	</svg>
)
