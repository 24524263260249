import React, {Fragment, useState, useEffect} from "react"
import moment from "moment-timezone"
import {useHistory} from "react-router-dom"
import {useForm, Controller} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"

// UI
import {Container, Row, Col} from "@uprise/grid"
import {H5} from "@uprise/headings"
// Constants
import COUNTRY_CODES from "constants/countrycodes"
// Components
import DropDown from "components/shared/DropDown"
// Slices
import {createBooking} from "features/Bookings/bookingSlice"
import {bookingDataSelector} from "features/Bookings/bookingSlice"
import {
	Text,
	Icon,
	HeaderInfoContainer,
	BookingDetails,
	ChangeButton,
	FormContainer,
	InfoTitle,
	FormElementContainer,
	FormElementLabel,
	FormInputContainer,
	FormTextInput,
	SubmitButton,
	ButtonContainer,
	PhoneInputWrapper
} from "./index.style"
import {MessageStyles} from "../../../../shared/Form/index.style"

const ConfirmBooking = ({coach, duration, timeslot, setTime, setSelectedCoach, timeZone, loading}) => {
	const [token, setToken] = useState(null)
	const [success, setSuccess] = useState(false)

	const {control, handleSubmit, errors} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onSubmit",
		reValidateMode: "onChange"
	})

	const history = useHistory()
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()

	const {createBookingApiState} = useSelector(bookingDataSelector)

	useEffect(() => {
		const _getToken = async () => {
			const token = await getAccessTokenSilently()
			setToken(token)
		}
		_getToken()
	}, [])

	const _handleConfirmBooking = value => {
		let data = {
			name: value.userName,
			phone: value.phoneNumber,
			countryCode: value.countryCode.label,
			countryName: value.countryCode.value,
			employerCode: value.employerCode,
			clientEmail: value.userEmail,
			coachId: coach.id,
			purpose: "Therapy",
			startTime: timeslot.start,
			callDuration: duration
		}

		dispatch(createBooking({data, token}))
	}

	return (
		<Fragment>
			<Container>
				<H5 className='m-b-2'>Please confirm details below before booking</H5>

				<HeaderInfoContainer>
					<BookingDetails>
						<FormContainer>
							<Icon alt={""} src={require("assets/images/icons/svg/user.svg")} />

							<FormContainer>
								<InfoTitle>Coach Name:</InfoTitle>
								<Text>{coach?.name}</Text>
							</FormContainer>
						</FormContainer>

						<FormContainer>
							<Icon src={require("assets/images/icons/svg/date-add.svg")} />
							<FormContainer>
								<InfoTitle>Booking Date:</InfoTitle>
								<Text>{moment(timeslot?.start).format("dddd - Do MMM, YYYY")}</Text>
							</FormContainer>
						</FormContainer>

						<FormContainer>
							<Icon src={require("assets/images/icons/svg/time.svg")} />
							<FormContainer>
								<InfoTitle>Booking Time:</InfoTitle>
								<Text>
									{/* {moment(time.start).tz(timeZone).format("dddd - Do MMM, YYYY hh:mm")} -{" "} */}
									{moment(timeslot.start).tz(timeZone).format("hh:mm a")}
								</Text>
							</FormContainer>{" "}
							&nbsp; <Text>{moment().tz(timeZone).format("z")}</Text>
						</FormContainer>
					</BookingDetails>

					<ChangeButton
						title={"Change"}
						data-testid='changeButton'
						variant={"primary"}
						width={"150px"}
						onClick={() => {
							setSelectedCoach(null)
							setTime(null)
							history.push("/bookings/new")
						}}
					/>
				</HeaderInfoContainer>

				<form
					onSubmit={handleSubmit(data => {
						_handleConfirmBooking(data)
					})}>
					<Row>
						<Col className='col-12 col-lg-12 col-md-6 col-sm-12'>
							<H5>Enter User Details</H5>
						</Col>
						<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
							<FormElementContainer error={errors && errors.userEmail?.type == "required"}>
								<FormElementLabel isRequired={true}>User Email</FormElementLabel>
								<FormInputContainer>
									<Controller
										name={"userEmail"}
										data-testid='userEmail'
										control={control}
										rules={{required: true}}
										defaultValue=''
										as={<FormTextInput type='text' placeholder='User Email' />}
										rules={{
											required: {
												value: true,
												message: "Email Address is required"
											},
											pattern: {
												value: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
												message: "Email address must be a valid email"
											},
											validate: {
												value: value => {
													let strings = value
													let invalid = false

													for (let i = 0, l = strings.length; i < l; i++) {
														let character = strings.charAt(i)
														if (
															!isNaN(character * 1) ||
															character === character.toLowerCase()
														) {
															continue
														} else {
															if (character === character.toUpperCase()) {
																invalid = true
															}
														}
													}

													return invalid ? "Email address must be lowercase" : true
												}
											}
										}}
									/>
								</FormInputContainer>
							</FormElementContainer>
							{errors?.userEmail?.message && (
								<MessageStyles htmlFor={1} focused={false} validation={errors?.userEmail}>
									{errors?.userEmail?.message}
								</MessageStyles>
							)}

							<FormElementContainer error={errors && errors.userName?.type == "required"}>
								<FormElementLabel isRequired={true}>User Name</FormElementLabel>
								<FormInputContainer>
									<Controller
										name={"userName"}
										data-testid='userName'
										control={control}
										rules={{required: true}}
										defaultValue=''
										rules={{
											required: {
												value: true,
												message: "Username is required"
											},
											maxLength: {
												value: 100,
												message: "Username can be a maximum of 100 characters"
											}
										}}
										as={<FormTextInput type='text' placeholder='User Name' />}
									/>
								</FormInputContainer>
							</FormElementContainer>

							{errors?.userName?.message && (
								<MessageStyles htmlFor={1} focused={false} validation={errors?.userName}>
									{errors?.userName?.message}
								</MessageStyles>
							)}

							<FormElementContainer error={errors && errors.phoneNumber?.type == "required"}>
								<FormElementLabel isRequired={true}>Phone Number</FormElementLabel>
								<div style={{flexBasis: "60%", marginTop: "5px"}}>
									<PhoneInputWrapper>
										<Controller
											name={"countryCode"}
											control={control}
											defaultValue={{
												label: COUNTRY_CODES[12].label,
												value: COUNTRY_CODES[12].value
											}}
											render={props => (
												<DropDown
													variant='primary'
													className='m-l-10'
													title={"Country code"}
													items={COUNTRY_CODES}
													label='Select Code'
													onChange={value => {
														props.onChange(value)
													}}
													initialSelectedItem={{
														label: COUNTRY_CODES[12].label,
														value: COUNTRY_CODES[12].label
													}}
													rules={{
														required: {
															value: true,
															message: "Country code is required"
														}
													}}
													menuStyle={{position: "absolute", zIndex: 2}}
													containerStyle={{maringRight: "10px", width: "100px"}}
													customListItemRender={item => (
														<div style={{display: "flex", justifyContent: "space-between"}}>
															<p>{item.name}</p>
															<p>{item.label}</p>
														</div>
													)}
												/>
											)}
										/>

										<Controller
											name={"phoneNumber"}
											control={control}
											rules={{required: true}}
											defaultValue=''
											rules={{
												required: {
													value: true,
													message: "Phone Number is required"
												},
												pattern: {
													value: /\d/g,
													message: "Phone number should be valid"
												},
												maxLength: {
													value: 15,
													message: "Phone Number can be a maximum of 15 characters"
												}
											}}
											render={props => (
												<FormTextInput
													type='text'
													data-testid='phoneNumber'
													onChange={value => props.onChange(value)}
													placeholder='Phone Number'
													style={{marginTop: "35px", marginLeft: "10px"}}
												/>
											)}
										/>
									</PhoneInputWrapper>
								</div>
							</FormElementContainer>

							{errors?.countryCode?.message && (
								<MessageStyles htmlFor={1} focused={false} validation={errors?.countryCode}>
									{errors?.countryCode?.message}
								</MessageStyles>
							)}

							{errors?.phoneNumber?.message && (
								<MessageStyles htmlFor={1} focused={false} validation={errors?.phoneNumber}>
									{errors?.phoneNumber?.message}
								</MessageStyles>
							)}

							<FormElementContainer error={errors && errors.employerCode?.type == "required"}>
								<FormElementLabel isRequired={true}>Access Code</FormElementLabel>
								<FormInputContainer>
									<Controller
										name={"employerCode"}
										data-testid='employerCode'
										control={control}
										rules={{required: true}}
										defaultValue=''
										rules={{
											required: {
												value: true,
												message: "Access Code is required"
											},
											maxLength: {
												value: 100,
												message: "Access Code can be a maximum of 100 characters"
											}
										}}
										as={<FormTextInput type='text' placeholder='Access Code' />}
									/>
								</FormInputContainer>
							</FormElementContainer>

							{errors?.accessCode?.message && (
								<MessageStyles htmlFor={1} focused={false} validation={errors?.accessCode}>
									{errors?.accessCode?.message}
								</MessageStyles>
							)}
						</Col>
					</Row>
					<Row className='py-4'>
						<ButtonContainer>
							<SubmitButton
								type={"submit"}
								data-testid='createBookingButton'
								variant={"primary"}
								title={"Create Booking"}
								isLoading={createBookingApiState.isFetching}
							/>
						</ButtonContainer>
					</Row>
				</form>
			</Container>
		</Fragment>
	)
}

export default ConfirmBooking
