import React from "react"
import PropTypes from "prop-types"

// Colors
import {backgrounds} from "@uprise/colors"
import {ContainerFluid, Row} from "@uprise/grid"

const Layout = ({children, backgroundColor, containerClassName, rowClassName}) => {
	return (
		<ContainerFluid height='100%' backgroundColor={backgroundColor}>
			<Row className={rowClassName}>{children}</Row>
		</ContainerFluid>
	)
}

Layout.propTypes = {
	backgroundColor: PropTypes.string,
	containerClassName: PropTypes.string,
	rowClassName: PropTypes.string
}

Layout.defaultProps = {
	backgroundColor: backgrounds.fadedPurple,
	rowClassName: "flex-nowrap"
}

export default Layout
