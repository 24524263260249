import styled from "styled-components"
import {Button} from "@uprise/button"
import {H2, H6} from "@uprise/headings"
import {extended, semantic} from "@uprise/colors"
import {P} from "@uprise/text"

export const HeaderTableContent = styled.div`
	display: flex;
`

export const HomeImage = styled.img`
	margin: auto;
`

export const HomeTitle = styled(H2)`
	text-align: center;
	margin: 12px;
`
export const EmployerHomeDescription = styled(P)`
	margin: 12px;
	text-align: center;
`

export const EmployerButton = styled(Button)`
	height: 32px;
	margin: auto 12px auto auto;
	padding: 7px 12px;
`

export const FormLevelContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`

export const TabsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	z-index: 0;
`

export const Tabs = styled.div`
	display: flex;
	flex-direction: row;
	box-shadow: 3px 4px 11px -1px rgb(219 221 227 / 50%);
	border-radius: 10px;
	border-bottom-right-radius: 0;
	width: auto;
`

export const Tab = styled.div`
	display: flex;
	padding: 0px 25px 0px 25px;
	min-height: 100px;
	justify-content: center;
	border-radius: 10px 10px 0 0;
	border-bottom-right-radius: 0;
	cursor: pointer;
	flex-direction: column;
	background-color: ${props => (props.active ? "#edeafa" : "")};
`
export const TabLabel = styled.div`
	position: relative;
	text-align: center;
	margin-top: 15px;
	display: flex;
	justify-content: center;

	&::after {
		content: "";
		position: absolute;
		background-color: #c1c0c5;
		top: 0;
		bottom: 0;
		left: ${props => (!props.firstChild ? 0 : "50%")};
		height: 1px;
		top: ${props => (props.active ? "-30%" : "-35%")};
	}
`

export const ItemContainer = styled.div`
	background-color: #fff;
	width: 100%;
	border-radius: 10px;
	border-top-left-radius: 0;
	z-index: 2;
	position: relative;
`
export const FormItem = styled.div`
	display: ${props => (props.active ? "block" : "none")};
`

export const UserNotFoundDescription = styled(P)`
	margin: 12px;
	text-align: center;
`
export const UserListButton = styled(Button)`
	width: 50%;
	margin: auto;
`

export const NoDataDiv = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
`
export const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: ${props => (props.error ? `1px solid ${semantic.error}` : `1px solid ${extended.purple.five}`)};
	padding: 8px 0;
`

export const FormElementLabel = styled(H6)`
	margin: 10px 0 10px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`
export const FormInputContainer = styled.div`
	flex-basis: 75%;
	position: relative;
	align-self: center;
	margin-bottom: ${props => (props.error ? '8px' : '0px')};
	
	> div {
		min-height: 0px;
	}
	input {
		font-family: "Proxima Nova";
	}
`
