import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import moment from "moment"
import {Container, Grid, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H4, H6, H5} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {useForm, Controller, useFieldArray} from "react-hook-form"
import {backgrounds, extended, primary, semantic} from "@uprise/colors"

import Datepicker from "components/shared/DatePicker"
import Radio from "components/shared/Radio"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const NumberInputContainer = styled(FormElementContainer)`
	width: unset;
`

const CheckBoxFormElementContainer = styled.div`
	width: 60%;
	display: flex;
	margin: 10px 0 10px auto;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #edeafa;
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormElementPlanLabel = styled(H6)`
	margin: 16px 0 8px 0;
	text-align: left;
	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputInlineContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-basis: 60%;
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	position: relative;
	align-self: center;
	font-family: ${props => (props.weight === "bold" ? "Proxima Nova Semibold" : "Proxima Nova")};
	color: #6d6c79;
`

const FormTextInput = styled(TextInputHorizontal)`
	width: 80px;
	height: 24px;
	padding: 2px 2px 2px 8px;
	border-radius: 4px;
	border: solid 1px #d4cbfb;
`

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const PrimaryButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
	outline: none;
`

const SecondaryButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
	border-color: transparent;
	background-color: #f6f4fc;
	outline: none;
`

const NavigationButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
`

const FormSpan = styled.span``

const FormSpanText = styled(P)`
	margin: 0 0 0 10px;
	color: unset;
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.35;
	color: #6d6c79;
	font-family: ${props => (props.weight === "bold" ? "Proxima Nova Semibold" : "Proxima Nova")};
	margin-right: 20px;
`

const FormSpanLabel = styled.label`
	display: flex;
	padding: 10px;
	padding-left: 0;
`

const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`

const TextInputWrap = styled.div`
	width: 60%;
	margin-left: 40%;
	display: flex;
`

const ResetCaps = ({startingDate, lastUpdated, capDetails, onComplete, onCancel, code, planData}) => {
	const {register, control, handleSubmit, watch, reset, trigger, setError, errors, setValue, shouldValidate} =
		useForm({
			// defaultValues: { }, //you can populate the fields by this attribute
			mode: "onSubmit",
			reValidateMode: "onChange"
		})

	const callCapPeriod = capDetails.callCapPeriod
	useEffect(() => {
		const latestCaps = callCapPeriod[callCapPeriod.length - 1]
		if (latestCaps.combinedBookingCaps > 0) setValue("coachingType", "combined", {shouldValidate: true})
		else setValue("coachingType", "individual", {shouldValidate: true})
	}, [])

	const watchSessionCap = watch(["coachingType"])

	return (
		<Container>
			<form
				onSubmit={handleSubmit(formData => {
					const data = {
						code,
						data: {
							caps: {
								capCalls: true,
								callCapPeriod: [
									...capDetails.callCapPeriod,
									{
										startingDate: formData.startingDate.toISOString(),
										combinedBookingCaps: parseInt(formData.combinedBookingCaps) || 0,
										therapyBookingCaps: parseInt(formData.therapyBookingCaps) || 0,
										coachingBookingCaps: parseInt(formData.coachingBookingCaps) || 0,
										updatedAt: moment().toISOString()
									}
								]
							}
						}
					}
					onComplete(data)
				})}>
				<Row>
					<Col className='col-12 col-lg-12 col-md-12 col-sm-12'>
						{ callCapPeriod?.length > 1 &&
							<FormElementContainer>
								<FormElementLabel isRequired={false}>Last Reset</FormElementLabel>
								<FormInputContainer className='text-left' isRequired={false} weight={"bold"}>
									{moment(callCapPeriod?.[callCapPeriod?.length - 1]?.updatedAt).format("DD-MM-YYYY")}
								</FormInputContainer>
							</FormElementContainer>
						} 

						<FormElementContainer error={errors.coachingType?.type === "required"}>
							<FormElementLabel isRequired={true}>Session Cap</FormElementLabel>
							<FormInputInlineContainer>
								<Controller
									name={"coachingType"}
									control={control}
									rules={{required: "Session Cap is required"}}
									defaultValue={"combined"}
									render={props => (
										<>
											<Radio
												checked={watchSessionCap.coachingType === "combined"}
												label={"Total Calls"}
												value={"combined"}
												onChange={e =>
													setValue("coachingType", "combined", {shouldValidate: true})
												}
												name={"coachingType"}
											/>
											<Radio
												checked={watchSessionCap.coachingType === "individual"}
												label={"Coaching and Therapy"}
												value={"individual"}
												onChange={e =>
													setValue("coachingType", "individual", {shouldValidate: true})
												}
												name={"coachingType"}
												width={"250px"}
											/>
										</>
									)}
								/>
							</FormInputInlineContainer>
						</FormElementContainer>

						{errors["coachingType"] && errors["coachingType"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["coachingType"]}>
								{errors["coachingType"] && errors["coachingType"].message}
							</MessageStyles>
						) : null}

						{watchSessionCap.coachingType === "combined" ? (
							<TextInputWrap>
								<NumberInputContainer error={errors.combinedBookingCaps?.type === "required"}>
									<FormSpanLabel>
										<Controller
											name={"combinedBookingCaps"}
											control={control}
											rules={{
												required: {
													value: true,
													message: "Sessions is required"
												},
												min: {
													value: 0,
													message: "Sessions should be at least 0"
												},
												maxLength: {
													value: 5,
													message: "Sessions cant exceed 99,999"
												}
											}}
											defaultValue={
												callCapPeriod?.[callCapPeriod?.length - 1]?.combinedBookingCaps
											}
											as={<FormTextInput type='number' />}
										/>
										<FormSpan>
											<FormSpanText fontSize='15px' weight={"bold"}>
												sessions
											</FormSpanText>
										</FormSpan>
									</FormSpanLabel>
								</NumberInputContainer>

								{errors["combinedBookingCaps"] && errors["combinedBookingCaps"].message ? (
									<MessageStyles
										htmlFor={1}
										focused={false}
										validation={errors["combinedBookingCaps"]}>
										{errors["combinedBookingCaps"] && errors["combinedBookingCaps"].message}
									</MessageStyles>
								) : null}
							</TextInputWrap>
						) : (
							<>
								<TextInputWrap>
									<NumberInputContainer error={errors.coachingBookingCaps?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"coachingBookingCaps"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Coaching is required"
													},
													min: {
														value: 0,
														message: "Coaching sessions should be at least 0"
													},
													maxLength: {
														value: 5,
														message: "Coaching sessions cant exceed 99,999"
													}
												}}
												defaultValue={
													callCapPeriod?.[callCapPeriod?.length - 1]?.coachingBookingCaps
												}
												as={<FormTextInput type='number' />}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													coaching
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>

									<NumberInputContainer error={errors.therapyBookingCaps?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"therapyBookingCaps"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Therapy is required"
													},
													min: {
														value: 0,
														message: "Therapy sessions should be at least 0"
													},
													maxLength: {
														value: 5,
														message: "Therapy sessions cant exceed 99,999"
													}
												}}
												defaultValue={
													callCapPeriod?.[callCapPeriod?.length - 1]?.therapyBookingCaps
												}
												as={<FormTextInput type='number' />}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													therapy
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>
								</TextInputWrap>

								{errors["coachingBookingCaps"] && errors["coachingBookingCaps"].message ? (
									<MessageStyles
										htmlFor={1}
										focused={false}
										validation={errors["coachingBookingCaps"]}>
										{errors["coachingBookingCaps"] && errors["coachingBookingCaps"].message}
									</MessageStyles>
								) : null}

								{errors["therapyBookingCaps"] && errors["therapyBookingCaps"].message ? (
									<MessageStyles
										htmlFor={1}
										focused={false}
										validation={errors["therapyBookingCaps"]}>
										{errors["therapyBookingCaps"] && errors["therapyBookingCaps"].message}
									</MessageStyles>
								) : null}
							</>
						)}
						<FormElementContainer error={errors.startingDate?.type === "required"}>
							<FormElementLabel isRequired={true}>Starting Date</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"startingDate"}
									control={control}
									rules={{required: "Starting date is required"}}
									defaultValue={moment(callCapPeriod?.[callCapPeriod?.length - 1]?.startingDate)}
									render={props => {
										return (
											<Datepicker
												//isFirst={true}
												date={props.value}
												onChange={e => props.onChange(e.target.value)}
												isLast={true}
											/>
										)
									}}
								/>
							</FormInputContainer>
						</FormElementContainer>

						{errors["startingDate"] && errors["startingDate"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["startingDate"]}>
								{errors["startingDate"] && errors["startingDate"].message}
							</MessageStyles>
						) : null}

						{moment().isAfter(moment(callCapPeriod?.[callCapPeriod?.length - 1]?.startingDate)) ? null : (
							<MessageStyles validation={true}>
								Caps can be reset again after{" "}
								{moment(callCapPeriod?.[callCapPeriod?.length - 1]?.startingDate).format("Do MMM YY")}
							</MessageStyles>
						)}
					</Col>
				</Row>
				<Row className='flex-row-reverse'>
					<ButtonContainer>
						<PrimaryButton
							type='submit'
							variant={"primary"}
							title={"Reset"}
							disabled={
								!moment().isAfter(moment(callCapPeriod?.[callCapPeriod?.length - 1]?.startingDate))
							}
						/>
					</ButtonContainer>
					<ButtonContainer>
						<SecondaryButton
							onClick={e => {
								e.preventDefault()
								onCancel()
							}}
							variant={"secondary"}
							title={"Cancel"}
						/>
					</ButtonContainer>
				</Row>
			</form>
		</Container>
	)
}

export default ResetCaps
