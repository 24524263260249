import React from "react"
import {useHistory} from "react-router-dom"

import PropTypes from "prop-types"

import Layout from "../../shared/Layout"
import {LeftPanel, Main, Header} from "../../shared/Panels"
import LeftMenu from "../../shared/Menus/Left"

import {Wrap} from "./index.style"

function map(children, func) {
	let index = 0
	return React.Children.map(children, child => (React.isValidElement(child) ? child : null))
}

const ComponentWrapper = ({
	children,
	menuActiveIndex,
	subMenuActiveIndex,
	headerTitle,
	headerRightAlignContent,
	backBtn,
	justifyHeader
}) => {
	const history = useHistory()
	const _handleBack = () => {
		history.goBack()
	}
	return (
		<Layout>
			<LeftPanel width={"200px"}>
				<LeftMenu activeId={menuActiveIndex} subMenuActiveId={subMenuActiveIndex} />
			</LeftPanel>
			<Wrap>
				<Header
					title={headerTitle}
					textAlign='center'
					width='100%'
					back={backBtn}
					justify={justifyHeader}
					handleBack={_handleBack}
					hideMobile={true}
					rightAlign={true}
					rightAlignContent={headerRightAlignContent}
				/>
				<Main padding={"40px"}>{map(children)}</Main>
			</Wrap>
		</Layout>
	)
}

export default ComponentWrapper

ComponentWrapper.propTypes = {
	menuActiveIndex: PropTypes.number,
	subMenuActiveIndex: PropTypes.number || undefined,
	// children: PropTypes.element || PropTypes.any,  "can now be array of nodes instead of just one"
	headerTitle: PropTypes.string,
	headerRightAlignContent: PropTypes.element
}

ComponentWrapper.defaultProps = {
	menuActiveIndex: 0,
	children: null,
	headerTitle: "",
	headerRightAlignContent: null
}
