import React from "react"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import {createBrowserHistory} from "history"

// Login
import Login from "./features/Auth"
// Risk callbacks
import RiskCallBacks from "./features/RiskCallBacks"
// Employers
import NewEmployer from "./features/Employers/NewEmployers"
import NewEmployerForm from "./features/Employers/NewEmployers/Forms"
import AllEmployer from "./features/Employers/AllEmployers"
import EmployerDetail from "./features/Employers/EmployerDetail"
// Bookings
import AllBookingsTable from "./features/Bookings/AllBookingsTable"
import CreateBooking from "./features/Bookings/CreateBooking"
import BookingResults from "./features/Bookings/CreateBooking/BookingResults"
import CreateBackDateBooking from "./features/Bookings/CreateBackDateBooking"
// Coaches
import AllCoaches from "./features/Coaches/AllCoaches"
import NewCoaches from "./features/Coaches/NewCoaches"
import CoachDetails from "./features/Coaches/CoachDetails"
import EditCoach from "./features/Coaches/EditCoach"
import Coaches from "./features/Coaches"
// Users
import AllUsers from "./features/Users/AllUsers"
import UserDetails from "./features/Users/UserDetails"
import AllUsersFiltered from "./features/Users/AllUsersFiltered"
import NewUser from "./features/Users/NewUser"

// Referrals
import Referrals from "./features/Workable/Referrals"

//Settings
import EAPCenter from "./features/Settings/EAPCenter"
// Helpers
import PrivateRoute from "./helpers/PrivateRoute"

export const history = createBrowserHistory()

const App = () => {
	return (
		<Router>
			<Switch>
				{/* Login */}
				<Route exact path='/login' render={() => <Login />} />
				{/* Risk callbacks */}
				<PrivateRoute exact path='/risk-callbacks' component={RiskCallBacks} />
				{/* Employers */}
				<PrivateRoute exact path='/employers/new' component={NewEmployer} />
				<PrivateRoute exact path='/employers/new/form' component={NewEmployerForm} />
				<PrivateRoute exact path='/employers/all' component={AllEmployer} />
				<PrivateRoute exact path='/employer/:code' component={EmployerDetail} />
				{/* Coaches */}
				<PrivateRoute exact path='/coaches' component={Coaches} />
				<PrivateRoute exact path='/coaches/all' component={AllCoaches} />
				<PrivateRoute exact path='/coaches/new' component={NewCoaches} />
				<PrivateRoute exact path='/coaches/:id/edit' component={EditCoach} />
				<PrivateRoute exact path='/coaches/:id' component={CoachDetails} />
				{/* Bookings */}
				<PrivateRoute exact path='/bookings/all' component={AllBookingsTable} />
				<PrivateRoute path='/bookings/new' component={CreateBooking} />
				<PrivateRoute path='/bookings/results' component={BookingResults} />
				<PrivateRoute path='/bookings/back' component={CreateBackDateBooking} />
				{/* Users */}
				<PrivateRoute path='/users/all' component={AllUsers} />
				<PrivateRoute path='/users/code/:employerCode' component={AllUsersFiltered} />
				<PrivateRoute exact path='/users/new' component={NewUser} />
				<PrivateRoute path='/users/:email' component={UserDetails} />
				{/* Workable */}
				<PrivateRoute exact path='/workable/referrals' component={Referrals} />

				<PrivateRoute exact path='/settings/eap-center' component={EAPCenter} />
				{/* Default */}
				<PrivateRoute exact path='/' component={AllBookingsTable} />
			</Switch>
		</Router>
	)
}

export default App
