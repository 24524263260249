import styled from "styled-components"
import {Button} from "@uprise/button"
import {primary} from "@uprise/colors"
import {H6} from "@uprise/headings"
import {P} from "@uprise/text"

export const HeaderTableContent = styled.div`
	display: flex;
`

export const Link = styled(P)`
	color: ${primary.purple};
	cursor: pointer;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #f8f8ff;
	border-radius: 10px;
`
export const TableContainer = styled.div`
	border-radius: 10px;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #ffffff;
`

export const ColumnContainer = styled.label`
	display: flex;
	justify-content: ${props => props.alignText};
`

export const ColumnText = styled(P)`
	font-size: 15px;
`

export const FilterDropdownContainer = styled.div``

export const FilterDropdownMenu = styled.ul`
	list-style-type: none;
	padding: 0;
`

export const FilterDropdownMenuItem = styled.li`
	display: flex;
	padding: 4px 12px;
	text-align: left;
	list-style-type: none;
	font-family: "Proxima Nova";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	background-color: #fff;
	color: #9796a0;

	&:last-child {
		border-bottom: 0;
	}
`

export const FilterDropdownMenuItemLabel = styled.label`
	display: flex;
`

export const FilterDropdownHeaderText = styled(P)`
	text-align: left;
	margin: 0 0 0 12px;
	font-family: "Proxima Nova";
	font-size: 12px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #9796a0;
`

export const FilterDropdownMenuItemSpanText = styled(P)`
	font-family: "Proxima Nova";
	margin: 0 0 0 20px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #6d6c79;
`

export const SearchInputContainer = styled.div`
	position: relative;
	display: flex;
`

export const SearchInputfigure = styled.figure`
	position: absolute;
	top: 7px;
	margin: 0;
`

export const SearchInputImage = styled.img``

export const SearchInput = styled.input`
	width: 240px;
	height: 32px;
	padding: 0 20px 0 32px;
	align-self: center;
	margin-right: 24px;
	border: 0;
	border-bottom: 1px solid ${primary.purple};
	&:focus {
		outline: none;
	}
`

export const ContactContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

export const ElementLabel = styled(H6)`
	margin: 16px 0 8px 0;
`

export const ContactValueContainer = styled.div`
	flex-basis: 60%;
	position: relative;
	align-self: center;
`

export const ModalButtonContainer = styled.div`
	display: flex;
`

export const ModalButton = styled(Button)`
	margin: 5px;
`
