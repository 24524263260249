export const features = [
	{
		label: "Set up",
		id: "setup",
		enabled: true
	},
	{
		label: "Coaching Sessions",
		id: "coaching-sessions",
		enabled: true
	},
	{
		label: "Risk Callback",
		id: "risk-callback",
		enabled: true
	},
	{
		label: "Family Entitlement",
		id: "family-entitlement",
		enabled: true
	},
	{
		label: "Care Navigator",
		id: "care-navigator",
		enabled: true
	}
]

export const companyIndustry = [
	{
		label: "Advertising",
		value: "Advertising"
	},
	{
		label: "Agriculture",
		value: "Agriculture"
	},
	{
		label: "Apparel",
		value: "Apparel"
	},
	{
		label: "Association",
		value: "Association"
	},
	{
		label: "Banking",
		value: "Banking"
	},
	{
		label: "Biotechnology",
		value: "Biotechnology"
	},
	{
		label: "Cannabis",
		value: "Cannabis"
	},
	{
		label: "Charity Organization",
		value: "Charity Organization"
	},
	{
		label: "Chemicals",
		value: "Chemicals"
	},
	{
		label: "Cities/Counties",
		value: "Cities/Counties"
	},
	{
		label: "Communications",
		value: "Communications"
	},
	{
		label: "Community Services",
		value: "Community Services"
	},
	{
		label: "Construction",
		value: "Construction"
	},
	{
		label: "Consulting",
		value: "Consulting"
	},
	{
		label: "Education",
		value: "Education"
	},
	{
		label: "Electronics",
		value: "Electronics"
	},
	{
		label: "Employment Staffing Services",
		value: "Employment Staffing Services"
	},
	{
		label: "Energy",
		value: "Energy"
	},
	{
		label: "Engineering",
		value: "Engineering"
	},
	{
		label: "Entertainment",
		value: "Entertainment"
	},
	{
		label: "Environmental",
		value: "Environmental"
	},
	{
		label: "Finance",
		value: "Finance"
	},
	{
		label: "First Responder",
		value: "First Responder"
	},
	{
		label: "Food & Beverage",
		value: "Food & Beverage"
	},
	{
		label: "Government",
		value: "Government"
	},
	{
		label: "Healthcare",
		value: "Healthcare"
	},
	{
		label: "Healthcare Sharing Ministry",
		value: "Healthcare Sharing Ministry"
	},
	{
		label: "Hospitality",
		value: "Hospitality"
	},
	{
		label: "Industrial",
		value: "Industrial"
	},
	{
		label: "Insurance",
		value: "Insurance"
	},
	{
		label: "Labor & Trust",
		value: "Labor & Trust"
	},
	{
		label: "Law Enforcement",
		value: "Law Enforcement"
	},
	{
		label: "Law Firm",
		value: "Law Firm"
	},
	{
		label: "Machinery",
		value: "Machinery"
	},
	{
		label: "Maintenance",
		value: "Maintenance"
	},
	{
		label: "Manufacturing",
		value: "Manufacturing"
	},
	{
		label: "Media",
		value: "Media"
	},
	{
		label: "Mining",
		value: "Mining"
	},
	{
		label: "Not For Profit",
		value: "Not For Profit"
	},
	{
		label: "Other",
		value: "Other"
	},
	{
		label: "Personal Services",
		value: "Personal Services"
	},
	{
		label: "Public Service",
		value: "Public Service"
	},
	{
		label: "Real Estate / Property Mgmt",
		value: "Real Estate / Property Mgmt"
	},
	{
		label: "Recreation",
		value: "Recreation"
	},
	{
		label: "Religious Organization",
		value: "Religious Organization"
	},
	{
		label: "Residential/Retirement Home",
		value: "Residential/Retirement Home"
	},
	{
		label: "Restoration",
		value: "Restoration"
	},
	{
		label: "Retail",
		value: "Retail"
	},
	{
		label: "Shipping",
		value: "Shipping"
	},
	{
		label: "Technology",
		value: "Technology"
	},
	{
		label: "Telecommunications",
		value: "Telecommunications"
	},
	{
		label: "Third Party Administrator",
		value: "Third Party Administrator"
	},
	{
		label: "Transportation",
		value: "Transportation"
	},
	{
		label: "Tribal Government",
		value: "Tribal Government"
	},
	{
		label: "Utilities",
		value: "Utilities"
	}
]

export const accountManager = [
	{
		label: "Marta Vega",
		value: "Marta Vega"
	},
	{
		label: "Tanya Baertsch",
		value: "Tanya Baertsch"
	},
	{
		label: "Chris Heady",
		value: "Chris Heady"
	},
	{
		label: "Billy Bourne",
		value: "Billy Bourne"
	},
	{
		label: "Yi Chen",
		value: "Yi Chen"
	},
	{
		label: "Nadia Wilder",
		value: "Nadia Wilder"
	},
	{
		label: "Keely Arrington",
		value: "Keely Arrington"
	}
]

export const salesManager = [
	{
		label: "Mikhail (Kumo) Koumoudouros",
		value: "Mikhail (Kumo) Koumoudouros"
	},
	{
		label: "Joshua Jaklevick",
		value: "Joshua Jaklevick"
	},
	{
		label: "Kim Tubetskoy",
		value: "Kim Tubetskoy"
	},
	{
		label: "Laurie Baron",
		value: "Laurie Baron"
	},
	{
		label: "Ted McVickers",
		value: "Ted McVickers"
	},
	{
		label: "Marta Vega",
		value: "Marta Vega"
	},
	{
		label: "Tanya Baertsch",
		value: "Tanya Baertsch"
	},
	{
		label: "Chris Heady",
		value: "Chris Heady"
	},
	{
		label: "Billy Bourne",
		value: "Billy Bourne"
	},
	{
		label: "Yi Chen",
		value: "Yi Chen"
	},
	{
		label: "Nadia Wilder",
		value: "Nadia Wilder"
	},
	{
		label: "Keely Arrington",
		value: "Keely Arrington"
	},
	{
		label: "Margaret Bond",
		value: "Margaret Bond"
	},
	{
		label: "Trisha Litzenberg",
		value: "Trisha Litzenberg"
	}
]

export const australianStates = [
	{
		label: "new south wales",
		value: "new_south_wales",
		abbreviation: "nsw",
		capital: "sydney",
		type: "state"
	},
	{
		label: "northern territory",
		value: "northern_territory",
		abbreviation: "nt",
		capital: "darwin",
		type: "territory"
	},
	{
		label: "queensland",
		value: "queensland",
		abbreviation: "qld",
		capital: "brisbane",
		type: "state"
	},
	{
		label: "south australia",
		value: "south australia",
		abbreviation: "sa",
		capital: "adelaide",
		type: "state"
	},
	{
		label: "tasmania",
		value: "tasmania",
		abbreviation: "tas",
		capital: "hobart",
		type: "state"
	},
	{
		label: "victoria",
		value: "victoria",
		abbreviation: "vic",
		capital: "melbourne",
		type: "state"
	},
	{
		label: "western australia",
		value: "western_australia",
		abbreviation: "wa",
		capital: "perth",
		type: "state"
	}
]

export const cancelReason = {
	inTime: "More than 24 hours notice",
	within24: "Within 24 hours of booking",
	userNoShow: "User no show",
	in_time: "More than 24 hours notice",
	within_24: "Within 24 hours of booking",
	user_no_show: "User no show",
	user_cancelled: "User cancelled"
}

export const cancelStatus= {
	user_cancelled: "User cancelled",
	coach_cancelled: "Coach Cancelled"
}

export const userType = [
	{
		label: "Coach Guided",
		value: "coachGuided"
	},
	{
		label: "User Guided",
		value: "userGuided"
	}
]

export const USStates = [
	{
		label: "Alabama",
		value: "AL"
	},
	{
		label: "Alaska",
		value: "AK"
	},
	{
		label: "American Samoa",
		value: "AS"
	},
	{
		label: "Arizona",
		value: "AZ"
	},
	{
		label: "Arkansas",
		value: "AR"
	},
	{
		label: "California",
		value: "CA"
	},
	{
		label: "Colorado",
		value: "CO"
	},
	{
		label: "Connecticut",
		value: "CT"
	},
	{
		label: "Delaware",
		value: "DE"
	},
	{
		label: "District Of Columbia",
		value: "DC"
	},
	{
		label: "Federated States Of Micronesia",
		value: "FM"
	},
	{
		label: "Florida",
		value: "FL"
	},
	{
		label: "Georgia",
		value: "GA"
	},
	{
		label: "Guam",
		value: "GU"
	},
	{
		label: "Hawaii",
		value: "HI"
	},
	{
		label: "Idaho",
		value: "ID"
	},
	{
		label: "Illinois",
		value: "IL"
	},
	{
		label: "Indiana",
		value: "IN"
	},
	{
		label: "Iowa",
		value: "IA"
	},
	{
		label: "Kansas",
		value: "KS"
	},
	{
		label: "Kentucky",
		value: "KY"
	},
	{
		label: "Louisiana",
		value: "LA"
	},
	{
		label: "Maine",
		value: "ME"
	},
	{
		label: "Marshall Islands",
		value: "MH"
	},
	{
		label: "Maryland",
		value: "MD"
	},
	{
		label: "Massachusetts",
		value: "MA"
	},
	{
		label: "Michigan",
		value: "MI"
	},
	{
		label: "Minnesota",
		value: "MN"
	},
	{
		label: "Mississippi",
		value: "MS"
	},
	{
		label: "Missouri",
		value: "MO"
	},
	{
		label: "Montana",
		value: "MT"
	},
	{
		label: "Nebraska",
		value: "NE"
	},
	{
		label: "Nevada",
		value: "NV"
	},
	{
		label: "New Hampshire",
		value: "NH"
	},
	{
		label: "New Jersey",
		value: "NJ"
	},
	{
		label: "New Mexico",
		value: "NM"
	},
	{
		label: "New York",
		value: "NY"
	},
	{
		label: "North Carolina",
		value: "NC"
	},
	{
		label: "North Dakota",
		value: "ND"
	},
	{
		label: "Northern Mariana Islands",
		value: "MP"
	},
	{
		label: "Ohio",
		value: "OH"
	},
	{
		label: "Oklahoma",
		value: "OK"
	},
	{
		label: "Oregon",
		value: "OR"
	},
	{
		label: "Palau",
		value: "PW"
	},
	{
		label: "Pennsylvania",
		value: "PA"
	},
	{
		label: "Puerto Rico",
		value: "PR"
	},
	{
		label: "Rhode Island",
		value: "RI"
	},
	{
		label: "South Carolina",
		value: "SC"
	},
	{
		label: "South Dakota",
		value: "SD"
	},
	{
		label: "Tennessee",
		value: "TN"
	},
	{
		label: "Texas",
		value: "TX"
	},
	{
		label: "Utah",
		value: "UT"
	},
	{
		label: "Vermont",
		value: "VT"
	},
	{
		label: "Virgin Islands",
		value: "VI"
	},
	{
		label: "Virginia",
		value: "VA"
	},
	{
		label: "Washington",
		value: "WA"
	},
	{
		label: "West Virginia",
		value: "WV"
	},
	{
		label: "Wisconsin",
		value: "WI"
	},
	{
		label: "Wyoming",
		value: "WY"
	}
]
