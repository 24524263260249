import React from "react"

export const IconCoaches = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 39'>
		<g fill='none' fillRule='evenodd'>
			<path d='M-5 0h40v40H-5z' />
			<path
				fill={props?.fill ? props?.fill : "#D5D5D8"}
				d='M15 19.048c7.785 0 14.115 6.633 14.282 13.928l.004.296v2.226a2.598 2.598 0 01-2.598 2.597H3.312a2.598 2.598 0 01-2.598-2.597v-2.226l.004-.296C.885 25.681 7.215 19.048 15 19.048zm8.355 14.222l2.073.002-.038-.016c-.615-.24-1.297-.195-2.035.014zM15 3.81a6.666 6.666 0 015.85 9.866 1.905 1.905 0 00-2.651 2.651A6.667 6.667 0 1115 3.81zM15 0c4.32 0 8.03 2.616 9.632 6.35a2.857 2.857 0 014.654 2.221v3.81a2.857 2.857 0 01-5.715 0v-1.905c0-4.734-3.837-8.571-8.571-8.571s-8.571 3.837-8.571 8.571v1.905a2.858 2.858 0 01-5.715 0v-3.81A2.857 2.857 0 015.369 6.35 10.475 10.475 0 0115 0z'
			/>
		</g>
	</svg>
)
