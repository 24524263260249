import React, {useEffect, useState, useCallback, Fragment, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import moment from "moment"
import _ from "lodash"
import {useAuth0} from "@auth0/auth0-react"
import _orderBy from "lodash/orderBy"

// UI

import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import TableComponent, {PAGE_SIZE} from "components/shared/Table"
import TableSortOrder from "components/shared/TableSortOrder"
import DropDown from "components/shared/DropDown"
import NotesModal from "./NotesModal"
import EmailModal from "./EmailModal"
import {Alerts} from "./Alerts"
import useDebounce from "../../hooks/useDebounce"
// Styles
import {
	HeaderTableContent,
	AssignTimeContainer,
	AssignTimeInput,
	AssigneTimeCloseButton,
	CommunicateButton,
	SearchUserInput,
	StatusRowContainer,
	StatusRowSpan,
	StatusRowTest,
	RowClickableText,
	FigureIcon,
	NoteTextArea,
	SearchInputContainer,
	SearchInputfigure,
	SearchInputImage
} from "./index.style"
import {ColumnContainer} from "shared/Table/index.style"

import {authSelector} from "../Auth/authSlice"

import {
	fetchRiskCallbackData,
	fetchRiskCallbackNote,
	riskCallbackDataSelector,
	assignRiskcallbackToUser,
	closeRiskCallback,
	openEmailModal,
	closeEmailModal,
	saveRiskCallbackNotes,
	openNoteModal,
	closeNoteModal,
	clearSendRiskCallbackEmailApiState,
	clearRiskCallBackApiState
} from "./riskCallbackSlice"

import OvalIcon from "assets/images/icons/svg/oval.svg"
import OvalClosedIcon from "assets/images/icons/svg/oval-closed.svg"
import NoteIcon from "assets/images/icons/svg/note-icon.svg"
import IconSearch from "assets/images/icons/svg/search.svg"

const RiskCallBack = ({user, history}) => {
	const dispatch = useDispatch()

	const fetchIdRef = useRef(0)
	const authState = useSelector(authSelector)
	const {
		modalState,
		saveRiskCallbackNotesApiState,
		sendRiskCallbackEmailApiState,
		assignRiskcallbackToUserApiState,
		closeRiskCallBackApiState,
		riskCallBackApiState
	} = useSelector(riskCallbackDataSelector)
	const [usersData, setUsersData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [coachesData, setCoachesData] = useState([])
	const [pageCount, setPageCount] = useState(0)
	const [selectedUser, setSelectedUser] = useState({})
	const [searchTerm, setSearchTerm] = useState("")
	const [token, setToken] = useState("")

	console.log("riskCallBackApiState", riskCallBackApiState)

	const {getAccessTokenSilently} = useAuth0()

	const [emailContent, setEmailContent] = useState({
		id: "",
		email: "",
		subject: "",
		body: ""
	})

	useEffect(() => {
		async function fetchToken() {
			const token = await getAccessTokenSilently()
			setToken(token)
		}

		fetchToken()
	}, [])

	useEffect(() => {
		// Clear state on unmount
		return () => dispatch(clearRiskCallBackApiState())
	}, [])

	useEffect(() => {
		if (riskCallBackApiState?.users?.length > 0) {
			let updateSelectedUser = riskCallBackApiState.users.find(user => selectedUser && user.id == selectedUser.id)

			setSelectedUser(updateSelectedUser ? updateSelectedUser : {})
			setUsersData(riskCallBackApiState.users)
		}

		if (riskCallBackApiState?.coaches?.length > 0) {
			setCoachesData(riskCallBackApiState.coaches)
		}
	}, [riskCallBackApiState])

	useEffect(() => {
		if (riskCallBackApiState.totalCount) {
			setPageCount(Math.ceil(riskCallBackApiState.totalCount / PAGE_SIZE))
		}
	}, [riskCallBackApiState.totalCount])

	const fetchData = useCallback(
		({pageSize, pageIndex, searchText}) => {
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current

			if (fetchId === fetchIdRef.current && token) {
				console.log("dispatch is coming")
				dispatch(
					fetchRiskCallbackData({
						limit: pageSize,
						skip: searchText || searchTerm ? 0 : pageSize * pageIndex,
						token,
						searchTerm: searchText || searchTerm
					})
				)
			}
		},
		[token]
	)

	const handleSearchChange = useCallback(
		_.debounce(
			() => {
				fetchData({pageSize: PAGE_SIZE, pageIndex: 0, searchText: searchTerm})
			},
			1000,
			{maxWait: 1000}
		),
		[searchTerm]
	)

	useEffect(() => {
		handleSearchChange()
		return handleSearchChange.cancel
	}, [searchTerm, handleSearchChange])

	const handleModalClose = () => {
		dispatch(closeEmailModal())
	}

	const handleNoteModalClose = () => {
		dispatch(closeNoteModal())
	}

	const _handleNoteSave = user => {
		const coachId = authState?.data?.id

		if (token.length) {
			dispatch(saveRiskCallbackNotes({id: user.id, coachId, email: user.email, note: user.value, token}))
			dispatch(closeNoteModal())
		}
	}

	const onCoachFilterChange = e => {
		if (e.value === 0) {
			setFilteredData(riskCallBackApiState.users)
		} else {
			const filteredData = riskCallBackApiState.users.filter(item => item.data.coachId == e.value)

			setFilteredData(filteredData)
		}
	}

	const onActiveFilterChange = e => {
		if (e.value === 2) {
			setFilteredData([])
		} else {
			const filteredData = usersData.filter(item => item.statusId == e.value)
			setFilteredData(filteredData)
		}
	}

	const AssignTimeAndCloseComponent = ({id, row}) => {
		const [duration, setDuration] = useState(null)
		const onCloseRiskCallback = () => {
			if (!duration) {
				alert("Please set duration")
				return
			}
			dispatch(closeRiskCallback({id, duration, token}))
		}

		return (
			<AssignTimeContainer>
				{row.data.duration ? (
					<div>{row.data.duration} Mins</div>
				) : (
					<React.Fragment>
						<AssignTimeInput type='text' onChange={e => setDuration(e.target.value)} />
						<p>Min</p>
						<AssigneTimeCloseButton title={"Close"} variant={"primary"} onClick={onCloseRiskCallback} />
					</React.Fragment>
				)}
			</AssignTimeContainer>
		)
	}

	const Communicate = ({id, row}) => {
		const onSendEmailClick = row => {
			setEmailContent({
				id: id,
				email: row.data.email,
				subject: "Your Wellbeing Check",
				body: ""
			})
			dispatch(openEmailModal())
		}
		return (
			<CommunicateButton
				align='center'
				title={row.data.isMailSend ? "Re-Send" : "Send email"}
				variant={"secondary"}
				onClick={() => onSendEmailClick(row)}
			/>
		)
	}

	const StatusRow = ({id, row}) => {
		return (
			<StatusRowContainer>
				<StatusRowSpan>
					<img src={row.status === "Active" ? OvalIcon : OvalClosedIcon} />
				</StatusRowSpan>
			</StatusRowContainer>
		)
	}

	const Notes = ({id, row, align}) => {
		const _handleNoteClick = row => {
			dispatch(fetchRiskCallbackNote({id: row.id, token}))

			setSelectedUser({id: row.id, name: row.data.name, email: row.email, notes: row.data.notes})
			dispatch(openNoteModal())
		}
		return (
			<FigureIcon align={align} onClick={() => _handleNoteClick(row)}>
				<img src={NoteIcon} alt={""} id={id} row={row} />
			</FigureIcon>
		)
	}

	const TherapistsDropDown = ({id, coachId, coaches}) => {
		const selectedCoach = coaches.filter(coach => coach.value === coachId)

		const onChange = (id, e) => {
			const coachId = e.value

			if (token) {
				dispatch(assignRiskcallbackToUser({id, coachId, token}))
			}
		}

		const dropdown = (
			<DropDown
				containerStyle={{
					alignSelf: "center",
					marginRight: "12px",
					position: "relative"
				}}
				initialSelectedItem={selectedCoach[0]}
				label='Select Coach'
				onChange={e => onChange(id, e)}
				menuStyle={{position: "absolute", zIndex: 2}}
				items={coaches}
			/>
		)

		return <div>{dropdown}</div>
	}

	const columns = [
		{
			Header: "Name",
			accessor: "name",
			width: "125px",
			sortType: "string",
			show: true,
			Cell: cellInfo => {
				return (
					<RowClickableText
						onClick={() => {
							history.push(`/users/${cellInfo.row.original.email}`)
						}}>
						{cellInfo.row.original.name}
					</RowClickableText>
				)
			}
		},
		{
			Header: "Email Address",
			accessor: "email",
			show: true,
			sortType: "string"
		},
		{
			Header: "Employer",
			width: "75px",
			accessor: d => {
				if (d?.code) {
					return d.code
				}
				return null
			},
			show: true,
			sortType: "string",
			Cell: cellInfo => {
				return (
					<RowClickableText
						onClick={() => {
							history.push(`/employer/${cellInfo.row.original.code}`)
						}}>
						{cellInfo.row.original.code}
					</RowClickableText>
				)
			}
		},
		{
			Header: "Date/Time of Check",
			accessor: "createdAt",
			show: true,
			Cell: ({cell: {value}}) => {
				return <Fragment>{value ? <div>{moment(value).format("Do MMMM YYYY hh:mm a")}</div> : "-"}</Fragment>
			}
		},
		{
			Header: "Wellbeing Therapist",
			disableSortBy: true,
			accessor: "progress",
			show: true,
			style: {overflow: "auto"},
			Cell: e => {
				return (
					<TherapistsDropDown id={e.row.original.id} coachId={e.row.original.coachId} coaches={coachesData} />
				)
			}
		},
		{
			Header: "State",
			width: "40px",
			accessor: "status",
			show: true,
			Cell: e => {
				return <StatusRow id={e.row.original.id} row={e.row.original} />
			}
		},
		{
			Header: "Communicate",
			disableSortBy: true,
			width: "75px",
			Cell: cellInfo => (
				<ColumnContainer align='center'>
					<Communicate id={cellInfo.row.original.id} row={cellInfo.row.original} />
				</ColumnContainer>
			)
		},
		{
			Header: "Assign time & close",
			// accessor: "",
			Cell: cellInfo => <AssignTimeAndCloseComponent id={cellInfo.row.original.id} row={cellInfo.row.original} />,
			show: true,
			disableSortBy: true
		},
		{
			Header: "Notes",
			width: "50px",
			accessor: "notes",
			Cell: cellInfo => <Notes align='center' id={cellInfo.row.original.id} row={cellInfo.row.original} />,
			show: true,
			disableSortBy: true
		}
	]

	console.log(closeRiskCallBackApiState)

	return (
		<ComponentWrapper
			menuActiveIndex={6}
			headerTitle='Risk Callback'
			headerRightAlignContent={
				<HeaderTableContent>
					<SearchInputContainer>
						<SearchInputfigure>
							<SearchInputImage src={IconSearch} />
						</SearchInputfigure>
						<SearchUserInput
							type={"text"}
							label={"email"}
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
							placeholder={"Search by user's email ID"}
							isRequired={false}
						/>{" "}
						<DropDown
							containerStyle={{
								alignSelf: "center",
								marginRight: "12px",
								position: "relative",
								zIndex: "2"
							}}
							label='Select Coach'
							onChange={onCoachFilterChange}
							menuStyle={{position: "absolute"}}
							items={coachesData}
						/>
						<DropDown
							label='Filter By'
							containerStyle={{
								alignSelf: "center",
								marginRight: "12px",
								position: "relative",
								zIndex: "2"
							}}
							onChange={onActiveFilterChange}
							menuStyle={{position: "absolute"}}
							items={[
								{label: "All", value: 2},
								{label: "Active", value: 1},
								{label: "In Active", value: 0}
							]}
						/>
					</SearchInputContainer>
				</HeaderTableContent>
			}>
			<Alerts
				className='m-b-5'
				closeRiskCallBackApiState={closeRiskCallBackApiState}
				assignRiskcallbackToUserApiState={assignRiskcallbackToUserApiState}
				saveRiskCallbackNotes={saveRiskCallbackNotes}
				saveRiskCallbackNotesApiState={saveRiskCallbackNotesApiState}
				sendRiskCallbackEmailApiState={sendRiskCallbackEmailApiState}
			/>

			<Card padding={spacing.s10} backgroundColor='white' data-testid='riskCallBackData'>
				<TableComponent
					columns={columns}
					pageCount={pageCount}
					fetchData={fetchData}
					data={filteredData.length > 0 ? filteredData : usersData}
					loading={riskCallBackApiState.isFetching || !riskCallBackApiState.isSuccess}
				/>
			</Card>

			<EmailModal
				isOpen={modalState.sendEmailModalState}
				handleClose={handleModalClose}
				emailContent={emailContent}
				token={token}
			/>
			<NotesModal
				isOpen={modalState.noteModalState}
				notes={selectedUser.notes}
				onSave={_handleNoteSave}
				user={selectedUser}
				onClose={handleNoteModalClose}
			/>
		</ComponentWrapper>
	)
}

export default RiskCallBack
