import React, {useEffect, Fragment, useState} from "react"

import Styled from "styled-components"
// Colors
import {extended} from "@uprise/colors"

// Components
import {Medium} from "@uprise/text"
import {H5} from "@uprise/headings"

// spacing
import {spacing} from "@uprise/spacing"

const SummaryStyles = Styled.section`
      background-color: #fff;
`

const Content = Styled.div`
	 
`

const Ul = Styled.ul`
      margin-bottom: ${spacing.s3};
      list-style-type: none;
      padding-left: 0;
`

const Li = Styled.li`
      display: flex;
	flex-direction: column;
      margin-bottom: ${spacing.s6};
	padding-bottom: ${spacing.s4};
	border: 1px solid ${extended.purple.four};
	border-right: 0;
	border-left: 0;
	border-top: 0;

	&:last-child {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
`

const removeHtmlTagsFromString = value => {
	return value.replace(/(<([^>]+)>)/gi, "")
}

export const WHO5STRESSResult = ({index, analysis, surveyTasks, survey}) => {
	const [surveyQuestions, setSurveyQuestions] = useState({})
	useEffect(() => {
		let questionsArr = {}
		surveyTasks.map(task => {
			let surveyQuestionsArr = survey.questions.filter(question => question.batteryId === task)

			questionsArr = {...questionsArr, [task]: surveyQuestionsArr}
		})
		setSurveyQuestions(questionsArr)
	}, [])

	return (
		<SummaryStyles>
			<Content>
				<H5 className='m-b-5 m-t-0'>WHO5</H5>
				<Ul className='m-b-5'>
					{analysis.WHO5 &&
						Object.entries(analysis.WHO5)
							.slice(1)
							.map((answer, index) => {
								if (typeof answer[1] === "object" && answer[1]) {
									if (answer[0] === "difficulties" && answer[1] !== undefined) {
										return (
											<Li key={index}>
												<Medium>
													Difficulties at work: {answer[1] ? answer[1].join(",") : "-"}
												</Medium>
											</Li>
										)
									} else if (answer[0] === "humanReadable") {
										return (
											<Li key={index}>
												<Medium weight='bold'>
													Overall : {answer[1] ? answer[1]["WHO5"] : "-"}
												</Medium>
											</Li>
										)
									}
								} else if (answer[1]) {
									return (
										<Li key={index}>
											<H5 className='m-t-0 m-b-3'>
												{" "}
												{surveyQuestions["WHO5"] &&
													surveyQuestions["WHO5"][index] &&
													removeHtmlTagsFromString(surveyQuestions["WHO5"][index].prompt)}
											</H5>
											<Medium>
												{surveyQuestions["WHO5"] &&
													surveyQuestions["WHO5"][index] &&
													surveyQuestions["WHO5"][index].options[answer[1]]}
											</Medium>
										</Li>
									)
								}
							})}
				</Ul>
				<H5 className='m-b-5'>Stress</H5>
				<Ul className='m-b-5'>
					{analysis.STRESS &&
						Object.entries(analysis.STRESS)
							.slice(1)
							.map((answer, index) => {
								if (typeof answer[1] === "object" && answer[1]) {
									if (answer[0] === "difficulties" && answer[1] !== undefined) {
										return (
											<Li key={index}>
												<Medium>
													Difficulties at work: {answer[1] ? answer[1].join(",") : "-"}
												</Medium>
											</Li>
										)
									} else if (answer[0] === "humanReadable") {
										return (
											<Li key={index}>
												<Medium weight='bold'>
													Overall : {answer[1] ? answer[1]["STRESS"] : "-"}
												</Medium>
											</Li>
										)
									}
								} else if (answer[1]) {
									return (
										<Li key={index}>
											<H5 className='m-t-0 m-b-3'>
												{" "}
												{surveyQuestions["STRESS"] &&
													surveyQuestions["STRESS"][index] &&
													removeHtmlTagsFromString(surveyQuestions["STRESS"][index].prompt)}
											</H5>
											<Medium>
												{surveyQuestions["STRESS"] &&
													surveyQuestions["STRESS"][index] &&
													surveyQuestions["STRESS"][index].options[answer[1]]}
											</Medium>
										</Li>
									)
								}
							})}
				</Ul>
				{analysis?.npsWork?.length > 0 ||
				analysis?.roiAbsenteeismDays?.length > 0 ||
				analysis?.roiPresenteeismDays?.length > 0 ||
				analysis?.roiTurnoverRiskDays?.length > 0 ||
				analysis?.stressLevel?.length > 0 ? (
					<Fragment>
						<H5 className='m-b-5'>Other</H5>
						<Ul className='m-b-5'>
							<Li>{analysis.npsWork.length && <Medium>npsWork: {analysis.npsWork}</Medium>}</Li>
							<Li>
								{analysis?.roiAbsenteeismDays?.length && (
									<Medium>roiAbsenteeismDays: {analysis.roiAbsenteeismDays}</Medium>
								)}
							</Li>
							<Li>
								{analysis?.roiPresenteeismDays?.length && (
									<Medium>roiPresenteeismDays: {analysis.roiPresenteeismDays}</Medium>
								)}
							</Li>
							<Li>
								{analysis?.roiTurnoverRiskDays?.length && (
									<Medium>roiTurnoverRiskDays: {analysis.roiTurnoverRiskDays}</Medium>
								)}
							</Li>
							<Li>
								{analysis?.stressLevel?.length && <Medium>stressLevel: {analysis.stressLevel}</Medium>}
							</Li>
						</Ul>
					</Fragment>
				) : null}
			</Content>
		</SummaryStyles>
	)
}
