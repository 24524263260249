import React from "react"

export const IconBookings = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
		<g fill='none' fillRule='evenodd'>
			<path d='M0 0h12v12H0z' />
			<path
				fill={props?.fill ? props?.fill : "#D5D5D8"}
				d='M2.286.571c.315 0 .571.256.571.572h6.286c0-.316.256-.572.571-.572h.572c.284 0 .52.208.564.48l.007.092c.631 0 1.143.512 1.143 1.143v8.571c0 .631-.512 1.143-1.143 1.143H1.143A1.143 1.143 0 010 10.857V2.286c0-.631.512-1.143 1.143-1.143 0-.316.256-.572.571-.572h.572zm8 3.429H1.714a.571.571 0 00-.564.479l-.007.092v5.715c0 .284.207.52.479.564l.092.007h8.572c.284 0 .52-.207.564-.479l.007-.092V4.57a.571.571 0 00-.571-.571zM6 4.571a2.858 2.858 0 11-.001 5.717A2.858 2.858 0 016 4.57zm0 1.143a.43.43 0 00-.422.352l-.007.077v1.143l.002.036.004.036-.006.07c0 .211.152.386.352.422L6 7.857h.857a.429.429 0 00.077-.85L6.857 7h-.429v-.857A.428.428 0 006 5.714z'
			/>
		</g>
	</svg>
)
