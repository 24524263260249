import React, {useState, useEffect, Fragment} from "react"
import styled from "styled-components"
import moment from "moment"
import {useSelector, useDispatch} from "react-redux"
import {useAuth0} from "@auth0/auth0-react"

import {Container, Grid, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H6} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {backgrounds, extended, primary, semantic} from "@uprise/colors"
import {useForm, Controller} from "react-hook-form"

import {employerSelector, findEmployerCodeExists} from "../../employerSlice"
import DropDown from "components/shared/DropDown"
import AutoComplete from "components/shared/AutoComplete"
import Datepicker from "components/shared/DatePicker"
import Radio from "components/shared/Radio"
import Toggle from "components/shared/Toggle"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const NumberInputContainer = styled(FormElementContainer)`
	width: unset;
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputInlineContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	align-self: center;
`

const ToggleContainer = styled(FormInputContainer)`
	display: flex;
	justify-content: flext-start;
`

const FormNumberInputFixedWidth = styled(TextInputHorizontal)`
	width: 80px;
	height: 24px;
	padding: 2px 2px 2px 8px;
	border-radius: 4px;
	border: solid 1px #d4cbfb;
`

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const PrimaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
`

const SecondaryButton = styled(Button)`
	width: 100px;
	height: 50px;
	margin: 0 6px;
	border-color: transparent;
	background-color: #f6f4fc;
`

const FormSpan = styled.span``

const FormSpanText = styled(P)`
	margin: 0 0 0 10px;
`

const FormSpanLabel = styled.label`
	display: flex;
	padding: 10px;
	padding-left: 0;
`

const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`

const TextInputWrap = styled.div`
	width: 60%;
	margin-left: 40%;
	display: flex;
`

const Code = ({onComplete, onPrevious, triggerValidation}) => {
	const dispatch = useDispatch()
	const {masterCodes, courses, employerCodeExists} = useSelector(employerSelector)
	const {getAccessTokenSilently} = useAuth0()
	const [token, setToken] = useState(null)
	const {register, control, handleSubmit, watch, reset, trigger, setError, setValue, errors} = useForm({
		defaultValues: {coachingType: "combined"}, //you can populate the fields by this attribute
		mode: "onChange",
		reValidateMode: "onChange"
	})

	useEffect(() => {
		async function fetchToken() {
			const token = await getAccessTokenSilently()

			setToken(token)
		}
		fetchToken()
	}, [getAccessTokenSilently])

	useEffect(() => {
		if (employerCodeExists) {
			setError("employerCode", {
				type: "manual",
				message: "Employer Code Already Exists"
			})
		}
	}, [employerCodeExists, setError])

	useEffect(() => {
		if (triggerValidation) {
			trigger()
		}
	}, [triggerValidation, trigger])

	const watchBudgetAlert = watch("budgetAlert", "")
	const watchCodeType = watch("codeType")

	useEffect(() => {
		setValue("coachingType", "combined", {shouldValidate: true})
	}, [])

	const watchSessionCap = watch(["coachingType"])
	const watchDates = watch(["codeStartDate", "codeExpiryDate"])
	const watchCapCalls = watch(["capCalls"])
	const dateCheck = () => {
		return !!(watchDates.codeStartDate && (watchDates?.codeStartDate).diff(watchDates.codeExpiryDate) > 0)
			? "Code Start Date cant exceed Code Expiry Date"
			: true
	}
	const dateCheckExpiry = () => {
		return !!(watchDates.codeExpiryDate && (watchDates?.codeExpiryDate).diff(watchDates.codeStartDate) < 0)
			? "Code Expiry Date cant be before Code Start Date"
			: true
	}

	return (
		<Container>
			<form
				onSubmit={handleSubmit(data => {
					if (!employerCodeExists) {
						const codeData = {
							...data,
							caps: {
								capCalls: data.capCalls,
								callCapPeriod: [
									{
										startingDate: data.codeStartDate.toISOString(),
										combinedBookingCaps: parseInt(data.combinedBookingCaps) || 0,
										therapyBookingCaps: parseInt(data.therapyBookingCaps) || 0,
										coachingBookingCaps: parseInt(data.coachingBookingCaps) || 0,
										updatedAt: moment().toISOString()
									}
								]
							}
						}
						onComplete(codeData)
					}
				})}>
				<Row>
					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer
							error={
								(errors && errors.employerCode?.type === "required") ||
								(errors && errors.employerCode?.type === "manual")
							}>
							<FormElementLabel isRequired={true}>Employer Code</FormElementLabel>

							<FormInputContainer>
								<Controller
									name={"employerCode"}
									control={control}
									rules={{required: "Employer Code is Required"}}
									defaultValue=''
									render={props => (
										<TextInputHorizontal
											type='text'
											data-testid='employerCode'
											id='employerCode'
											onBlur={() => {
												dispatch(findEmployerCodeExists({token, code: props.value}))
											}}
											placeholder='Employer Code'
											onChange={props.onChange}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["employerCode"] && errors["employerCode"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["employerCode"]}>
								{errors["employerCode"] && errors["employerCode"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer error={errors.codeType?.type === "required"}>
							<FormElementLabel isRequired={true}>Code Type</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"codeType"}
									control={control}
									rules={{required: "Code Type is Required"}}
									defaultValue=''
									render={props => (
										<DropDown
											isFirst={true}
											testId='codeType'
											variant='primary'
											items={[
												{
													label: "Regular",
													value: "regular"
												},
												{
													label: "Master",
													value: "master"
												},
												{
													label: "Family",
													value: "family"
												}
											]}
											label='Code Type'
											onChange={item => props.onChange(item.value)}
											initialSelectedItem={""}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["codeType"] && errors["codeType"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["codeType"]}>
								{errors["codeType"] && errors["codeType"].message}
							</MessageStyles>
						) : null}
						{watchCodeType && watchCodeType !== "master" ? (
							<FormElementContainer>
								<FormElementLabel>Link to master code</FormElementLabel>
								<FormInputContainer>
									<Controller
										name={"masterCode"}
										control={control}
										defaultValue=''
										render={props => (
											<AutoComplete
												items={masterCodes}
												initialSelectedItem={""}
												onChange={value => props.onChange(value)}
												menuStyle={{position: "absolute", zIndex: 2}}
											/>
										)}
									/>
								</FormInputContainer>
							</FormElementContainer>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Code Label</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"codeLabel"}
									data-testid='codeLabel'
									control={control}
									defaultValue=''
									as={<TextInputHorizontal id='codeLabel' type='text' placeholder='Label' />}
								/>
							</FormInputContainer>
						</FormElementContainer>

						<FormElementContainer error={errors.codeStartDate?.type === "required"}>
							<FormElementLabel isRequired={true}>Code start date</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"codeStartDate"}
									control={control}
									rules={{required: "Code Start Date is Required", validate: dateCheck}}
									defaultValue={moment()}
									render={props => {
										return (
											<Datepicker
												isFirst={true}
												testId='codeStartDate'
												date={props.value}
												onChange={e => props.onChange(e.target.value)}
											/>
										)
									}}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["codeStartDate"] && errors["codeStartDate"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["codeStartDate"]}>
								{errors["codeStartDate"] && errors["codeStartDate"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer error={errors.codeExpiryDate?.type === "required"}>
							<FormElementLabel isRequired={true}>Code expiry date</FormElementLabel>
							<FormInputContainer>
								<Controller
									control={control}
									rules={{required: "Code Expiry Date is Required", validate: dateCheckExpiry}}
									defaultValue={moment().add(1, "years")}
									render={props => {
										return (
											<Datepicker
												isFirst={true}
												testId='codeExpiryDate'
												date={props.value}
												onChange={e => props.onChange(e.target.value)}
											/>
										)
									}}
									name={"codeExpiryDate"}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["codeExpiryDate"] && errors["codeExpiryDate"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["codeExpiryDate"]}>
								{errors["codeExpiryDate"] && errors["codeExpiryDate"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer error={errors.course?.type === "required"}>
							<FormElementLabel isRequired={true}>Course</FormElementLabel>
							<FormInputContainer>
								<Controller
									control={control}
									name={"course"}
									rules={{required: "Course is Required"}}
									defaultValue=''
									render={props => (
										<DropDown
											isFirst={true}
											testId='course'
											variant='primary'
											items={courses.map(course => {
												return {
													label: course,
													value: course
												}
											})}
											label='Select Course'
											onChange={item => {
												props.onChange(item.value)
											}}
											initialSelectedItem={""}
											menuStyle={{position: "absolute", zIndex: 2}}
										/>
									)}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["course"] && errors["course"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["course"]}>
								{errors["course"] && errors["course"].message}
							</MessageStyles>
						) : null}
					</Col>

					<Col className='col-6 col-lg-6 col-md-6 col-sm-12'>
						<FormElementContainer error={errors.maxUser?.type === "required"}>
							<FormElementLabel isRequired={true}>Max users</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"maxUser"}
									data-testid='maxUser'
									control={control}
									rules={{required: "Max Users is Required"}}
									defaultValue=''
									as={<TextInputHorizontal id='maxUser' type='number' placeholder='Max users' />}
								/>
							</FormInputContainer>
						</FormElementContainer>
						{errors["maxUser"] && errors["maxUser"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["maxUser"]}>
								{errors["maxUser"] && errors["maxUser"].message}
							</MessageStyles>
						) : null}

						<FormElementContainer>
							<FormElementLabel>Cap calls</FormElementLabel>
							<ToggleContainer>
								<Controller
									name={"capCalls"}
									control={control}
									defaultValue={true}
									render={({onChange, ref, value}) => (
										<Toggle
											checked={value}
											onChange={() => {
												onChange(!value)
											}}
											backgroundColorChecked={"#7d60ff"}
											width='42'
											height='24'
											InputRef={ref}
											sliderHeight='20.8'
											sliderWidth='20.8'
											translate='18'
										/>
									)}
								/>
							</ToggleContainer>
						</FormElementContainer>

						<FormElementContainer error={errors.coachingType?.type === "required"}>
							<FormElementLabel isRequired={true}>Session Cap</FormElementLabel>
							<FormInputInlineContainer>
								<Controller
									name={"coachingType"}
									control={control}
									rules={{required: "Session Cap is required"}}
									defaultValue={"combined"}
									render={props => (
										<>
											<Radio
												checked={watchSessionCap.coachingType === "combined"}
												label={"Total Calls"}
												value={"combined"}
												onChange={e =>
													setValue("coachingType", "combined", {shouldValidate: true})
												}
												name={"coachingType"}
											/>
											<Radio
												checked={watchSessionCap.coachingType === "individual"}
												label={"Coaching Calls"}
												value={"individual"}
												onChange={e =>
													setValue("coachingType", "individual", {shouldValidate: true})
												}
												name={"coachingType"}
											/>
										</>
									)}
								/>
							</FormInputInlineContainer>
						</FormElementContainer>

						{errors["coachingType"] && errors["coachingType"].message ? (
							<MessageStyles htmlFor={1} focused={false} validation={errors["coachingType"]}>
								{errors["coachingType"] && errors["coachingType"].message}
							</MessageStyles>
						) : null}

						{watchSessionCap.coachingType === "combined" ? (
							<>
								<TextInputWrap>
									<NumberInputContainer error={errors.combinedBookingCaps?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"combinedBookingCaps"}
												data-testid='combinedBookingCaps'
												control={control}
												rules={{
													required: {
														value: true,
														message: "Sessions is required"
													},
													min: {
														value: 0,
														message: "Sessions should be at least 0"
													}
												}}
												defaultValue={0}
												as={
													<FormNumberInputFixedWidth
														id='combinedBookingCaps'
														type='number'
														disabled={!watchCapCalls.capCalls}
													/>
												}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													sessions
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>
								</TextInputWrap>

								{errors["combinedBookingCaps"] && errors["combinedBookingCaps"].message ? (
									<MessageStyles
										htmlFor={1}
										focused={false}
										validation={errors["combinedBookingCaps"]}>
										{errors["combinedBookingCaps"] && errors["combinedBookingCaps"].message}
									</MessageStyles>
								) : null}
							</>
						) : (
							<>
								<TextInputWrap>
									<NumberInputContainer error={errors.coachingBookingCaps?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"coachingBookingCaps"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Coaching is required"
													},
													min: {
														value: 0,
														message: "Coaching sessions should be at least 0"
													}
												}}
												defaultValue={0}
												as={
													<FormNumberInputFixedWidth
														type='number'
														disabled={!watchCapCalls.capCalls}
													/>
												}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													coaching
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>

									<NumberInputContainer error={errors.therapyBookingCaps?.type === "required"}>
										<FormSpanLabel>
											<Controller
												name={"therapyBookingCaps"}
												control={control}
												rules={{
													required: {
														value: true,
														message: "Therapy is required"
													},
													min: {
														value: 0,
														message: "Therapy sessions should be at least 0"
													}
												}}
												defaultValue={0}
												as={
													<FormNumberInputFixedWidth
														type='number'
														disabled={!watchCapCalls.capCalls}
													/>
												}
											/>
											<FormSpan>
												<FormSpanText fontSize='15px' weight={"bold"}>
													therapy
												</FormSpanText>
											</FormSpan>
										</FormSpanLabel>
									</NumberInputContainer>
								</TextInputWrap>

								{errors["coachingBookingCaps"] && errors["coachingBookingCaps"].message ? (
									<MessageStyles
										htmlFor={1}
										focused={false}
										validation={errors["coachingBookingCaps"]}>
										{errors["coachingBookingCaps"] && errors["coachingBookingCaps"].message}
									</MessageStyles>
								) : null}

								{errors["therapyBookingCaps"] && errors["therapyBookingCaps"].message ? (
									<MessageStyles
										htmlFor={1}
										focused={false}
										validation={errors["therapyBookingCaps"]}>
										{errors["therapyBookingCaps"] && errors["therapyBookingCaps"].message}
									</MessageStyles>
								) : null}
							</>
						)}
					</Col>
				</Row>
				<Row className='flex-row-reverse mt-4'>
					<ButtonContainer>
						<PrimaryButton type='submit' variant={"primary"} title={"Next"} data-testid='nextButton' />
					</ButtonContainer>
					<ButtonContainer>
						<SecondaryButton
							type='button'
							variant={"secondary"}
							title={"Previous"}
							onClick={onPrevious}
							data-testid='previousButton'
						/>
					</ButtonContainer>
				</Row>
			</form>
		</Container>
	)
}

export default Code
