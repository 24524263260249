import React from "react"
import styled from "styled-components"
import {Container, Grid, Row, Col} from "@uprise/grid"
import {H4, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {useHistory} from "react-router-dom"
import {useDispatch} from "react-redux"
import {clearEmployerApiState} from "../../employerSlice"
import success from "assets/images/svg/success.svg"
import {spacing} from "@uprise/spacing"

const PageContainer = styled(Container)``

const Heading = styled(H4)`
	text-align: center;
`

const SubHeading = styled(H6)`
	text-align: center;
`

const ImageFigure = styled.figure`
	text-align: center;
`

const Description = styled(P)`
	text-align: center;
`

const ButtonWrap = styled.div`
	display: flex;
	justify-content: center;
`

const SuccessPage = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	return (
		<PageContainer>
			<Heading className='m-t-10'>Success</Heading>
			<ImageFigure>
				<img src={success} />
			</ImageFigure>

			<SubHeading>New Employer Form has been successfully submitted!</SubHeading>
			<Description>Please check your email for confirmation.. See you soon!</Description>

			<ButtonWrap>
				<Button
					size='medium'
					data-testid='successMessage'
					paddingLeft={spacing.s10}
					paddingRight={spacing.s10}
					fullWidth={false}
					className='m-t-10 m-b-6'
					title={"Close"}
					onClick={() => {
						dispatch(clearEmployerApiState())
						history.push("/employers/all")
					}}
				/>
			</ButtonWrap>
		</PageContainer>
	)
}

export default SuccessPage
