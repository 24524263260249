import React from "react"
import {bool, func, string, element} from "prop-types"
import Styled from "styled-components"
import {spacing} from "@uprise/spacing"
import {backgrounds, extended} from "@uprise/colors"
import {H2} from "@uprise/headings"
import {text} from "@uprise/typography"

const HeaderStyle = Styled.header`
	display:  flex;
	visibility: visible;
	justify-content: ${props => (props.justify ? props.justify : "space-between")};
	height: 100px;
      flex-direction: row;
      /* width: 100%; */
      align-items: center;
      text-align: center;
      color: ${props => props.color};
      background-color: ${backgrounds.white};
      box-shadow: inset 0 -1px 0 0 ${extended.purple.five};
	padding:  ${spacing.s9};
	
	@media (max-width: 475px) {
		padding: ${props => (props.hideMobile ? 0 : spacing.s5)};
		visibility:  ${props => (props.hideMobile ? "hidden" : "visible")};
		height: auto;
	}
`

const RightAlignedDiv = Styled.div`
 algin-self : flex-end;
`

const BackIcon = Styled.img`
      height: 16px;
      width: 9px;
      margin-right: ${spacing.s7};

      &:hover {
            cursor: pointer
      }
`

export const Header = ({
	textAlign,
	title,
	className,
	width,
	weight,
	color,
	back,
	hideMobile,
	handleBack,
	rightAlign,
	rightAlignContent,
	justify
}) => {
	return (
		<HeaderStyle
			className={className}
			width={width}
			weight={weight}
			textAlign={textAlign}
			color={color}
			justify={justify}
			hideMobile={hideMobile}>
			{back && (
				<BackIcon
					src={require(`assets/images/icons/svg/chevron-left.svg`)}
					onClick={() => handleBack()}
				/>
			)}
			<H2
				textAlign={textAlign}
				// width={width}
				fontSizeXs={text.t5}
				textAlignXs='center'>
				{title}
			</H2>
			{rightAlign ? <RightAlignedDiv>{rightAlignContent}</RightAlignedDiv> : null}
		</HeaderStyle>
	)
}

// array, bool, func, number, object, string
// symbol, node, element, elementType
// instanceOf oneOf oneOfType shape, exact, func, any
Header.propTypes = {
	weight: string,
	textAlign: string,
	title: string,
	className: string,
	width: string,
	color: string,
	back: bool,
	handleBack: func,
	rightAlign: bool,
	rightAlignContent: element
}

Header.defaultProps = {
	width: "",
	back: false,
	handleBack: func,
	rightAlign: false,
	rightAlignContent: null
}
