import React, {Fragment, useEffect} from "react"
import {useDispatch} from "react-redux"
// UI
import {Alert} from "@uprise/alert"
// Slices
import {clearBookingApiState} from "features/Bookings/bookingSlice"

export const Alerts = ({fetchAllCoachesAvailabilityApiState, createBookingApiState}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (fetchAllCoachesAvailabilityApiState.isError) {
			setTimeout(() => {
				dispatch(clearBookingApiState())
			}, 5000)
		}

		if (createBookingApiState.isSuccess) {
			setTimeout(() => {
				dispatch(clearBookingApiState())
			}, 8000)
		}
	}, [dispatch, createBookingApiState, fetchAllCoachesAvailabilityApiState])

	return (
		<Fragment>
			{fetchAllCoachesAvailabilityApiState.isError && (
				<Alert className='m-b-5' type='error'>
					{fetchAllCoachesAvailabilityApiState.message}
				</Alert>
			)}

			{createBookingApiState.isSuccess && (
				<Alert className='m-b-5' type='success'>
					Booking successfully created
				</Alert>
			)}

			{createBookingApiState.isError && (
				<Alert className='m-b-5' type='success'>
					{createBookingApiState.message}
				</Alert>
			)}
		</Fragment>
	)
}
