import React, {useState, useEffect, Fragment} from "react"
import {Alert} from "@uprise/alert"
import {useDispatch, useSelector} from "react-redux"
import {Loader} from "@uprise/loader"
import ComponentWrapper from "components/HOC/ComponentWrapper"
import GeneralIcon from "assets/images/icons/svg/icon-general.svg"
import ContactIcon from "assets/images/icons/svg/icon-contact.svg"
// import ContractIcon from "../../../../assets/images/icons/svg/icon-contracts.svg"
import PlanIcon from "assets/images/icons/svg/icon-plans.svg"

import General from "./General"
// import Contracts from "./Contracts"
import Contact from "./Contact"
import Code from "./Code"
import Plan from "./Plan"
import SuccessPage from "./SuccessPage"

import {fetchMasterCodes, fetchCourses, createEmployer, employerSelector} from "../../employerSlice"
import {useAuth0} from "@auth0/auth0-react"

import {
	HeaderTableContent,
	Container,
	EmployerButton,
	FormLevelContainer,
	FormLevel,
	Icon,
	FormLevelLabelContainer,
	FormLevelLabelHeader,
	FormLevelLabelText,
	ItemContainer,
	FormItem,
	PrimaryButton,
	SecondaryButton
} from "./index.style"
const Forms = ({history}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [generalFormValues, setGeneralFormValues] = useState({})
	const [contractFormValues, setContractFormValues] = useState({})
	const [contactFormValues, setContactFormValues] = useState({})
	const [codeFormValues, setCodeFormValues] = useState({})
	const [planFormValues, setPlanFormValues] = useState({})
	const [formCompletedState, setFormCompletedState] = useState({
		0: false,
		1: false,
		2: false,
		3: false,
		4: false
	})

	const [currentValidationIndex, setCurrentValidationIndex] = useState(null)
	const [token, setToken] = useState(null)
	const {getAccessTokenSilently} = useAuth0()
	const dispatch = useDispatch()

	const {apiState, createEmployerState} = useSelector(employerSelector)

	useEffect(() => {
		async function fetchData() {
			const token = await getAccessTokenSilently()
			setToken(token)
			dispatch(fetchMasterCodes({token}))
		}

		fetchData()
	}, [])

	const navigateToNextTab = tabId => {
		if (formCompletedState[tabId - 1] || tabId === 0) {
			setActiveIndex(tabId)
		} else {
			setCurrentValidationIndex(activeIndex)
		}
	}

	const _handleGeneralSectionComplete = data => {
		setGeneralFormValues(data)
		setFormCompletedState({...formCompletedState, 0: true})
		setActiveIndex(1)
	}

	const onContactSectionComplete = data => {
		setContactFormValues(data)
		setActiveIndex(2)
		setFormCompletedState({...formCompletedState, 1: true})
	}

	const onCodeSectionComplete = data => {
		setCodeFormValues(data)
		setFormCompletedState({...formCompletedState, 2: true})
		setActiveIndex(3)
	}

	const onPlanSectionComplete = async data => {
		console.log(data)

		setPlanFormValues(data)
		setFormCompletedState({...formCompletedState, 4: true})

		let codeObj = {
			codeType: codeFormValues.codeType,
			codeLabel: codeFormValues.codeLabel,
			codeStartDate: codeFormValues.codeStartDate,
			masterCode: codeFormValues.masterCode,
			course: codeFormValues.course,
			maxUser: codeFormValues.maxUser,
			coachingCalls: codeFormValues.coachingCalls,
			therapyCalls: codeFormValues.therapyCalls,
			budgetAlert: codeFormValues.budgetAlert,
			budgetexceedAction: codeFormValues.budgetexceedAction,
			emailResults: codeFormValues.emailResults,
			budgetAmount: codeFormValues.budgetAmount,
			status: 1
		}

		if (codeFormValues.masterCode) {
			codeObj["masterCode"] = codeFormValues.masterCode
		}

		const {combinedBookingCaps, therapyBookingCaps, coachingBookingCaps} = codeFormValues.caps.callCapPeriod[0]

		if (!token) {
			;(async () => {
				const tkn = await getAccessTokenSilently()
				setToken(tkn)
			})()
		}

		dispatch(
			createEmployer({
				data: {
					code: codeFormValues.employerCode,
					expiryDate: codeFormValues.codeExpiryDate,
					data: {
						general: generalFormValues,
						// contract: contractFormValues,
						contact: contactFormValues.contact,
						code: codeObj,
						plan: data
					},
					caps: {
						capCalls: codeFormValues.caps.capCalls,
						callCapPeriod: [
							{
								...codeFormValues.caps.callCapPeriod[0],
								combinedBookingCaps: combinedBookingCaps || 0,
								therapyBookingCaps: therapyBookingCaps || 0,
								coachingBookingCaps: coachingBookingCaps || 0
							}
						]
					}
				},
				token
			})
		)
	}

	return (
		<ComponentWrapper
			menuActiveIndex={2}
			subMenuActiveIndex={8}
			headerTitle='New Employer Form'
			headerRightAlignContent={
				<HeaderTableContent>
					{/* <SecondaryButton size={"small"} variant='primary' title={"Cancel"} />
					<PrimaryButton size={"small"} variant='primary' title={"Save"} /> */}
				</HeaderTableContent>
			}>
			{createEmployerState.isSuccess && (
				<Alert className='m-b-10' type='success'>
					Employer Created Successfully
				</Alert>
			)}

			{createEmployerState.isError && (
				<Alert className='m-b-10' type='error'>
					Error while Inserting data
				</Alert>
			)}

			<Container>
				{apiState && apiState.isLoading ? (
					<Loader />
				) : (
					<Fragment>
						<FormLevelContainer>
							<FormLevel onClick={() => navigateToNextTab(0)} active={activeIndex === 0}>
								<FormLevelLabelContainer firstChild={true} active={activeIndex === 0}>
									<Icon src={GeneralIcon} />
									{activeIndex === 0 ? (
										<FormLevelLabelHeader>General</FormLevelLabelHeader>
									) : (
										<FormLevelLabelText>General</FormLevelLabelText>
									)}
								</FormLevelLabelContainer>
							</FormLevel>
							<FormLevel onClick={() => navigateToNextTab(1)} active={activeIndex === 1}>
								<FormLevelLabelContainer active={activeIndex === 1}>
									<Icon src={ContactIcon} />
									{activeIndex === 1 ? (
										<FormLevelLabelHeader>Contacts</FormLevelLabelHeader>
									) : (
										<FormLevelLabelText>Contacts</FormLevelLabelText>
									)}
								</FormLevelLabelContainer>
							</FormLevel>
							<FormLevel onClick={() => navigateToNextTab(2)} active={activeIndex === 2}>
								<FormLevelLabelContainer active={activeIndex === 2}>
									<Icon src={GeneralIcon} />
									{activeIndex === 2 ? (
										<FormLevelLabelHeader>Code</FormLevelLabelHeader>
									) : (
										<FormLevelLabelText>Code</FormLevelLabelText>
									)}
								</FormLevelLabelContainer>
							</FormLevel>
							<FormLevel onClick={() => navigateToNextTab(3)} active={activeIndex === 3}>
								<FormLevelLabelContainer lastChild={true} active={activeIndex === 3}>
									<Icon src={PlanIcon} />
									{activeIndex === 3 ? (
										<FormLevelLabelHeader>Plan</FormLevelLabelHeader>
									) : (
										<FormLevelLabelText>Plan</FormLevelLabelText>
									)}
								</FormLevelLabelContainer>
							</FormLevel>
						</FormLevelContainer>
						<ItemContainer>
							{createEmployerState && createEmployerState.isSuccess ? (
								<SuccessPage />
							) : (
								<Fragment>
									<FormItem active={activeIndex === 0}>
										<General
											triggerValidation={currentValidationIndex === 0}
											onComplete={_handleGeneralSectionComplete}
											onCancel={() => {
												history.push("/employers/new")
											}}
										/>
									</FormItem>
									<FormItem active={activeIndex === 1}>
										<Contact
											onComplete={onContactSectionComplete}
											triggerValidation={currentValidationIndex === 1}
											onPrevious={() => setActiveIndex(0)}
										/>
									</FormItem>
									<FormItem active={activeIndex === 2}>
										<Code
											onComplete={onCodeSectionComplete}
											triggerValidation={currentValidationIndex === 2}
											onPrevious={() => setActiveIndex(1)}
										/>
									</FormItem>
									<FormItem active={activeIndex === 3}>
										<Plan
											onComplete={onPlanSectionComplete}
											triggerValidation={currentValidationIndex === 3}
											onPrevious={() => setActiveIndex(2)}
										/>
									</FormItem>
								</Fragment>
							)}
						</ItemContainer>
					</Fragment>
				)}
			</Container>
		</ComponentWrapper>
	)
}

export default Forms
