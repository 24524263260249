import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"

import {post, get} from "helpers/api"

export const initialState = {
	loading: false,
	hasErrors: false,
	bookings: {
		total: 0,
		data: []
	},
	coaches: [],
	coachesAvailability: {},
	coachAvailability: {},
	fetchCoachesApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: "",
		data: []
	},
	fetchAllCoachesAvailabilityApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	fetchCoachAvailabilityApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	fetchCoachesAvailabilityApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	fetchBookingsApiState: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		message: ""
	},
	cancelBookingApiState: {
		isSuccess: false,
		isFetching: false,
		isError: false,
		message: ""
	},
	createBookingApiState: {
		isSuccess: false,
		isFetching: false,
		isError: false,
		message: ""
	},
	createBackDateBookingApiState: {
		isSuccess: false,
		isFetching: false,
		isError: false,
		message: ""
	}
}

export const fetchBookingsData = createAsyncThunk(
	"booking/fetchBookingsData",
	async ({coachId, limit, skip, user, search, filter, token}, thunkAPI) => {
		let response = await get(
			`v2/bookings/coach/${coachId}?limit=${limit}&skip=${skip}&search=${search}&filter=${filter}&isAdmin=${
				user["https://app.uprise.co/app_metadata"].role === "admin"
			}`,
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.status === 200) {
			return response
		} else {
			return thunkAPI.rejectWithValue("Error while Fetching Coach Availability")
		}
	}
)

export const cancelBooking = createAsyncThunk(
	"booking/cancel-booking",
	async ({coachId, eventId, userEmail, bookingId, cancelReason, status, token}, thunkAPI) => {
		const response = await post(
			`v2/bookings/cancel`,
			{coachId, eventId, userEmail, bookingId, cancelReason, status},
			{},
			{Authorization: `Bearer ${token}`}
		)

		if (response.error) {
			return thunkAPI.rejectWithValue(response)
		} else {
			return response
		}
	}
)

export const fetchCoaches = createAsyncThunk("booking/fetchAllCoaches", async ({token}, thunkAPI) => {
	let response = await get(`v2/coaches?disabled=0`, {}, {Authorization: `Bearer ${token}`})

	if (response.error) {
		return thunkAPI.rejectWithValue("Error while fetching Coaches")
	} else {
		return response
	}
})

export const fetchAllCoachesAvailability = createAsyncThunk(
	"booking/fetchAllCoachesAvailability",
	async ({data, token}, thunkAPI) => {
		let response = await post("v2/coaches/availability", data, {}, {Authorization: `Bearer ${token}`})

		if (response.status === 200) {
			return response
		} else {
			return thunkAPI.rejectWithValue("Error while fetching all coaches availability")
		}
	}
)

export const fetchCoachAvailability = createAsyncThunk(
	"booking/fetchCoachAvailability",
	async ({data, token}, thunkAPI) => {
		let response = await post("v2/coach/availability", data, {}, {Authorization: `Bearer ${token}`})

		if (response.status === 200) {
			return response
		} else {
			return thunkAPI.rejectWithValue("Error while fetching coach availability")
		}
	}
)

export const createBackDateBooking = createAsyncThunk("booking/createDateBooking", async ({data, token}, thunkAPI) => {
	let response = await post("v2/bookings/create/back", data, {}, {Authorization: `Bearer ${token}`})

	if (response.error) {
		return thunkAPI.rejectWithValue(response)
	} else {
		return response
	}
})

export const createBooking = createAsyncThunk("booking/createBooking", async ({data, token}, thunkAPI) => {
	let response = await post("v2/bookings/create", data, {}, {Authorization: `Bearer ${token}`})
	if (response.error) {
		return thunkAPI.rejectWithValue(response)
	} else {
		return response
	}
})

const bookingSlice = createSlice({
	name: "booking",
	initialState: initialState,
	reducers: {
		clearCoachAvailability: state => {
			state.coachesAvailability = {}

			return state
		},
		clearAllBookings: state => {
			state.coachAvailability = {}

			return state
		},
		clearCancelBookingApiState: state => {
			state.cancelBookingApiState.isError = false
			state.cancelBookingApiState.isSuccess = false
			state.cancelBookingApiState.message = ""
			return state
		},
		clearBookingApiState: state => {
			state.bookings.data = []
			state.fetchBookingsApiState.isError = false
			state.fetchBookingsApiState.isSuccess = false
			state.fetchBookingsApiState.message = ""

			state.cancelBookingApiState.isError = false
			state.cancelBookingApiState.isSuccess = false
			state.cancelBookingApiState.message = ""

			state.createBackDateBookingApiState.isSuccess = false
			state.createBackDateBookingApiState.isError = false
			state.createBackDateBookingApiState.message = ""

			state.createBookingApiState.isSuccess = false
			state.createBookingApiState.isError = false
			state.createBookingApiState.message = ""

			state.fetchAllCoachesAvailabilityApiState.isSuccess = false
			state.fetchAllCoachesAvailabilityApiState.isError = false
			state.fetchAllCoachesAvailabilityApiState.message = ""

			state.createBookingApiState.isSuccess = false
			state.createBookingApiState.isError = false
			state.createBookingApiState.isFetching = false
			state.createBookingApiState.message = ""

			state.coachesAvailability = {}
			return state
		}
	},
	extraReducers: {
		[fetchBookingsData.pending]: state => {
			state.fetchBookingsApiState.isFetching = true
		},
		[fetchBookingsData.rejected]: (state, {payload}) => {
			state.fetchBookingsApiState.isFetching = false
			state.fetchBookingsApiState.isError = true
			state.fetchBookingsApiState.message = payload?.error?.message || "Error, please contact tech support"
		},
		[fetchBookingsData.fulfilled]: (state, {payload}) => {
			state.bookings.total = payload.totalCount
			state.bookings.data = payload.data
			state.fetchBookingsApiState.isFetching = false
			state.fetchBookingsApiState.isError = false
			state.fetchBookingsApiState.isSuccess = true
		},
		[fetchCoaches.pending]: state => {
			state.fetchCoachesApiState.isFetching = true
			state.fetchCoachesApiState.isError = false
			state.fetchCoachesApiState.isSuccess = false
		},
		[fetchCoaches.fulfilled]: (state, {payload}) => {
			state.fetchCoachesApiState.isFetching = false
			state.fetchCoachesApiState.isError = false
			state.fetchCoachesApiState.isSuccess = true
			state.fetchCoachesApiState.data = Object.entries(payload.data).map(([key, value]) => value)
		},
		[fetchCoaches.rejected]: (state, {payload}) => {
			state.fetchCoachesApiState.isFetching = false
			state.fetchCoachesApiState.isError = true
			state.fetchCoachesApiState.isSuccess = false
			state.fetchCoachesApiState.message = payload
		},
		[cancelBooking.pending]: state => {
			state.cancelBookingApiState.isSuccess = false
			state.cancelBookingApiState.isFetching = true
		},
		[cancelBooking.rejected]: (state, {payload}) => {
			state.cancelBookingApiState.isSuccess = false
			state.cancelBookingApiState.isError = true
			state.cancelBookingApiState.message = payload?.error?.message || "Error, please contact tech support"
		},
		[cancelBooking.fulfilled]: (state, {payload}) => {
			state.cancelBookingApiState.isFetching = false
			state.bookings.data = state.bookings.data.filter(booking => booking.id !== payload.bookingId)
			state.cancelBookingApiState.isSuccess = true
			state.cancelBookingApiState.message = "Booking has been successfully cancelled"
		},
		[fetchAllCoachesAvailability.pending]: state => {
			state.fetchAllCoachesAvailabilityApiState.isFetching = true
			state.fetchAllCoachesAvailabilityApiState.isError = false
			state.fetchAllCoachesAvailabilityApiState.isSuccess = false
		},
		[fetchAllCoachesAvailability.fulfilled]: (state, {payload}) => {
			state.fetchAllCoachesAvailabilityApiState.isFetching = false
			state.fetchAllCoachesAvailabilityApiState.isSuccess = true
			state.fetchAllCoachesAvailabilityApiState.isError = false
			state.coachesAvailability = payload
		},
		[fetchAllCoachesAvailability.rejected]: state => {
			state.fetchAllCoachesAvailabilityApiState.isError = true
			state.fetchAllCoachesAvailabilityApiState.isFetching = false
			state.fetchAllCoachesAvailabilityApiState.isSuccess = false
			state.fetchAllCoachesAvailabilityApiState.message = "Something went wrong while fetching availability"
		},
		[fetchCoachAvailability.fulfilled]: (state, {payload}) => {
			state.fetchCoachAvailabilityApiState.isFetching = false
			state.fetchCoachAvailabilityApiState.isSuccess = true
			state.coachAvailability = payload
		},
		[fetchCoachAvailability.rejected]: state => {
			state.fetchCoachAvailabilityApiState.isError = true
			state.fetchCoachAvailabilityApiState.isFetching = false
			state.fetchCoachAvailabilityApiState.message = "Something went wrong while fetching availability"
		},
		[fetchCoachAvailability.pending]: state => {
			state.fetchCoachAvailabilityApiState.isFetching = true
		},
		[createBooking.pending]: state => {
			state.createBookingApiState.message = ""
			state.createBookingApiState.isFetching = true
			state.createBackDateBookingApiState.isSuccess = false
			state.createBookingApiState.isError = false
		},
		[createBooking.rejected]: (state, {payload}) => {
			state.createBookingApiState.isError = true
			state.createBookingApiState.isFetching = false
			state.createBackDateBookingApiState.isFetching = false
			state.createBackDateBookingApiState.isSuccess = false

			if (payload.error === "BadEmployerCode") {
				state.createBookingApiState.message = "Access code is invalid"
			} else if (payload.error === "BOOKING_EVENT_FAILED") {
				state.createBookingApiState.message = "Error updating calendar, please contact tech support"
			} else {
				state.createBookingApiState.message = payload.message
			}
		},
		[createBooking.fulfilled]: state => {
			state.createBookingApiState.isSuccess = true
			state.createBookingApiState.isFetching = false
			state.createBookingApiState.isError = false
			state.createBookingApiState.message = ""
		},
		[createBackDateBooking.pending]: state => {
			state.createBackDateBookingApiState.message = ""
		},
		[createBackDateBooking.rejected]: (state, {payload}) => {
			state.createBackDateBookingApiState.isError = true
			state.createBackDateBookingApiState.isFetching = false
			state.createBackDateBookingApiState.isSuccess = false
			state.createBackDateBookingApiState.message = payload.message
		},
		[createBackDateBooking.fulfilled]: state => {
			state.createBackDateBookingApiState.isSuccess = true
			state.createBackDateBookingApiState.isError = false
			state.createBackDateBookingApiState.isFetching = false
			state.createBackDateBookingApiState.message = ""
		}
	}
})

export const {
	clearCoachAvailability,
	cancelBookingApiState,
	clearCancelBookingApiState,
	clearBookingApiState,
	clearAllBookings
} = bookingSlice.actions

export const bookingDataSelector = state => state.booking

export default bookingSlice
