export const OptionMenuArray = [
	// {
	// 	label: "Share",
	// 	value: "share",
	// 	icon: "icon-share"
	// },
	{
		label: "Edit",
		value: "edit",
		icon: "icon-edit"
	}
	// {
	// 	label: "Archive",
	// 	value: "archive",
	// 	icon: "icon-archive"
	// }
]
