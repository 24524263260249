import React from "react"
import styled from "styled-components"

const CheckboxContainer = styled.div`
	display: flex;
	vertical-align: middle;
	align-self: center;

	&:hover {
		cursor: pointer;
	}
`

const Icon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 3px;
	transform: ${props => (props.iconYPos ? `translateY(${props.iconYPos}%)` : "translateY(-3%)")};
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({type: "checkbox"})`
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`

const StyledCheckbox = styled.div`
	display: inline-block;
	width: ${props => (props.width ? `${props.width}px` : "16px")};
	height: ${props => (props.height ? `${props.height}px` : "16px")};
	background-color: ${props => (props.checked ? "#7d60ff" : "#fff")};
	border-radius: 3px;
	transition: all 150ms;
	border: 1px solid #6d6c79;

	${HiddenCheckbox}:focus + & {
		box-shadow: 0 0 0 3px #b7aafa;
	}

	${Icon} {
		visibility: ${props => (props.checked ? "visible" : "hidden")};
	}
`

const Checkbox = ({className, width, height, checked, iconYPos, testId, onChange, ...props}) => (
	<CheckboxContainer className={className} data-testid={testId}>
		<HiddenCheckbox checked={checked} {...props} />
		<StyledCheckbox width={width} height={height} checked={checked} onClick={onChange}>
			<Icon viewBox='0 0 24 24' preserveAspectRatio='xMidYMin' iconYPos={iconYPos}>
				<polyline points='20 6 9 17 4 12' />
			</Icon>
		</StyledCheckbox>
	</CheckboxContainer>
)

export default Checkbox
