import React, {Fragment, useEffect, useMemo} from "react"
import {useTable, usePagination, useExpanded, useBlockLayout, useSortBy} from "react-table"
import ReactPagination from "react-paginate"
// UI
import {P} from "@uprise/text"
import {H4} from "@uprise/headings"
import Preloader from "../Preloader/Preloader"
import Styled from "styled-components"
// Styles
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableHeader,
	TableData,
	Pagination,
	PaginationButtonContainer,
	PaginationButton,
	PaginationIndex,
	RightIconSpan,
	LeftIconSpan,
	NextButtonIcon,
	BackButtonIcon,
	NoDataWrap,
	PaginationContainer
} from "./table.style"
import styles from "./index.module.css"

const SET_PAGE_INDEX = "SET_PAGE_INDEX"

const TableSortedHeader = Styled.span`
	display: flex;
	justify-content: center;
  align-items: center;
	position: relative;
  padding-right: 12px;
`
const SortIcon = Styled.span`
	position: absolute;
	right: 10px;
`

const ArrowIcon = Styled.span`
  border: ${props => (props.sortingUsed ? "solid #7d60ff" : "solid #ccc")};
	border-width: 0 1px 1px 0;
	padding: 3px;
	display: inline-block;
	transform: ${props => (props.direction === 1 ? "rotate(225deg)" : "rotate(45deg)")};
	transition: transform 0.1s linear;
	margin-left: 24px;
	top: ${props => (props.direction === 1 ? "1px" : "-2px")};
    	position: relative;

	&:hover {
		cursor: pointer;
	}
`

export const PAGE_SIZE = 20

const TableComponent = ({
	columns,
	data,
	fetchData,
	pageCount: controlledPageCount,
	loading = true,
	isPaginated = true,
	...props
}) => {
	const defaultColumn = useMemo(
		() => ({
			// minWidth: 20,
			// maxWidth: 115
		}),
		[]
	)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setHiddenColumns,
		dispatch,
		state: {pageIndex, pageSize}
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: {
				pageIndex: 0,
				pageSize: props.pageSize ? props.pageSize : PAGE_SIZE,
				hiddenColumns: columns.filter(column => !column.show).map(column => column.id)
			},
			stateReducer: (newState, action, prevState) => {
				switch (action.type) {
					case SET_PAGE_INDEX:
						return {
							...newState,
							pageIndex: action.payload
						}
					default:
						return newState
				}
			},
			manualPagination: true,
			// manualSortBy: true,
			autoResetPage: false,
			pageCount: controlledPageCount
		},
		useSortBy,
		useExpanded,
		usePagination
	)
	useEffect(() => {
		fetchData && fetchData({pageIndex, pageSize})
	}, [fetchData, pageIndex, pageSize])

	return (
		<Fragment>
			{loading && <Preloader />}

			{data.length === 0 && !loading && (
				<NoDataWrap>
					<H4 className='m-t-0 m-b-0'>No results found</H4>
				</NoDataWrap>
			)}

			{data.length > 0 && !loading && (
				<Fragment>
					<Table cellspacing='0' {...getTableProps()}>
						<TableHead>
							{headerGroups.map(headerGroup => (
								<TableRow {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<TableHeader
											width={column.width}
											{...column.getHeaderProps(column.getSortByToggleProps())}
											onClick={() => column?.toggleSortBy?.(!column.isSortedDesc)}>
											<TableSortedHeader>
												{column.render("Header")}
												{!column.disableSortBy && (
													<SortIcon>
														{column.isSortedDesc ? (
															<ArrowIcon direction={0} sortingUsed={column.isSorted} />
														) : (
															<ArrowIcon direction={1} sortingUsed={column.isSorted} />
														)}
													</SortIcon>
												)}
											</TableSortedHeader>
										</TableHeader>
									))}
								</TableRow>
							))}
						</TableHead>

						<TableBody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row)
								return (
									<TableRow
										className='tableRow'
										{...row.getRowProps({
											style: {
												// This Applies the background color only to the sub rows. to find the subrows, we are using row depth
												background: row.depth > 0 ? "#f8f8ff" : ""
											}
										})}>
										{row.cells.map((cell, i) => {
											return (
												<TableData
													alignText={i === 1 || i === 2 ? "center" : "left"}
													{...cell.getCellProps({
														style: {wordWrap: "break-word"}
													})}>
													{cell.render("Cell")}
												</TableData>
											)
										})}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>

					{pageCount > 1 && (
						<PaginationContainer>
							<ReactPagination
								breakClassName={styles["page-item"]}
								breakLinkClassName={styles["page-link"]}
								containerClassName={styles.pagination}
								pageClassName={styles["page-item"]}
								pageLinkClassName={styles["page-link"]}
								previousClassName={styles["page-item"]}
								previousLinkClassName={styles["page-link"]}
								nextClassName={styles["page-item"]}
								nextLinkClassName={styles["page-link"]}
								activeClassName={styles.active}
								disabledClassName={styles.disabled}
								pageCount={pageOptions.length}
								forcePage={pageIndex}
								breakLabel={null}
								pageRangeDisplayed={9}
								marginPagesDisplayed={0}
								onPageChange={({selected}) => {
									gotoPage(selected)
									dispatch({
										type: SET_PAGE_INDEX,
										payload: selected
									})
								}}
							/>
						</PaginationContainer>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

export default TableComponent
