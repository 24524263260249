import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {post, get} from "helpers/api"
export const initialState = {
	fetchCoachByIdApiState: {
		isFetching: false,
		isError: false,
		isSuccess: false,
		message: "",
		coach: []
	},
	createCoachApiState: {
		isFetching: false,
		isError: false,
		isSuccess: false,
		message: ""
	},
	allCoachesApiState: {
		totalCount: 0,
		isFetching: false,
		isError: false,
		isSuccess: false,
		coaches: [],
		message: ""
	},
	coachUpdateApiState: {
		isFetching: false,
		isError: false,
		isSuccess: false,
		message: ""
	},
	coachCreateApiState: {
		isFetching: false,
		isError: false,
		isSuccess: false,
		message: ""
	},
	coachStatusApiState: {
		isFetching: false,
		isError: false,
		isSuccess: false,
		message: ""
	},
	coachesCalendarApiState: {
		isFetching: false,
		isError: false,
		isSuccess: false,
		message: ""
	},
	validCals: {}
}

export const fetchCoachesData = createAsyncThunk(
	"coaches/fetchCoachesData",
	async ({limit, skip, searchTerm, token}, thunkAPI) => {
		const response = await get(`v2/coaches?limit=${limit}&skip=${skip}&search=${searchTerm}`, null, {
			Authorization: `Bearer ${token}`
		})

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const fetchCoachesCalendarData = createAsyncThunk(
	"coaches/fetchCoachesCalendarData",
	async ({token}, thunkAPI) => {
		const response = get(`v2/coaches/calendars`, null, {
			Authorization: `Bearer ${token}`
		}).then(value => {
			const validCals = {}
			value.forEach(coach => {
				validCals[coach.id] = coach.accessRole
			})
			return validCals
		})

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const toggleCoachStatus = createAsyncThunk(
	"coaches/toggleCoachStatus",
	async ({token, id, status}, thunkAPI) => {
		const response = await post("v2/coaches/status", {id, status}, null, {
			Authorization: `Bearer ${token}`
		})

		if (response.error) {
			return thunkAPI.rejectWithValue(response.error)
		} else {
			return response
		}
	}
)

export const fetchCoachById = createAsyncThunk("coaches/fetchCoachById", async ({token, id}, thunkAPI) => {
	const response = await Promise.all([
		get(`v2/coaches/${id}`, null, {
			Authorization: `Bearer ${token}`
		}),
		get(`v2/coaches/calendars`, null, {
			Authorization: `Bearer ${token}`
		}).then(value => {
			const validCals = {}
			value.forEach(coach => {
				validCals[coach.id] = coach.accessRole
			})
			return validCals
		})
	])

	if (response[0].error) {
		return thunkAPI.rejectWithValue(response[0].error)
	} else if (response[1].error) {
		return thunkAPI.rejectWithValue(response[1].error)
	} else {
		return response
	}
})

export const createCoach = createAsyncThunk("coaches/createCoach", async ({token, history, data}, thunkAPI) => {
	const response = await post(`v2/coaches/create`, data, null, {
		Authorization: `Bearer ${token}`
	})

	if (response.error) {
		return thunkAPI.rejectWithValue(response)
	} else {
		history.push(`/coaches/${response.id}`)
		return response
	}
})

export const updateCoach = createAsyncThunk("coaches/updateCoach", async ({token, history, data}, thunkAPI) => {
	const response = await post(
		`v2/coaches/update`,
		data,
		{},
		{
			Authorization: `Bearer ${token}`
		}
	)

	if (response.error) {
		return thunkAPI.rejectWithValue(response)
	} else {
		history.push(`/coaches/${response.data.id}`)

		return response
	}
})

const coachSlice = createSlice({
	name: "coach",
	initialState: initialState,
	reducers: {
		clearCoachCreateApiState: state => {
			state.coachCreateApiState.isSuccess = false
			state.coachCreateApiState.isError = false
			state.coachCreateApiState.isFetching = false
			state.coachCreateApiState.message = ""
			return state
		},
		clearCoachUpdateApiState: state => {
			state.coachUpdateApiState.isSuccess = false
			state.coachUpdateApiState.isError = false
			state.coachUpdateApiState.isFetching = false
			state.coachUpdateApiState.message = ""
			return state
		},
		clearCoachStatusApiState: state => {
			state.coachStatusApiState.isSuccess = false
			state.coachStatusApiState.isError = false
			state.coachStatusApiState.isFetching = false
			state.coachStatusApiState.message = ""
			return state
		},
		clearCoachesDataState: state => {
			state.allCoachesApiState.isSuccess = false
			state.allCoachesApiState.isError = false
			state.allCoachesApiState.isFetching = false
			state.allCoachesApiState.message = ""
			state.allCoachesApiState.coaches = []
			return state
		}
	},
	extraReducers: {
		[createCoach.pending]: state => {
			state.createCoachApiState.isFetching = true
			state.createCoachApiState.isError = false
			state.createCoachApiState.isSuccess = false
			state.createCoachApiState.message = ""
		},
		[createCoach.rejected]: (state, {payload}) => {
			switch (payload.error) {
				case "ValidationFail":
					switch (payload.errors[0].property) {
						case "instance.name":
							payload.message = "Please enter valid name of between 0-100 letters"
							break
						case "instance.availabilityHours":
							payload.message = "Please enter valid availabilityHours of between 0-100 letters"
							break
						case "instance.location":
							payload.message = "Please enter valid location of between 0-100 letters"

							break
						case "instance.phoneNumber":
							payload.message = "Please enter valid phone number of between 0-100 letters"

							break
						case "instance.expertise":
							payload.message = "Please enter valid expertise of between 0-100 letters"
							break
						default:
							payload.message = "Something went wrong, reload the page or come back later"
							break
					}
					break
				default:
					payload.message = "Something went wrong, reload the page or come back later"
			}

			state.createCoachApiState.isError = true
			state.createCoachApiState.isFetching = false
			state.createCoachApiState.isSuccess = false
		},
		[createCoach.fulfilled]: (state, {payload}) => {
			state.createCoachApiState.isFetching = false
			state.createCoachApiState.isError = false
			state.createCoachApiState.isSuccess = true
			state.createCoachApiState.message = "Coach created successfully"
			return state
		},
		[fetchCoachById.pending]: state => {
			state.fetchCoachByIdApiState.isFetching = true
			state.fetchCoachByIdApiState.isError = false
			state.fetchCoachByIdApiState.isSuccess = false
			state.fetchCoachByIdApiState.message = ""
		},
		[fetchCoachById.rejected]: (state, {payload}) => {
			state.fetchCoachByIdApiState.isError = true
			state.fetchCoachByIdApiState.isFetching = false
			state.fetchCoachByIdApiState.isSuccess = false
			state.fetchCoachByIdApiState.message = payload?.message
		},
		[fetchCoachById.fulfilled]: (state, {payload}) => {
			state.fetchCoachByIdApiState.isFetching = false
			state.fetchCoachByIdApiState.isError = false
			state.fetchCoachByIdApiState.isSuccess = true
			state.fetchCoachByIdApiState.coach = payload[0].data
			state.fetchCoachByIdApiState.validCals = payload[1]
			return state
		},
		[updateCoach.pending]: state => {
			state.coachUpdateApiState.isFetching = true
			state.coachUpdateApiState.isError = false
			state.coachUpdateApiState.isSuccess = false
			state.coachUpdateApiState.message = ""
		},
		[updateCoach.rejected]: (state, {payload}) => {
			switch (payload.error) {
				case "ValidationFail":
					switch (payload.errors[0].property) {
						case "instance.name":
							payload.message = "Please enter valid name of between 0-100 letters"
							break
						case "instance.availabilityHours":
							payload.message = "Please enter valid availabilityHours of between 0-100 letters"
							break
						case "instance.location":
							payload.message = "Please enter valid location of between 0-100 letters"

							break
						case "instance.phoneNumber":
							payload.message = "Please enter valid phone number of between 0-100 letters"

							break
						case "instance.expertise":
							payload.message = "Please enter valid expertise of between 0-100 letters"
							break
						default:
							payload.message = "Something went wrong, reload the page or come back later"
							break
					}
					break
				default:
					payload.message = "Something went wrong, reload the page or come back later"
			}

			state.coachUpdateApiState.isError = true
			state.coachUpdateApiState.isFetching = false
			state.coachUpdateApiState.isSuccess = false
			state.coachUpdateApiState.message = payload?.message
		},
		[updateCoach.fulfilled]: (state, {payload}) => {
			state.coachUpdateApiState.isFetching = false
			state.coachUpdateApiState.isError = false
			state.coachUpdateApiState.isSuccess = true
			state.coachUpdateApiState.message = "Coach successfully updated"
			return state
		},
		[toggleCoachStatus.pending]: state => {
			state.coachStatusApiState.isFetching = true
			state.coachStatusApiState.isError = false
			state.coachStatusApiState.isSuccess = false
			state.coachStatusApiState.message = ""
		},
		[toggleCoachStatus.rejected]: (state, {payload}) => {
			console.log(payload)
			state.coachStatusApiState.isError = true
			state.coachStatusApiState.isFetching = false
			state.coachStatusApiState.isSuccess = false
			state.coachStatusApiState.message = payload?.message
		},
		[toggleCoachStatus.fulfilled]: (state, {payload}) => {
			state.coachStatusApiState.isFetching = false
			state.coachStatusApiState.isError = false
			state.coachStatusApiState.isSuccess = true
			return state
		},
		[fetchCoachesCalendarData.pending]: state => {
			state.coachesCalendarApiState.isFetching = true
			state.coachesCalendarApiState.isError = false
			state.coachesCalendarApiState.isSuccess = false
			state.coachesCalendarApiState.message = ""
		},
		[fetchCoachesCalendarData.rejected]: (state, {payload}) => {
			state.coachesCalendarApiState.isError = true
			state.coachesCalendarApiState.isFetching = false
			state.coachesCalendarApiState.isSuccess = false
			state.coachesCalendarApiState.message = payload?.message
		},
		[fetchCoachesCalendarData.fulfilled]: (state, {payload}) => {
			state.coachesCalendarApiState.isFetching = false
			state.coachesCalendarApiState.isError = false
			state.coachesCalendarApiState.isSuccess = true
			state.coachesCalendarApiState.validCals = payload
			return state
		},
		[fetchCoachesData.pending]: state => {
			state.allCoachesApiState.isFetching = true
			state.allCoachesApiState.isError = false
			state.allCoachesApiState.isSuccess = false
			state.allCoachesApiState.message = ""
		},
		[fetchCoachesData.rejected]: (state, {payload}) => {
			state.allCoachesApiState.isError = true
			state.allCoachesApiState.isFetching = false
			state.allCoachesApiState.isSuccess = false
			state.allCoachesApiState.message = payload.message
		},
		[fetchCoachesData.fulfilled]: (state, {payload}) => {
			state.allCoachesApiState.isFetching = false
			state.allCoachesApiState.isError = false
			state.allCoachesApiState.isSuccess = true
			state.allCoachesApiState.coaches = payload.data
			state.allCoachesApiState.totalCount = payload.totalCount
			return state
		}
	}
})

export const {clearCoachesDataState, clearCoachStatusApiState, clearCoachCreateApiState, clearCoachUpdateApiState} =
	coachSlice.actions

export const coachDataSelector = state => state.coaches
export const coachByIdSelector = state => state.coaches.coachById
export const createCoachSelector = state => state.coaches
export const coachCalendarSelector = state => state.coaches.validCals

export default coachSlice
