import React, {useState, Fragment, useEffect, useRef} from "react"
import {useAuth0} from "@auth0/auth0-react"
import {Modal} from "@uprise/modal"
import {useDispatch} from "react-redux"
import {Controller, useForm} from "react-hook-form"
import moment from "moment"

// Components
import {ConfirmModal} from "components/shared/ConfirmModal"
// UI
import {TextArea, TextInput} from "@uprise/form"
import {Button} from "@uprise/button"
import {spacing} from "@uprise/spacing"
// Slices
import {fetchUserNotes, createUserNotes, updateUserNote, deleteUserNote} from "../../Users/usersSlice"
// API
import {post} from "helpers/api"
// styles
import {FormElementContainer, FormInputContainer} from "../../../shared/Form/index.style"
import {ButtonWrap} from "./index.style"

export const Note = ({note, userEmail, handleCancel}) => {
	console.log(note)
	const [edit, setEdit] = useState(false)
	const [newNote, setNewNote] = useState(note.type === "new")
	const [openCreateModal, setOpenCreateModal] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)

	const {getAccessTokenSilently} = useAuth0()
	const dispatch = useDispatch()

	const {trigger, control, handleSubmit, errors} = useForm({
		defaultValues: {content: note?.content || "", title: note?.title || ""},
		reValidateMode: "onChange",
		mode: "onChange"
	})

	async function handleFetchNotes() {
		const token = await getAccessTokenSilently()
		const coachId = note.coachId

		dispatch(fetchUserNotes({userEmail, coachId, token}))
	}

	const _handleCreateModal = async () => {
		const valid = await trigger()

		if (valid) {
			setOpenCreateModal(true)
		}
	}

	const _handleCreate = handleSubmit(async data => {
		data = {
			client: userEmail,
			coachId: note.coachId,
			title: data.title,
			content: data.content
		}

		try {
			const token = await getAccessTokenSilently()

			dispatch(
				createUserNotes({userEmail, coachId: note.coachId, title: data.title, content: data.content, token})
			)

			setOpenCreateModal(false)
			setNewNote(false)
			setEdit(false)
		} catch (err) {
			console.log(err)
		}
	})

	const _handleDeleteModal = () => {
		setOpenDeleteModal(true)
	}

	const _deleteNote = async () => {
		const token = await getAccessTokenSilently()
		dispatch(deleteUserNote({id: note.id, token: token}))
		setOpenDeleteModal(false)
	}

	const _handleEdit = () => {
		setEdit(edit ? false : true)
	}

	const _handleUpdate = handleSubmit(async data => {
		const token = await getAccessTokenSilently()

		dispatch(
			updateUserNote({
				id: note.id,
				userEmail,
				coachId: note.coachId,
				title: data.title,
				content: data.content,
				token
			})
		)

		setEdit(false)
	})

	useEffect(() => {
		setEdit(newNote)
	}, [])

	return (
		<Fragment>
			<FormElementContainer error={errors.note} className='m-t-5'>
				<FormInputContainer>
					<TextInput
						name='date'
						label='Date'
						disabled={true}
						outline={false}
						border={true}
						value={moment(note.createdAt).format("LLLL")}
						padding={spacing.s3}
						onChange={() => {}}
						type='text'
						id='date'
					/>

					<Controller
						name={"title"}
						control={control}
						defaultValue={note.title}
						rules={{
							required: {value: true, message: "Title is required"}
						}}
						render={props => {
							return (
								<Fragment>
									<TextInput
										name='title'
										label='Title'
										disabled={edit ? false : true}
										outline={false}
										border={true}
										padding={spacing.s3}
										validation={errors}
										type='text'
										placeholder='Title'
										id='title'
										value={props.value}
										onChange={event => props.onChange(event.target.value)}
									/>
								</Fragment>
							)
						}}
					/>
					<Controller
						name={"content"}
						control={control}
						defaultValue={note.content}
						rules={{
							required: {value: true, message: "Note is required"}
						}}
						render={props => {
							return (
								<Fragment>
									<TextArea
										name='content'
										minHeight='200px'
										label='Note'
										disabled={edit ? false : true}
										outline={false}
										validation={errors}
										value={props.value}
										onChange={event => props.onChange(event.target.value)}
										border={true}
										padding={spacing.s3}
										placeholder='Your session note..'
										id='content'
									/>
								</Fragment>
							)
						}}
					/>

					<Fragment>
						{newNote ? (
							<ButtonWrap className='m-b-10'>
								<Button
									className='m-t-0'
									variant='primary'
									title='Cancel'
									size='small'
									fullWidth={false}
									onClick={handleCancel}
								/>

								<Button
									className='m-t-0 m-b-10  '
									variant='primary'
									title='Create'
									size='small'
									fullWidth={false}
									onClick={_handleCreateModal}
								/>
							</ButtonWrap>
						) : (
							<ButtonWrap className='m-b-10'>
								<Button
									className='m-t-0'
									variant='primary'
									title='Delete'
									size='small'
									fullWidth={false}
									onClick={_handleDeleteModal}
								/>

								<Button
									className='m-t-0'
									variant='primary'
									title={edit ? "Update" : "Edit"}
									size='small'
									fullWidth={false}
									onClick={edit ? _handleUpdate : _handleEdit}
								/>
							</ButtonWrap>
						)}

						<ConfirmModal
							openModal={openDeleteModal}
							handleSubmit={() => _deleteNote()}
							handleClose={() => setOpenDeleteModal(false)}>
							Are you sure you want to delete this note?
						</ConfirmModal>
						<ConfirmModal
							openModal={openCreateModal}
							handleSubmit={() => _handleCreate()}
							handleClose={() => setOpenCreateModal(false)}>
							Are you sure you want to submit?
						</ConfirmModal>
					</Fragment>
				</FormInputContainer>
			</FormElementContainer>
		</Fragment>
	)
}
