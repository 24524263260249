import styled from "styled-components"
import {H6} from "@uprise/headings"
import {extended, semantic} from "@uprise/colors"

export const BookingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	padding: 10px;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #f8f8ff;
	border-radius: 10px;
	width: 100%;
`

export const FormContainer = styled.div`
	display: flex;
`
export const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	border-bottom: ${props => (props.error ? `1px solid ${semantic.error}` : `1px solid ${extended.purple.five}`)};
	padding: 8px 0;
`

export const FormElementLabel = styled(H6)`
	margin: 10px 0 10px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`
export const FormInputContainer = styled.div`
	flex-basis: 75%;
	position: relative;
	align-self: center;
	margin-bottom: ${props => (props.error ? '8px' : '0px')};
	
	> div {
		min-height: 0px;
	}
	input {
		font-family: "Proxima Nova";
	}
`