import React, {Fragment, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import styled from "styled-components"
import moment from "moment"
import {useAuth0} from "@auth0/auth0-react"
// UI
import {Row, Col} from "@uprise/grid"
import {Modal} from "@uprise/modal"
import {H5, H4} from "@uprise/headings"
import {Button} from "@uprise/button"
import {spacing} from "@uprise/spacing"
import {Card} from "@uprise/card"
import {Alert} from "@uprise/alert"
// Assets
import CoachesSVG from "assets/images/svg/icon-coaches.svg"
import Clock from "assets/images/png/clock-svg.png"
import CalIcon from "assets/images/png/calendar.png"
// Slices
import {userDataSelector, fetchBookingsByUserEmail} from "../usersSlice"
import {fetchEmployerByCode, employerSelector} from "../../Employers/employerSlice"
import {bookingDataSelector, cancelBooking} from "../../Bookings/bookingSlice"
// Styles
import {NoDataDiv} from "./index.style"
import {ModalButtonContainer, RadioLabel, RadioDiv} from "../../Bookings/AllBookingsTable/index.style"
// Helpers
import {cancelReason, cancelStatus} from "helpers/constants"
// Components
import Booking from "./Booking"
import {StateChangeTypes} from "downshift"
// helpers
import {calculateCaps} from "../../../helpers/utils"

export const HeaderTableContent = styled.div`
	display: flex;
`

export const CustomButton = styled(Button)`
	margin: 5px;
`

const Input = styled.input`
	margin-bottom: ${spacing.s3};
`

const Bookings = () => {
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()
	const [openModal, setOpenModal] = useState(false)
	const [selectedUserBookingToDelete, setSelectedUserBookingToDelete] = useState(null)
	const [cancellingBookingBefore24Hours, setCancellingBookingBefore24Hours] = useState(false)
	const [cancelReasonState, setCancelReasonState] = useState("within24")
	const [token, setToken] = useState(null)
	const [futureBookings, setFutureBookings] = useState([])
	const [pastBookings, setPastBookings] = useState([])
	const [cancelledBookings, setCancelledBookings] = useState([])
	const [callsRemaining, setCallsRemaining] = useState(0)
	const [capsReached, setCapsReached] = useState(false)
	const [status, setStatus] = useState("coach_cancelled")

	const {bookings, profile, userBookingsApiState} = useSelector(userDataSelector)
	const {cancelBookingApiState} = useSelector(bookingDataSelector)
	const {selectedEmployerDetails} = useSelector(employerSelector)

	const _handleCancelBooking = async () => {
		const authToken = await getAccessTokenSilently()
		setToken(authToken)

		dispatch(
			cancelBooking({
				coachId: selectedUserBookingToDelete.coachId,
				eventId: selectedUserBookingToDelete.eventId,
				bookingId: selectedUserBookingToDelete.id,
				userEmail: selectedUserBookingToDelete.email,
				cancelReason: cancelReasonState,
				token: authToken,
				status: status === "coach_cancelled"
			})
		)
		setOpenModal(false)
	}

	const _checkCancelOption = value => {
		setCancelReasonState(value)
	}

	const _checkCancelStatus = value => {
		setStatus(value)
	}

	const _handleModal = booking => {
		setSelectedUserBookingToDelete(booking)
		let bookingStart = moment(booking.start).utc().subtract(1, "days")
		let currentTime = moment().utc()

		if (bookingStart > currentTime) {
			setCancellingBookingBefore24Hours(true)
		}
	}

	useEffect(() => {
		if (cancelBookingApiState.isSuccess) {
			const getToken = async () => {
				const authToken = await getAccessTokenSilently()
				dispatch(fetchBookingsByUserEmail({userEmail: profile?.email, token: authToken}))
			}
			getToken()
		}
	}, [cancelBookingApiState])

	useEffect(() => {
		const {companyCallCap, finalCaps} = calculateCaps(selectedEmployerDetails)
		let future = [],
			past = [],
			cancelled = []

		console.log("bookings", bookings)

		let totalValidBookings =
			bookings
				?.filter(booking => {
					return booking?.id
				})
				.filter(booking => moment(booking.createdAt).diff(moment(finalCaps?.startingDate), "days") >= 0)
				.filter(booking => {
					if (["within24", "within_24", "userNoShow", "user_no_show"].includes(booking.cancelReason))
						return true
					else return !booking.cancelled
				}) || []

		bookings?.map(booking => {
			if (booking.cancelled) {
				cancelled.push(booking)
			} else if (booking.type === "F2F") f2f.push(booking)
			else if (moment(booking.start).unix() >= moment().unix()) future.push(booking)
			else past.push(booking)
		})

		if (!selectedEmployerDetails?.caps?.capCalls) {
			setCallsRemaining("unlimited")
			setCapsReached(false)
		} else if (totalValidBookings.length >= companyCallCap && companyCallCap !== 0) {
			// companyCallCap is 0 when capCalls is false
			// bookings > total caps
			setCallsRemaining(parseInt(companyCallCap) - totalValidBookings.length)
			setCapsReached(true)
		} else {
			// not capped, bookings < total caps
			setCallsRemaining(parseInt(companyCallCap) - totalValidBookings.length)
			setCapsReached(false)
		}

		setFutureBookings(future)
		setPastBookings(past)
		setCancelledBookings(cancelled)
	}, [bookings, selectedEmployerDetails])

	const bookingsArray = [
		{bookings: futureBookings, tense: "future", title: "Future Bookings"},
		{bookings: pastBookings, tense: "past", title: "Past Bookings"},
		{bookings: cancelledBookings, tense: "cancelled", title: "Cancelled Bookings"}
	]

	return (
		<Card padding={spacing.s10}>
			<Row className='justify-content-between'>
				<Col className='col-12 col-lg-12 col-md-12 col-sm-12'>
					<Alert className='m-b-10' textAlign='center' type={capsReached ? "error" : "primary"}>
						{`${profile?.name} has ${
							capsReached ? `reached the maximum bookings` : `${callsRemaining} bookings remaining`
						}`}
					</Alert>
				</Col>
				{bookingsArray.map((item, index) => {
					let numCols = 0
					bookingsArray.forEach(item => {
						if (item.bookings.length) {
							numCols++
						}
					})

					return (
						item.bookings.length > 0 && (
							<Booking
								bookings={item.bookings}
								tense={item.tense}
								handleModal={_handleModal}
								toggleModal={setOpenModal}
								numCols={numCols}
								key={index}
								title={item.title}
							/>
						)
					)
				})}
				{!(futureBookings.length || pastBookings.length || cancelledBookings.length) && (
					<NoDataDiv>No Bookings found</NoDataDiv>
				)}
			</Row>
			<Modal
				isOpen={openModal}
				handleClose={() => setOpenModal(false)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"20%"}
				textAlign='center'>
				<H4>{`Cancel Booking`}</H4>
				<ModalButtonContainer>
					<H5 className='m-t-0 m-b-10'>Booking was cancelled:</H5>
					<H5 textAlign={"left"} className='m-b-0'>
						Status:
					</H5>
					<RadioDiv className='radio'>
						<RadioLabel>
							<input
								className='radio-input'
								type='radio'
								name='status'
								checked={status === "coach_cancelled"}
								onClick={() => _checkCancelStatus("coach_cancelled")}
							/>
							<span>{cancelStatus["coach_cancelled"]}</span>
						</RadioLabel>
					</RadioDiv>
					<RadioDiv className='radio'>
						<RadioLabel>
							<input
								className='radio-input'
								type='radio'
								name='status'
								checked={status === "user_cancelled"}
								onClick={() => _checkCancelStatus("user_cancelled")}
							/>
							{cancelStatus["user_cancelled"]}
						</RadioLabel>
					</RadioDiv>
					<H5 textAlign={"left"} className='m-b-0'>
						Cancel Reason:
					</H5>
					<RadioDiv className='radio'>
						<RadioLabel>
							<input
								className='radio-input'
								type='radio'
								name='cancelReason'
								checked={cancelReasonState === "inTime"}
								onClick={() => _checkCancelOption("inTime")}
							/>
							<span>{cancelReason["inTime"]}</span>
						</RadioLabel>
					</RadioDiv>
					<RadioDiv className='radio'>
						<RadioLabel>
							<input
								className='radio-input'
								type='radio'
								name='cancelReason'
								checked={cancelReasonState === "within24"}
								onClick={() => _checkCancelOption("within24")}
							/>
							{cancelReason["within24"]}
						</RadioLabel>
					</RadioDiv>
					<RadioDiv className='radio'>
						<RadioLabel>
							<input
								className='radio-input'
								type='radio'
								name='cancelReason'
								checked={cancelReasonState === "userNoShow"}
								onClick={() => _checkCancelOption("userNoShow")}
							/>
							{cancelReason["userNoShow"]}
						</RadioLabel>
					</RadioDiv>
					<HeaderTableContent className='m-t-10'>
						<CustomButton
							title='Cancel'
							size='medium'
							onClick={() => setOpenModal(false)}
							variant='secondary'
						/>
						<CustomButton
							title='Confirm'
							onClick={() => _handleCancelBooking()}
							size='medium'
							variant='primary'
							disabled={!cancelReasonState}
						/>
					</HeaderTableContent>
				</ModalButtonContainer>
			</Modal>
		</Card>
	)
}

export default Bookings
