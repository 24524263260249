import React, {useState, useEffect, useCallback, useRef, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux"
import _ from "lodash"
import moment from "moment"
import {useAuth0} from "@auth0/auth0-react"

// UI
import {Container} from "@uprise/grid"
import {text} from "@uprise/typography"
import {P} from "@uprise/text"
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"
import {primary} from "@uprise/colors"
import {Alerts} from "../Alerts"
import {Modal} from "@uprise/modal"
import {H4} from "@uprise/headings"

// Components
import ComponentWrapper from "components/HOC/ComponentWrapper"
import TableComponent from "components/shared/Table"
import Checkbox from "components/shared/Checkbox"
import DropDown from "components/shared/DropDown"
import TableSortOrder from "components/shared/TableSortOrder"

import EditEmployerDetails from "../EmployerDetail/EditEmployerDetail"
import IconSearch from "assets/images/icons/svg/search.svg"
import {
	fetchEmployerData,
	employerSelector,
	clearEmployerApiState,
	fetchEmployerByCode,
	updateEmployerDetailsApi
} from "../employerSlice"
// Utils
import {getUniqueBy} from "./utils"
// Styles
import {
	HeaderTableContent,
	ColumnContainer,
	SearchInput,
	SearchInputContainer,
	SearchInputfigure,
	SearchInputImage,
	ContactContainer,
	ContactValueContainer,
	ElementLabel,
	ModalButtonContainer,
	Link
} from "./index.style"

const AllEmployers = ({history}) => {
	const [token, setToken] = useState(null)
	const [pageCount, setPageCount] = React.useState(0)
	const [selectedMainContact, setSelectedMainContact] = useState({
		name: "",
		companyName: "",
		positionTitle: "",
		phoneNumber: "",
		emailAddress: "",
		contactFunction: "",
		contactTier: 0
	})
	const [openModal, setOpenModal] = useState(false)
	const [openEditEmployerModal, setEditEmployerOpenModal] = useState(false)
	const [actionButtonClicked, setActionButtonClicked] = useState(false)
	const [employerCodeSearchTermText, setEmployerCodeSearchTermText] = useState("")
	const [companyNameSortingOrder, setCompanyNameSortingOrder] = useState(0)

	const fetchIdRef = useRef(0)
	const dispatch = useDispatch()
	const {getAccessTokenSilently} = useAuth0()
	const {employers, employerDataApiState, updateEmployerState, selectedEmployerDetails} =
		useSelector(employerSelector)

	const [state, setState] = useState({
		employerRowData: [],
		companyNameFilters: [],
		selectedEmployerDetailsForAction: {}
	})

	useEffect(() => {
		// Clear state on unmount
		return () => dispatch(clearEmployerApiState())
	}, [])

	useEffect(() => {
		setPageCount(Math.ceil(employers.total / 20))
	}, [employers])

	const fetchData = useCallback(
		({pageSize, pageIndex}) => {
			// This will get called when the table needs new data
			// You could fetch your data from literally anywhere,
			// even a server. But for this example, we'll just fake it.

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current

			if (fetchId === fetchIdRef.current && token) {
				dispatch(
					fetchEmployerData({
						limit: pageSize,
						skip: pageSize * pageIndex,
						token,
						searchTerm: employerCodeSearchTermText
					})
				)
			}
		},
		[token, employerCodeSearchTermText]
	)

	useEffect(() => {
		async function getToken() {
			const token = await getAccessTokenSilently()
			setToken(token)
		}

		getToken()
	}, [])

	useEffect(() => {
		if (employers.data) {
			const filterArray = getUniqueBy(employers.data, "companyName").map((employer, index) => {
				return {
					id: index,
					label: employer.companyName,
					value: employer.companyName,
					checked: false
				}
			})

			setState({...state, employerRowData: employers.data, companyNameFilters: filterArray})
		}
	}, [employers])

	useEffect(() => {
		if (selectedEmployerDetails && selectedEmployerDetails.data && actionButtonClicked) {
			setEditEmployerOpenModal(!openEditEmployerModal)
		}
	}, [selectedEmployerDetails])

	const _handleActionClick = actionId => {
		if (actionId === 1) {
			dispatch(fetchEmployerByCode({code: state.selectedEmployerDetailsForAction.code, token}))
			setActionButtonClicked(true)
		}
	}

	const _handleEmployerDetailUpdate = data => {
		dispatch(updateEmployerDetailsApi({data, token}))
	}

	const handleSearchEmployerCodeChange = useCallback(
		_.debounce(
			value => {
				setEmployerCodeSearchTermText(value)
			},
			1500,
			{maxWait: 1500}
		),
		[]
	)

	const onSelectRow = (code, data) => {
		let selectedRow = {}
		let selectedData = data.map(item => {
			if (item.code === code) {
				selectedRow = item
				return {...item, selected: !item.selected}
			} else {
				return {...item, selected: false}
			}
		})
		setState({
			...state,
			selectedEmployerDetailsForAction: selectedRow,
			employerRowData: selectedData
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: "Company Name",
				width: "15%",
				accessor: "companyName",
				sortType: "string",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='left' data-testid='companyName'>
							<Checkbox
								checked={cellInfo.row.original.selected}
								testId='checkBox'
								iconYPos={"0"}
								onChange={e => {
									onSelectRow(cellInfo.row.original.code, state.employerRowData)
								}}
							/>
							<Link
								fontSize={text.t4}
								className='m-l-5'
								color={primary.purple}
								onClick={() => {
									dispatch(clearEmployerApiState())
									history.push(`/employer/${cellInfo.row.original.code}`)
								}}
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}>
								{cellInfo.row.original.companyName}
							</Link>
						</ColumnContainer>
					)
				}
			},

			{
				Header: "Code",
				accessor: "code",
				show: true,
				Cell: cellInfo => {
					return (
						<ColumnContainer alignText='center'>
							<Link
								fontSize={text.t4}
								className='m-l-5'
								color={primary.purple}
								onClick={() => {
									dispatch(clearEmployerApiState())
									history.push(`/employer/${cellInfo.row.original.code}`)
								}}
								id={cellInfo.row.original.id}
								row={cellInfo.row.original}>
								{cellInfo.row.original.code}
							</Link>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Number of Employees",
				accessor: "companySize",
				show: true
			},
			{
				Header: "Account Manager",
				accessor: "accountManager",
				show: true,
				style: {overflow: "auto"},
				Cell: cellInfo => (
					<ColumnContainer alignText='center'>
						<P fontSize={text.t4}>{cellInfo.row.original.accountManager}</P>
					</ColumnContainer>
				)
			},
			{
				Header: "Main Contact",
				accessor: "mainContact.label",
				sortType: "string",
				show: true,

				Cell: cellInfo => {
					if (cellInfo.row.original.mainContact.value) {
						return (
							<ColumnContainer alignText='center' data-testid='mainContact'>
								<P
									fontSize={text.t4}
									onClick={() => {
										const selectedContact = cellInfo.row.original.contacts.find(
											contact => contact.emailAddress === cellInfo.row.original.mainContact.value
										)

										setSelectedMainContact({
											name: `${selectedContact.firstName} ${selectedContact.lastName}`,
											companyName: cellInfo.row.original.companyName,
											positionTitle: selectedContact.positionTitle,
											phoneNumber: selectedContact.phoneNumber,
											contactTier: `${selectedContact.contactTier} Tier`,
											contactFunction: selectedContact.contactFunction,
											emailAddress: selectedContact.emailAddress
										})
										setOpenModal(true)
									}}
									id={cellInfo.row.original.mainContact.value}
									row={cellInfo.row.original.mainContact.value}>
									{cellInfo.row.original.mainContact.label}
								</P>
							</ColumnContainer>
						)
					} else {
						return <div>{cellInfo.row.original.mainContact.label}</div>
					}
				}
			},
			{
				Header: "Contract Start",
				accessor: d => {
					if (d?.contractStart) {
						return new Date(moment(d.contractStart, ["DD/MM/YYYY", "YYYY-MM-DD"]).toISOString())
					}
					return null
				},
				sortType: "datetime",
				Cell: ({cell: {value}}) => {
					return (
						<ColumnContainer alignText='center'>
							<P fontSize={text.t4}>{value ? moment(value).format("Do MMM YYYY") : "-"}</P>
						</ColumnContainer>
					)
				}
			},
			{
				Header: "Contract End",
				accessor: d => {
					if (d?.contractEnd) {
						return new Date(moment(d.contractEnd, ["DD/MM/YYYY", "YYYY-MM-DD"]).toISOString())
					}
					return null
				},
				sortType: "datetime",
				Cell: ({cell: {value}}) => {
					return (
						<ColumnContainer alignText='center'>
							<P fontSize={text.t4}>{value ? moment(value).format("Do MMM YYYY") : "-"}</P>
						</ColumnContainer>
					)
				}
			}
			// {
			// 	Header: "Pricing Type",
			// 	accessor: "pricingType",
			// 	show: true,
			// 	Cell: cellInfo => {
			// 		return (
			// 			<ColumnContainer alignText='center'>
			// 				<P fontSize={text.t4}>{cellInfo.row.original.pricingType}</P>
			// 			</ColumnContainer>
			// 		)
			// 	}
			// }
		],
		[token, state.employerRowData]
	)

	return (
		<ComponentWrapper
			menuActiveIndex={2}
			subMenuActiveIndex={9}
			headerTitle='All Employers'
			headerRightAlignContent={
				<HeaderTableContent>
					<SearchInputContainer>
						<SearchInputfigure>
							<SearchInputImage src={IconSearch} />
						</SearchInputfigure>
						<SearchInput
							type={"text"}
							label={"email"}
							data-testid='searchByEmployerCode'
							onChange={e => handleSearchEmployerCodeChange(e.target.value)}
							placeholder={"Search by Employer Code"}
							isRequired={false}
						/>{" "}
						<DropDown
							containerStyle={{
								listStyleType: "none",
								alignSelf: "center",
								marginRight: "12px",
								position: "relative",
								zIndex: "2"
							}}
							label='Actions'
							testId='actions'
							onChange={({value}) => _handleActionClick(value)}
							menuStyle={{position: "absolute"}}
							isLast={true}
							items={[
								{
									label: "Edit",
									value: 1
								}
							]}
						/>
					</SearchInputContainer>
				</HeaderTableContent>
			}>
			<Container>
				{employerDataApiState.isError && employerDataApiState.message && (
					<Alert type='error'>{employerDataApiState.message} </Alert>
				)}
				<Alerts updateEmployerState={updateEmployerState} />
			</Container>

			<Card padding={spacing.s10} backgroundColor='white'>
				<TableComponent
					pageCount={pageCount}
					fetchData={fetchData}
					columns={columns}
					loading={employerDataApiState.isFetching || !employerDataApiState.isSuccess}
					data={state.employerRowData}
				/>
			</Card>

			<Modal
				isOpen={openEditEmployerModal}
				handleClose={() => setEditEmployerOpenModal(false)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"70%"}
				scroll={true}
				textAlign='center'>
				<H4>{`Edit Employer`}</H4>
				<ModalButtonContainer>
					<EditEmployerDetails
						capDetails={selectedEmployerDetails.caps}
						data={selectedEmployerDetails.data}
						onComplete={_handleEmployerDetailUpdate}
						onCancel={() => setEditEmployerOpenModal(false)}
						expiryDate={selectedEmployerDetails.expiryDate}
						employerCode={selectedEmployerDetails.code}
					/>
				</ModalButtonContainer>
			</Modal>

			<Modal
				isOpen={openModal}
				handleClose={() => setOpenModal(false)}
				padding={"24px"}
				backgroundColor={"#ffffff"}
				width={"50%"}
				height={"2000px"}
				scroll={true}
				textAlign='center'
				data-testid='contactDetails'>
				'<H4>{`Contact Details`}</H4>
				<ContactContainer>
					<ElementLabel data-testid='name-contactDetails'>Name</ElementLabel>
					<ContactValueContainer>{selectedMainContact.name}</ContactValueContainer>
				</ContactContainer>
				<ContactContainer>
					<ElementLabel data-testid='companyName-contactDetails'>Company Name</ElementLabel>
					<ContactValueContainer>{selectedMainContact.companyName}</ContactValueContainer>
				</ContactContainer>
				<ContactContainer>
					<ElementLabel data-testid='title-contactDetails'>Title</ElementLabel>
					<ContactValueContainer>{selectedMainContact.positionTitle}</ContactValueContainer>
				</ContactContainer>
				<ContactContainer>
					<ElementLabel data-testid='phoneNumber-contactDetails'>Phone Number</ElementLabel>
					<ContactValueContainer>{selectedMainContact.phoneNumber}</ContactValueContainer>
				</ContactContainer>
				<ContactContainer>
					<ElementLabel data-testid='email-contactDetails'>Email Address</ElementLabel>
					<ContactValueContainer>{selectedMainContact.emailAddress}</ContactValueContainer>
				</ContactContainer>
				<ContactContainer>
					<ElementLabel data-testid='function-contactDetails'>Function</ElementLabel>
					<ContactValueContainer>{selectedMainContact.contactFunction}</ContactValueContainer>
				</ContactContainer>
				<ContactContainer>
					<ElementLabel data-testid='tier-contactDetails'>Tier</ElementLabel>
					<ContactValueContainer>{selectedMainContact.contactTier}</ContactValueContainer>
				</ContactContainer>
			</Modal>
		</ComponentWrapper>
	)
}

export default AllEmployers
