import React from "react"

//uprise
import {Container, Col, Row} from "@uprise/grid"
import {Card} from "@uprise/card"
import {spacing} from "@uprise/spacing"

//components
import NotificationForm from "./NotificationForm"
import NotificationHistory from "./NotificationHistory"

const NotificationPage = ({userEmail}) => {
	return (
		<Card padding={spacing.s10}>
			<Container>
				<Col className='col-12'>
					<Row>
						<NotificationForm userEmail={userEmail} />
					</Row>
				</Col>

				<Col>
					<Row>
						<NotificationHistory userEmail={userEmail} />
					</Row>
				</Col>
			</Container>
		</Card>
	)
}

export default NotificationPage
