import Styled from "styled-components"
import Checkbox from "../Checkbox"
import { P } from '@uprise/text'



export const Container = Styled.div`
 border :  solid 1px #d4cbfb;
 border-radius : 4px;
 position: relative;
`

export const TriggerButtonContainer = Styled.div`
width: 100%;
/* margin: auto; */
`

export const TriggerButton = Styled.button`
  width: 100%;
  max-width :${props => (props.buttonWidth ? props.buttonWidth : "400px")}; 
  padding: 2px 22px 2px 12px;
  border-radius: 10px;
  text-align: left;
  outline: none;
  border: 0;
  background-color: transparent;
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #6d6c79;
  position: relative;
`

export const ArrowIcon = Styled.span`
border: solid #7d60ff;
  border-width: 0 1px 1px 0;
  padding: 3px;
  float: right;
  display: inline-block;
  transform: ${props => (props.isOpen ? "rotate(225deg)" : "rotate(45deg)")};
  transition: transform 0.1s linear;
  position: absolute;
  right: 12px;
  top: 35%;
  `

export const MenuContainer = Styled.div`
margin : 5px 0 0 0;
width: 100%;
position: absolute;
z-index : 999;
background-color: #ffffff;
transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
border-radius: 10px;
max-height: 280px;
box-shadow: 0 2px 4px 0 rgba(219, 221, 227, 0.5);
`  

export const LeftMenuContainer = Styled.div`
     width: 35%;
    float: left;
    box-shadow: inset -1px 0 0 0 #edeafa;
    background-color: #ffffff;
    max-height: 280px;
    height: 100%;
    overflow-y: scroll;
`

export const LeftMenuItemContainer = Styled.ul`
    list-style: none;
    /* padd ing: 10px 11px 10px 0; */
    width: 100%;
    margin: 0;
    padding : 0;
`

export const LeftMenuItems = Styled.li`
    list-style: none;
    width: 100%;
    display: block;
`

export const LeftMenuLink = Styled.a`
    cursor: pointer;
    display : flex;
    width: 100%;
    text-align: left;
    box-shadow: none;
    position : relative;
    float : left;
    padding : 10px 0 10px 8px;
    background-color : ${props => props.highlightedIndex ? "#f8f8ff" : "#ffffff"};
`

export const LeftMenuText = Styled(P)`
    height : 20px;
    line-height : 1;
    
`

export const LeftMenuArrowIcon = Styled.span`
    border: solid #7d60ff;
  border-width: 0 1px 1px 0;
  padding: 3px;
  display: inline-block;
  transform: rotateZ(315deg);
  position: absolute;
  top: 40%;
  right : 12px;

  &&::before{   
      content: "";
  }
`


export const RightMenuContainer = Styled.div`
  width : 65%;
  float: right;
  background-color: #f8f8ff;
  height: 280px;
  overflow-y : scroll;
`

export const RightMenuItems = Styled.div`
    width : 100;
    position: relative;
`

export const RightMenuItemContainer = Styled.div`
    display : flex;
    align-items: center;
    padding : 10px 0 10px 24px;
    
`

export const CheckboxLabel = Styled(P)`
    margin-left : 12px;
    font-size : 15px;
    font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  height: 20px;
`


export const CountSpan = Styled.span`
    border-radius: 500px;
    height: 100%;
    margin-left: 10px;
    line-height: 18px;
    background: #7d60ff;
    color: #fff;
    min-width: 18px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 700;
    vertical-align: middle;
    padding: 0 7px;
`