import React, {useState} from "react"
// import {bool, func, object, string, array} from "prop-types"
import {GlobalContainer, InputContainer, Label, Slider, Input} from "./index.style"

const generateRandomNumber = () => Math.floor(Math.random() * 10000 + 1)

const Toggle = ({
	onChange,
	checked,
	disabled,
	width,
	height,
	translate,
	backgroundColorChecked,
	backgroundColorUnchecked,
	backgroundColorButton,
	name = `toggle-id-${generateRandomNumber()}`,
	value,
	labelRight,
	labelLeft,
	sliderWidth,
	sliderHeight,
	InputRef,
	justifyContent,
	testId
}) => {
	const [status, setStatus] = useState(checked)

	const _handleChange = e => {
		setStatus(!status)
		onChange(!status)
	}
	return (
		<GlobalContainer justifyContent={justifyContent} htmlFor={`${name}-input`}>
			{labelLeft && <Label>{labelLeft}</Label>}
			<InputContainer width={width} height={height}>
				<Input
					data-testid={testId}
					type='checkbox'
					name={name}
					ref={InputRef}
					value={value}
					checked={status}
					disabled={disabled}
					translate={translate}
					backgroundColorUnchecked={backgroundColorUnchecked}
					backgroundColorChecked={backgroundColorChecked}
					id={`${name}-input`}
					onChange={() => _handleChange()}
				/>
				<Slider
					data-testid={testId}
					sliderWidth={sliderWidth}
					sliderHeight={sliderHeight}
					backgroundColorUnchecked={backgroundColorUnchecked}
					backgroundColorButton={backgroundColorButton}
				/>
			</InputContainer>
			{labelRight && <Label>{labelRight}</Label>}
		</GlobalContainer>
	)
}

Toggle.defaultProps = {
	InputRef: null,
	backgroundColorChecked: "#2193F3",
	backgroundColorUnchecked: "#f8f8ff",
	backgroundColorButton: "#fff",
	sliderWidth: 26,
	sliderHeight: 26,
	width: 60,
	height: 34,
	translate: 26
}

export default Toggle
