import styled from "styled-components"
import {primary} from "@uprise/colors"
import {Button} from "@uprise/button"
import {spacing} from "@uprise/spacing"
import {Select, TextInputHorizontal} from "@uprise/form"
import {Note} from "@uprise/text"
import {H2, H3, H4, H5, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import IconRight from "components/Icons/IconRight"

export const HeaderTableContent = styled.div`
	display: flex;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
	box-shadow: 0 4px 10px 0 rgba(219, 221, 227, 0.5);
	background-color: #f8f8ff;
	border-radius: 10px;
`

export const ColumnContainer = styled.label`
	display: flex;
	justify-content: ${props => props.alignText};
`

export const TableColumnContainer = styled.label`
	display: flex;
	justify-content: center;
`

export const HeaderText = styled.label`
	display: flex;
	justify-content: ${props => props.alignText};
`

export const TableFirstColumnText = styled(P)`
	padding: 6px 0 6px 12px;
	margin: 0;
	cursor: pointer;
	font-family: "Proxima Nova";
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
`

export const FilterDropdownContainer = styled.div``

export const FilterDropdownMenu = styled.ul`
	list-style-type: none;
	padding: 0;
`

export const FilterDropdownMenuItem = styled.li`
	display: flex;
	padding: 4px 12px;
	text-align: left;
	list-style-type: none;
	font-family: "Proxima Nova";
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	background-color: #fff;
	color: #9796a0;

	&:last-child {
		border-bottom: 0;
	}
`

export const FilterDropdownMenuItemLabel = styled.label`
	display: flex;
`

export const FilterDropdownHeaderText = styled(P)`
	text-align: left;
	margin: 0 0 0 12px;
	font-family: "Proxima Nova";
	font-size: 12px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #9796a0;
`

export const FilterDropdownMenuItemSpanText = styled(P)`
	font-family: "Proxima Nova";
	margin: 0 0 0 20px;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.85;
	letter-spacing: normal;
	color: #6d6c79;
`

export const SearchInputContainer = styled.div`
	position: relative;
	display: flex;
`

export const SearchInputfigure = styled.figure`
	position: absolute;
	top: 7px;
	margin: 0;
`

export const SearchInputImage = styled.img``

export const SearchInput = styled.input`
	width: 240px;
	height: 32px;
	padding: 0 20px 0 32px;
	align-self: center;
	margin-right: 24px;
	border: 0;
	border-bottom: 1px solid ${primary.purple};
	&:focus {
		outline: none;
	}
`

export const ActionButtonContainer = styled.div`
	cursor: pointer;
`

export const CustomButton = styled(Button)`
	margin: 5px;
`

export const ModalButtonContainer = styled.div``

export const RadioLabel = styled.label`
	margin-bottom: 10px;
	text-align: left;
`

export const RadioDiv = styled.div`
	margin: 10px 0;
	text-align: left;
`

// Custom
export const EmployerEmailColumn = styled(TableFirstColumnText)`
	width: 100%;
`

export const EmployerCodeColumn = styled(TableFirstColumnText)`
	color: #7d60ff;
	width: 100%;
`
export const IconButton = styled.div`
	position: relative;
	background-color: ${primary.purple};
	height: 100%;
	width: ${spacing.s8};
	padding: ${spacing.s3};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: ${spacing.s3};
`

export const DropdownMenu = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
	display: block;
	background-color: #f8f8ff;
`

export const DropdownItem = styled.a`
	font-family: Proxima Nova;
	font-size: 15px;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-decoration: none;

	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
`

export const IconRightSvg = styled(IconRight)`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
`
