import React, {useState, useEffect, Component, Fragment} from "react"

import Styled from "styled-components"
// Colors
import {extended} from "@uprise/colors"
// Components
import {Medium} from "@uprise/text"
import {H5} from "@uprise/headings"
// spacing
import {spacing} from "@uprise/spacing"

const SummaryStyles = Styled.section`
      background-color: #fff;
`

const Content = Styled.div`
	padding: ${spacing.s6};
`

const Ul = Styled.ul`
      margin-bottom: ${spacing.s3};
      list-style-type: none;
      padding-left: 0;
`

const Li = Styled.li`
      display: flex;
	flex-direction: column;
      margin-bottom: ${spacing.s6};
	padding-bottom: ${spacing.s4};
	border: 1px solid ${extended.purple.four};
	border-right: 0;
	border-left: 0;
	border-top: 0;

	&:last-child {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
`

const SpanText = Styled.span`
  margin : 0 5px;
`

const removeHtmlTagsFromString = value => {
	return value.replace(/(<([^>]+)>)/gi, "")
}

export const RTWUSWHO5Result = ({index, surveyTasks, analysis, survey}) => {
	const [surveyQuestions, setSurveyQuestions] = useState({})
	useEffect(() => {
		let questionsArr = {}
		surveyTasks.map(task => {
			let surveyQuestionsArr = survey.questions.filter(question => question.batteryId === task)

			questionsArr = {...questionsArr, [task]: surveyQuestionsArr}
		})
		setSurveyQuestions(questionsArr)
	}, [])

	return (
		<SummaryStyles>
			{analysis.WHO5RTWUS ? (
				<Content>
					<H5 className='m-b-5'>WHO5</H5>
					<Ul className='m-b-5'>
						{analysis.WHO5RTWUS &&
							Object.entries(analysis.WHO5RTWUS)
								.slice(1)
								.map((answer, index) => {
									if (typeof answer[1] === "object" && answer[1]) {
										if (answer[0] === "difficulties" && answer[1] !== undefined) {
											return (
												<Li key={index}>
													<Medium>
														Difficulties at work: {answer[1] ? answer[1].join(",") : "-"}
													</Medium>
												</Li>
											)
										} else if (answer[0] === "humanReadable") {
											return (
												<Li key={index}>
													<Medium weight='bold'>
														Overall : {answer[1] ? answer[1]["WHO5"] : "-"}
													</Medium>
												</Li>
											)
										}
									} else if (answer[1] !== null && answer[1] !== undefined) {
										return (
											<Li key={index}>
												<Medium>
													<H5 className='m-t-0 m-b-3'>
														{" "}
														{surveyQuestions["WHO5RTWUS"] &&
															surveyQuestions["WHO5RTWUS"][index] &&
															removeHtmlTagsFromString(
																surveyQuestions["WHO5RTWUS"][index].prompt
															)}
													</H5>

													{surveyQuestions["WHO5RTWUS"] &&
														surveyQuestions["WHO5RTWUS"][index] &&
														surveyQuestions["WHO5RTWUS"][index].options[answer[1]]}
												</Medium>
											</Li>
										)
									}
								})}
					</Ul>
				</Content>
			) : null}

			{analysis.WHO5 ? (
				<Content>
					<H5 className='m-b-5'>WHO5</H5>
					<Ul className='m-b-5'>
						{analysis.WHO5 &&
							Object.entries(analysis.WHO5).map((answer, index) => {
								if (typeof answer[1] === "object" && answer[1]) {
									if (answer[0] === "difficulties" && answer[1] !== undefined) {
										return (
											<Li key={index}>
												<Medium>
													Difficulties at work: {answer[1] ? answer[1].join(",") : "-"}
												</Medium>
											</Li>
										)
									} else if (answer[0] === "humanReadable") {
										return (
											<Li key={index}>
												<Medium weight='bold'>
													Overall : {answer[1] ? answer[1]["WHO5"] : "-"}
												</Medium>
											</Li>
										)
									}
								} else if (answer[1]) {
									return (
										<Li key={index}>
											<Medium>
												<H5 className='m-t-0 m-b-3'>
													{" "}
													{surveyQuestions["WHO5"] &&
														surveyQuestions["WHO5"][index] &&
														removeHtmlTagsFromString(surveyQuestions["WHO5"][index].prompt)}
												</H5>

												{surveyQuestions["WHO5"] &&
													surveyQuestions["WHO5"][index] &&
													surveyQuestions["WHO5"][index].options[answer[1]]}
											</Medium>
										</Li>
									)
								}
							})}
					</Ul>
				</Content>
			) : null}

			{analysis.RTW_US_ONBOARDING ? (
				<Content>
					<H5 className='m-b-5'>RTW_US_ONBOARDING</H5>
					<Ul className='m-b-5'>
						{analysis.RTW_US_ONBOARDING &&
							Object.entries(analysis.RTW_US_ONBOARDING).map((answer, index) => {
								if (typeof answer[1] === "object" && answer[1] !== null) {
									if (answer[1]["RTW_US_ONBOARDING"]) {
										return (
											<Li key={index}>
												<Medium>Score: {answer[1]["RTW_US_ONBOARDING"]}</Medium>
											</Li>
										)
									} else if ((answer[0] = "difficulties")) {
										return (
											<Li key={index}>
												<Medium>Difficulties at work: {answer[1].join(",")}</Medium>
											</Li>
										)
									}
								} else {
									if (
										surveyQuestions["RTW_US_ONBOARDING"] &&
										surveyQuestions["RTW_US_ONBOARDING"][index] &&
										surveyQuestions["RTW_US_ONBOARDING"][index].type &&
										surveyQuestions["RTW_US_ONBOARDING"][index].type === "multi"
									) {
										return (
											<Li key={index}>
												<Medium>
													<H5 className='m-t-0 m-b-3'>
														{surveyQuestions["RTW_US_ONBOARDING"] &&
															surveyQuestions["RTW_US_ONBOARDING"][index] &&
															surveyQuestions["RTW_US_ONBOARDING"][index].prompt}
													</H5>

													{surveyQuestions["RTW_US_ONBOARDING"] &&
														surveyQuestions["RTW_US_ONBOARDING"][index] &&
														surveyQuestions["RTW_US_ONBOARDING"][index]?.options[answer[1]]}
												</Medium>
											</Li>
										)
									} else if (
										surveyQuestions["RTW_US_ONBOARDING"] &&
										surveyQuestions["RTW_US_ONBOARDING"][index] &&
										surveyQuestions["RTW_US_ONBOARDING"][index].type &&
										surveyQuestions["RTW_US_ONBOARDING"][index].type === "date"
									) {
										return (
											<Li key={index}>
												<Medium>
													<H5 className='m-t-0 m-b-3'>
														{surveyQuestions["RTW_US_ONBOARDING"] &&
															surveyQuestions["RTW_US_ONBOARDING"][index] &&
															surveyQuestions["RTW_US_ONBOARDING"][index].prompt}
													</H5>

													{answer[1]}
												</Medium>
											</Li>
										)
									} else if (
										surveyQuestions["RTW_US_ONBOARDING"] &&
										surveyQuestions["RTW_US_ONBOARDING"][index]
									) {
										return (
											<Li key={index}>
												<Medium>
													<H5 className='m-t-0 m-b-3'>
														{surveyQuestions["RTW_US_ONBOARDING"] &&
															surveyQuestions["RTW_US_ONBOARDING"][index] &&
															surveyQuestions["RTW_US_ONBOARDING"][index].prompt}
													</H5>
													{surveyQuestions["RTW_US_ONBOARDING"] &&
														surveyQuestions["RTW_US_ONBOARDING"][index] &&
														surveyQuestions["RTW_US_ONBOARDING"][index]?.options[answer[1]]}
												</Medium>
											</Li>
										)
									}
								}
							})}
					</Ul>
				</Content>
			) : null}

			{analysis.ECOVAL ? (
				<Content>
					<H5 className='m-b-5'>ECOVAL</H5>
					<Ul className='m-b-5'>
						{analysis.ECOVAL &&
							Object.entries(analysis.ECOVAL)
								.slice(1)
								.map((answer, index) => {
									if (typeof answer[1] === "object" && answer[1] !== null) {
										if (answer[1]["ECOVAL"]) {
											return (
												<Li key={index}>
													<Medium>Score: {answer[1]["ECOVAL"]}</Medium>
												</Li>
											)
										} else if (answer[0] === "difficulties") {
											return (
												<Li key={index}>
													<Medium>Difficulties at work: {answer[1].value}</Medium>
												</Li>
											)
										} else {
											return (
												<Li key={index}>
													<H5 className='m-t-0 m-b-3'>
														{surveyQuestions["ECOVAL"] &&
															surveyQuestions["ECOVAL"][index] &&
															surveyQuestions["ECOVAL"][index].prompt}
													</H5>
													<Medium>
														{surveyQuestions["ECOVAL"] &&
															surveyQuestions["ECOVAL"][index] &&
															surveyQuestions["ECOVAL"][index].options[answer[1].value]
																.label}
														{surveyQuestions["ECOVAL"] &&
														surveyQuestions["ECOVAL"][index] &&
														surveyQuestions["ECOVAL"][index].options[answer[1].value].inputs
															? surveyQuestions["ECOVAL"][index].options[
																	answer[1].value
															  ].inputs.map((input, index) => {
																	return (
																		<Fragment>
																			<SpanText>
																				{answer[1][`input${index}`]}
																			</SpanText>
																			<SpanText>{input}</SpanText>
																		</Fragment>
																	)
															  })
															: ""}
													</Medium>
												</Li>
											)
										}
									} else {
										return (
											<Li key={index}>
												<H5 className='m-t-0 m-b-3'>
													{surveyQuestions["ECOVAL"] &&
														surveyQuestions["ECOVAL"][index] &&
														surveyQuestions["ECOVAL"][index].prompt}
												</H5>
												<Medium>{answer[1]}</Medium>
											</Li>
										)
									}
								})}
					</Ul>
				</Content>
			) : null}

			{analysis.ECOVALUS ? (
				<Content>
					<H5 className='m-b-5'>ECOVAL</H5>
					<Ul className='m-b-5'>
						{analysis.ECOVALUS &&
							Object.entries(analysis.ECOVALUS)
								.slice(1)
								.map((answer, index) => {
									if (typeof answer[1] === "object" && answer[1] !== null) {
										if (answer[1]["ECOVALUS"]) {
											return (
												<Li key={index}>
													<Medium>Score: {answer[1]["ECOVALUS"]}</Medium>
												</Li>
											)
										} else if (answer[0] === "difficulties") {
											return (
												<Li key={index}>
													<Medium>Difficulties at work: {answer[1].value}</Medium>
												</Li>
											)
										} else {
											return (
												<Li key={index}>
													<H5 className='m-t-0 m-b-3'>
														{surveyQuestions["ECOVALUS"] &&
															surveyQuestions["ECOVALUS"][index] &&
															surveyQuestions["ECOVALUS"][index].prompt}
													</H5>
													<Medium>
														{surveyQuestions["ECOVALUS"] &&
															surveyQuestions["ECOVALUS"][index] &&
															surveyQuestions["ECOVALUS"][index].options[answer[1].value]
																.label}
														{surveyQuestions["ECOVALUS"] &&
														surveyQuestions["ECOVALUS"][index] &&
														surveyQuestions["ECOVALUS"][index].options[answer[1].value]
															.inputs
															? surveyQuestions["ECOVALUS"][index].options[
																	answer[1].value
															  ].inputs.map((input, index) => {
																	return (
																		<Fragment>
																			<SpanText>
																				{answer[1][`input${index}`]}
																			</SpanText>
																			<SpanText>{input}</SpanText>
																		</Fragment>
																	)
															  })
															: ""}
													</Medium>
												</Li>
											)
										}
									} else {
										return (
											<Li key={index}>
												<H5 className='m-t-0 m-b-3'>
													{surveyQuestions["ECOVALUS"] &&
														surveyQuestions["ECOVALUS"][index] &&
														surveyQuestions["ECOVALUS"][index].prompt}
												</H5>
												<Medium>{answer[1]}</Medium>
											</Li>
										)
									}
								})}
					</Ul>
				</Content>
			) : null}

			{analysis.RTW_FEEDBACK ? (
				<Content>
					<H5 className='m-b-5'>FEEDBACK</H5>
					<Ul className='m-b-5'>
						{analysis.RTW_FEEDBACK &&
							Object.entries(analysis.RTW_FEEDBACK).map((answer, index) => {
								if (typeof answer[1] === "object" && answer[1] !== null) {
									if (answer[1]["RTW_FEEDBACK"]) {
										return (
											<Li key={index}>
												<Medium>Score: {answer[1]["RTW_FEEDBACK"]}</Medium>
											</Li>
										)
									} else if ((answer[0] = "difficulties")) {
										return (
											<Li key={index}>
												<Medium>Difficulties at work: {answer[1].value}</Medium>
											</Li>
										)
									}
								} else {
									return (
										<Li key={index}>
											<Medium>
												{surveyQuestions["RTW_FEEDBACK"] &&
													surveyQuestions["RTW_FEEDBACK"][index] &&
													surveyQuestions["RTW_FEEDBACK"][index].prompt}
												: {answer[1]}
											</Medium>
										</Li>
									)
								}
							})}
					</Ul>
				</Content>
			) : null}

			{analysis.RTW_US_FEEDBACK ? (
				<Content>
					<H5 className='m-b-5'>FEEDBACK</H5>
					<Ul className='m-b-5'>
						{analysis.RTW_US_FEEDBACK &&
							Object.entries(analysis.RTW_US_FEEDBACK).map((answer, index) => {
								if (typeof answer[1] === "object" && answer[1] !== null) {
									if (answer[1]["RTW_US_FEEDBACK"]) {
										return (
											<Li key={index}>
												<Medium>Score: {answer[1]["RTW_US_FEEDBACK"]}</Medium>
											</Li>
										)
									} else if ((answer[0] = "difficulties")) {
										return (
											<Li key={index}>
												<Medium>Difficulties at work: {answer[1].value}</Medium>
											</Li>
										)
									}
								} else {
									return (
										<Li key={index}>
											<Medium>
												{surveyQuestions["RTW_US_FEEDBACK"] &&
													surveyQuestions["RTW_US_FEEDBACK"][index] &&
													surveyQuestions["RTW_US_FEEDBACK"][index].prompt}
												: {answer[1]}
											</Medium>
										</Li>
									)
								}
							})}
					</Ul>
				</Content>
			) : null}
		</SummaryStyles>
	)
}
