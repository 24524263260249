import React from "react"
import styled from "styled-components"
import moment from "moment"
import {useHistory} from "react-router-dom"

// UI
import {Row, Col} from "@uprise/grid"
import {H5, H4, H6} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {backgrounds, primary, extended} from "@uprise/colors"
import {spacing} from "@uprise/spacing"
import {text} from "@uprise/typography"
// Assets
import CoachesSVG from "assets/images/svg/icon-coaches.svg"
import Clock from "assets/images/png/clock-svg.png"
import CalIcon from "assets/images/png/calendar.png"
// Helpers
import {cancelReason} from "helpers/constants"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	position: relative;
	align-self: center;
`
const Icon = styled.img`
	height: 15px;
	margin-right: 10px;
`

const BorderDiv = styled.div`
	height: 1px;
	width: 100%;
	background-color: #af9efd;
`

const Link = styled.p`
	color: ${primary.purple};
	cursor: pointer;
`

const CancelBtn = styled(Button)`
	width: 120px;
	height: 36px;
	margin-top: ${spacing.s5};
	margin-right: ${spacing.s4};
	border-radius: 10px;
	font-size: 16px;
	border: none;
	color: ${extended.orange.one};
	background-color: ${extended.orange.five};

	&:hover {
		background-color: ${extended.orange.four};
		color: ${extended.orange.two};
	}
`

const CancelReason = styled.span`
	display: inline-block;
	background-color: ${extended.purple.one};
	padding: ${spacing.s2} ${spacing.s4};
	border-radius: 10px;
	color: white;
	font-size: ${text.t3};
	float: right;
`

export const HeaderTableContent = styled.div`
	display: flex;
`

export const CustomButton = styled(Button)`
	margin: 5px;
`

const BookingWrap = styled.div`
	margin-bottom: ${spacing.s4};
	min-height: 280px;
	padding: ${spacing.s6};
	border-radius: 5px;
	background-color: ${backgrounds.fadedPurple};
`

const Booking = ({handleModal, toggleModal, bookings, title, numCols}) => {
	const history = useHistory()

	let cols
	if (numCols === 3) {
		cols = "col-4 col-lg-4 col-md-4 col-sm-4"
	} else if (numCols === 2) {
		cols = "col-6 col-lg-6 col-md-6 col-sm-6"
	} else {
		cols = "col-12 col-lg-12 col-md-12 col-sm-12"
	}

	return (
		<Col className={cols}>
			<H4 className='m-t-0 m-b-3'>{title}</H4>
			{bookings.map((booking, index) => (
				<BookingWrap key={index}>
					<H5 className='m-t-0 m-b-5'>
						Therapy
						{booking.cancelReason && <CancelReason>{cancelReason[booking.cancelReason]}</CancelReason>}
					</H5>
					<BorderDiv />
					<FormElementContainer>
						<FormElementLabel>
							<Icon src={CalIcon} />
							Date, time
						</FormElementLabel>
						<P className='m-t-1' fontSize={text.t5}>
							{moment(booking.start).format("Do MMM YYYY, LT")}
						</P>
					</FormElementContainer>
					<FormElementContainer>
						<FormElementLabel>
							<Icon src={Clock} />
							Duration
						</FormElementLabel>
						<P fontSize={text.t5}>{booking.duration ? `${booking.duration} mins` : "-"} </P>
					</FormElementContainer>
					<FormElementContainer>
						<FormElementLabel>
							<Icon src={CoachesSVG} />
							Coach
						</FormElementLabel>
						<Link
							className='m-t-2'
							onClick={() => {
								history.push(`/coaches/${booking.coachId}`)
							}}>
							{booking.coachName}
						</Link>
					</FormElementContainer>

					{!booking.cancelled && (
						<Row className='justify-content-end'>
							<CancelBtn
								title={"Cancel"}
								onClick={() => {
									toggleModal(true)
									handleModal(booking)
								}}
							/>
						</Row>
					)}
				</BookingWrap>
			))}
		</Col>
	)
}

export default Booking
