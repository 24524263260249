import React, {Fragment, useState, useEffect} from "react"
import styled from "styled-components"
import moment from "moment"
import {Container, Grid, Row, Col} from "@uprise/grid"
import {TextInputHorizontal} from "@uprise/form"
import {H4, H6, H5} from "@uprise/headings"
import {P} from "@uprise/text"
import {Button} from "@uprise/button"
import {useForm, Controller, useFieldArray} from "react-hook-form"
import {useSelector} from "react-redux"
import {backgrounds, extended, primary, semantic} from "@uprise/colors"

import DropDown from "components/shared/DropDown"
import Datepicker from "components/shared/DatePicker"
import AutoComplete from "components/shared/AutoComplete"
import Radio from "components/shared/Radio"
import Toggle from "components/shared/Toggle"

import {settingsSelector} from "../settingsSlice"

const FormElementContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const NumberInputContainer = styled(FormElementContainer)`
	width: unset;
`

const CheckBoxFormElementContainer = styled.div`
	width: 60%;
	display: flex;
	margin: 10px 0 10px auto;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #edeafa;
`

const FormElementLabel = styled(H6)`
	margin: 16px 0 8px 0;

	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormElementPlanLabel = styled(H6)`
	margin: 16px 0 8px 0;
	text-align: left;
	&:after {
		content: "${props => (props.isRequired ? "*" : "")}";
	}
`

const FormInputContainer = styled.div`
	flex-basis: 60%;
	position: relative;
	align-self: center;
`

const FormTextInput = styled(TextInputHorizontal)``

const FormNumberInputFixedWidth = styled(TextInputHorizontal)`
	width: 80px;
	height: 24px;
	padding: 2px 2px 2px 8px;
	border-radius: 4px;
	border: solid 1px #d4cbfb;
`

const FormTextArea = styled.textarea`
	border: none;
	resize: none;
	width: 100%;
`

const ButtonContainer = styled.div`
	display: flex;
`

const PrimaryButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
	outline: none;
`

const SecondaryButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
	border-color: transparent;
	background-color: #f6f4fc;
	outline: none;
`

const NavigationButton = styled(Button)`
	width: 100%;
	min-width: 100px;
	height: 50px;
	margin: 10px 6px 0;
`

const FormSpan = styled.span``

const FormSpanText = styled(P)`
	margin: 0 0 0 10px;
`

const FormSpanLabel = styled.label`
	display: flex;
	padding: 10px;
	padding-left: 0;
`

const FormRightItemContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FormRightContainer = styled.div`
	display: flex;
	flex-basis: 60%;
	justify-content: space-between;
`

const PlanContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: ${props => (props.error ? "1px solid #ff9999" : "1px solid #edeafa")};
`

const FeaturePricingInputContainer = styled.div`
	align-self: center;
`

const MessageStyles = styled.label`
	font-size: 12px;
	color: ${props => {
		if (props.focused) {
			return `${extended.blue.one}`
		} else if (props.validation) {
			return `${semantic.error}`
		} else {
			return `${extended.purple.five}`
		}
	}};
	display: block;
	margin-top: 8px;
	transition: font-size 0.2s;
`

const MessageStylesCaps = styled(MessageStyles)`
	padding-left: 40%;
	text-align: left;
`

const TextInputWrap = styled.div`
	width: 60%;
	margin-left: 40%;
	display: flex;
`

const RadioWrap = styled.div`
	display: flex;
`

const updateModal = ({type, onComplete, onCancel}) => {
	const {eapCenter} = useSelector(settingsSelector)

	const {register, control, handleSubmit, watch, reset, trigger, setError, errors, setValue} = useForm({
		// defaultValues: {}; you can populate the fields by this attribute
		mode: "onSubmit",
		reValidateMode: "onChange"
	})

	useEffect(() => {
		if (eapCenter) {
			setValue("name", eapCenter.name)
			setValue("phoneNumber", eapCenter.eapPhone)
		}
	}, [eapCenter])

	return (
		<Container>
			<form
				onSubmit={handleSubmit(formData => {
					// console.log("formData", formData)
					onComplete({...formData, id: eapCenter.id})
				})}>
				<Row>
					<Col className='col-12 col-lg-12 col-md-12 col-sm-12'>
						<FormElementContainer error={errors.companyName?.type === "required"}>
							<FormElementLabel isRequired={true}>Name</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"name"}
									data-testid='companyName'
									control={control}
									rules={{required: true}}
									as={<FormTextInput type='text' placeholder='Company Name' />}
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>
					<Col className='col-12 col-lg-12 col-md-12 col-sm-12'>
						<FormElementContainer error={errors.companyName?.type === "required"}>
							<FormElementLabel isRequired={true}>Phone Number</FormElementLabel>
							<FormInputContainer>
								<Controller
									name={"phoneNumber"}
									data-testid='phoneNumber'
									control={control}
									rules={{required: true}}
									as={<FormTextInput type='text' placeholder='Phone Number' />}
								/>
							</FormInputContainer>
						</FormElementContainer>
					</Col>
				</Row>

				<Row className='flex-row-reverse'>
					<ButtonContainer>
						<PrimaryButton
							type='submit'
							variant={"primary"}
							title={type === "add" ? "Add" : "Update"}
							data-testid='addButton'
						/>
					</ButtonContainer>
					<ButtonContainer>
						<SecondaryButton
							onClick={e => {
								e.preventDefault()
								onCancel()
							}}
							variant={"secondary"}
							title={"Cancel"}
							data-testid='cancelButton'
						/>
					</ButtonContainer>
				</Row>
			</form>
		</Container>
	)
}

export default updateModal
